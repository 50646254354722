<template>
  <form class="flex w-full flex-col justify-between" v-if="!loading">
    <div class="grow space-y-lgSpace p-lgSpace">
      <div class="grid grid-cols-1 gap-smSpace">
        <div class="text-lg font-bold">{{ title.date }} at {{ title.time }}</div>
        <div class="pb-lgSpace text-sm">{{ room.name }}</div>
        <input-wrapper field="product_ids" :form="form" :label-text="$t('booking.columns.products')">
          <v-multi-select
            v-model="form.product_ids"
            :search-url="productEndpoint"
            :placeholder="$t('booking.placeholders.product')"
            track-by="id"
            label="name"
            search-key="filter[name]"
            :is-multiple="true"
          />
        </input-wrapper>
        <div class="rounded bg-slate-100 text-sm leading-10">
          <v-checkbox
            class="flex items-center space-x-xsSpace p-mdSpace"
            v-model="form.is_editable"
            :label-text="$t('booking.headings.edit_confirmation_email')"
          />
          <div v-if="form.is_editable" class="p-mdSpace pt-0">
            <input-wrapper field="message" :form="form">
              <ckeditor :editor="classicEditor" v-model="form.message" name="content"></ckeditor>
            </input-wrapper>
          </div>
        </div>
        <v-notification v-if="message" :type="messageType" :content="message" />
      </div>
    </div>
    <div
      class="flex items-end justify-end rounded p-lgSpace sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
    >
      <div class="flex space-x-xsSpace">
        <a @click="close" class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')"></a>
        <a @click.prevent="save" class="btn-primary btn-sm" v-text="$t('generic.buttons.notify')"></a>
      </div>
    </div>
  </form>
  <div v-else aria-label="Loading..." role="status" class="flex h-full items-center justify-center space-x-2">
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>
<script>
import Form from "form-backend-validation";
import moment from "moment/moment";

export default {
  inject: ["bus", "config"],
  components: {},
  props: {
    room: {
      type: Object,
      required: true,
    },
    appointment: {
      type: Object,
      required: true,
    },
    currentDate: {
      type: String,
      required: true,
    },
  },
  created() {
    axios
      .get("/api/admin/notifications/show?type=notify_waitlist")
      .then(({ data }) => {
        let content = null;
        if (data.data.hasOwnProperty("content")) {
          content = data.data.content;
        }

        this.initiateForm(content);
      })
      .catch(() => {
        this.initiateForm();
      });
  },
  computed: {
    productEndpoint() {
      return `/api/admin/products?filter[active]=1&filter[public]=1&filter[room]=${this.room.room_id}&filter[duration]=${this.appointment.duration}`;
    },
  },
  data() {
    return {
      loading: false,
      message: null,
      messageType: "success",
      title: {
        date: moment(this.currentDate, this.config.DATETIME_FORMAT).format("dddd, MMMM Do YYYY"),
        time: moment(this.appointment.start_at, "h:mm").format("LT"),
      },
      products: [],
      form: new Form(),
    };
  },
  methods: {
    initiateForm(content = null) {
      const formDetail = {
        product_ids: [],
        message: content,
        is_editable: null,
      };
      this.form = new Form(formDetail, { resetOnSuccess: false });
    },
    close() {
      this.bus.$emit("closePanel", () => {
        if (this.message) {
          this.bus.$emit("tableFilterChange", { params: {} });
        }
      });
    },

    save() {
      this.loading = true;
      this.form
        .post(`/api/admin/appointments/${this.appointment.id}/notify-waitlist`)
        .then((data) => {
          this.loading = false;
          this.message = data.statusText;
          this.messageType = data.count ? "success" : "warning";
          setTimeout(() => {
            this.close();
          }, 3000);
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
