<template>
  <div class="z-50">
    <div class="z-10" :class="{ 'fixed right-4 top-4': !isClosed }">
      <div class="flex items-center justify-between">
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <button
            @click="isClosed = !isClosed"
            type="button"
            class="rounded-md p-2"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <v-icon
              icon="Bars3Icon"
              class="h-8 w-8 text-white2"
              :class="{ hidden: !isClosed, block: isClosed }"
            ></v-icon>
            <v-icon
              icon="XMarkIcon"
              class="h8 w-8 text-orange-900"
              :class="{ hidden: isClosed, block: !isClosed }"
            ></v-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="fixed inset-0 bg-stone-200 md:hidden" :class="{ hidden: isClosed }" v-if="!isClosed">
      <div class="border-b-2 border-orange-900 px-mdSpace py-xlSpace text-orange-900">
        <a
          v-for="item in items"
          :href="item.url"
          class="block rounded-md py-xsSpace text-4xl"
          :class="{
            'font-semibold': item.active,
          }"
          aria-current="page"
        >
          {{ item.label }}
        </a>
      </div>
      <div class="px-mdSpace py-xlSpace text-orange-900">
        <a @click="signOut" class="block rounded-md py-xsSpace text-4xl" aria-current="page">
          {{ $t("user.buttons.sign_out") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    signOutUrl: {
      type: String,
      required: true,
    },
    items: {
      type: Object,
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    async signOut() {
      await axios
        .post(this.signOutUrl, {})
        .then((response) => {
          localStorage.removeItem("customerId");
          window.location = "/client/login";
        })
        .catch(console.error)
        .finally(() => {});
    },
    goHome() {
      if (this.homeUrl) {
        window.location.href = this.homeUrl;
        return;
      }

      window.location.reload();
    },
  },
};
</script>
