<template>
  <div class="space-y-smSpace py-smSpace" v-if="refundMethods.length">
    <div class="grid grid-cols-1 gap-smSpace">
      <input-wrapper
        v-if="!initialMethod"
        field="refund_method"
        :form="modelValue"
        :label-text="$t('booking.columns.refund_method')"
      >
        <v-multi-select
          v-model="modelValue.refund_method"
          @update:modelValue="changeMethod"
          :init-options="refundMethods"
          :placeholder="$t('booking.placeholders.select_refund_method')"
          :is-multiple="false"
        />
      </input-wrapper>
      <input-wrapper field="refund_amount" :form="modelValue" :label-text="$t('booking.columns.payment_value')">
        <input
          type="number"
          :readonly="!canUpdateRefundAmount"
          v-model="modelValue.refund_amount"
          :max="maxAmount"
          :min="0"
          @input="changeAmount"
          :placeholder="$t('booking.placeholders.amount')"
        />
      </input-wrapper>
      <input-wrapper v-if="showCard" field="token" :form="modelValue" label-text="">
        <div v-if="cards.length > 0" class="flex items-center justify-end">
          <a
            class="flex cursor-pointer items-center justify-end font-medium text-gray-500 hover:text-gray-700"
            @click="toggleAddNewCard"
          >
            {{ $t(addNewCard ? "payment.buttons.use_existing_card" : "generic.buttons.add_new") }}
          </a>
        </div>
        <square-payment v-if="addNewCard || cards.length === 0" ref="square" />
        <div v-else class="relative space-y-xsSpace">
          <div v-for="card in cards" class="relative" :key="card.card_id">
            <div
              type="text"
              class="wrapper__input input cursor-pointer !pt-smSpace"
              :class="{ '!border !border-gray-300 bg-tertiary-200': modelValue.token === card.card_id }"
              @click="selectCard(card)"
            >
              {{ `**** **** **** ${card.last_4}` }}
            </div>
            <div class="absolute bottom-0 right-xsSpace top-0 flex items-center gap-xsSpace text-xs">
              <div class="cursor-pointer rounded border border-gray-400 px-xsSpace py-3xsSpace text-blue-600">
                {{ card.exp_month + "/" + card.exp_year }}
              </div>
              <div class="cursor-pointer rounded border border-gray-400 px-xsSpace py-3xsSpace text-blue-600">
                {{ card.card_brand }}
              </div>
            </div>
          </div>
        </div>
      </input-wrapper>
    </div>
    <slot></slot>
  </div>
  <p class="text-sm" v-else>
    {{ $t("generic.words.no_card") }}
  </p>
</template>
<script>
import Payment from "~vue/mixins/Payment";
import axios from "axios";

export default {
  inject: ["bus", "config"],
  mixins: [Payment],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
    maxAmount: {
      type: Number,
      required: true,
    },
    canUpdateRefundAmount: {
      type: Boolean,
      default: false,
    },
    initialMethod: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue", "update:refund-start", "update:refund-end"],
  data() {
    return {
      refundMethods: [],
      cards: [],
      showCard: false,
      addNewCard: false,
    };
  },
  mounted() {
    this.refundMethods = this.getRefundPayments(this.booking, this.config);

    if (this.initialMethod) {
      this.changeMethod(this.initialMethod);
    }
  },
  methods: {
    selectCard(card) {
      this.modelValue.token = card ? card.card_id : null;
      this.addNewCard = false;
    },
    changeMethod(method = null) {
      if (method) {
        this.modelValue.refund_method = method;
      }

      this.$emit("update:modelValue", this.modelValue);

      if (this.modelValue.refund_method === this.config.PAYMENT_CARD) {
        this.showCard = true;
        this.getCards();

        return;
      }

      this.showCard = false;
    },
    changeAmount() {
      this.$emit("update:modelValue", this.modelValue);
      if (this.modelValue.refund_amount > this.maxAmount) {
        this.modelValue.refund_amount = this.maxAmount;
      }
    },
    async refund() {
      this.$emit("update:refund-start");
      this.bus.$emit("refundStart");
      let res = null;
      try {
        const isCard = this.modelValue.refund_method === this.config.PAYMENT_CARD;
        if (this.$refs.square) {
          this.modelValue.token = await this.$refs.square.submit();
        }
        res = await this.modelValue.post(`/api/admin/bookings/${this.booking.id}/refund`);
        if (isCard) {
          this.checkRefund(this.booking.id);
        }
        this.resetForm();
      } finally {
        this.$emit("update:refund-end", res);
        this.bus.$emit("refundEnd", res);
      }
    },
    getCards() {
      axios.get(`/api/admin/square/${this.booking.customer_id}/cards`).then(({ data }) => {
        this.cards = data.data;
        if (!this.cards.length && this.payment.type === this.config.PAYMENT_CARD) {
          this.addNewCard = true;
        }
      });
    },
    resetForm() {
      this.modelValue.refund_method = null;
      this.modelValue.refund_amount = null;
      this.$emit("update:modelValue", this.modelValue);
    },
    toggleAddNewCard() {
      this.addNewCard = !this.addNewCard;
    },
  },
};
</script>
