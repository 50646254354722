<template>
  <div class="relative px-xsSpace">
    <h2 class="pb-mdSpace text-lg font-semibold text-tertiary-700">
      {{ $t("client.headings.confirm_cancellation_heading") }}
    </h2>
    <div class="mb-xlSpace rounded border p-xsSpace">
      <div class="rounded-lg bg-white pb-lgSpace">
        <p class="font-semibold text-orange-900">
          {{ booking.title }} <br />
          {{ booking.variation_title }}
        </p>
        <p class="font-light text-orange-900">
          {{ booking.date }} {{ $t("generic.words.at") }} {{ booking.booking_time }}
        </p>
      </div>
      <div>
        <v-checkbox
          v-model="isConfirmed"
          :label-text="$t('client.headings.confirm_cancellation') + ` (ID-${booking.id})`"
        ></v-checkbox>
        <div v-if="raiseError" class="v-field-error flex rounded-md bg-pink-100 px-xsSpace py-1">
          <v-icon icon="SolidExclamationCircleIcon" classes="w-5 h-5 text-red-400 mr-2xsSpace"></v-icon>
          <small class="block text-sm text-red-500">{{ $t("client.validation.confirm_required") }}</small>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-row-reverse gap-mdSpace">
    <a class="btn-primary btn-sm !bg-orange-900" type="button" @click.prevent="confirm">
      <span>{{ $t("generic.buttons.submit") }}</span>
    </a>
    <a class="btn-secondary btn-sm" @click.prevent="discard"> {{ $t("generic.buttons.discard") }} </a>
  </div>
</template>
<script>
export default {
  props: {
    booking: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isLoading: false,
      isConfirmed: false,
      raiseError: false,
    };
  },
  watch: {
    isConfirmed: {
      handler(newValue) {
        this.raiseError = false;
      },
    },
  },
  methods: {
    discard() {
      this.$emit("closeModal");
    },
    confirm() {
      this.raiseError = false;
      if (!this.isConfirmed) {
        this.raiseError = true;
        return;
      }

      this.$emit("confirm");
    },
  },
};
</script>
