<template>
  <collapsible default-open :open="open">
    <div class="my-smSpace space-y-lgSpace rounded-md bg-white2 bg-opacity-10 px-mdSpace py-lgSpace text-white2">
      <div class="flex items-start justify-between">
        <div class="space-y-2">
          <collapsible-trigger v-if="!alwaysOpen" class="w-full">
            <div class="w-full font-semibold text-white2">{{ label || $t("booking.fields.contact_detail") }}</div>
          </collapsible-trigger>
          <div v-else class="w-full font-semibold text-white2">{{ label || $t("booking.fields.contact_detail") }}</div>
          <v-checkbox
            v-if="hasCopyBtn"
            v-model="isCopy"
            :label-text="$t('booking.labels.same_as_contact_details')"
            dark-theme
          />
        </div>
        <collapsible-trigger v-if="!alwaysOpen">
          <template v-slot="{ collapsed }">
            <transition>
              <v-icon
                icon="ChevronDownIcon"
                class="h-4 w-4 transform cursor-pointer text-white2 transition-transform duration-300 ease-in-out"
                :class="collapsed ? 'rotate-180' : ''"
              />
            </transition>
          </template>
        </collapsible-trigger>
      </div>
      <collapsible-content class="space-y-6">
        <div class="flex flex-col gap-1 md:flex-row">
          <input-wrapper
            class="flex-1 text-white2"
            :field="`${formPath}first_name`"
            :form="form"
            :is-required="true"
            :label-text="$t('booking.fields.name')"
            :dark-theme="true"
          >
            <input
              class="bg-brand bg-opacity-10 placeholder-white2 placeholder-opacity-50"
              type="text"
              v-model="innerDataFirstName"
              :placeholder="$t('customer.placeholders.first_name')"
            />
          </input-wrapper>
          <input-wrapper
            class="flex-1 text-white2"
            :field="`${formPath}last_name`"
            :form="form"
            label-text="&nbsp;"
            :dark-theme="true"
          >
            <input
              class="bg-brand bg-opacity-10 placeholder-white2 placeholder-opacity-50"
              type="text"
              v-model="innerDataLastName"
              :placeholder="$t('customer.placeholders.last_name')"
            />
          </input-wrapper>
        </div>

        <input-wrapper
          class="text-white2"
          :field="`${formPath}email`"
          :form="form"
          :is-required="true"
          :label-text="$t('booking.fields.email')"
          :dark-theme="true"
        >
          <div class="flex flex-col">
            <input
              class="bg-brand bg-opacity-10 placeholder-white2 placeholder-opacity-50"
              type="text"
              v-model="innerDataEmail"
              :placeholder="$t('booking.placeholders.email')"
            />
            <span v-if="!formPath" class="my-xsSpace text-white2 opacity-50">
              {{ $t("booking.fields.email_notification") }}
            </span>
          </div>
        </input-wrapper>

        <input-wrapper
          class="text-white2"
          :field="`${formPath}phone`"
          :form="form"
          :is-required="true"
          :label-text="$t('booking.fields.mobile_phone')"
          :dark-theme="true"
        >
          <div class="flex flex-col">
            <phone-number v-model="innerDataPhone" :placeholder="$t('booking.placeholders.phone')" dark-mode />
          </div>
        </input-wrapper>
      </collapsible-content>
    </div>
  </collapsible>
</template>

<script>
export default {
  name: "ContactDetail",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    formPath: {
      type: String,
      required: false,
      default: "",
    },
    alwaysOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasCopyBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    copied: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["update:model-value", "on-copy"],
  computed: {
    innerDataFirstName: {
      get() {
        return this.modelValue?.first_name;
      },
      set(newValue) {
        this.modelValueSet("first_name", newValue);
      },
    },
    innerDataLastName: {
      get() {
        return this.modelValue?.last_name;
      },
      set(newValue) {
        this.modelValueSet("last_name", newValue);
      },
    },
    innerDataEmail: {
      get() {
        return this.modelValue?.email;
      },
      set(newValue) {
        this.modelValueSet("email", newValue);
      },
    },
    innerDataPhone: {
      get() {
        return this.modelValue?.phone;
      },
      set(newValue) {
        this.modelValueSet("phone", newValue);
      },
    },
    isCopy: {
      get() {
        return this.copied;
      },
      set(newValue) {
        this.open = !newValue;
        this.$emit("on-copy", newValue);
      },
    },
  },
  data() {
    return {
      open: true,
    };
  },
  methods: {
    modelValueSet(key, value) {
      this.$emit("update:model-value", {
        ...this.modelValue,
        [key]: value,
      });
    },
  },
};
</script>

<style scoped>
input::placeholder {
  @apply !text-white2 !opacity-50;
}
</style>
