<template>
  <div class="mb-lgSpace space-y-lgSpace rounded-md px-mdSpace pt-lgSpace text-white2 shadow-sm">
    <h2 class="mb-smSpace text-center text-2xl">
      <span v-if="cart.name">
        {{ $t("booking.headings.welcome_customer_following_appointment").replace(":attribute", cart.name ?? "") }}
      </span>
      <span v-else>
        {{ $t("booking.headings.following_appointment") }}
      </span>
    </h2>
    <table class="w-full">
      <tbody>
        <tr>
          <td class="text-left">{{ $t("venue.headings.information") }} :</td>
          <td class="text-right">{{ appointment.venue_name }}</td>
        </tr>
        <tr>
          <td class="text-left">{{ $t("booking.headings.appointment_date") }} :</td>
          <td class="text-right">{{ appointment.date }}</td>
        </tr>
        <tr>
          <td class="text-left">{{ $t("booking.headings.appointment_time") }} :</td>
          <td class="text-right">{{ appointment.start_at }} - {{ appointment.stop_at }}</td>
        </tr>
        <tr>
          <td class="text-left">{{ $t("booking.headings.appointment_duration") }} :</td>
          <td class="text-right">{{ appointment.duration }}</td>
        </tr>
      </tbody>
    </table>
    <input-wrapper
      class="text-white2"
      field="email"
      :form="form"
      :label-text="$t('booking.fields.email')"
      :dark-theme="true"
    >
      <input class="bg-brand" type="text" v-model="form.email" :placeholder="$t('booking.placeholders.email')" />
    </input-wrapper>
    <input-wrapper
      class="text-white2"
      field="phone"
      :form="form"
      :label-text="$t('booking.fields.phone')"
      :dark-theme="true"
    >
      <phone-number v-model="form.phone" :placeholder="$t('booking.placeholders.phone')" dark-mode />
    </input-wrapper>
    <input-wrapper
      class="text-white2"
      field="name"
      :form="form"
      :label-text="$t('booking.fields.name')"
      :dark-theme="true"
    >
      <input class="bg-brand" type="text" v-model="form.name" :placeholder="$t('booking.placeholders.customer_name')" />
    </input-wrapper>
    <voucher-select v-model="form.vouchers"></voucher-select>
    <product-list
      :category-id="form.category_id"
      v-model="form.product_id"
      :applicable-product-ids="applicableProductIds"
      @update:modelValue="(productId) => scroll(productId, $refs.variantList)"
    />
    <div ref="variantList">
      <variant-list v-if="form.product_id" :product-id="form.product_id" v-model="form.product_variant_id" />
    </div>
    <div class="mb-lgSpace flex justify-center py-lgSpace">
      <button class="btn-secondary !block items-center !bg-brand !text-white2" @click="submit">
        {{ $t("booking.buttons.continue_to_checkout") }}
      </button>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";

export default {
  inject: ["config"],
  name: "ClientWaitListBooking",
  props: {
    cart: {
      type: Object,
      default: () => {
        return {};
      },
    },
    appointment: {
      type: Object,
      default: () => {
        return {};
      },
    },
    applicableProductIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    checkoutUrl: {
      type: String,
      required: true,
    },
    fromWaitList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: new Form(
        {
          email: this.cart.email,
          phone: this.cart.phone ?? "",
          name: this.cart.name ?? "",
          category_id: this.cart.hasOwnProperty("category_id") ? this.cart.category_id : null,
          vouchers: this.cart.hasOwnProperty("vouchers") ? this.cart.vouchers : [],
          quantity: this.cart.hasOwnProperty("quantity") ? this.cart.quantity : 1,
          product_id: this.cart.hasOwnProperty("product_id") ? this.cart.product_id : null,
          product_variant_id: this.cart.hasOwnProperty("product_variant_id") ? this.cart.product_variant_id : null,
          appointment_ids: this.cart.hasOwnProperty("appointment_ids") ? this.cart.appointment_ids : [],
          users: this.cart.hasOwnProperty("users") ? this.cart.users : [],
        },
        { resetOnSuccess: false },
      ),
    };
  },
  methods: {
    submit() {
      this.form.post("/api/booking/cart/start").then(() => {
        this.form.post("/api/booking/cart/availability").then(() => {
          window.location.href = this.checkoutUrl;
        });
      });
    },
  },
};
</script>
