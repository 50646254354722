<template>
  <form class="flex w-full flex-col justify-between" v-show="!loading">
    <side-panel ref="sidePanel" :disable-listeners="true"></side-panel>
    <div class="absolute right-6 top-14 space-x-xsSpace">
      <a @click="clickAddTask(appointment)" class="btn-sm btn-secondary bg-white">
        <span>{{ $t("task.buttons.add") }}</span>
      </a>
    </div>
    <div class="grow space-y-lgSpace p-lgSpace">
      <div class="space-y-lgSpace">
        <div class="grid grid-cols-1 gap-smSpace">
          <div class="flex flex-row items-start space-x-xsSpace">
            <div class="flex-grow">
              <div class="pb-mdSpace text-lg font-bold">{{ title.date }} at {{ title.time }}</div>
              <div class="pb-mdSpace text-sm">{{ room.name }}</div>
            </div>
            <v-toggle
              :value="appointment.is_online"
              :title="appointment.is_online ? 'Online' : 'Offline'"
              @input="onChangeSlotStatus($event, appointment)"
            ></v-toggle>
          </div>

          <product-select
            :form="form"
            v-model:product-id="form.product_id"
            v-model:product-variant-id="form.product_variant_id"
            v-model:payment-value="productAmount"
            :endpoint="productEndpoint"
          />

          <customer-input
            :form="form"
            v-model:customer-id="form.customer_id"
            v-model:first-name="form.first_name"
            v-model:last-name="form.last_name"
            @selectClient="selectClient($event)"
          />
          <input-wrapper field="phone" :form="form" :label-text="$t('booking.columns.phone')">
            <phone-number v-model="form.phone" :placeholder="$t('booking.placeholders.phone')" />
          </input-wrapper>
          <input-wrapper field="email" :form="form" :label-text="$t('booking.columns.email')">
            <input type="text" v-model="form.email" :placeholder="$t('booking.placeholders.email')" />
          </input-wrapper>
          <payment
            class="py-xsSpace"
            ref="payment"
            :form="form"
            :customer-id="form.customer_id"
            :product-id="form.product_id"
            :product-variant-id="form.product_variant_id"
            :amount="productAmount"
            endpoint="/api/admin/bookings/cart"
            :disabled-methods="disabledPaymentMethods"
          />
        </div>
      </div>
    </div>
    <div
      class="flex w-full flex-col items-end justify-end rounded p-lgSpace sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
    >
      <div class="flex space-x-xsSpace">
        <a @click="close" class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')"></a>
        <NotifyWaitlistButton
          :isNotified="appointment.is_notified"
          :action-data="actionData"
          button-classes="float-right"
        ></NotifyWaitlistButton>
        <a @click.prevent="save" class="btn-primary btn-sm" v-text="$t('generic.buttons.continue')"></a>
      </div>
    </div>
  </form>
  <div v-show="loading" aria-label="Loading..." role="status" class="flex h-full items-center justify-center space-x-2">
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>
<script>
import Form from "form-backend-validation";
import moment from "moment/moment";
import ProductSelect from "~vue/components/bookings/ProductSelect.vue";
import axios from "axios";
import NotifyWaitlistButton from "@/js/vue/components/bookings/NotifyWaitlistButton.vue";
import { mapState } from "vuex";

export default {
  inject: ["config", "bus"],
  components: { NotifyWaitlistButton, ProductSelect },
  props: {
    id: {
      type: String,
    },
    useDuration: {
      type: Boolean,
      default: true,
    },
    room: {
      type: Object,
      required: true,
    },
    appointment: {
      type: Object,
      required: true,
    },
    currentDate: {
      type: String,
      required: true,
    },
    currentUser: {
      type: Object,
    },
  },
  computed: {
    removeAppointmentEndpoint() {
      return `/api/admin/appointments/${this.appointment.id}`;
    },
    productEndpoint() {
      if (!this.useDuration) {
        return `/api/admin/products?filter[active]=1&filter[room]=${this.room.room_id}`;
      }
      return `/api/admin/products?filter[active]=1&filter[room]=${this.room.room_id}&filter[duration]=${this.appointment.duration}`;
    },
    actionData() {
      return {
        room: this.room,
        appointment: this.appointment,
        currentDate: this.currentDate,
      };
    },
    isAdminOrManager() {
      return (this.currentUser?.roles || []).some((r) => r.name === "admin" || r.name === "manager");
    },
    disabledPaymentMethods() {
      if (
        !this.isAdminOrManager &&
        moment(this.currentDate, this.config.DATETIME_FORMAT).isBefore(moment().add(1, "days"))
      ) {
        return [this.config.PAYMENT_LATER];
      }

      return [];
    },

    ...mapState({
      variants: (state) => state.product.productVariants,
    }),
  },
  data() {
    return {
      loading: false,
      title: {
        date: moment(this.currentDate, this.config.DATETIME_FORMAT).format("dddd, MMMM Do YYYY"),
        time: moment(this.appointment.start_at, "h:mm").format("LT"),
      },
      form: new Form(
        {
          id: this.id,
          customer_id: null,
          first_name: null,
          last_name: null,
          phone: null,
          email: null,
          address: null,
          appointment_id: this.appointment.id,
          product_id: null,
          product_variant_id: null,
          use_product_duration: !this.useDuration,
          payment: {
            type: null,
            amount: null,
          },
        },
        { resetOnSuccess: false },
      ),
      productAmount: 0,
    };
  },
  mounted() {
    this.$store.dispatch("bookingCreate/setAppointment", this.appointment);
    this.$store.dispatch("bookingCreate/setRoom", this.room);
    this.$store.dispatch("bookingCreate/setCurrentDate", this.currentDate);
    this.$store.dispatch("bookingCreate/setCurrentUser", this.currentUser);

    if (this.id) {
      axios.get("/api/admin/bookings/cart/" + this.id).then(({ data }) => {
        this.form.populate(data);
        this.productAmount = data.payment.amount;
      });
    }

    this.bus.$on("closePanelInPanel", () => {
      this.$refs.sidePanel && this.$refs.sidePanel.close();
    });
  },
  beforeUnmount() {
    this.bus.$off("closePanelInPanel");
  },
  methods: {
    getFieldValue(field, children = null, defaultValue = null) {
      if (children && this.createForm.hasOwnProperty(children) && this.createForm[children].hasOwnProperty(field)) {
        return this.createForm[children][field];
      }

      if (this.createForm.hasOwnProperty(field)) {
        return this.createForm[field];
      }

      return defaultValue;
    },
    close() {
      this.bus.$emit("closePanel");
    },
    selectClient(client) {
      if (client) {
        this.form.customer_id = client.id;
        this.form.first_name = client.first_name;
        this.form.last_name = client.last_name;
        this.form.phone = client.phone;
        this.form.email = client.email;
        this.form.address = client.address;
        return;
      }

      this.form.customer_id = null;
      this.form.first_name = null;
      this.form.last_name = null;
      this.form.phone = null;
      this.form.email = null;
      this.form.address = null;
    },
    async save() {
      this.loading = true;
      try {
        const data = await this.$refs.payment.submit();
        this.moveToConfirmPage(data);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    moveToConfirmPage(data) {
      this.bus.$emit("openSidePanel", {
        componentName: "BookingConfirm",
        title: "",
        componentData: {
          id: data.id,
        },
        closeCallback: "",
      });
    },

    onChangeSlotStatus(value, slot) {
      if (slot.is_shift_rescheduled) {
        return;
      }
      const params = {
        appointment_id: slot.id,
        online: value,
      };
      axios.post(`/api/admin/bookings/rooms/toggle-status`, params).then(() => {
        this.bus.$emit("bookingsChange", {
          columnIndex: this.room.columnIndex,
          toggleStatus: params,
        });
      });

      if (value) {
        return;
      }

      this.close();
    },

    clickAddTask(appointment) {
      const productVariantName = this.variants.find((variant) => variant.id === this.form.product_variant_id)?.name;
      this.$refs.sidePanel.open(
        "TaskEdit",
        {
          taskable: {
            class: this.config.taskTypes.appointment,
            id: appointment.id,
          },
          inPanel: true,
        },
        `${this.$t("task.labels.add_for")} ${(this.form.first_name + " " + this.form.last_name).trim() || ""} - ${
          productVariantName || ""
        }`,
        () => {},
      );
    },
  },
};
</script>
