<template>
  <div class="mb-lgSpace space-y-xsSpace">
    <template v-for="formData in cart.product.forms">
      <div
        v-bind:key="formData.id"
        class="v-checkout-form grid-x grid-margin-x grid-margin-y callout curved-box-shadow"
        v-if="formData.fields"
      >
        <div class="mb-4 mt-lgSpace flex justify-between font-semibold text-white2" v-if="formData.title">
          <span class="text-lg"> Form : {{ formData.title }}</span>
        </div>
        <template v-for="field in formData.fields" :key="field.id">
          <input-wrapper
            class="mb-2 pt-mdSpace text-white2"
            :class="'input-wrapper input-wrapper--' + field.type"
            :field="fieldName(field, formData.id)"
            :form="form"
            :label-text="labelText(field)"
            :is-required="field.required"
            :highlight-error-label="false"
          >
            <v-field
              class="v-field-custom-booking v-field-customer"
              v-model="form[formName(formData.id)][fieldName(field)]"
              :name="fieldName(field, formData.id)"
              :type="field.type"
              :options="field.options"
              :placeholder="field.placeholder"
              :field="field"
              :editable="true"
            ></v-field>
          </input-wrapper>
        </template>
      </div>
    </template>
    <div class="pt-mdSpace font-semibold text-white2">
      <span class="text-lg" v-if="cart.total > 0">{{ $t("booking.headings.payment_method") }}</span>
      <div class="mt-smSpace">
        <square-payment ref="square" v-if="cart.total > 0" />
        <div class="mb-2xlSpace flex justify-center pt-6">
          <button
            class="btn-secondary !block w-full items-center !bg-white !text-brand"
            @click="submit"
            :disabled="loading"
          >
            <div
              v-if="loading"
              aria-label="Loading..."
              role="status"
              class="flex items-center justify-center space-x-2"
            >
              <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
              <span>
                {{ $t("generic.words.processing") }}
              </span>
            </div>
            <span v-else-if="cart.total > 0">{{ $t("booking.buttons.pay_now") }}</span>
            <span v-else>{{ $t("booking.buttons.book_now") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import VField from "~vendor/form-builder/resources/js/components/VField.vue";

export default {
  name: "Checkout",
  components: { VField },
  inject: ["notificationService", "config", "bus"],
  props: {
    initCart: {
      required: true,
    },
  },
  computed: {
    hasProductForms() {
      return this.cart.product?.forms?.length;
    },
    isBelongsToVoucherOnlyRoom() {
      return this.cart.appointment.is_belongs_to_gift_voucher_only_room;
    },
  },
  data() {
    return {
      loading: false,
      form: new Form(),
      cart: this.initCart,
    };
  },
  created() {
    this.bus.$on("vouchersUpdated", async () => {
      const { data } = await axios.get("/api/booking/cart");
      this.cart = data;
    });

    const formDetail = {
      token: "",
    };
    if (this.hasProductForms) {
      this.cart.product.forms.forEach((formData) => {
        const data = formData.fields;
        formDetail[`form${formData.id}`] = {};
        data.forEach((o) => {
          formDetail[`form${formData.id}`][`${o.name}`] = o.type === "check-group" ? [] : "";
        });
      });
    }
    this.form = new Form(formDetail, { resetOnSuccess: false });
  },
  methods: {
    formName(formId) {
      return `form${formId}`;
    },
    fieldName(field, formId) {
      if (formId) {
        return `form${formId}.${field.name}`;
      }
      return `${field.name}`;
    },
    labelText(field) {
      return field.type === "paragraph" ? "" : field.label;
    },
    async submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      try {
        if (this.cart.total > 0) {
          this.form.token = await this.$refs.square.submit();
        }

        const order = await this.form.post("/api/booking/checkout");
        window.location.href = `/booking/${order.id}/confirmed`;
      } catch (error) {
        const { response } = error;

        let err = "Invalid";
        if (error.hasOwnProperty("message")) {
          err = error.message;
        }
        if (response && response.hasOwnProperty("data") && response.data.hasOwnProperty("message")) {
          err = response.data.message;
        }

        this.notificationService.notify(err, "error");
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
input::placeholder {
  @apply !text-white2 opacity-60;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}
</style>
