<template>
  <div class="flex w-full flex-col justify-between">
    <div class="grow space-y-lgSpace p-smSpace xl:p-lgSpace">
      <form class="grid grid-cols-1 gap-smSpace">
        <div class="text-xl font-bold">{{ form.id ? "Edit Note" : "Add Note" }}</div>
        <input-wrapper field="description" :form="form" :label-text="$t('product.fields.description')">
          <v-textarea v-model="form.description" :placeholder="$t('product.fields.description')"></v-textarea>
        </input-wrapper>
      </form>
      <v-footer :save="save">
        <a @click.prevent="close" class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')"></a>
      </v-footer>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import axios from "axios";

export default {
  name: "BookingNoteDetail",
  inject: ["notificationService", "bus"],
  components: {},
  props: {
    bookingNote: {
      type: Object,
      default: () => {
        return {};
      },
    },
    timeSlots: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      form: new Form(
        {
          id: this.getFieldValue("id"),
          description: this.getFieldValue("description"),
        },
        { resetOnSuccess: false },
      ),
    };
  },

  methods: {
    close() {
      this.bus.$emit("closePanel");
    },
    getMinutes(s) {
      const p = s.split(":").map(Number);
      return p[0] * 60 + p[1];
    },
    getFieldValue(field, defaultValue = null) {
      if (this.bookingNote.hasOwnProperty(field)) {
        return this.bookingNote[field];
      }

      return defaultValue;
    },
    save() {
      const { id, room_id, venue_id } = this.bookingNote;
      axios
        .patch(`/api/admin/rooms/${room_id}/booking-notes/${id}`, {
          venue_id,
          description: this.form.description,
        })
        .then(({ data }) => {
          this.notificationService.notify(data.statusText);
          this.bus.$emit("tableFilterChange", { params: {} });
          this.close();
        });
    },
  },
};
</script>
