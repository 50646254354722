class NotificationService {
  constructor(bus) {
    this.bus = bus;
  }

  /**
   * @param {String} message
   * @param {String} type success|error|info|notice
   */
  notify(message, type = "success") {
    this.bus.$emit("showNotification", {
      content: message,
      type: type,
    });
  }

  /**
   * @param {String} message
   * @param {String} type success|error|info|notice
   */
  notifyPanel(message, type = "success") {
    this.bus.$emit("showNotificationPanel", {
      content: message,
      type: type,
    });
  }
}

export default NotificationService;
