<template>
  <component :is="icon" :class="classes"></component>
</template>

<script>
import {
  EyeIcon,
  AdjustmentsHorizontalIcon,
  ArrowDownCircleIcon,
  ArrowLeftOnRectangleIcon,
  ArrowLongRightIcon,
  ArrowPathIcon,
  ArrowsPointingOutIcon,
  ArrowTrendingUpIcon,
  Bars3Icon,
  BellAlertIcon,
  BellIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  DocumentCheckIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  EllipsisHorizontalIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  GiftIcon,
  HomeIcon,
  InformationCircleIcon,
  LinkIcon,
  ListBulletIcon,
  MagnifyingGlassIcon,
  MagnifyingGlassMinusIcon,
  MapIcon,
  MapPinIcon,
  MinusCircleIcon,
  MinusIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
  PhoneIcon,
  PlusCircleIcon,
  PlusIcon,
  PuzzlePieceIcon,
  QrCodeIcon,
  RectangleStackIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
  Square2StackIcon,
  StarIcon,
  TagIcon,
  TicketIcon,
  TrashIcon,
  UserGroupIcon,
  UserIcon,
  XMarkIcon,
  CalendarDaysIcon,
  ArrowsUpDownIcon,
  XCircleIcon,
  PencilIcon,
  WrenchScrewdriverIcon,
  ArrowTopRightOnSquareIcon,
  ChatBubbleLeftIcon,
} from "@heroicons/vue/24/outline";
import { ExclamationCircleIcon as SolidExclamationCircleIcon } from "@heroicons/vue/24/solid";
export default {
  name: "VIcon",
  components: {
    ChatBubbleLeftIcon,
    EyeIcon,
    AdjustmentsHorizontalIcon,
    ArrowDownCircleIcon,
    ArrowLeftOnRectangleIcon,
    ArrowLongRightIcon,
    ArrowPathIcon,
    ArrowTrendingUpIcon,
    ArrowsPointingOutIcon,
    Bars3Icon,
    BellAlertIcon,
    BellIcon,
    CheckCircleIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    ClipboardDocumentListIcon,
    ClockIcon,
    Cog6ToothIcon,
    CreditCardIcon,
    CurrencyDollarIcon,
    DocumentCheckIcon,
    DocumentMagnifyingGlassIcon,
    DocumentTextIcon,
    EllipsisHorizontalIcon,
    EnvelopeIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    GiftIcon,
    HomeIcon,
    InformationCircleIcon,
    LinkIcon,
    ListBulletIcon,
    MagnifyingGlassIcon,
    MagnifyingGlassMinusIcon,
    MapIcon,
    MapPinIcon,
    MinusCircleIcon,
    MinusIcon,
    PaperAirplaneIcon,
    PencilSquareIcon,
    PhoneIcon,
    PlusCircleIcon,
    PlusIcon,
    PuzzlePieceIcon,
    QrCodeIcon,
    RectangleStackIcon,
    Squares2X2Icon,
    SquaresPlusIcon,
    Square2StackIcon,
    StarIcon,
    TagIcon,
    TicketIcon,
    TrashIcon,
    UserGroupIcon,
    UserIcon,
    XMarkIcon,
    CalendarDaysIcon,
    ArrowsUpDownIcon,
    XCircleIcon,
    PencilIcon,
    SolidExclamationCircleIcon,
    WrenchScrewdriverIcon,
    ArrowTopRightOnSquareIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      required: false,
      default: "w-5 h-5",
    },
  },
};
</script>
