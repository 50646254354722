<template>
  <div v-if="!editing" class="flex flex-row items-center gap-smSpace" @click.prevent.stop="toggleEditing">
    <slot :value="innerValue">
      <span class="flex-none text-sm text-tertiary-600">
        {{ innerValue }}
      </span>
    </slot>
  </div>
  <div v-else class="flex space-x-xsSpace">
    <input type="text" ref="input" v-model="innerValue" class="p-xsSpace" />
    <div class="flex space-x-2xsSpace">
      <button class="btn-primary btn-icon btn-sm" @click.stop.prevent="update">
        <v-icon v-if="saving" icon="ArrowPathIcon" class="animate-spin" />
        <v-icon v-else icon="CheckIcon"></v-icon>
      </button>
      <button class="btn-secondary btn-icon btn-sm" @click.stop.prevent="cancel">
        <v-icon icon="XMarkIcon"></v-icon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Editable",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editing: false,
      saving: false,
      innerValue: this.modelValue,
    };
  },
  watch: {
    editing(newValue) {
      this.$emit("editing", newValue);
    },
  },
  methods: {
    toggleEditing() {
      if (this.disabled) return;
      if (this.editing) return;

      this.editing = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
        this.$refs.input.setSelectionRange(0, this.innerValue.length);
      });
    },
    update() {
      this.$emit("update:model-value", this.innerValue);
      this.editing = false;
    },
    cancel() {
      this.innerValue = this.modelValue;
      this.editing = false;
    },
  },
};
</script>
