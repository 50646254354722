<template>
  <div class="max-w-xl space-y-lgSpace pt-xlSpace">
    <form class="grid grid-cols-1 gap-smSpace">
      <div>
        <h3 class="text-base font-semibold leading-6 text-gray-900">{{ $t("integration.headings.detail") }}</h3>
        <p class="mt-xsSpace text-sm text-gray-500">{{ $t("integration.headings.description") }}</p>
      </div>
      <input-wrapper field="name" :form="form" :label-text="$t('integration.columns.name')">
        <input type="text" v-model="form.name" :placeholder="$t('integration.placeholders.name')" />
      </input-wrapper>
      <input-wrapper field="description" :form="form" :label-text="$t('integration.columns.description')">
        <textarea v-model="form.description" :placeholder="$t('integration.placeholders.description')"></textarea>
      </input-wrapper>
      <input-wrapper field="service" :form="form" :label-text="$t('integration.columns.service')">
        <v-multi-select
          v-model="form.service"
          :init-options="services"
          :placeholder="$t('integration.placeholders.service')"
          :is-multiple="false"
        />
      </input-wrapper>
      <input-wrapper field="scopes" :form="form" :label-text="$t('integration.columns.scopes')">
        <textarea v-model="form.scopes" :placeholder="$t('integration.placeholders.scopes')"></textarea>
      </input-wrapper>
      <input-wrapper field="token_expires_at" :form="form" :label-text="$t('integration.columns.token_expires_at')">
        {{ form.token_expires_at }}
      </input-wrapper>
      <input-wrapper field="status" :form="form" :label-text="$t('integration.columns.status')">
        {{ form.status }}
      </input-wrapper>
    </form>
    <v-footer :list-url="listUrl" :save="save"></v-footer>
  </div>
</template>

<script>
import Form from "form-backend-validation";

export default {
  name: "IntegrationEdit",
  inject: ["notificationService"],
  components: {},
  props: {
    integration: {
      type: Object,
      default: () => {
        return {};
      },
    },
    services: {
      type: Array,
      required: true,
    },
    listUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      form: new Form(
        {
          id: this.getFieldValue("id"),
          name: this.getFieldValue("name"),
          description: this.getFieldValue("description"),
          service: this.getFieldValue("service"),
          scopes: this.getFieldValue("scopes") || [],
          // active: Boolean(Number(this.getFieldValue("active"))),
        },
        { resetOnSuccess: false },
      ),
    };
  },

  methods: {
    getFieldValue(field, defaultValue = null) {
      if (this.integration.hasOwnProperty(field)) {
        return this.integration[field];
      }

      return defaultValue;
    },
    save() {
      if (this.integration.hasOwnProperty("id")) {
        this.form
          .patch(`/api/admin/integrations/${this.integration.id}`)
          .then(({}) => {
            window.location = this.listUrl;
          })
          .catch(({ response }) => {
            this.notificationService.notify(response.data.message, "error");
          });

        return;
      }

      this.form
        .post(`/api/admin/integrations`)
        .then(({ data }) => {
          window.location = this.listUrl;
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
  },
};
</script>
