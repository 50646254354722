<template>
  <textarea
    class="form-textarea mt-1 block w-full rounded border-tertiary-300 text-sm shadow-sm placeholder:text-tertiary-400 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
    :rows="rows"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    :placeholder="placeholder"
  >
  </textarea>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
    },
    rows: {
      type: Number,
      default: 4,
    },
    placeholder: {
      type: String,
    },
  },
};
</script>
