<template>
  <div class="mx-auto mb-lgSpace space-y-lgSpace rounded-md px-mdSpace pt-lgSpace text-white2 sm:w-[560px] sm:px-0">
    <input-wrapper class="text-white2" field="venue_id" :form="form" :label-text="$t('booking.fields.venue')">
      <select v-model="form.venue_id" class="bg-brand">
        <option v-for="venue in venues" :value="venue.id">{{ venue.name }}</option>
      </select>
    </input-wrapper>
    <p class="py-smSpace text-sm text-white2" v-if="selectedVenue" v-text="selectedVenue.location" />
    <div class="mt-mdSpace flex justify-center">
      <button class="btn-secondary !block items-center !bg-brand !text-white2" @click="submit" v-if="!loading">
        {{ $t("generic.buttons.continue") }}
      </button>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";

export default {
  name: "VenueSelect",
  props: {
    venues: {
      required: true,
    },
    submitUrl: {
      type: String,
      default: `/api/booking/cart/start`,
    },
    nextUrl: {
      type: String,
      default: `/booking`,
    },
  },
  data() {
    return {
      form: new Form(
        {
          venue_id: null,
        },
        { resetOnSuccess: false },
      ),
      selectedVenue: null,
      loading: false,
    };
  },
  watch: {
    "form.venue_id": {
      handler(newValue, oldValue) {
        this.selectedVenue = this.venues.find((o) => o.id === newValue);
      },
      deep: true,
    },
  },
  mounted() {
    this.form.venue_id = this.venues.length ? this.venues[0].id : null;
  },

  methods: {
    submit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.form
        .post(this.submitUrl)
        .then(() => {
          window.location.href = this.nextUrl;
        })
        .finally((e) => {
          this.loading = false;
        });
    },
  },
};
</script>
