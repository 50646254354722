class SessionStorageService {
  /**
   * @param {Object} data
   */
  setBookingInfoData(data) {
    try {
      sessionStorage.setItem("bookingInfo", JSON.stringify(data));
    } catch (error) {
      console.error("Error setting bookingInfoData in sessionStorage:", error);
    }
  }

  getBookingInfoData() {
    try {
      const serializedData = sessionStorage.getItem("bookingInfo");
      return serializedData ? JSON.parse(serializedData) : null;
    } catch (error) {
      console.error("Error getting bookingInfoData from sessionStorage:", error);
    }
  }

  removeBookingInfoData() {
    sessionStorage.removeItem("bookingInfo");
  }
}

export default SessionStorageService;
