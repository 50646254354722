<template>
  <div class="flex-rows mb-xsSpace flex justify-between">
    <div class="rounded py-xsSpace text-sm">
      <ul>
        <li>
          {{ $t("booking.headings.booking_total") }}
          <strong class="text-green-600">{{ summary.total }}</strong>
        </li>
        <li>
          {{ $t("booking.headings.possible_revenue") }}: Min
          <strong class="text-green-600">{{ toAUD(summary.min) }}</strong> / Max
          <strong class="text-orange-600">{{ toAUD(summary.max) }}</strong>
        </li>
      </ul>
    </div>
    <div class="z-40 flex w-60 items-center justify-end">
      <div>
        <v-filter entity="room-previews" class="flex space-x-xsSpace" />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  inject: ["bus"],
  components: {},
  props: {
    room: {
      type: Object,
      default: () => {
        return {};
      },
    },
    weekdays: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      day: null,
      summary: {
        total: 0,
        min: 0,
        max: 0,
      },
    };
  },
  created() {
    this.bus.$on("bookingSummary", (summary) => {
      this.summary = summary;
    });
  },
  watch: {
    day: {
      handler(newValue) {
        this.bus.$emit("tableFilterChange", {
          params: {
            "filter[day]": newValue,
            "filter[venue]": this.room.venue_id,
            "filter[rooms]": this.room.id,
          },
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.day = moment().format("dddd").toLowerCase();
  },
};
</script>
