<template>
  <div class="space-y-smSpace">
    <div
      v-if="currentMedia"
      class="flex items-center justify-center rounded bg-gray-100 ring-1 ring-slate-200 sm:p-mdSpace"
    >
      <div class="relative">
        <img
          :src="currentMedia.original_url"
          alt=""
          class="pointer-events-none h-72 object-cover object-cover group-hover:opacity-75 sm:h-96"
        />
        <a
          class="btn-tertiary btn-sm absolute right-1 top-1 cursor-pointer rounded bg-white text-sm text-tertiary-400 hover:text-tertiary-900"
          @click.prevent="currentMedia = null"
        >
          {{ $t("generic.buttons.close") }}
        </a>
      </div>
    </div>
    <ul
      role="list"
      class="grid grid-cols-4 gap-4 rounded p-mdSpace ring-1 ring-slate-200 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-6"
      v-if="media.length"
    >
      <li v-for="(file, index) in media" :key="file.id" class="relative">
        <div
          class="group block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
        >
          <img
            :src="file.original_url"
            alt=""
            class="pointer-events-none h-20 w-40 object-cover object-cover group-hover:opacity-75 sm:w-60"
          />
          <button type="button" class="absolute inset-0 focus:outline-none" @click="show(file)">
            <span class="sr-only">View details for {{ file.custom_properties.original_filename }}</span>
          </button>
          <a
            class="absolute right-1 top-1 cursor-pointer text-tertiary-400 hover:text-tertiary-900"
            v-if="allowDeleting"
            @click.prevent="fireDeletedEvent(file, index)"
          >
            <v-icon icon="TrashIcon" />
          </a>
          <v-icon class="absolute left-1 top-1 text-yellow-400" v-if="file.is_feature" icon="StarIcon" />
          <v-icon
            class="absolute left-1 top-1 cursor-pointer text-tertiary-400 hover:text-tertiary-900"
            v-else
            icon="StarIcon"
            @click="setFeature(file.id)"
          />
        </div>
        <p class="pointer-events-none mt-xsSpace block truncate text-sm font-medium text-gray-900">
          {{ file.custom_properties.original_filename }}
        </p>
        <p class="pointer-events-none block text-sm font-medium text-gray-500">
          {{ formatFileSize(file.size) }}
        </p>
      </li>
    </ul>
    <ul v-else>
      <p class="text-xs font-medium text-red-500">No media uploaded...</p>
    </ul>
    <v-upload
      v-if="allowUploading"
      :model="model"
      :model-class="modelClass"
      :upload-endpoint="uploadEndpoint"
      display-style="list"
      :media="media"
      :csrf="csrf"
      :new-file-uploaded="fireUploadedEvent"
    />
  </div>
</template>

<script>
import bytes from "bytes";
import VDefault from "./VDefault.vue";
import VMediaImage from "./VMediaImage.vue";
import VUpload from "./VUpload.vue";
import { startsWith } from "lodash";
export default {
  components: {
    VMediaImage,
    VDefault,
    VUpload,
  },
  props: {
    media: {
      type: Array,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    modelClass: {
      type: String,
      required: true,
    },
    uploadEndpoint: {
      type: String,
      default: "/api/admin/media/upload",
    },
    allowUploading: {
      type: Boolean,
      default: true,
    },
    allowDeleting: {
      type: Boolean,
      default: true,
    },
    allowEditing: {
      type: Boolean,
      default: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    allowSetFeature: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      csrf: this.$root.csrf_token,
      currentMedia: null,
    };
  },
  methods: {
    show(file) {
      this.currentMedia = file;
    },
    formatDate(value) {
      return value == null ? "" : new Date(value).toLocaleDateString();
    },
    formatFileSize(value) {
      return value == null ? "" : bytes(value);
    },
    getComponent(file) {
      if (startsWith(file.mime_type, "image")) {
        return "media-image";
      }
      return "default";
    },
    isApplication(file) {
      return startsWith(file.mime_type, "application") || startsWith(file.mime_type, "text");
    },
    fireUploadedEvent(item) {
      this.$emit("media-uploaded", item);
    },
    fireDeletedEvent(item, index) {
      if (this.currentMedia && this.currentMedia.id === item.id) {
        this.currentMedia = null;
      }
      this.$emit("media-deleted", { id: item.id, index: index });
    },
    getMedia(item) {
      if (this.isApplication(item) && item.children.length) {
        return item.children.map((o) => {
          o.width = "auto";
          o.height = "auto";
          return o;
        });
      }
      item.width = "auto";
      item.height = "auto";
      return [item];
    },
    setFeature(mediaId) {
      this.$emit("media-set-feature", { id: mediaId });
    },
  },
};
</script>

<style scoped>
table {
  td {
    @apply px-3 py-4 text-sm text-tertiary-500 lg:table-cell;
  }
  th {
    @apply py-smSpace pl-mdSpace pr-3 text-left text-sm font-medium text-tertiary-700 sm:pl-lgSpace;
  }
}
</style>
