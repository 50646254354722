const state = () => ({
  items: [],
  currentCustomer: {},
});

const getters = {};

const actions = {
  setCustomer({ commit }, customer) {
    commit("setCustomer", customer);
  },
  async fetchCustomer({ commit }, id) {
    await axios(`/api/client/customers/${id}`).then(({ data }) => {
      commit("setCustomer", data.data);
    });
  },
  async fetchCustomers({ commit }) {
    await axios("/api/client/customers").then(({ data }) => {
      commit("setCustomers", data.data);
    });
  },
};

const mutations = {
  setCustomer(state, customer) {
    state.currentCustomer = customer;
    if (customer) {
      const index = state.items.findIndex((o) => o.id === customer.id);
      if (index >= 0) {
        state.items[index] = customer;
      }
    }
  },
  setCustomers(state, customers) {
    state.items = customers;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
