<template>
  <div :class="classes">
    <form class="grid grid-cols-1 gap-smSpace">
      <div>
        <h3 class="text-base font-semibold leading-6 text-gray-900">{{ $t("wait_list.headings.detail") }}</h3>
        <p class="mt-xsSpace text-sm text-gray-500">{{ $t("wait_list.headings.description") }}</p>
      </div>
      <input-wrapper field="customer_id" :form="form" :label-text="$t('wait_list.columns.customer')">
        <v-multi-select
          v-model="form.customer_id"
          search-url="/api/admin/customers?filter[active]=1"
          :placeholder="$t('wait_list.columns.customer')"
          track-by="id"
          label="name"
          search-key="filter[customer_term]"
        />
      </input-wrapper>
      <input-wrapper field="venue_id" :form="form" :label-text="$t('wait_list.columns.venue')">
        <v-multi-select
          v-model="form.venue_id"
          search-url="/api/admin/venues?filter[active]=1"
          :placeholder="$t('wait_list.columns.venue')"
          track-by="id"
          label="name"
          search-key="filter[name]"
        />
      </input-wrapper>
      <input-wrapper field="product_category_id" :form="form" :label-text="$t('product.fields.category')">
        <v-multi-select
          v-model="form.product_category_id"
          search-url="/api/generic/product-categories?filter[active]=1&filter[parent_id]="
          :placeholder="$t('product.placeholders.category')"
          track-by="id"
          label="name"
          search-key="filter[name]"
          :allow-empty="false"
          @selectOption="changeCategory"
        />
      </input-wrapper>
      <input-wrapper
        v-if="form.product_category_id && currentCategoryId === form.product_category_id"
        field="product_id"
        :form="form"
        :label-text="$t('wait_list.columns.product')"
      >
        <v-multi-select
          ref="productSelection"
          v-model="form.product_id"
          :search-url="getProductUrl"
          :placeholder="$t('wait_list.columns.product')"
          track-by="id"
          label="name"
          search-key="filter[product]"
        />
      </input-wrapper>
      <div class="my-smSpace space-y-2xsSpace">
        <p class="text-sm text-gray-500" v-html="$t('wait_list.placeholders.group_select_date_hint')" />
        <div class="grid grid-cols-1 gap-smSpace">
          <input-wrapper field="date" :form="form" :label-text="$t('wait_list.columns.date')">
            <date-picker
              v-model:value="form.date"
              :placeholder="$t('wait_list.placeholders.date')"
              format="DD-MM-YYYY"
              value-type="format"
              type="date"
              class="w-full"
            />
          </input-wrapper>

          <input-wrapper field="day_of_week" :form="form" :label-text="$t('wait_list.columns.day_of_week')">
            <v-multi-select
              v-model="form.day_of_week"
              :init-options="weekdays"
              :placeholder="$t('wait_list.placeholders.day_of_week')"
              :is-multiple="true"
            />
          </input-wrapper>
        </div>
      </div>

      <v-checkbox v-model="form.active" :label-text="$t('wait_list.columns.active')"></v-checkbox>
      <v-checkbox v-model="form.gift_voucher" :label-text="$t('wait_list.columns.gift_voucher')"></v-checkbox>
      <voucher-select
        v-if="form.gift_voucher"
        v-model="form.vouchers"
        :dark-theme="false"
        :hide-label="true"
      ></voucher-select>
    </form>
    <v-footer :in-panel="inPanel" :list-url="listUrl" :save="save"></v-footer>
  </div>
</template>

<script>
import Form from "form-backend-validation";

export default {
  name: "WaitListEdit",
  inject: ["notificationService", "bus"],
  props: {
    waitList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    weekdays: {
      type: Array,
      default: () => {
        return [];
      },
    },
    listUrl: {
      type: String,
      required: true,
    },
    createUrl: {
      type: String,
    },
    classes: {
      type: String,
      required: false,
      default: "max-w-xl space-y-lgSpace",
    },
    inPanel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentCategoryId: this.getFieldValue("product_category_id", null),
      form: new Form(
        {
          id: this.getFieldValue("id"),
          date: this.getFieldValue("date"),
          day_of_week: this.getFieldValue("day_of_week", []),
          active: Boolean(Number(this.getFieldValue("active"))),
          customer_id: this.getFieldValue("customer_id", null),
          venue_id: this.getFieldValue("venue_id", null),
          product_id: this.getFieldValue("product_id", null),
          product_category_id: this.getFieldValue("product_category_id", null),
          gift_voucher: Boolean(Number(this.getFieldValue("gift_voucher"))),
          vouchers: this.getFieldValue("vouchers") ?? [],
        },
        { resetOnSuccess: false },
      ),
    };
  },

  computed: {
    getProductUrl() {
      return (
        "/api/admin/products?filter[active]=1&filter[public]=1&filter[in_category_id]=" + this.form.product_category_id
      );
    },
  },

  methods: {
    getFieldValue(field, defaultValue = null) {
      if (this.waitList.hasOwnProperty(field)) {
        return this.waitList[field];
      }

      return defaultValue;
    },
    close() {
      if (this.inPanel) {
        this.bus.$emit("closePanelInPanel");
        return;
      }

      location.href = this.listUrl;
    },
    changeCategory(category) {
      if (category.id === this.form.product_category_id) {
        return;
      }

      this.form.populate({
        product_id: null,
      });

      this.$nextTick(() => {
        this.currentCategoryId = category.id;
      });
    },
    save() {
      if (this.waitList.hasOwnProperty("id")) {
        this.form
          .patch(`/api/admin/wait-lists/${this.waitList.id}`)
          .then(({}) => {
            this.notificationService.notify(this.$t("generic.responses.update_success"));
            this.close();
          })
          .catch(({ response }) => {
            this.notificationService.notify(response.data.message, "error");
          });

        return;
      }

      this.form
        .post(`/api/admin/wait-lists`)
        .then(({ data }) => {
          this.notificationService.notify(this.$t("generic.responses.create_success"));
          this.close();
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
  },
};
</script>
