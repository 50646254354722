/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import axios, { isAxiosError } from "axios";
import $bus from "./lib/Vue3EventBus";
window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (isAxiosError(error) && error.response.status === 403) {
      $bus.$emit("showNotification", {
        content: "You are not authorized to perform this action",
        type: "error",
      });
    } else {
      $bus.$emit("showNotification", {
        content: error.response.data.message,
        type: "error",
      });
    }

    return Promise.reject(error);
  },
);

window.Locale = document.head.querySelector('meta[name="locale"]');
