<template>
  <a @click.stop.prevent="copyLink" :class="classes">
    <span>{{ $t(title) }}</span>
  </a>
</template>
<script>
export default {
  name: "CopyButton",
  inject: ["notificationService"],
  props: {
    copyString: {
      type: String,
      default: () => {
        return null;
      },
    },
    copiedMessage: {
      type: String,
      default: () => {
        return "generic.words.copied";
      },
    },
    title: {
      type: String,
      default: () => {
        return "generic.words.copy";
      },
    },
    classes: {
      type: String,
      default: () => {
        return "flex w-full cursor-pointer items-center space-x-xsSpace p-xsSpace hover:bg-neutral-100";
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.copyString);
      this.notificationService.notify(this.$t(this.copiedMessage));
    },
  },
};
</script>
