import Form from "form-backend-validation";

export default {
  inject: ["config"],
  props: {
    saveUrl: {
      type: String,
      default: "/api/admin/generic/update",
    },
    modelClass: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      saving: false,
      currentNameMap: {},
      showNameInputMap: {},
      nameForm: new Form({ update_data: {}, model_class: this.modelClass, model_id: null }, { resetOnSuccess: false }),
    };
  },
  methods: {
    toggleNameInput(id, name) {
      this.showNameInputMap[id] = !this.showNameInputMap[id];
      this.currentNameMap[id] = name;

      if (!this.showNameInputMap[id]) {
        return;
      }

      this.$nextTick(() => {
        (this.$refs[`nameInput` + id][0] || this.$refs[`nameInput` + id]).focus();
      });
    },
    updateName(id, newName) {
      this.currentNameMap[id] = newName;
      this.saving = true;
      this.nameForm
        .populate({ update_data: { name: newName }, model_id: id })
        .put(this.saveUrl)
        .then(() => {
          this.afterUpdateName(id, newName);
          this.showNameInputMap[id] = false;
          this.saving = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    afterUpdateName() {},
  },
};
