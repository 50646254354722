<template>
  <div class="space-y-lgSpace pt-lgSpace">
    <div class="flex gap-xlSpace">
      <form class="grid w-1/2 grid-cols-1 gap-smSpace">
        <input-wrapper field="key" :form="form" :label-text="$t('notification.fields.key')">
          <v-multi-select
            v-model="form.key"
            :init-options="keys"
            :placeholder="$t('notification.fields.key')"
            :is-multiple="false"
            track-by="id"
            label="label"
          />
        </input-wrapper>
        <input-wrapper field="name" :form="form" :label-text="$t('notification.fields.name')">
          <input type="text" v-model="form.name" :placeholder="$t('notification.placeholders.name')" />
        </input-wrapper>
        <input-wrapper field="type" :form="form" :label-text="$t('notification.fields.type')">
          <v-multi-select
            v-model="form.type"
            :init-options="types"
            :placeholder="$t('notification.placeholders.type')"
            :is-multiple="false"
          />
        </input-wrapper>
        <input-wrapper field="product_category_id" :form="form" :label-text="$t('product.fields.category')">
          <v-multi-select
            v-model="form.product_category_id"
            search-url="/api/generic/product-categories?filter[active]=1&filter[parent_id]="
            :placeholder="$t('product.placeholders.category')"
            track-by="id"
            label="name"
            search-key="filter[name]"
          />
        </input-wrapper>
        <input-wrapper field="product_ids" :form="form" :label-text="$t('notification.fields.products')">
          <v-multi-select
            v-model="form.product_ids"
            search-url="/api/admin/products?filter[active]=1"
            :placeholder="$t('notification.fields.products')"
            track-by="id"
            label="name"
            search-key="filter[name]"
            is-multiple
          />
        </input-wrapper>
        <input-wrapper field="subject" :form="form" :label-text="$t('notification.fields.subject')">
          <input type="text" v-model="form.subject" :placeholder="$t('notification.placeholders.subject')" />
        </input-wrapper>
        <input-wrapper field="content" :form="form" :label-text="$t('notification.fields.content')">
          <ckeditor :editor="classicEditor" v-model="form.content" name="content"></ckeditor>
        </input-wrapper>
        <v-checkbox v-model="form.active" :label-text="$t('generic.words.active')"></v-checkbox>
        <v-footer :list-url="listUrl" :save="save"></v-footer>
      </form>
      <form class="flex w-1/2 flex-col rounded bg-blue-50 p-xsSpace">
        <div v-if="form.id" class="flex flex-col gap-smSpace">
          <input-wrapper field="model_id" :form="testForm" :label-text="$t('generic.words.model')" class="w-2/3">
            <v-multi-select
              v-model="testForm.model_id"
              :search-url="notificationModelEndpoint"
              :placeholder="$t('generic.words.model')"
              track-by="id"
              label="full_title"
              search-key="filter[term]"
            />
          </input-wrapper>
          <div class="relative flex pr-3xsSpace">
            <input-wrapper
              v-if="form.type === 'email'"
              field="email"
              :form="testForm"
              :label-text="$t('generic.words.email')"
              class="w-2/3"
            >
              <input type="text" v-model="testForm.email" :placeholder="$t('generic.words.email')" />
            </input-wrapper>

            <input-wrapper
              v-if="form.type === 'sms'"
              field="mobile"
              :form="testForm"
              :label-text="$t('generic.words.mobile')"
              class="w-2/3"
            >
              <input type="text" v-model="testForm.mobile" :placeholder="$t('generic.words.mobile')" />
            </input-wrapper>

            <div class="relative -right-smSpace top-[20px]">
              <a class="btn-primary btn-sm flex min-h-[35px] min-w-[70px] items-center justify-center" @click="send">
                <span v-if="!loading" class="flex items-center">
                  {{ $t("generic.buttons.test") }}
                  <v-icon icon="PaperAirplaneIcon" class="ml-xsSpace"></v-icon>
                </span>
                <v-icon v-else icon="ArrowPathIcon" class="animate-spin" />
              </a>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-smSpace pt-smSpace">
          <div :key="number" v-for="(wildcard, number) in wildcards">
            {{ wildcard }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Form from "form-backend-validation";

export default {
  name: "NotificationEdit",
  inject: ["notificationService", "config"],
  props: {
    notification: {
      type: Object,
      default: () => {
        return {};
      },
    },
    listUrl: {
      type: String,
      required: true,
    },
    wildcards: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      form: new Form(
        {
          id: this.getFieldValue("id"),
          key: this.getFieldValue("key"),
          name: this.getFieldValue("name"),
          type: this.getFieldValue("type"),
          product_category_id: this.getFieldValue("product_category_id"),
          product_ids: this.getFieldValue("product_ids", []),
          subject: this.getFieldValue("subject"),
          content: this.getFieldValue("content"),
          active: Boolean(Number(this.getFieldValue("active"))),
        },
        { resetOnSuccess: false },
      ),
      types: this.config.notificationTypes,
      keys: this.config.notificationKeys,
      testForm: new Form(
        {
          model_id: null,
          type: this.getFieldValue("type"),
          email: null,
          mobile: null,
          notification: null,
        },
        { resetOnSuccess: false },
      ),
      loading: false,
    };
  },

  watch: {
    "form.type": {
      handler(newValue) {
        this.testForm.type = newValue;
      },
    },
  },

  computed: {
    notificationModelEndpoint() {
      return `/api/admin/notifications/${this.notification.id}/models`;
    },
  },

  methods: {
    getFieldValue(field, defaultValue = null) {
      if (this.notification.hasOwnProperty(field)) {
        return this.notification[field];
      }

      return defaultValue;
    },
    send() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.testForm.notification = this.form.data();

      this.testForm
        .post(`/api/admin/notifications/${this.notification.id}/test`)
        .then((res) => {
          this.notificationService.notify(res.statusText);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      if (this.notification.hasOwnProperty("id")) {
        this.form.patch(`/api/admin/notifications/${this.notification.id}`).then(({}) => {
          window.location = this.listUrl;
        });

        return;
      }

      this.form.post(`/api/admin/notifications`).then(({}) => {
        window.location = this.listUrl;
      });
    },
  },
};
</script>
