<template>
  <a @click.stop.prevent="open" :class="classes">
    <slot>
      <span v-if="title">{{ title }}</span>
      <i class="fa fa-plus" v-else></i>
    </slot>
  </a>
</template>
<script>
export default {
  name: "OpenSidePanelButton",
  inject: ["bus"],
  props: {
    componentName: {
      type: String,
      default: () => {
        return null;
      },
    },
    title: {
      type: String,
      default: () => {
        return null;
      },
    },
    panelTitle: {
      type: String,
      default: () => {
        return null;
      },
    },
    componentData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    callback: {
      type: Function,
      default: () => {
        return {};
      },
    },
    classes: {
      type: String,
      default: () => {
        return null;
      },
    },
    sideEffects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    open() {
      this.bus.$emit("openSidePanel", {
        componentName: this.componentName,
        title: this.panelTitle,
        componentData: this.componentData,
        closeCallback: this.callback,
      });
      // FIXME: Add side effects for bookings page only
      if (window.location.pathname.startsWith("/admin/bookings") && this.sideEffects.length) {
        this.sideEffects.forEach((sideEffect) => {
          this.bus.$emit(sideEffect.event, sideEffect.data);
        });
      }
    },
  },
};
</script>
