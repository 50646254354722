<template>
  <div class="mx-auto flex max-w-[344px] flex-col gap-1 md:max-w-[530px]">
    <p
      v-if="currentCustomer.display_name"
      class="font-sloganSerif text-2xl font-bold text-white2 md:text-[44px] md:leading-tight"
    >
      <span class="mx-auto block max-w-[460px]">{{ $t("generic.hi") }} {{ currentCustomer.display_name }}</span>
    </p>
    <div v-if="customersCount > 1" class="flex items-start justify-center gap-2 px-4">
      <span class="text-left">
        {{ $t("client.headings.want_to_switch") }}
      </span>
      <button v-if="!isOpen" class="underline hover:text-gray-300" @click="isOpen = !isOpen">
        {{ $t("generic.buttons.select") }}
      </button>
    </div>
    <div
      class="mx-auto flex max-w-sm justify-items-center overflow-hidden transition-all"
      :class="{ 'h-10 md:h-16': isOpen, 'h-0': !isOpen }"
    >
      <customer-select></customer-select>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  inject: ["bus"],
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    this.bus.$on("customerSelected", () => {
      this.isOpen = false;
    });
  },
  computed: {
    ...mapState({
      customersCount: (state) => state.customer.items.length,
      currentCustomer: (state) => state.customer.currentCustomer,
    }),
  },
};
</script>
