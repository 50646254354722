<template>
  <div class="space-y-mdSpace pb-smSpace">
    <h1 class="text-xl">{{ $t("report.headings.appointment_report") }}</h1>
    <div class="flex items-center justify-between">
      <date-picker
        v-model:value="filters['filter[date_range]']"
        :append-to-body="false"
        :clearable="false"
        placeholder="Select a range"
        :range="true"
        format="DD/MM/YYYY"
        type="date"
        class="!w-[220px]"
        @change="loadSummary"
      />
      <div class="flex flex-col justify-end space-x-xsSpace sm:flex-row sm:items-center max-sm:space-y-smSpace">
        <v-filter entity="appointment-reports" class="flex justify-end"></v-filter>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-mdSpace pr-2xsSpace">
      <percentage :data="nBRAppointments"></percentage>
      <percentage :data="valueOfBookedAppointments"></percentage>
      <percentage :data="valueOfAvailableAppointments"></percentage>
    </div>
    <div class="mr-1 rounded p-xsSpace ring-1">
      <p class="py-xsSpace font-semibold">{{ $t("report.headings.payment_types") }}</p>
      <div class="flex">
        <div class="flex w-1/5 flex-col text-center">
          <p class="font-semibold">{{ $t("generic.words.total") }}</p>
          <p class="border-t pt-smSpace font-semibold">{{ toAUD(summary.payment_types?.total) }}</p>
        </div>
        <div class="flex w-1/5 flex-col text-center">
          <p class="border-l font-semibold">{{ $t("generic.words.unpaid") }}</p>
          <p class="border-l border-t pt-smSpace font-semibold" :class="{ 'text-red-600': totalUnpaid > 0 }">
            {{ toAUD(totalUnpaid) }}
          </p>
        </div>
        <div class="flex w-3/5 flex-col">
          <p class="border-l pl-lgSpace font-semibold">{{ $t("generic.words.paid") }}</p>
          <p
            class="border-l border-t pl-lgSpace pt-smSpace font-semibold text-green-600"
            :class="{ 'text-green-600': totalPaid > 0 }"
          >
            {{ toAUD(totalPaid) }}
          </p>
          <div class="flex">
            <div class="w-1/3 border-l pl-lgSpace pt-smSpace">
              <p>{{ $t("generic.words.cash") }}</p>
              <p class="font-semibold" :class="{ 'text-green-600': totalCash > 0 }">{{ toAUD(totalCash) }}</p>
            </div>
            <div class="w-1/3 border-l pl-lgSpace pt-smSpace">
              <p>{{ $t("generic.words.card") }}</p>
              <p class="font-semibold" :class="{ 'text-green-600': totalCard > 0 }">{{ toAUD(totalCard) }}</p>
            </div>
            <div class="w-1/3 border-l pl-lgSpace pt-smSpace">
              <p>{{ $t("generic.words.gift_card") }}</p>
              <p class="font-semibold" :class="{ 'text-green-600': totalVoucher > 0 }">{{ toAUD(totalVoucher) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="-mx-4 sm:-mx-6 md:mx-0 md:rounded-lg">
      <div class="flex items-center justify-between">
        <h3 class="text-xl">{{ $t("report.headings.breakdown") }}</h3>
        <div>
          <div class="mb-xsSpace mr-1 mt-lgSpace flex items-center gap-2 rounded-full p-xsSpace ring-1">
            <v-badge
              v-for="key in Object.keys(filterOptions)"
              :value="key"
              @click="selectOption(key)"
              class="cursor-pointer py-[7px] text-white hover:bg-primary-700"
              :class="{
                '!bg-primary-700': filters['filter[view_by]'] === key,
                '!bg-primary-200': filters['filter[view_by]'] !== key,
              }"
              >{{ filterOptions[key] }}
            </v-badge>
          </div>
        </div>
      </div>

      <table class="min-w-full">
        <thead class="bg-tertiary-100">
          <tr>
            <th
              class="table-cell px-3 py-smSpace text-left text-sm font-medium text-tertiary-700"
              v-text="getHeaderTitle()"
            ></th>
            <th
              class="py-smSpace pl-mdSpace pr-3 text-left text-sm font-medium text-tertiary-700 max-lg:hidden"
              v-for="column in summary.columns"
            >
              {{ column }}
            </th>
          </tr>
        </thead>
        <Transition>
          <tbody class="divide-y divide-tertiary-200 bg-white" v-if="!loading">
            <tr
              class="align-top lg:align-middle"
              v-for="item in summary.breakdown"
              v-if="summary.breakdown && summary.breakdown.length"
            >
              <td class="whitespace-nowrap py-4 pl-smSpace pr-3 text-sm font-medium text-tertiary-900">
                <p class="w-[250px] overflow-hidden text-ellipsis whitespace-nowrap">{{ getTitle(item) }}</p>
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ numberFormat(item.available) }}
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ numberFormat(item.booked) }}
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ parseFloat(item.utilisation).toFixed(2) }}%
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ toAUD(item.min_opportunity) }} ~ {{ toAUD(item.max_opportunity) }}
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ toAUD(item.booked_value) }}
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ item.opportunity_realised?.toFixed(2) }}%
              </td>
              <td
                class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900"
                :class="{ '!text-green-600': item.paid > 0 }"
              >
                {{ toAUD(item.paid) }}
              </td>
              <td
                class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900"
                :class="{ '!text-red-600': item.unpaid > 0 }"
              >
                {{ toAUD(item.unpaid) }}
              </td>
            </tr>
            <tr v-else>
              <td class="py-mdSpace text-sm">{{ $t("generic.words.no_item") }}</td>
            </tr>
          </tbody>
        </Transition>
      </table>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue-datepicker-next";
import moment from "moment";

export default {
  components: { DatePicker },
  inject: ["bus"],
  props: {
    fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tabs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      summary: {},
      filters: {
        "filter[view_by]": "room_id",
        "filter[date_range]": [moment().subtract(1, "week").toDate(), moment().toDate()],
      },
      filterOptions: {
        room_id: "Room",
        product_category_id: "Category",
        product_id: "Product",
      },
    };
  },
  beforeUnmount() {
    this.bus.$off("tableFilterChange");
  },
  created() {
    this.bus.$on("tableFilterChange", ({ params }) => {
      this.filters["filter[venue]"] = params["filter[venue]"];
      this.filters["filter[rooms]"] = params["filter[rooms]"];
      this.loadSummary();
    });
  },
  computed: {
    totalUnpaid() {
      return this.summary.payment_types?.unpaid;
    },
    totalCash() {
      return this.summary.payment_types?.paid.cash;
    },
    totalCard() {
      return this.summary.payment_types?.paid.card;
    },
    totalVoucher() {
      return this.summary.payment_types?.paid.voucher;
    },
    totalPaid() {
      return this.totalCash + this.totalCard + this.totalVoucher;
    },
    nBRAppointments() {
      return {
        chart: {
          datasets: [
            {
              backgroundColor: ["#81aa82", "#f1f1f1"],
              data: [this.summary.utilisation, 100 - this.summary.utilisation],
            },
          ],
        },
        title: this.$t("report.headings.nbr_appointments"),
        percentage_title: this.$t("report.headings.utilisation"),
        percentage_value: this.summary.utilisation,
        statistic: [
          {
            title: this.$t("generic.words.available"),
            value: this.numberFormat(this.summary.available),
          },
          {
            title: this.$t("generic.words.booked"),
            value: this.numberFormat(this.summary.appointments_booked),
          },
        ],
      };
    },
    valueOfAvailableAppointments() {
      return {
        chart: {
          datasets: [
            {
              backgroundColor: ["#a481cf", "#f1f1f1"],
              data: [this.summary.opportunity_realised_available, 100 - this.summary.opportunity_realised_available],
            },
          ],
        },
        title: this.$t("report.headings.value_of_available_appointments"),
        percentage_title: this.$t("report.headings.opportunity_realised"),
        percentage_value: this.summary.opportunity_realised_available,
        statistic: [
          {
            title: this.$t("generic.words.available"),
            value:
              this.toAUD(this.summary.value_of_available_appointments?.min) +
              " ~ " +
              this.toAUD(this.summary.value_of_available_appointments?.max),
          },
          {
            title: this.$t("generic.words.booked"),
            value: this.toAUD(this.summary.value_of_appointments_booked),
          },
        ],
      };
    },
    valueOfBookedAppointments() {
      return {
        chart: {
          datasets: [
            {
              backgroundColor: ["#a481cf", "#f1f1f1"],
              data: [this.summary.opportunity_realised_booked, 100 - this.summary.opportunity_realised_booked],
            },
          ],
        },
        title: this.$t("report.headings.value_of_booked_appointments"),
        percentage_title: this.$t("report.headings.opportunity_realised"),
        percentage_value: this.summary.opportunity_realised_booked,
        statistic: [
          {
            title: this.$t("generic.words.available"),
            value:
              this.toAUD(this.summary.value_of_booked_appointments?.min) +
              " ~ " +
              this.toAUD(this.summary.value_of_booked_appointments?.max),
          },
          {
            title: this.$t("generic.words.booked"),
            value: this.toAUD(this.summary.value_of_appointments_booked),
          },
        ],
      };
    },
  },
  methods: {
    getHeaderTitle() {
      if (this.filters["filter[view_by]"] === "room_id") {
        return this.$t("report.buttons.room");
      }

      if (this.filters["filter[view_by]"] === "product_category_id") {
        return this.$t("report.buttons.category");
      }

      return this.$t("report.buttons.product");
    },
    getTitle(row) {
      if (this.filters["filter[view_by]"] === "room_id") {
        return row.room_name;
      }

      if (this.filters["filter[view_by]"] === "product_category_id") {
        return row.product_category_name;
      }

      return row.product_name;
    },
    selectOption(option) {
      this.filters["filter[view_by]"] = option;
      this.loadSummary();
    },
    loadSummary() {
      this.loading = true;
      axios
        .get("/api/admin/reports/appointments/summary", {
          params: this.filters,
        })
        .then(({ data }) => {
          this.summary = Object.assign({}, data);
          this.loading = false;
        });
    },
  },
};
</script>
