<template>
  <div class="mx-xsSpace space-y-mdSpace py-mdSpace">
    <h4 class="text-sm font-semibold">{{ $t("customer.headings.primary") }}</h4>
    <div class="flex items-center rounded-sm px-smSpace py-xsSpace text-sm ring-1" v-if="primaryCustomer">
      <p class="flex w-1/5 items-center justify-between space-x-xsSpace" v-html="`#${primaryCustomer.id}`"></p>
      <p
        class="w-1/5 cursor-pointer font-semibold leading-none hover:text-blue-600"
        v-html="primaryCustomer.display_name"
      />
      <p class="w-1/5 leading-none" v-html="primaryCustomer.email"></p>
      <p class="w-1/5 leading-none" v-html="primaryCustomer.phone"></p>
    </div>
    <h4 class="text-sm font-semibold">{{ $t("customer.headings.possible_duplicates") }}</h4>
    <template v-if="items.length > 1">
      <div v-for="item in items" class="space-y-xsSpace rounded-sm px-smSpace py-xsSpace text-sm ring-1">
        <div class="flex items-center rounded-sm py-xsSpace text-sm">
          <div class="flex w-1/5 items-center justify-between space-x-xsSpace">
            <v-checkbox v-model="item.selected" :label-text="`#${item.id}`"></v-checkbox>
          </div>
          <p class="w-1/5 cursor-pointer font-semibold leading-none hover:text-blue-600" v-html="item.display_name" />
          <p class="w-1/5 leading-none" v-html="item.email"></p>
          <p class="w-1/5 leading-none" v-html="item.phone"></p>
          <div class="relative flex w-1/5 justify-end">
            <a
              class="btn-secondary btn-sm absolute -top-1.5 right-20"
              :class="{ 'btn-primary !text-white': item.primary }"
              @click="primary(item)"
              >{{ $t("customer.headings.primary") }}</a
            >
            <a class="cursor-pointer hover:bg-tertiary-100 hover:text-tertiary-800" @click="expand(item)">
              <v-icon :icon="item.expand ? 'ChevronUpIcon' : 'ChevronDownIcon'"></v-icon>
            </a>
          </div>
        </div>
        <Transition>
          <div class="w-full space-y-mdSpace" v-if="item.expand">
            <div>
              <h3 v-if="item.bookings.length">{{ $t("customer.headings.bookings") }}</h3>
              <div
                v-for="booking in item.bookings"
                class="flex items-center justify-between rounded-sm border-b border-blue-200 py-xsSpace text-sm"
              >
                <p class="w-1/3">#{{ booking.id }} {{ booking.order_item.itemable.name }}</p>
                <p>{{ booking.appointment.readable_period }}</p>
              </div>
            </div>
            <div>
              <h3 v-if="item.orders.length" class="">{{ $t("customer.headings.orders") }}</h3>
              <div
                v-for="order in item.orders"
                class="flex items-center justify-between rounded-sm border-b border-blue-200 py-xsSpace text-sm"
              >
                <p class="w-1/3">#{{ order.id }} {{ order.email + " | " + order.phone }}</p>
                <p>
                  <v-badge type="success" class="mb-2xsSpace mr-2xsSpace">
                    <span :class="{ 'text-orange-700': order.balance > 0 }">{{ toAUD(order.total_paid) }}</span>
                    /
                    <span>{{ toAUD(order.total) }}</span>
                  </v-badge>
                </p>
                <p>{{ formatDate(order.created_at, true) }}</p>
              </div>
            </div>
            <div>
              <h3 v-if="item.cards.length" class="">{{ $t("customer.headings.cards") }}</h3>
              <div
                v-for="card in item.cards"
                class="flex items-center justify-between rounded-sm border-b border-blue-200 py-xsSpace text-sm"
              >
                <p class="w-1/3">#{{ card.id }} {{ card.card_brand }}</p>
                <p>{{ card.cardholder_name }}</p>
                <p>**** **** **** {{ card.last_4 }} | {{ card.exp_month + "/" + card.exp_year }}</p>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </template>
    <span class="text-sm" v-else>
      {{ $t("generic.words.no_item") }}
    </span>

    <div class="flex justify-end" v-if="items.filter((o) => o.selected).map((o) => o.id).length">
      <a @click.prevent="merge" class="btn-primary btn-sm" v-text="$t('generic.buttons.merge')"></a>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["notificationService"],
  props: {
    customer: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      items: [],
    };
  },

  computed: {
    primaryCustomer() {
      return this.items.find((o) => o.primary);
    },
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      axios.get(`/api/admin/customers/${this.customer.id}/duplicates`).then(({ data }) => {
        this.items = data.data;
        this.items.map((i) => {
          i.selected = i.id !== this.customer.id;
          i.expand = false;
          i.primary = i.id === this.customer.id;
        });
      });
    },
    expand(item) {
      item.expand = !item.expand;
    },
    primary(item) {
      item.primary = true;
      item.selected = false;

      this.items = [
        ...this.items.map((o) => {
          if (item.id !== o.id) {
            o.primary = false;
            o.selected = true;
          }

          return o;
        }),
      ];
    },
    merge() {
      axios
        .post(`/api/admin/customers/${this.primaryCustomer.id}/duplicates/merge`, {
          ids: this.items.filter((o) => o.selected).map((o) => o.id),
        })
        .then(({ data }) => {
          this.notificationService.notify(data.statusText);
          setTimeout(() => {
            window.location.href = data.redirectTo;
          }, 1000);
        });
    },
  },
};
</script>
