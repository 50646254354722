<template>
  <form class="flex flex-col space-y-lgSpace px-lgSpace py-lgSpace">
    <h3 class="text-lg">{{ $t("generic.words.gift_voucher") }}</h3>
    <input-wrapper field="venue_id" :form="form" :label-text="$t('booking.fields.venue')">
      <v-multi-select
        v-model="form.venue_id"
        search-url="/api/admin/venues?filter[active]=1"
        :placeholder="$t('venue.placeholders.venue')"
        track-by="id"
        label="name"
        search-key="filter[name]"
        :allow-empty="false"
        @selectOption="selectVenue"
      ></v-multi-select>
    </input-wrapper>
    <div v-if="form.voucher_ids && form.voucher_ids.length" class="mt-xsSpace">
      <input-wrapper field="quantity" :form="form" :label-text="$t('gift_voucher.fields.quantity')" :dark-theme="true">
        <input min="1" type="number" v-model="form.quantity" :placeholder="$t('gift_voucher.placeholders.quantity')" />
      </input-wrapper>
    </div>
    <v-tabs :tabs="tabs">
      <template v-slot:[tab.id] v-for="tab in tabs">
        <voucher-select
          v-model="form.voucher_ids"
          :is-bath-pass="tab.id === slug($t('gift_voucher.labels.bathing_multi_passes'))"
        />
      </template>
    </v-tabs>
    <div
      v-if="form.voucher_ids"
      class="mt-mdSpace flex flex-col justify-end rounded sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
    >
      <a @click.prevent="book" class="btn-primary btn-sm" v-text="$t('generic.buttons.continue')"></a>
    </div>
  </form>
</template>
<script>
import Form from "form-backend-validation";
import VoucherSelect from "./VoucherSelect.vue";

export default {
  inject: ["config", "bus"],
  components: { VoucherSelect },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      form: new Form(
        {
          venue_id: 1,
          quantity: 1,
          voucher_ids: null,
          venue: null,
        },
        { resetOnSuccess: false },
      ),
      tabs: [
        {
          id: this.slug(this.$t("gift_voucher.labels.dollar_value_gift_cards")),
          name: this.$t("gift_voucher.labels.dollar_value_gift_cards"),
          active: true,
        },
        {
          id: this.slug(this.$t("gift_voucher.labels.bathing_multi_passes")),
          name: this.$t("gift_voucher.labels.bathing_multi_passes"),
          active: false,
        },
      ],
    };
  },
  created() {
    this.bus.$on("tabChanged", (tab) => {
      this.form.voucher_ids = null;
    });
  },
  methods: {
    selectVenue(venue) {
      this.form.venue = {
        name: venue.name,
      };
    },
    book() {
      this.form.post("/api/voucher/cart").then((res) => {
        this.bus.$emit("openSidePanel", {
          componentName: "QuickGiftVoucherDetails",
          componentData: { data: res },
          closeCallback: () => {},
        });
      });
    },
  },
};
</script>
