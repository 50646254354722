<template>
  <side-panel ref="sidePanel" :disable-listeners="true"></side-panel>
  <form class="flex w-full flex-col justify-between" v-if="!loading">
    <div class="relative grow space-y-lgSpace p-smSpace xl:p-lgSpace">
      <div class="grid grid-cols-1 gap-smSpace pb-2xlSpace">
        <div class="grid grid-cols-1">
          <div class="absolute right-8 top-8 flex flex-row space-x-xsSpace">
            <v-actions :show-action-icon="false" classes="w-[240px] -right-8 top-3" ref="actions">
              <template v-slot:button>
                <span class="btn-sm btn-secondary">{{ $t("generic.actions") }}</span>
              </template>
              <template v-slot:dropdown>
                <ul class="divide-y text-sm">
                  <v-action-dropdown-item v-if="!booking.is_cancelled" @click="sendEmail('confirmation')">
                    {{ $t("task.buttons.send_booking_confirmation_email") }}
                  </v-action-dropdown-item>
                  <v-action-dropdown-item v-if="!booking.is_cancelled" @click="sendEmail('waiver')">
                    {{ $t("task.buttons.send_waiver") }}
                  </v-action-dropdown-item>
                  <v-action-dropdown-item
                    v-if="!booking.is_cancelled && !booking.has_reschedule_task"
                    @click="addRescheduleBookingTask"
                  >
                    {{ $t("task.buttons.add_reschedule_booking_task") }}
                  </v-action-dropdown-item>
                  <v-action-dropdown-item
                    v-if="!booking.is_cancelled && !!booking?.details?.expired_at"
                    @click="sendEmail('hold')"
                  >
                    {{ $t("task.buttons.send_hold_email") }}
                  </v-action-dropdown-item>
                  <v-action-dropdown-item
                    v-if="!booking.is_cancelled && booking.is_reminded"
                    @click="sendEmail('booking_reminder')"
                  >
                    {{ $t("task.buttons.send_reminder") }}
                  </v-action-dropdown-item>
                  <v-action-dropdown-item
                    v-if="!booking.is_cancelled && booking.has_been_rescheduled"
                    @click="sendEmail('booking_reschedule')"
                  >
                    {{ $t("task.buttons.send_reschedule") }}
                  </v-action-dropdown-item>
                  <v-action-dropdown-item v-if="booking.is_cancelled" @click="sendEmail('booking_cancellation')">
                    {{ $t("task.buttons.send_cancellation") }}
                  </v-action-dropdown-item>
                </ul>
              </template>
            </v-actions>
            <a v-if="showAddTask" @click="clickAddTask" class="btn-sm btn-secondary">
              <span>{{ $t("task.buttons.add") }}</span>
            </a>
          </div>
          <div class="text-xl font-bold">
            <a :href="`/admin/settings/customers/${booking.client_id}/edit`" target="_blank">
              {{ booking.client }}
            </a>
          </div>
          <p class="text-lg font-semibold">
            <a v-if="bookingsByDateUrl" :href="bookingsByDateUrl">
              {{ booking.date }}
            </a>
            <template v-else>{{ booking.date }}</template>
          </p>
          <p class="text-lg font-semibold">{{ booking.appointment.room }}</p>
          <p class="text-lg font-semibold">{{ booking.start_at }} - {{ booking.stop_at }}</p>
          <p class="text-lg font-semibold">{{ booking.duration }} min</p>
          <div class="flex justify-between pt-smSpace text-sm">
            <p>{{ booking.title }}</p>
            <p v-if="!booking.is_cancelled">
              <v-icon
                icon="PencilSquareIcon"
                class="cursor-pointer hover:text-blue-600"
                @click="edit"
                v-if="!showEdit"
              ></v-icon>
              <a v-else class="btn-primary btn-sm" @click="updateBooking">{{ $t("generic.buttons.update") }}</a>
            </p>
            <v-badge
              v-else-if="booking.is_cancelled && booking.order_bookings.length === 1"
              type="error"
              :value="$t('booking.labels.cancelled')"
              class="self-center"
            />
          </div>

          <product-select
            class="mt-xsSpace"
            v-if="showEdit"
            :form="form"
            v-model:product-id="paymentForm.product_id"
            v-model:product-variant-id="paymentForm.product_variant_id"
            v-model:payment-value="paymentForm.payment.amount"
            :endpoint="productEndpoint"
          />
        </div>
        <div v-if="booking.order_bookings.length > 1" class="text-sm">
          <div
            v-for="booking in booking.order_bookings"
            class="mb-2xsSpace flex min-h-[50px] cursor-pointer flex-row items-center justify-between gap-1 rounded bg-slate-100 px-mdSpace py-2xsSpace"
          >
            <v-badge
              v-if="Boolean(booking.deleted_at)"
              :value="booking.status.value"
              :type="booking.status.type"
              class="self-center"
            />
            <strong class="flex-1" :title="booking.title">{{ booking.category }}</strong>
            <span>{{ booking.client.display_name }}</span>
            <span>({{ booking.client.email }})</span>
          </div>
        </div>
        <v-tag-cloud :tags="tags" @input="onUpdatedTags" />
        <div class="flex w-full flex-col justify-between">
          <div class="grow">
            <div class="activity flex flex-col gap-smSpace">
              <input-wrapper :form="form" :label-text="$t('booking.labels.booking_note')">
                <div class="content">
                  <div class="content__text">
                    <textarea
                      v-model="bookingNoteForm.booking_note"
                      class="content__text--textarea focus:ring-0"
                      rows="4"
                      :placeholder="$t('booking.placeholders.booking_note')"
                    ></textarea>
                  </div>
                  <div class="content__action w-20">
                    <div
                      class="content__action-button"
                      :class="{
                        'content__action-button--disable':
                          bookingNoteForm.booking_note === bookingNoteForm.initial.booking_note,
                      }"
                      @click="saveBookingNoteForm"
                    >
                      {{ $t("generic.buttons.save") }}
                    </div>
                  </div>
                </div>
              </input-wrapper>
              <input-wrapper :form="form" :label-text="$t('booking.labels.customer_note')">
                <div class="content">
                  <div class="content__text">
                    <textarea
                      v-model="customerNoteForm.customer_note"
                      class="content__text--textarea focus:ring-0"
                      rows="4"
                      :placeholder="$t('booking.placeholders.customer_note')"
                    />
                  </div>

                  <div class="content__action w-20">
                    <div
                      class="content__action-button"
                      :class="{
                        'content__action-button--disable':
                          customerNoteForm.customer_note === customerNoteForm.initial.customer_note,
                      }"
                      @click="saveCustomerNoteForm"
                    >
                      {{ $t("generic.buttons.save") }}
                    </div>
                  </div>
                </div>
              </input-wrapper>
            </div>
          </div>
        </div>
        <div class="space-y-lgSpace">
          <v-tabs :autoMinify="true" :tabs="visibleTabDataList" nav-class="-mb-px flex space-x-xsSpace">
            <template v-slot:history>
              <v-activity-log
                :filter-event="`activityTableFilterChange_${bookingId}`"
                :model-id="bookingId"
                :model-class="bookingClass"
                :allow-comment="true"
                :current-user="currentUser"
                :timezone="booking.timezone"
              >
                <v-filter
                  :filterEmitsTo="`activityTableFilterChange_${bookingId}`"
                  filter-endpoint="/api/generic/activity-logs/filters"
                  entity="activity-logs"
                  class="flex flex-row-reverse space-x-2 space-x-reverse"
                >
                </v-filter>
              </v-activity-log>
            </template>
            <template v-slot:appointment>
              <v-activity-log
                :filter-event="`activityTableFilterChange_${booking.appointment.id}`"
                :model-id="booking.appointment.id"
                :model-class="booking.appointment.class"
                :allow-comment="true"
                :current-user="currentUser"
              >
                <v-filter
                  :filterEmitsTo="`activityTableFilterChange_${booking.appointment.id}`"
                  filter-endpoint="/api/generic/activity-logs/filters"
                  entity="activity-logs"
                  class="flex flex-row-reverse space-x-2 space-x-reverse"
                >
                </v-filter>
              </v-activity-log>
            </template>
            <template v-slot:payment>
              <ul class="mt-xsSpace">
                <li class="flex justify-between gap-x-6 border-b px-xsSpace py-smSpace">
                  <div class="flex gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <strong class="text-sm leading-6">{{ $t("booking.labels.amount") }}</strong>
                    </div>
                  </div>
                  <div class="hidden sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6">
                      <strong>{{ toAUD(booking.payment_details.total) }}</strong>
                    </p>
                  </div>
                </li>
                <li>
                  <ul role="list" class="divide-y border-b" v-if="booking.payment_details.payments.length">
                    <li
                      v-for="payment in booking.payment_details.payments"
                      :key="payment.id"
                      class="flex justify-between gap-x-6 px-xsSpace py-smSpace"
                    >
                      <div class="flex gap-x-4 text-sm">
                        <div class="flex items-center gap-smSpace">
                          <div
                            class="relative cursor-pointer"
                            @mouseover="showToolTip($event, payment)"
                            @mouseout="hideToolTip($event, payment)"
                          >
                            <span v-html="payment.title"></span>
                            <div
                              :id="`payment-${payment.id}`"
                              class="-right-50 absolute hidden w-[250px] -translate-x-1.5 -translate-y-full items-center justify-center rounded-md bg-gray-800 p-smSpace text-xs text-white"
                              v-html="payment.tooltip"
                            ></div>
                          </div>
                          <a
                            v-if="isAdminOrManager && payment.is_cancellable"
                            class="btn-secondary !py-3xsSpace !text-sm"
                            @click="remove(payment)"
                          >
                            {{ $t("generic.buttons.remove") }}
                          </a>
                          <div
                            v-if="
                              isAdminOrManager &&
                              booking.is_refundable &&
                              payment.payment_method === 'square' &&
                              payment.amount > 0
                            "
                            class="flex gap-xsSpace"
                          >
                            <input
                              type="number"
                              class="!w-[80px] py-3xsSpace"
                              v-model="payment.actual_legacy_square_refundable_amount"
                            />
                            <a
                              :class="legacyRefundButtonClasses(payment)"
                              @click="confirmAndRemove(payment)"
                              class="btn-secondary flex !w-[64px] justify-center !rounded-lg !px-xsSpace !py-3xsSpace !text-sm"
                            >
                              <v-icon
                                v-if="paymentLoading"
                                icon="ArrowPathIcon"
                                class="h-lgSpace w-lgSpace animate-spin"
                              />
                              <span v-else>{{ $t("generic.buttons.refund") }}</span>
                            </a>
                          </div>
                          <span
                            v-if="
                              payment.payment_method === config.PAYMENT_CARD &&
                              payment.amount < 0 &&
                              payment.square_status !== 'COMPLETED'
                            "
                          >
                            ({{ payment.square_status }})
                          </span>
                          <span
                            v-else-if="
                              payment.payment_method === config.PAYMENT_CARD &&
                              payment.amount < 0 &&
                              payment.square_status === 'COMPLETED'
                            "
                          >
                            <a target="_blank" :href="payment.square_link">
                              <v-icon class="text-blue-400 hover:text-blue-600" icon="EyeIcon"></v-icon>
                            </a>
                          </span>
                        </div>
                      </div>
                      <div class="hidden sm:flex sm:flex-col sm:items-end">
                        <p class="text-sm leading-6">
                          <span v-if="payment.use"
                            >{{ payment.use }} {{ $t("generic.use") + (payment.use > 1 ? "s" : "") }}
                            {{ $t("gift_voucher.labels.redeemed") }}</span
                          >
                          <span v-else>{{ toAUD(payment.amount) }}</span>
                        </p>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="flex justify-between border-b">
                  <total-paid-details
                    :booking="booking"
                    :allow-refund="isAdminOrManager"
                    :on-pay-success="onPaySuccess"
                    :update-booking-payments="updateBookingPayments"
                  />
                </li>
                <li class="flex items-center justify-between border-b">
                  <balance-outstanding
                    :booking="booking"
                    v-model:notification-message="notificationMessage"
                    v-model:payment-form="paymentForm"
                    :on-pay-success="onPaySuccess"
                  />
                </li>
              </ul>
            </template>
            <template v-slot:details>
              <ul role="list" class="divide-y divide-gray-100">
                <li class="flex justify-between gap-x-6 py-smSpace">
                  <div class="flex gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6">
                        {{ $t("booking.columns.order_id") }}
                      </p>
                    </div>
                  </div>
                  <div class="hidden sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6">
                      {{ booking.order_id }}
                    </p>
                  </div>
                </li>
                <li
                  :key="field"
                  v-for="(value, field) in booking.details"
                  class="flex justify-between gap-x-6 py-smSpace"
                >
                  <div class="flex gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6">
                        {{ capitalizeText(field) }}
                      </p>
                    </div>
                  </div>
                  <div class="hidden sm:flex sm:flex-col sm:items-end">
                    <p class="text-sm leading-6">
                      <a v-if="field === 'email'" class="text-blue-600" :href="`mailto:${value}`">{{ value }}</a>
                      <a v-else-if="field === 'phone'" class="text-blue-600" :href="`tel:${value}`">{{ value }}</a>
                      <template v-else>
                        {{ value }}
                      </template>
                    </p>
                  </div>
                </li>
              </ul>
              <div class="flex gap-x-4">
                <div class="min-w-0 flex-auto">
                  <p class="text-sm font-semibold leading-6">
                    {{ $t("booking.headings.forms") }}
                  </p>
                </div>
              </div>
              <template v-if="booking.legacy_forms.length !== 0" v-for="legacyFormData in booking.legacy_forms">
                <div class="mb-mdSpace bg-slate-50 p-mdSpace pb-9">
                  <div class="mb-2xsSpace flex flex-row justify-between rounded bg-slate-100 p-mdSpace text-sm">
                    <span>{{ legacyFormData.acuity_values.name }}</span>
                  </div>
                  <template v-for="field in legacyFormData.acuity_values.values" :key="field.id">
                    <div class="mb-2xsSpace flex flex-row justify-between rounded bg-slate-100 p-smSpace text-sm">
                      <p>
                        <strong>{{ $t("booking.headings.question") }}</strong
                        >: {{ field.name }}
                        <br />
                        <strong>{{ $t("booking.headings.answer") }}</strong
                        >: {{ field.value }}
                      </p>
                    </div>
                  </template>
                </div>
              </template>

              <template v-for="formData in booking.forms">
                <div class="mb-2xsSpace flex flex-row justify-between rounded bg-slate-100 p-mdSpace text-sm">
                  <span>{{ formData.form.title }}</span>
                  <a @click="editForm(formData.form.id)" v-text="$t('generic.buttons.edit')" class="cursor-pointer"></a>
                </div>
                <div
                  v-if="form.required_forms.includes(formData.form.id)"
                  class="v-booking-details-form mb-mdSpace bg-slate-50 pb-9"
                >
                  <template v-for="field in formData.form.fields" :key="field.id">
                    <input-wrapper
                      class="p-mdSpace"
                      :class="'input-wrapper input-wrapper--' + field.type"
                      :field="fieldName(field, formData.form.id)"
                      :form="form"
                      :label-text="field.label"
                      :is-required="field.required"
                    >
                      <v-field
                        class="v-field-custom-booking v-field-admin"
                        :class="{ '-checkbox': field.type === 'checkbox' }"
                        v-model="form[formName(formData.form.id)][fieldName(field)]"
                        :name="fieldName(field, formData.form.id)"
                        :type="field.type"
                        :options="field.options"
                        :placeholder="field.placeholder"
                        :field="field"
                        :editable="!booking.is_cancelled"
                      ></v-field>
                    </input-wrapper>
                  </template>
                  <a
                    @click="editForm(formData.form.id, true)"
                    class="btn-secondary btn-sm float-right"
                    v-text="$t('generic.buttons.close')"
                  ></a>
                </div>
              </template>
              <a
                v-if="form.required_forms.length && !booking.is_cancelled"
                @click.prevent="saveForm"
                class="btn-primary btn-sm float-right my-1"
                v-text="$t('generic.buttons.update')"
              ></a>
            </template>
            <template v-slot:client>
              <ul role="list" class="divide-y divide-gray-100">
                <li class="flex items-center justify-between gap-x-6 py-smSpace">
                  <div class="flex gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6">
                        {{ $t("customer.headings.customer") }}
                      </p>
                    </div>
                  </div>
                  <div class="flex justify-between" :class="{ 'w-full': showEditClient }">
                    <a
                      v-if="!showEditClient"
                      class="text-sm font-semibold leading-6"
                      :href="`/admin/settings/customers/${booking.client_id}/edit`"
                      target="_blank"
                    >
                      {{ booking.client }}
                    </a>
                    <div class="relative w-full" v-else>
                      <customer-input
                        :form="paymentForm"
                        v-model:customer-id="paymentForm.client_id"
                        v-model:first-name="paymentForm.first_name"
                        v-model:last-name="paymentForm.last_name"
                        hide-label
                        @selectClient="selectClient($event)"
                        class="[&_.v-field-label]:hidden"
                      />
                    </div>
                    <p class="flex items-center pl-smSpace">
                      <v-icon
                        icon="PencilSquareIcon"
                        class="cursor-pointer hover:text-blue-600"
                        @click="editClient"
                        v-if="!showEditClient"
                      ></v-icon>
                      <a v-else class="btn-primary btn-sm !py-[4px] pl-smSpace" @click="updateBooking">
                        {{ $t("generic.buttons.update") }}
                      </a>
                    </p>
                  </div>
                </li>
                <li v-for="(value, field) in booking.clients" class="flex justify-between gap-x-6 py-smSpace">
                  <div class="flex gap-x-4">
                    <div class="min-w-0 flex-auto">
                      <p class="text-sm font-semibold leading-6">
                        {{ capitalizeText(field) }}
                      </p>
                    </div>
                  </div>
                  <div class="hidden sm:flex sm:flex-col sm:items-end" v-if="value && value.hasOwnProperty('panel')">
                    <a class="cursor-pointer text-sm leading-6 hover:text-blue-400" @click="openSidePanel(value)">
                      {{ value.title }}
                    </a>
                  </div>
                  <div class="hidden sm:flex sm:flex-col sm:items-end" v-else>
                    <p class="text-sm leading-6">
                      <a v-if="field === 'email'" class="text-blue-600" :href="`mailto:${value}`">{{ value }}</a>
                      <a v-else-if="field === 'phone'" class="text-blue-600" :href="`tel:${value}`">{{ value }}</a>
                      <a v-else-if="field === 'has_card'">{{ value ? "Yes" : "No" }}</a>
                      <template v-else>
                        {{ value }}
                      </template>
                    </p>
                  </div>
                </li>
              </ul>
            </template>
            <!--            <template v-slot:staff>-->
            <!--              <ul role="list" class="divide-y divide-gray-100" v-if="booking.is_assigned">-->
            <!--                <li v-for="(value, field) in booking.staffs" class="flex justify-between gap-x-6 py-smSpace">-->
            <!--                  <div class="flex gap-x-4">-->
            <!--                    <div class="min-w-0 flex-auto">-->
            <!--                      <p class="text-sm font-semibold leading-6 text-gray-900">-->
            <!--                        {{ capitalizeText(field) }}-->
            <!--                      </p>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="hidden sm:flex sm:flex-col sm:items-end">-->
            <!--                    <p class="text-sm leading-6 text-gray-900">{{ value }}</p>-->
            <!--                  </div>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--              <div class="mt-mdSpace text-sm" v-else>{{ $t("booking.headings.empty_staff") }}</div>-->
            <!--            </template>-->
            <template v-slot:reschedule>
              <reschedule-booking-selector
                :bookings="booking.order_bookings"
                :current-booking="booking"
                :current-user="currentUser"
                :show-new-scheduled-booking="showNewScheduledBooking"
              />
            </template>
            <template v-slot:cancel>
              <cancel-booking-selector :in-panel="inPanel" :booking="booking" :current-user="currentUser" />
            </template>
          </v-tabs>
        </div>
      </div>
      <div class="fixed bottom-0 rounded-r bg-white pb-smSpace pr-smSpace pt-smSpace text-sm">
        <p>
          {{ $t("booking.labels.scheduled_by") }} <strong>{{ booking.created_by }}</strong>
        </p>
        <p v-if="booking.updated_by">
          {{ $t("booking.labels.updated_by") }} <strong>{{ booking.updated_by }}</strong>
        </p>
      </div>
    </div>
    <div class="fixed top-0 w-1/3 rounded-r bg-white px-smSpace pt-smSpace text-sm">
      <v-notification v-if="notificationMessage" class="" :type="notificationType" :content="notificationMessage">
      </v-notification>
    </div>
  </form>
  <div
    v-else
    aria-label="Loading..."
    role="status"
    class="absolute left-1/2 top-1/2 flex -translate-x-1/2 items-center justify-center space-x-2"
  >
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>
<script>
import axios from "axios";
import VField from "~vendor/form-builder/resources/js/components/VField.vue";
import Form from "form-backend-validation";
import ProductSelect from "@/js/vue/components/bookings/ProductSelect.vue";
import Payment from "@/js/vue/mixins/Payment";
import BalanceOutstanding from "./BalanceOutstanding.vue";
import TotalPaidDetails from "./TotalPaid.vue";
import moment from "moment";
import RescheduleBookingSelector from "@/js/vue/components/bookings/RescheduleBookingSelector.vue";

export default {
  inject: ["config", "bus", "notificationService"],
  components: { RescheduleBookingSelector, ProductSelect, VField, BalanceOutstanding, TotalPaidDetails },
  mixins: [Payment],
  props: {
    columnIndex: {
      type: Number,
    },
    bookingId: {
      type: Number,
    },
    bookingClass: {
      type: String,
    },
    currentUser: {
      type: Object,
    },
    showAddTask: {
      type: Boolean,
      default: false,
    },
    inPanel: {
      type: Boolean,
      default: false,
    },
    defaultTab: {
      type: String,
      default: "Payment",
    },
    visibleTabs: {
      type: Array,
      default: () => ["Details", "Client", "Payment", "History", "Appointment", "Reschedule", "Cancel"],
    },
    forceCheckTerminalPayments: {
      type: [Boolean],
      default: false,
    },
    showNewScheduledBooking: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasAddOn() {
      return this.booking.order_bookings.filter((o) => o.linked_id).length > 0;
    },
    primaryBookings() {
      return this.booking.order_bookings
        .filter((o) => {
          if (this.hasAddOn) {
            return !o.linked_id;
          }
          return true;
        })
        .map((o) => {
          return o;
        });
    },
    tags() {
      if (!this.booking) {
        return [];
      }

      return this.booking.labels.map((o) => {
        return {
          name: o.value,
          colour: o.type,
          id: o.id,
        };
      });
    },

    visibleTabDataList() {
      return this.tabData.filter((tab) => this.visibleTabs.includes(tab.name));
    },

    bookingsByDateUrl() {
      if (!this.booking || !this.booking.date) return null;

      return `/admin/bookings?filter[date]=${moment(this.booking.date, "dddd, MMMM Do YYYY").format("DD-MM-YYYY")}`;
    },
    productEndpoint() {
      return `/api/admin/products?filter[active]=1&filter[room]=${this.booking.appointment.room_id}&filter[duration]=${this.booking.appointment.duration}`;
    },
    roles() {
      return (this.currentUser?.roles || []).map((role) => role.name) || [];
    },
    isAdminOrManager() {
      if (!this.roles) {
        return false;
      }

      return (
        this.roles.includes(this.config.roles.admin.toLowerCase()) ||
        this.roles.includes(this.config.roles.manager.toLowerCase())
      );
    },
  },
  filters: {
    capitalize(value) {
      if (!value) return "";

      value = value.toString();

      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  data() {
    return {
      loading: false,
      paymentLoading: false,
      booking: {},
      showEdit: false,
      showEditClient: false,
      paymentForm: new Form(
        {
          client_id: null,
          customer_id: null,
          first_name: null,
          last_name: null,
          type: null,
          product_id: null,
          product_variant_id: null,
          venue_id: null,
          vouchers: [],
          payment: {
            type: null,
            amount: null,
          },
        },
        { resetOnSuccess: false },
      ),
      form: new Form(),
      bookingNoteForm: new Form({ booking_note: this.booking?.note || "" }, { resetOnSuccess: false }),
      customerNoteForm: new Form(
        { customer_note: this.booking?.appointment?.customer_note || "" },
        { resetOnSuccess: false },
      ),
      tabData: [
        { name: "Details", active: false },
        { name: "Client", active: false },
        { name: "Payment", active: false },
        { name: "History", active: false },
        { name: "Appointment", active: false },
      ],
      notificationMessage: null,
      notificationType: "success",
      tooltipTimeout: null,
    };
  },
  created() {
    this.bus.$on("closePanelInPanel", () => {
      this.$refs.sidePanel && this.$refs.sidePanel.close();
    });

    this.getBooking(false, () => {
      if (this.forceCheckTerminalPayments) {
        this.checkPayments(this.booking.order_id).then((response) => {
          this.onPaySuccess(response);
        });
      }
    });
  },
  methods: {
    legacyRefundButtonClasses(payment) {
      return {
        "!bg-gray-200 !cursor-not-allowed": this.paymentLoading || payment.legacy_square_refundable_amount <= 0,
        "hover:bg-neutral-100 cursor-pointer": payment.legacy_square_refundable_amount > 0,
      };
    },
    confirmAndRemove(payment) {
      if (
        payment.actual_legacy_square_refundable_amount <= 0 ||
        payment.legacy_square_refundable_amount <= 0 ||
        payment.actual_legacy_square_refundable_amount > payment.legacy_square_refundable_amount ||
        this.paymentLoading
      ) {
        return;
      }

      this.bus.$emit("openModal", {
        componentName: "RefundConfirmation",
        componentData: {
          header: this.$t("payment.headings.refund"),
          payment: payment.raw_data,
          amount: payment.actual_legacy_square_refundable_amount,
        },
        callback: () => {
          this.remove(payment);
        },
      });
    },
    remove(payment) {
      if (
        this.paymentLoading ||
        (payment.payment_method === "square" && payment.legacy_square_refundable_amount <= 0)
      ) {
        return;
      }

      this.paymentLoading = true;
      let url = `/api/admin/payments/${payment.id}`;
      let amount = 0;
      if (payment.payment_method === "square" && payment.actual_legacy_square_refundable_amount > 0) {
        amount = payment.actual_legacy_square_refundable_amount;
        url = url + "?amount=" + amount;
      }

      axios
        .post(url)
        .then(() => {
          this.getBooking(true, () => {
            if (this.forceCheckTerminalPayments && amount > 0) {
              this.checkPayments(this.booking.order_id).then((response) => {
                this.onPaySuccess(response);
              });
            }

            this.bus.$emit("bookingsChange", {
              column: this.booking.appointment,
            });
          });
          this.resetForm();

          this.paymentLoading = false;
        })
        .catch(({ response }) => {
          setTimeout(() => {
            this.notificationType = "success";
            this.notificationMessage = null;
          }, 1500);
          this.paymentLoading = false;
          this.notificationType = "error";
          this.notificationMessage = response.data.message;
        });
    },
    showToolTip($event, payment) {
      const el = document.getElementById(`payment-${payment.id}`);
      this.tooltipTimeout = setTimeout(() => {
        el.style = "display: inline;";

        setTimeout(() => {
          el.style = "display: hidden;";
          clearTimeout(this.tooltipTimeout);
        }, 10000);
      }, 100);
    },
    hideToolTip($event, payment) {
      const el = document.getElementById(`payment-${payment.id}`);
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }

      el.style = "display: hidden;";
    },
    copyLink(url) {
      navigator.clipboard.writeText(url);
      this.notificationMessage = this.$t("generic.responses.copy_success");
      setTimeout(() => {
        this.notificationMessage = null;
      }, 1500);
    },
    close() {
      if (this.inPanel) {
        this.bus.$emit("closePanelInPanel");
        return;
      }
      this.bus.$emit("closePanel");
    },
    formName(formId) {
      return `form${formId}`;
    },
    fieldName(field, formId) {
      if (formId) {
        return `form${formId}.${field.name}`;
      }
      return `${field.name}`;
    },
    editForm(formId, isRemove = false) {
      if (isRemove) {
        const index = this.form.required_forms.indexOf(formId);
        if (index !== -1) {
          this.form.required_forms.splice(index, 1);
        }
        return;
      }
      if (!this.form.required_forms.includes(formId)) {
        this.form.required_forms.push(formId);
      }
    },
    updateBooking() {
      this.paymentForm.patch(`/api/admin/bookings/${this.bookingId}`).then((data) => {
        this.getBooking(true);
        this.showEdit = false;
        this.showEditClient = false;
        this.bus.$emit("bookingsChange", { columnIndex: this.columnIndex, appointment: data });
      });
    },
    updateBookingPayments(response) {
      this.booking.payment_details = response.data.payment_details;
      this.booking.is_paid = this.booking.payment_details.balance === 0;
      this.paymentForm.payment.amount = this.booking.payment_details.balance;
    },
    onPaySuccess(response) {
      this.resetForm();
      if (response && Object.keys(response).length) {
        this.updateBookingPayments(response);
        this.notificationMessage = this.$t("booking.responses.payment_updated");

        setTimeout(() => {
          this.notificationMessage = null;
        }, 1500);
      }
    },
    resetForm() {
      this.paymentForm.payment.type = null;
      this.paymentForm.payment.amount = null;
      this.paymentForm.payment.token = null;
    },

    onUpdatedTags(tags) {
      axios
        .post(`/api/generic/labels`, {
          labels: tags,
          labellable_type: this.bookingClass,
          labellable_id: this.bookingId,
        })
        .then(({ data }) => {
          this.bus.$emit("bookingsChange", { columnIndex: this.columnIndex, appointment: data });
        });
    },
    getBooking(reloadBookingOnly = false, callable = () => {}) {
      this.loading = true;
      axios.get(`/api/admin/bookings/${this.bookingId}`).then(({ data }) => {
        this.booking = data;
        this.paymentForm.client_id = data.client_id; // Todo recheck customer/clientid
        this.paymentForm.customer_id = data.customer_id; // Todo recheck customer/clientid
        this.paymentForm.first_name = data.first_name;
        this.paymentForm.last_name = data.last_name;
        this.paymentForm.product_id = data.product_id;
        this.paymentForm.product_variant_id = data.product_variant_id;
        this.paymentForm.quantity = data.payment_details.unpaid_bath_count;
        this.paymentForm.venue_id = data.venue_id;
        this.paymentForm.payment.amount = this.booking.payment_details.balance;

        this.bookingNoteForm.withData({
          booking_note: data.note,
        });
        this.customerNoteForm.withData({
          customer_note: data.appointment.customer_note,
        });
        this.generateForm();

        if (!this.booking.is_cancelled) {
          const newItems = [
            { name: "Reschedule", active: false },
            { name: "Cancel", active: false },
          ];

          const uniqueNewItems = newItems.filter((newItem) => {
            return !this.tabData.some((existingItem) => existingItem.name === newItem.name);
          });

          this.tabData.push(...uniqueNewItems);
        }

        if (!reloadBookingOnly) {
          this.tabData.map((tab) => {
            if (tab.name === this.defaultTab) {
              tab.active = true;
            }
          });
        }
        this.loading = false;
        callable();
      });
    },
    generateForm() {
      const formDetail = {
        required_forms: [],
      };
      if (this.booking.product_forms.length) {
        this.booking.product_forms.forEach((formData) => {
          const formValue = this.booking.forms.find((form) => form.form_id === formData.id);
          const data = formValue ? formValue.values : formData.fields;
          formDetail[`form${formData.id}`] = {};
          data.map((o) => {
            formDetail[`form${formData.id}`][`${o.name}`] = formValue ? o.value : o.type === "check-group" ? [] : "";
          });
        });
      }
      this.form = new Form(formDetail, { resetOnSuccess: false });
    },
    saveForm() {
      this.form.post(`/api/admin/bookings/${this.bookingId}/update-forms`).then((_data) => {
        this.notificationMessage = this.$t("generic.responses.update_success");
        setTimeout(() => {
          this.notificationMessage = null;
        }, 1500);
        this.getBooking();
      });
    },
    saveBookingNoteForm() {
      if (this.bookingNoteForm.booking_note === this.bookingNoteForm.initial.booking_note) {
        return;
      }

      this.bookingNoteForm.patch(`/api/admin/bookings/${this.bookingId}`).then(() => {
        this.notificationMessage = this.$t("generic.responses.update_success");
        setTimeout(() => {
          this.notificationMessage = null;
        }, 1500);
        this.bookingNoteForm.withData(this.bookingNoteForm.data());
      });
    },
    saveCustomerNoteForm() {
      if (this.customerNoteForm.customer_note === this.customerNoteForm.initial.customer_note) {
        return;
      }

      this.customerNoteForm.patch(`/api/admin/bookings/${this.bookingId}`).then((data) => {
        this.notificationMessage = this.$t("generic.responses.update_success");
        setTimeout(() => {
          this.notificationMessage = null;
        }, 1500);
        this.customerNoteForm.withData({
          customer_note: data.customer_note,
        });
      });
    },
    capitalizeText(text) {
      if (!text) return "";
      return text.charAt(0).toUpperCase() + text.replace("_", " ").slice(1);
    },
    edit() {
      this.showEdit = !this.showEdit;
    },

    editClient() {
      this.showEditClient = !this.showEditClient;
    },
    selectClient(client) {
      this.paymentForm.client_id = client.id;
      this.paymentForm.first_name = client.first_name;
      this.paymentForm.last_name = client.last_name;

      this.booking.client_first_name = client.first_name;
      this.booking.client_last_name = client.last_name;
      this.booking.clients.email = client.email;
      this.booking.clients.phone = client.phone;
      this.booking.clients.address = client.address;
    },

    clickAddTask() {
      this.$refs.sidePanel.open(
        "TaskEdit",
        {
          taskable: {
            class: this.config.taskTypes.booking,
            id: this.bookingId,
          },
          inPanel: true,
        },
        `${this.$t("task.labels.add_for")} ${this.booking.client} - ${this.booking.product_variant}`,
        () => {},
      );
    },

    openSidePanel(sidePanel) {
      if (sidePanel && sidePanel.panel) {
        this.$refs.sidePanel.open(sidePanel.panel, sidePanel.panel_data, "", () => {});
      }
    },

    async sendEmail(type) {
      let url = "";
      switch (type) {
        case "confirmation":
          url = `/api/admin/bookings/${this.booking.id}/send-confirmation`;
          break;
        case "waiver":
          url = `/api/admin/customers/${this.booking.client_id}/waivers/send`;
          break;
        case "hold":
          url = `/api/admin/bookings/${this.booking.id}/send-hold-email`;
          break;
        default:
          url = `/api/admin/bookings/${this.booking.id}/${type}`;
          break;
      }

      return axios.post(url).then(({ data }) => {
        this.notificationMessage = data.statusText;
        setTimeout(() => {
          this.notificationMessage = null;
        }, 1500);
        this.$refs.actions.active = false;
      });
    },
    async addRescheduleBookingTask() {
      return axios
        .post(`/api/admin/bookings/reschedule-tasks`, {
          ids: [this.booking.id],
        })
        .then((response) => {
          this.$refs.actions.active = false;
          this.notificationMessage = response.data.statusText;
          setTimeout(() => {
            this.notificationMessage = null;
          }, 1500);
          this.getBooking(true);

          this.bus.$emit("bookingsChange", {
            column: {
              capacity_index: response.data.bookings[0].appointment.capacity_index,
              room_id: response.data.bookings[0].appointment.room_id,
            },
          });
        });
    },
  },
};
</script>
