const state = () => ({
  isExpanded: JSON.parse(sessionStorage.getItem("isExpanded")) ?? false,
  zoom: Number(sessionStorage.getItem("zoomValue") ?? 0.4),
  mode: null,
  venueId: Number(sessionStorage.getItem("venueId") ?? 0.4),
  forPreview: false,
  forDefault: false,
});

const getters = {};

const actions = {
  setExpanded({ commit }, isExpanded) {
    commit("setExpanded", isExpanded);
  },
  setZoom({ commit }, value) {
    commit("setZoom", value);
  },
  setMode({ commit }, value) {
    commit("setMode", value);
  },
};

const mutations = {
  setExpanded(state, isExpanded) {
    state.isExpanded = isExpanded;
    sessionStorage.setItem("isExpanded", isExpanded);
  },
  setZoom(state, value) {
    state.zoom = value;
    sessionStorage.setItem("zoomValue", value);
  },
  setMode(state, value) {
    state.mode = value;
    if (value === "roster") {
      state.forDefault = false;
      state.forPreview = false;
    }

    if (value === "default") {
      state.forDefault = true;
      state.forPreview = false;
    }

    if (value === "preview") {
      state.forPreview = true;
      state.forDefault = false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
