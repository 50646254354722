<template>
  <label
    :for="field"
    class="block space-y-2xsSpace text-sm font-medium leading-none text-tertiary-700"
    :class="{ 'form-error': form.errors.has(field) }"
  >
    <span
      v-if="labelText || $slots.label"
      class="v-field-label"
      :class="{ 'text-error-700': highlightErrorLabel && !darkTheme && form.errors.has(field) }"
    >
      <template v-if="$slots.label">
        <slot name="label"></slot>
      </template>
      <template v-else>
        {{ labelText }}
        <span v-if="isRequired"> *</span>
      </template>
    </span>
    <slot />
    <div v-if="form.errors.has(field)" class="v-field-error flex rounded-md bg-pink-100 px-xsSpace py-1">
      <v-icon icon="SolidExclamationCircleIcon" classes="w-5 h-5 text-red-400 mr-2xsSpace"></v-icon>
      <small class="block text-sm text-red-500">{{ form.errors.first(field) }}</small>
    </div>
  </label>
</template>

<script>
export default {
  name: "InputWrapper",
  props: {
    form: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    labelText: {
      type: String,
    },
    darkTheme: {
      type: Boolean,
      required: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
    },
    highlightErrorLabel: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
<style>
.form-error {
  input,
  select,
  textarea {
    @apply border-red-400;
  }
}
</style>
