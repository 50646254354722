<template>
  <div v-if="success" class="flex flex-col space-y-lgSpace py-2lgSpace text-white2">
    <p class="text-center font-sloganSerif text-6xl font-bold">{{ $t("wait_list.headings.completed") }}</p>
    <p class="text-center text-xl font-normal">{{ $t("wait_list.headings.completed_subtitle") }}</p>
    <div class="flex flex-col space-y-lgSpace pb-lgSpace">
      <div class="flex flex-col rounded-lg border border-white bg-brand p-smSpace">
        <div class="flex flex-col space-y-2 text-white2">
          <p class="m-0 text-lg font-semibold">{{ $t("wait_list.headings.summary") }}</p>
          <div class="flex flex-col space-y-smSpace rounded-lg bg-white bg-opacity-10 p-smSpace">
            <span class="text-lg font-semibold">
              {{ selectedProduct?.name || `${$t("generic.words.any")} - ${selectedCategory.name}` }}
            </span>
            <div class="flex flex-col">
              <span class="text-xs font-normal text-white2/80">
                {{ $t("wait_list.labels.prefered_date") }}
              </span>
              <span class="text-base">
                <template v-if="form.day_of_week.length">
                  {{ form.day_of_week.map((day) => weekdays.find((o) => o.id === day).label).join(", ") }}
                </template>
                <template v-else-if="form.date">
                  {{ form.date }}
                </template>
                <template v-else>
                  {{ $t("generic.words.any") }}
                </template>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-mdSpace rounded-lg border border-white bg-brand p-smSpace">
        <div class="flex flex-col space-y-2 text-white2">
          <p class="m-0 text-lg font-semibold">{{ $t("wait_list.labels.contact_details") }}</p>
          <div class="space-y-0 text-white2">
            <p class="text-xs text-white2/50">{{ $t("wait_list.labels.contact_details") }}</p>
            <p class="text-base">{{ form.first_name }} {{ form.last_name }}</p>
            <p class="text-base">{{ form.email }}</p>
            <p class="text-base">{{ form.phone }}</p>
          </div>
          <div v-if="form.vouchers.length" class="space-y-0 text-white2">
            <p class="text-xs text-white2/50">{{ $t("wait_list.columns.voucher") }}</p>
            <p class="text-base">{{ form.vouchers.join(", ") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <template v-else>
    <div class="space-y-4 rounded-xl bg-white/10 p-4 text-base leading-[21px] text-white2">
      <p>{{ $t("wait_list.headings.intro") }}</p>
    </div>
    <div class="mb-lgSpace mt-4 space-y-6 text-white2">
      <input-wrapper
        class="text-white2"
        field="email"
        :form="form"
        :label-text="$t('wait_list.labels.email')"
        dark-theme
      >
        <div class="flex flex-col">
          <input class="bg-brand" type="text" v-model="form.email" :placeholder="$t('wait_list.placeholders.email')" />
          <span class="my-xsSpace text-white2 opacity-50">
            {{ $t("wait_list.placeholders.email_notification") }}
          </span>
        </div>
      </input-wrapper>

      <input-wrapper
        class="text-white2"
        field="phone"
        :form="form"
        :label-text="$t('wait_list.labels.phone')"
        :dark-theme="true"
      >
        <phone-number v-model="form.phone" :placeholder="$t('wait_list.placeholders.phone')" dark-mode />
      </input-wrapper>
      <div class="flex flex-col gap-1 md:flex-row">
        <input-wrapper
          class="flex-1 text-white2"
          field="first_name"
          :form="form"
          :label-text="$t('wait_list.labels.customer_name')"
          :dark-theme="true"
        >
          <input
            class="bg-brand"
            type="text"
            v-model="form.first_name"
            :placeholder="$t('wait_list.placeholders.customer_first_name')"
          />
        </input-wrapper>
        <input-wrapper class="flex-1 text-white2" field="last_name" :form="form" label-text="&nbsp;" :dark-theme="true">
          <input
            class="bg-brand"
            type="text"
            v-model="form.last_name"
            :placeholder="$t('wait_list.placeholders.customer_last_name')"
          />
        </input-wrapper>
      </div>

      <div class="flex flex-col">
        <label class="mb-2xsSpace text-sm font-normal leading-normal text-white2">
          <span class="text-md">{{ $t("wait_list.columns.voucher") }}</span>
          <br />
          <span class="text-xs">{{ $t("wait_list.columns.voucher_description") }}</span>
        </label>
        <voucher-select v-model="form.vouchers" hide-label dark-theme></voucher-select>
      </div>

      <input-wrapper class="text-white2" field="venue_id" :form="form" :label-text="$t('wait_list.columns.venue')">
        <v-multi-select
          v-model="form.venue_id"
          search-url="/api/client/venues?filter[active]=1"
          :placeholder="$t('wait_list.columns.venue')"
          track-by="id"
          label="name"
          search-key="filter[name]"
          class-name="client"
          :allow-empty="false"
        />
      </input-wrapper>
      <div class="space-y-2">
        <label class="text-sm font-medium text-white2">
          {{ $t("wait_list.headings.select_a_category") }}
        </label>
        <div class="grid grid-cols-2 gap-6">
          <button
            v-for="category in categories"
            class="btn-secondary !block items-center !bg-brand !py-4 font-sloganSerif !text-[26px] !font-bold !text-white2"
            :class="selectedCategory && category.id === selectedCategory.id ? 'opacity-100' : 'opacity-50'"
            @click="selectCategory(category)"
          >
            {{ category.name }}
          </button>
        </div>
        <div v-if="form.errors.has('category_id')" class="flex w-full rounded-md bg-pink-100 px-xsSpace py-1">
          <v-icon icon="SolidExclamationCircleIcon" classes="w-5 h-5 text-red-400 mr-2xsSpace"></v-icon>
          <small class="block text-sm text-red-500"> {{ form.errors.first("category_id") }}</small>
        </div>
      </div>
      <div
        v-if="selectedCategory && form.venue_id && !form.product_id"
        class="flex flex-col rounded-xl bg-white p-mdSpace pb-xsSpace"
      >
        <p class="block text-lg font-semibold text-brand">
          {{ $t("generic.words.any") }} - {{ selectedCategory.name }}
        </p>
        <div
          class="flex items-center gap-4 py-xsSpace"
          :class="{ 'justify-between': fromPrice, 'justify-end': !fromPrice }"
        >
          <span v-if="fromPrice" class="text-sm text-brand">{{ $t("generic.from") }} ${{ fromPrice }}</span>
          <a
            class="btn-secondary btn-md border-2"
            :class="isJoinWaitListSpecific ? '!bg-brand !text-white2' : '!border-0 !bg-white !text-brand'"
            @click="toggleAny"
          >
            <template v-if="isJoinWaitListSpecific">{{ $t("generic.buttons.select") }}</template>
            <v-icon v-else icon="TrashIcon" class="h-4 w-4"></v-icon>
          </a>
        </div>
      </div>

      <product-list
        v-if="form.venue_id && isJoinWaitListSpecific"
        :category-id="form.product_category_id"
        :venue-id="form.venue_id"
        v-model="form.product_id"
        v-model:products="products"
        @update:modelValue="isJoinWaitListSpecific = true"
        :hide-others="true"
      />

      <div class="space-y-2xsSpace">
        <div class="leading-normal text-white2">
          <p class="m-0">{{ $t("wait_list.headings.which_date_prefer") }}</p>
          <p class="m-0 text-white2 text-opacity-70">{{ $t("wait_list.placeholders.date_prefer_intro") }}</p>
        </div>
        <div class="space-y-4">
          <div class="my-smSpace flex flex-col gap-smSpace !text-base !text-white2">
            <label class="flex items-center space-x-xsSpace">
              <input type="radio" name="prefer" value="any" v-model="prefer" />
              <span class="cursor-pointer text-sm">{{ $t("wait_list.labels.any_date_works") }}</span>
            </label>
            <label class="flex items-center space-x-xsSpace">
              <input type="radio" name="prefer" value="date" v-model="prefer" />
              <span class="cursor-pointer text-sm">{{ $t("wait_list.labels.select_a_date") }}</span>
            </label>
            <input-wrapper v-if="prefer === 'date'" class="text-white2" field="date" :form="form">
              <date-picker
                v-model:value="form.date"
                :placeholder="$t('wait_list.placeholders.date')"
                format="DD-MM-YYYY"
                value-type="format"
                type="date"
                class="block !w-full"
              />
            </input-wrapper>
            <label class="flex items-center space-x-xsSpace">
              <input type="radio" name="prefer" value="days_of_week" v-model="prefer" />
              <span class="cursor-pointer text-sm">{{ $t("wait_list.labels.days_of_week") }}</span>
            </label>
            <div v-if="prefer === 'days_of_week'" class="grid grid-cols-3 gap-4 md:grid-cols-4 md:pl-7">
              <label v-for="day in weekdays" :key="day.id" class="flex items-center space-x-xsSpace">
                <input type="checkbox" :value="day.id" v-model="form.day_of_week" />
                <span class="cursor-pointer text-sm">{{ day.label }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <button @click="submit" class="btn-secondary !block w-full items-center !bg-white !text-brand">
        {{ $t("generic.buttons.submit") }}
      </button>
    </div>
  </template>
</template>

<script>
import Form from "form-backend-validation";
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "WaitListOrder",
  inject: ["notificationService", "sessionStorageService"],
  props: {
    weekdays: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      categories: [],
      selectedCategory: null,
      isJoinWaitListSpecific: true,
      products: [],
      success: false,
      prefer: null,
      form: new Form(
        {
          email: "",
          phone: "",
          first_name: "",
          last_name: "",
          vouchers: [],
          venue_id: null,
          product_id: null,
          product_category_id: null,
          date: null,
          day_of_week: [],
        },
        { resetOnSuccess: false },
      ),
    };
  },
  mounted() {
    this.initiateCategories();
    const bookingInfo = this.sessionStorageService.getBookingInfoData();
    if (window.location.search === "?booking" && bookingInfo) {
      Object.keys(bookingInfo).forEach((key) => {
        this.form[key] = bookingInfo[key];
      });
      return;
    }
    const id = parseInt(localStorage.getItem("customerId"));
    if (id) {
      this.$store.dispatch("customer/fetchCustomer", id);
    }
  },
  computed: {
    ...mapState({
      currentCustomer: (state) => state.customer.currentCustomer,
    }),
    selectedCategory() {
      return this.categories.find((o) => o.id === this.form.product_category_id);
    },
    selectedProduct() {
      return this.products.find((o) => o.id === this.form.product_id);
    },
    fromPrice() {
      if (!this.selectCategory || !Array.isArray(this.products) || !this.products.length) {
        return null;
      }

      return this.products.sort((a, b) => a.lowest_price - b.lowest_price)[0].lowest_price;
    },
  },
  watch: {
    currentCustomer: {
      handler(newValue, oldValue) {
        this.form.email = this.getFieldValue("email", null);
        this.form.phone = this.getFieldValue("phone", null);
        this.form.first_name = this.getFieldValue("first_name", null);
        this.form.last_name = this.getFieldValue("last_name", null);
      },
      deep: true,
    },
    prefer(newValue) {
      if (newValue === "any") {
        this.form.date = null;
        this.form.day_of_week = [];
      }

      if (newValue === "date") {
        this.form.day_of_week = [];
      }

      if (newValue === "days_of_week") {
        this.form.date = null;
      }
    },
  },
  methods: {
    initiateCategories() {
      axios.get("/api/generic/product-categories?filter[parent]=true").then(({ data }) => {
        this.categories = data.data.map((o) => {
          o.active = false;

          return o;
        });

        if (this.form.product_category_id) {
          const category = this.categories.find((o) => o.id === this.form.product_category_id);
          if (category) {
            this.selectCategory(category, false);
          }
        }
      });
    },
    selectCategory(item, reset = true) {
      this.form.product_category_id = item.id;
      this.form.product_id = reset ? null : this.form.product_id;
    },
    toggleAny() {
      this.form.product_id = null;
      this.isJoinWaitListSpecific = !this.isJoinWaitListSpecific;
    },
    getFieldValue(field, defaultValue = null) {
      if (this.currentCustomer.hasOwnProperty(field)) {
        return this.currentCustomer[field];
      }

      return defaultValue;
    },
    submit() {
      this.form.post("/api/wait-lists").then(() => {
        this.notificationService.notify(this.$t("generic.responses.create_success"));
        this.sessionStorageService.removeBookingInfoData();
        this.success = true;
      });
    },
  },
};
</script>
<style scoped>
:deep(.mx-datepicker) {
  @apply !block;

  .mx-input {
    height: 38px;
    border-radius: 8px;
  }

  .mx-input::placeholder {
    @apply !text-white2 !opacity-60;
  }
}

:deep(.mx-datepicker .mx-input) {
  @apply !bg-brand !text-white2;
}

:deep(.mx-datepicker .mx-icon-calendar) {
  @apply !text-white2;
}

input::placeholder {
  @apply !text-white2 opacity-60;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}
</style>
