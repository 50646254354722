<template>
  <div>
    <button :class="classes" @click.prevent.stop="onClick">
      <span>{{ label }}</span>
    </button>
  </div>
</template>

<script>
import Form from "form-backend-validation";
export default {
  name: "AjaxDeleteButton",
  inject: ["bus"],
  props: {
    link: {
      type: String,
    },
    classes: {
      type: String,
      default: "flex w-full cursor-pointer items-center space-x-xsSpace p-xsSpace hover:bg-neutral-100",
    },
    label: {
      type: String,
      default: "Delete",
    },
    completedEvent: {
      type: String,
      default: "",
    },
  },

  methods: {
    async onClick() {
      const form = new Form({
        _method: "DELETE",
      });
      await form.post(this.link);

      if (!this.completedEvent) {
        return;
      }

      this.bus.$emit(this.completedEvent);
    },
  },
};
</script>
