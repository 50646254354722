<template>
  <div v-if="remainingTime > 0">
    <p>{{ formattedMinutes }}:{{ formattedSeconds }} {{ $t("appointment.labels.remaining") }}</p>
  </div>
</template>

<script>
export default {
  inject: ["bus"],
  props: {
    expiryAt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      remainingTime: 0, // Remaining time in seconds
      intervalId: null, // To store the interval
    };
  },
  computed: {
    minutes() {
      return Math.floor((this.remainingTime % (60 * 60)) / 60);
    },
    seconds() {
      return Math.floor(this.remainingTime % 60);
    },
    formattedMinutes() {
      return String(this.minutes).padStart(2, "0");
    },
    formattedSeconds() {
      return String(this.seconds).padStart(2, "0");
    },
  },
  methods: {
    calculateRemainingTime() {
      const expiryTime = new Date(this.expiryAt).getTime();
      const currentTime = new Date().getTime();
      this.remainingTime = Math.max(0, Math.floor((expiryTime - currentTime) / 1000));
    },
    startCountdown() {
      this.calculateRemainingTime();
      if (this.remainingTime > 0) {
        this.intervalId = setInterval(() => {
          this.calculateRemainingTime();
          if (this.remainingTime <= 0) {
            clearInterval(this.intervalId);
            this.bus.$emit("countdown-timer-timeout");
            this.$emit("timeout");
          }
        }, 1000);
      }
    },
  },
  mounted() {
    this.startCountdown();
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>
