<template>
  <div
    ref="container"
    v-if="products.length"
    class="relative max-h-[400px] overflow-y-auto sm:max-h-[350px] lg:max-h-[700px]"
  >
    <template v-for="product in products" :key="product.id">
      <div
        v-if="modelValueOutOfProductLists || !(hide && hideOthers && modelValue && modelValue !== product.id)"
        class="mb-xsSpace flex flex-col rounded-xl bg-white p-mdSpace opacity-50 hover:opacity-75"
        :class="{
          '!opacity-100': modelValueOutOfProductLists || !modelValue || modelValue === product.id,
        }"
      >
        <p class="block cursor-pointer text-lg font-semibold text-brand" @click="selectProduct(product.id)">
          {{ product.name }}
        </p>
        <p class="block cursor-pointer text-base text-neutral-700" @click="selectProduct(product.id)">
          ({{ product.abstract }})
        </p>
        <span class="text-md block pt-xsSpace text-neutral-700">{{ product.description }}</span>
        <div class="flex items-center justify-between gap-4 py-xsSpace pb-0">
          <div
            class="text-sm !text-brand"
            :class="
              !modelValue || modelValue === product.id || modelValueOutOfProductLists ? '' : '!bg-white opacity-50'
            "
          >
            {{ $t("booking.headings.from") }}{{ product.lowest_price }}
          </div>
          <div class="flex space-x-mdSpace" v-if="hideOthers && modelValue && !modelValueOutOfProductLists">
            <a
              @click.prevent.stop="remove"
              class="btn-secondary btn-sm border-0 !border-transparent !text-brand"
              :class="modelValue === product.id ? '' : '!bg-white opacity-50'"
            >
              <v-icon icon="TrashIcon" class="h-4 w-4 text-brand"></v-icon>
            </a>
            <quantity-stepper
              v-if="showQuantityStepper"
              v-model="currentQuantity"
              :min="1"
              :max="maxQuantity"
              @update:modelValue="$emit('update:quantity', $event)"
            />
          </div>
          <button
            class="btn-secondary !border-0 bg-brand !text-white hover:bg-brand"
            v-else
            @click="selectProduct(product.id)"
          >
            {{ $t("generic.words.book") }}
          </button>
        </div>
      </div>
    </template>
    <div class="sticky bottom-0 left-[48%] right-0 w-xlSpace cursor-pointer" v-if="products.length > 1 && !modelValue">
      <v-icon icon="ChevronDownIcon" class="h-8 w-8 rounded-full bg-brand p-1 text-white" @click="scrollDown" />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProductList",
  props: {
    categoryId: {
      type: [Number, String],
      default: null,
    },
    isMassage: {
      type: Boolean,
      required: true,
    },
    venueId: {
      type: Number,
      default: null,
    },
    modelValue: {
      required: true,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    showQuantityStepper: {
      type: Boolean,
      default: false,
    },
    hideOthers: {
      type: Boolean,
      required: false,
      default: false,
    },
    isWeekday: {
      type: Boolean,
      default: null,
    },
    applicableProductIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      products: [],
      hide: this.hideOthers,
      currentQuantity: this.quantity || 1,
    };
  },
  mounted() {
    if (this.categoryId) {
      this.getProducts();
    }
  },
  computed: {
    endpoint() {
      const url = new URL(location.href);

      if (this.venueId) {
        url.searchParams.set("venue_id", this.venueId);
      }

      if (this.isWeekday !== null) {
        url.searchParams.set("by_week", this.isWeekday ? "weekday" : "weekend");
      }

      if (this.categoryId) {
        url.searchParams.set("parent_category_id", this.categoryId);
      }

      if (this.applicableProductIds.length) {
        url.searchParams.set("ids", this.applicableProductIds.join(","));
      }

      return `/api/booking/products` + url.search;
    },
    maxQuantity() {
      return parseInt(this.isMassage ? this.appConfigs.max_massage_slot : this.appConfigs.max_bathrooms_slot);
    },
    modelValueOutOfProductLists() {
      return !this.products.find((product) => product.id === this.modelValue);
    },
  },
  watch: {
    categoryId: {
      handler(newValue, oldValue) {
        this.getProducts(false);
      },
    },
    venueId: {
      handler(newValue, oldValue) {
        this.getProducts(true);
      },
    },
  },
  methods: {
    remove() {
      this.hide = false;
      this.currentQuantity = 0;
      this.$emit("update:modelValue", null);
    },
    selectProduct(id) {
      this.hide = true;
      if (id === this.modelValue) {
        return;
      }

      this.currentQuantity = 1;
      this.$emit("update:modelValue", id);
    },
    getProducts(reset = false) {
      this.loading = true;
      axios.get(this.endpoint).then(({ data }) => {
        this.products = data.data;
        this.$emit("update:products", this.products);
        if (reset) {
          this.remove();
        }
        this.loading = false;
      });
    },
    scrollDown() {
      if (this.$refs.container) {
        this.$refs.container.scrollTop += 100;
      }
    },
  },
};
</script>
