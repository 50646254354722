<template>
  <div class="flex flex-row flex-nowrap items-center gap-2">
    <div class="relative flex flex-grow overflow-hidden rounded-md">
      <input
        type="number"
        v-model="durationInHours"
        :placeholder="$t('generic.placeholders.duration_in_hours')"
        class="flex-grow"
      />
      <div class="absolute inset-px left-auto flex items-center justify-center bg-neutral-300 p-xsSpace">
        {{ $t("generic.words.hours") }}
      </div>
    </div>
    <span>{{ $t("generic.words.or") }}</span>
    <div class="relative flex flex-grow overflow-hidden rounded-md">
      <input
        type="number"
        v-model="durationInMinutes"
        :placeholder="$t('generic.placeholders.duration_in_minutes')"
        class="flex-grow"
      />
      <div class="absolute inset-px left-auto flex items-center justify-center bg-neutral-300 p-xsSpace">
        {{ $t("generic.words.minutes") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HourOrMinuteInput",
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    durationUnit: {
      type: String,
      default: "hour",
    },
  },
  computed: {
    durationInHours: {
      get() {
        return (
          (this.durationUnit === "hour"
            ? parseInt(this.innerDuration)
            : Math.round((this.innerDuration / 60 + Number.EPSILON) * 100) / 100) || ""
        );
      },
      set(value) {
        this.innerDuration = this.durationUnit === "hour" ? value : value * 60;
      },
    },
    durationInMinutes: {
      get() {
        return (this.durationUnit === "minute" ? this.innerDuration : this.innerDuration * 60) || "";
      },
      set(value) {
        this.innerDuration =
          this.durationUnit === "minute"
            ? parseInt(value)
            : Math.round((this.innerDuration / 60 + Number.EPSILON) * 100) / 100;
      },
    },
  },
  watch: {
    innerDuration: {
      immediate: true,
      handler(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  data() {
    return {
      innerDuration: this.modelValue || 0,
    };
  },
};
</script>
