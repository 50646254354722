<template>
  <div class="flex items-center p-0" @click.prevent.stop="toggle">
    <slot :collapsed="isCollapsed">
      <transition type="transition">
        <VIcon
          icon="ChevronDownIcon"
          class="h-6 w-6 transform cursor-pointer text-gray-400 transition-transform duration-300 ease-in-out hover:text-gray-800"
          :class="isCollapsed ? 'rotate-180' : ''"
        ></VIcon>
      </transition>
    </slot>
  </div>
</template>

<script>
export default {
  name: "CollapsibleTrigger",
  inject: {
    isCollapsed: { from: "collapsed" },
    toggleFn: { from: "toggle" },
  },
  props: {
    context: {
      type: String,
      required: false,
      default: "default",
      description: "The context of the collapsible, the child components will use this to provide the collapsed state.",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle: function () {
      if (this.disabled) return;

      this.toggleFn(this.context);
    },
  },
};
</script>
