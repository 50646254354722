<template>
  <div
    v-if="loading"
    aria-label="Loading..."
    role="status"
    class="flex h-full items-center justify-center pt-lgSpace text-center text-black"
  >
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="font-medium text-black text-tertiary-500">{{ $t("generic.loading") }}</span>
  </div>
  <div class="overflow-y-auto" v-else>
    <side-panel ref="sidePanel" :disable-listeners="true"></side-panel>
    <form v-for="(voucher, index) in this.vouchers" :key="index" class="flex w-full flex-col justify-between">
      <div class="relative space-y-smSpace p-mdSpace">
        <h4 v-if="!index" class="text-lg">{{ $t("gift_voucher_instance.headings.voucher_history") }}</h4>
        <div class="absolute right-4 top-0 flex space-x-xsSpace">
          <copy-button
            v-if="voucher.remaining"
            classes="btn-sm btn-secondary bg-white"
            title="gift_voucher_instance.buttons.pay_link"
            copied-message="gift_voucher.responses.pay_link_copied"
            :copy-string="voucher.pay_link"
          >
          </copy-button>
          <a @click="clickAddTask(voucher)" class="btn-sm btn-secondary bg-white">
            <span>{{ $t("task.buttons.add") }}</span>
          </a>
        </div>
        <div class="space-y-smSpace rounded p-xsSpace ring-1">
          <div class="space-y-2xsSpace text-sm">
            <div class="flex justify-between">
              <p>
                {{ $t("gift_voucher_instance.fields.voucher") }}
              </p>
              <a class="hover:text-blue-400" :href="voucher.giftVoucherUrl" target="_blank">
                <strong>{{ voucher.gift_voucher.title }}</strong>
              </a>
            </div>
            <div class="flex justify-between">
              <p>
                {{ $t("gift_voucher_instance.fields.voucher_instance") }}
              </p>
              <div class="flex items-center justify-center gap-xsSpace">
                <a
                  v-if="!voucher.deleted"
                  :href="voucher.view_url"
                  target="_blank"
                  class="flex w-full cursor-pointer items-center justify-center space-x-xsSpace rounded-lg p-2xsSpace hover:bg-neutral-100 hover:text-blue-600"
                >
                  <v-icon icon="EyeIcon" classes="w-4 h-4"></v-icon>
                </a>
                <a
                  v-if="!voucher.deleted"
                  @click="copyLink(voucher)"
                  class="flex w-full cursor-pointer items-center justify-center space-x-xsSpace rounded-lg p-2xsSpace hover:bg-neutral-100 hover:text-blue-600"
                >
                  <v-icon icon="LinkIcon" classes="w-4 h-4"></v-icon>
                </a>
                <a :href="voucher.customer_voucher_url" target="_blank">
                  <strong>{{ voucher.code }}</strong>
                </a>
                <v-badge v-if="voucher.deleted" :value="$t('generic.words.deleted')" type="warning"> </v-badge>
              </div>
            </div>
            <div class="flex justify-between" v-if="voucher.gift_voucher.is_bath_pass">
              <p>
                {{ $t("gift_voucher_instance.fields.remaining_count") }}
              </p>
              <span>{{ voucher.remaining_count }}</span>
            </div>
            <div class="flex justify-between" v-else>
              <p>
                {{ $t("gift_voucher_instance.fields.balance") }}
              </p>
              <span>{{ toAUD(voucher.balance) }}</span>
            </div>
            <div class="flex justify-between" v-if="voucher.remaining">
              <p>
                {{ $t("gift_voucher_instance.labels.outstanding") }}
              </p>
              <span>{{ toAUD(voucher.remaining) }}</span>
            </div>
            <div class="flex justify-between">
              <p>
                {{ $t("gift_voucher_instance.columns.purchased") }}
              </p>
              <span>{{ formatDate(voucher.created_at) }}</span>
            </div>
            <div class="flex justify-between">
              <p>
                {{ $t("gift_voucher_instance.fields.expiry") }}
              </p>
              <span>{{ formatDate(voucher.expiry) }}</span>
            </div>
          </div>
        </div>
        <div class="space-y-smSpace rounded p-xsSpace ring-1" v-if="showRefundable">
          <h4>{{ $t("gift_voucher_instance.labels.refundable_payments") }}</h4>
          <template v-if="!paymentLoading">
            <div v-if="voucher.refundable_payments.length">
              <ul role="list" class="space-y-smSpace rounded p-smSpace text-sm ring-1">
                <li class="flex justify-between" v-for="(payment, index) in voucher.refundable_payments">
                  <div class="flex items-center gap-smSpace">
                    <div
                      class="relative cursor-pointer"
                      @mouseover="showToolTip($event, payment)"
                      @mouseout="hideToolTip($event, payment)"
                    >
                      <span v-html="payment.title"></span>
                      <div
                        :id="`payment-${payment.id}`"
                        class="-right-50 absolute hidden w-[250px] -translate-x-1.5 -translate-y-full items-center justify-center rounded-md bg-gray-800 p-smSpace text-xs text-white"
                        v-html="payment.tooltip"
                      ></div>
                    </div>
                    <div v-if="payment.refundable" class="flex gap-xsSpace">
                      <input
                        type="number"
                        class="!w-[80px] py-3xsSpace"
                        v-model="payment.actual_legacy_square_refundable_amount"
                      />
                      <a
                        :class="legacyRefundButtonClasses(payment)"
                        @click="confirmAndRemove(payment)"
                        class="btn-secondary flex !w-[64px] justify-center !rounded-lg !px-xsSpace !py-3xsSpace !text-sm"
                      >
                        <v-icon v-if="refundLoading" icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
                        <span v-else>{{ $t("generic.buttons.refund") }}</span>
                      </a>
                    </div>
                    <span
                      v-if="
                        payment.payment_method === 'square' &&
                        payment.amount < 0 &&
                        payment.square_status !== 'COMPLETED'
                      "
                    >
                      <span v-if="payment.square_status">({{ payment.square_status }}) </span>
                    </span>
                  </div>
                  <p class="flex items-center">
                    {{ toAUD(Number(payment.refundable ? -payment.amount : payment.amount)) }}
                  </p>
                </li>
              </ul>
            </div>
            <span v-else>{{ $t("generic.words.no_item") }}</span>
          </template>
          <div
            v-else
            aria-label="Loading..."
            role="status"
            class="absolute left-1/2 top-1/2 flex -translate-x-1/2 items-center justify-center space-x-2"
          >
            <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
            <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
          </div>
        </div>
        <div class="space-y-smSpace rounded p-xsSpace ring-1">
          <h4>{{ $t("gift_voucher_instance.headings.customer") }}</h4>
          <div class="space-y-2xsSpace text-sm">
            <div class="flex justify-between">
              <p>
                {{ $t("gift_voucher_instance.fields.gifter") }}
              </p>
              <a
                class="hover:text-blue-400"
                v-if="voucher.gifter.name !== 'System'"
                :href="voucher.gifter.url"
                target="_blank"
              >
                {{ voucher.gifter.name }}</a
              >
              <span v-else> {{ voucher.gifter.name }} </span>
            </div>
            <div v-if="voucher.giftee" class="flex justify-between">
              <p>
                {{ $t("gift_voucher_instance.fields.giftee") }}
              </p>
              <span class="ml-mdSpace" v-html="voucher.giftees"></span>
            </div>
          </div>
        </div>
        <div class="space-y-smSpace rounded p-xsSpace ring-1" v-if="!voucher.deleted">
          <p v-if="!voucher.payments.length" class="mt-smSpace text-sm text-orange-900">
            {{ $t("gift_voucher_instance.headings.has_not_used") }}
          </p>
          <h4 v-else>{{ $t("generic.words.payments") }}</h4>
          <div
            :key="payment.id"
            v-for="(payment, index) in voucher.payments"
            :class="{
              'border-b pb-smSpace': index + 1 < voucher.payments.length,
              hidden: voucher.gift_voucher.is_bath_pass && !payment.order,
            }"
          >
            <div class="space-y-2xsSpace text-sm">
              <h4>{{ `Payment #${payment.id}` }}</h4>
              <template v-if="payment.order">
                <div class="flex justify-between">
                  <p>
                    {{ $t("gift_voucher_instance.labels.order_id") }}
                  </p>
                  <span>#{{ payment.order.id }}</span>
                </div>
                <div class="flex justify-between">
                  <p>
                    {{ $t("generic.words.date") }}
                  </p>
                  <span>{{ formatDate(payment.created_at) }}</span>
                </div>
                <div class="flex justify-between">
                  <p>
                    {{ $t("customer.fields.email") }}
                  </p>
                  <a
                    class="hover:text-blue-400"
                    :href="`/admin/settings/customers/${payment.order.customer_id}/edit`"
                    target="_blank"
                    >{{ payment.order.email }}</a
                  >
                </div>
                <div
                  v-for="order_item in payment.order.order_items"
                  class="cursor-pointer hover:text-blue-400"
                  @click="openPanel(order_item.with_trashed_booking)"
                >
                  <div class="flex justify-between">
                    <p>
                      {{ order_item?.itemable?.name ?? "NA" }}
                    </p>
                    <strong>{{ toAUD(order_item.price) }}</strong>
                  </div>
                  <div class="flex justify-between">
                    <p>
                      {{ $t("gift_voucher_instance.headings.appointment") }}
                    </p>
                    <span>{{
                      order_item.with_trashed_booking.appointment?.readable_period ?? $t("generic.buttons.cancel")
                    }}</span>
                  </div>
                </div>
              </template>
              <div class="flex justify-between">
                <p>
                  {{ getPaymentTitle(payment) }}
                </p>
                <strong :class="{ 'text-green-600': payment.amount < 0, 'text-red-600': payment.amount > 0 }">{{
                  (payment.amount < 0 ? "+" : "") + toAUD(-payment.amount)
                }}</strong>
              </div>
              <div class="flex justify-between">
                <p>
                  {{ $t("generic.words.date") }}
                </p>
                <span>{{ formatDate(payment.created_at) }}</span>
              </div>
              <div class="flex justify-between" v-if="payment.updated_by?.full_name">
                <p>
                  {{ $t("generic.updated_by") }}
                </p>
                <strong>
                  {{ payment.updated_by.full_name }}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <v-activity-log
      class="p-mdSpace"
      v-if="currentUser && vouchers && vouchers.length === 1"
      model-class="App\Models\GiftVouchers\GiftVoucherInstance"
      :model-id="vouchers[0].id.toString()"
      :current-user="currentUser"
      :timezone="appConfigs.default_timezone"
    />
    <div class="flex items-center justify-end px-mdSpace py-xsSpace" v-if="showActions">
      <button class="btn-primary btn-sm mr-xsSpace" @click="purchaseAnother">
        {{ $t("generic.buttons.purchase_another") }}
      </button>
      <button class="btn-secondary btn-sm" @click="close">{{ $t("generic.buttons.close") }}</button>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";

export default {
  inject: ["config", "bus", "notificationService"],
  props: {
    voucherCode: {
      type: String,
      required: true,
    },
    showAddTask: {
      type: Boolean,
      default: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      required: true,
    },
    showRefundable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vouchers: [],
      loading: false,
      paymentLoading: false,
      refundLoading: false,
      tooltipTimeout: null,
      interval: null,
    };
  },
  computed: {
    endpoint() {
      return `/api/admin/gift-voucher-instances/${this.voucherCode}`;
    },
  },
  created() {
    this.bus.$on("closePanelInPanel", () => {
      this.$refs.sidePanel && this.$refs.sidePanel.close();
    });
    this.getDetail();
    this.checkPayments();
  },
  methods: {
    checkPayments() {
      return new Promise((resolve, reject) => {
        this.interval = setInterval(async () => {
          const response = await axios.get(this.endpoint);
          if (response.data.length) {
            const allDone =
              response.data[0].refundable_payments.filter((o) => o.square_status === "PENDING").length === 0;
            if (allDone) {
              resolve(response);
              clearInterval(this.interval);
            }
          } else {
            resolve({});
            clearInterval(this.interval);
          }
        }, 3000);
      });
    },
    remove(payment) {
      if (
        payment.payment_method !== "square" ||
        this.refundLoading ||
        payment.legacy_square_refundable_amount <= 0 ||
        payment.actual_legacy_square_refundable_amount <= 0
      ) {
        return;
      }

      this.refundLoading = true;
      const url = `/api/admin/payments/${payment.id}?amount=${payment.actual_legacy_square_refundable_amount}&is_gift_voucher=true`;

      axios
        .post(url)
        .then(() => {
          this.getDetail(false);
          this.checkPayments().then((response) => {
            if (response && Object.keys(response).length) {
              this.vouchers = Object.assign({}, response.data);
            }
          });
          this.refundLoading = false;
        })
        .catch(({ response }) => {
          this.refundLoading = false;
        });
    },
    showToolTip($event, payment) {
      const el = document.getElementById(`payment-${payment.id}`);
      this.tooltipTimeout = setTimeout(() => {
        el.style = "display: inline;";

        setTimeout(() => {
          el.style = "display: hidden;";
          clearTimeout(this.tooltipTimeout);
        }, 10000);
      }, 100);
    },
    hideToolTip($event, payment) {
      const el = document.getElementById(`payment-${payment.id}`);
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }

      el.style = "display: hidden;";
    },
    legacyRefundButtonClasses(payment) {
      return {
        "!bg-gray-200 !cursor-not-allowed": this.refundLoading || payment.legacy_square_refundable_amount <= 0,
        "hover:bg-neutral-100 cursor-pointer": payment.legacy_square_refundable_amount > 0,
      };
    },
    confirmAndRemove(payment) {
      if (
        payment.actual_legacy_square_refundable_amount <= 0 ||
        payment.legacy_square_refundable_amount <= 0 ||
        payment.actual_legacy_square_refundable_amount * 100 > payment.legacy_square_refundable_amount ||
        this.refundLoading
      ) {
        return;
      }

      this.bus.$emit("openModal", {
        componentName: "RefundConfirmation",
        componentData: {
          header: this.$t("payment.headings.refund"),
          payment: payment.raw_data,
          amount: payment.actual_legacy_square_refundable_amount,
        },
        callback: () => {
          this.remove(payment);
        },
      });
    },
    getPaymentTitle(payment) {
      if (payment.notes) {
        return payment.notes;
      }

      if (!payment.notes && !payment.order_id && Number(payment.amount) < 0) {
        return this.$t("gift_voucher_instance.labels.initial_amount");
      }

      if (Number(payment.amount) > 0) {
        return this.$t("gift_voucher_instance.fields.paid_amount");
      }

      return this.$t("gift_voucher_instance.fields.refunded");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-yyyy");
    },
    giftees(voucher) {
      return voucher.giftee
        ? voucher.giftee
            .filter(Boolean)
            .map((giftee) => {
              return `<a class="hover:text-blue-400" href='/admin/settings/customers/${giftee.id}/edit' target="_blank">${giftee.display_name}</a>`;
            })
            .join(", ")
        : "";
    },
    giftVoucherUrl(voucher) {
      return `/admin/settings/gift-vouchers/${voucher.gift_voucher.id}/edit`;
    },
    customerVoucherUrl(voucher) {
      if (voucher.giftee_id) {
        return `/admin/settings/customers/${voucher.giftee_id}/edit?active_tab=gift-vouchers`;
      }

      return "#";
    },
    getDetail(loading = true) {
      if (loading) {
        this.loading = true;
      }

      this.paymentLoading = true;
      axios
        .get(this.endpoint)
        .then(({ data }) => {
          this.vouchers = data.map((voucher) => {
            voucher.giftees = this.giftees(voucher);
            voucher.deleted = Boolean(voucher?.deleted_at);
            voucher.giftVoucherUrl = this.giftVoucherUrl(voucher);
            voucher.customer_voucher_url = this.customerVoucherUrl(voucher);
            return voucher;
          });
          if (loading) {
            this.loading = false;
          }
          this.paymentLoading = false;
        })
        .catch(() => {
          if (loading) {
            this.loading = false;
          }

          this.paymentLoading = false;
        });
    },
    purchaseAnother() {
      this.bus.$emit("openSidePanel", {
        componentName: "GiftVoucherFindAvailabilities",
      });
    },
    close() {
      clearInterval(this.interval);
      this.bus.$emit("closePanel");
    },
    clickAddTask(voucher) {
      const title = `Voucher Instance Task: "${voucher.gift_voucher.title}" Instance "${voucher.code}"`;
      const description = `Purchaser: ${this.giftees(voucher)}
Voucher Name: "${voucher.gift_voucher.title}"
Instance: "${voucher.code}"
then let them type in anything else and save.`;
      this.$refs.sidePanel.open(
        "TaskEdit",
        {
          task: {
            title,
            description,
          },
          taskable: {
            class: this.config.taskTypes.giftVoucherInstance,
            id: voucher.id,
          },
          inPanel: true,
        },
        this.$t("task.buttons.add"),
        () => {},
      );
    },
    openPanel(trashed_booking) {
      this.bus.$emit("openSidePanel", {
        componentName: "BookingDetail",
        title: "",
        componentData: {
          bookingId: trashed_booking.id,
          bookingClass: trashed_booking.class,
          showAddTask: true,
        },
      });
    },
    copyLink(voucher, key = "code") {
      navigator.clipboard.writeText(voucher[key]);
      this.notificationService.notify(this.$t(`gift_voucher.responses.${key}_copied`));
    },
  },
};
</script>
