<template>
  <div class="rounded p-xsSpace ring-1">
    <p class="pb-mdSpace pl-2xsSpace font-semibold">{{ data.title }}</p>
    <div class="flex items-center gap-lgSpace">
      <div class="relative !h-44 !w-44">
        <div class="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center">
          <p class="text-6xl font-bold">{{ data.percentage_value?.toFixed() }}%</p>
          <p class="text-center text-sm">{{ data.percentage_title }}</p>
        </div>
        <Doughnut :data="data.chart" :options="options" />
      </div>
      <div class="flex flex-col gap-smSpace">
        <div class="flex flex-col" v-for="statistic in data.statistic">
          <p>{{ statistic.title }}</p>
          <p class="font-bold">{{ statistic.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);

export default {
  name: "Percentage",
  components: {
    Doughnut,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        cutout: "86%",
      },
    };
  },
  created() {},
  methods: {},
};
</script>
