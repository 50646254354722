<template>
  <div class="mb-10 flex max-h-full flex-1">
    <div class="flex max-h-full flex-1 flex-col">
      <div v-if="colNum > 1" class="flex justify-end p-mdSpace">
        <label class="flex cursor-pointer items-center">
          <v-toggle :ring="false" :value="isExpanded" @input="handleExpandedChange"></v-toggle>
          <span class="ml-xsSpace text-sm font-semibold">{{ $t("shift_template.buttons.expand") }}</span>
        </label>
      </div>
      <main ref="container" class="relative max-h-full flex-1">
        <div ref="scrollable" class="absolute inset-0 overflow-auto">
          <div
            ref="layout"
            class="relative z-20 min-w-full bg-tertiary-200"
            :style="{
              width: colWidth * (maxSlotCol + 1) + ($refs.slotTime?.clientWidth || 40) + 'px',
              height: rowHeight * rowNum + 'px',
            }"
          >
            <div ref="slotTitle" class="sticky top-0 z-30 flex flex-row pb-6 pl-10">
              <div v-if="isExpanded" v-for="(_, index) in Array(colNum).fill(0)" class="px-px">
                <div
                  class="flex h-10 flex-shrink-0 items-center justify-center rounded border-2 border-blue-400 bg-white text-sm font-semibold"
                  :style="{ width: colWidth - 2 + 'px' }"
                >
                  Slot {{ index + 1 }}
                </div>
              </div>
              <div
                v-else
                class="flex h-10 w-full items-center justify-center rounded border-2 border-blue-400 bg-white text-sm font-semibold"
              >
                {{ form.name || $t("shift_template.placeholders.name") }}
              </div>
            </div>
            <div
              class="absolute left-0 top-16 z-10 h-full w-full"
              :style="`background: repeating-linear-gradient(#cbd5e1, #cbd5e1 2px, #e2e8f0 2px, #e2e8f0 ${
                rowHeight * 2
              }px)`"
            ></div>
            <div ref="slotTime" class="sticky left-0 top-16 z-20 h-full w-10">
              <div
                v-for="(_, index) in Array(rowNum / 4).fill(0)"
                class="absolute mt-0.5 -translate-y-1/2 bg-tertiary-200 p-1 text-xs"
                :style="{ top: index * rowHeight * 4 + 'px' }"
              >
                {{ index + 6 }}:00
              </div>
            </div>
            <div
              ref="placeholder"
              v-if="
                !isDraggingLeave && isDragging && draggingSlot && placeholderCol !== null && placeholderRow !== null
              "
              class="absolute z-10 p-px"
              :style="{
                width: colWidth + 'px',
                height: rowHeight * (draggingSlot?.duration / 15) + 'px',
                left: placeholderCol * colWidth + ($refs.slotTime?.clientWidth || 40) + 'px',
                top: placeholderRow * rowHeight + ($refs.slotTitle?.clientHeight || 64) + 'px',
              }"
            >
              <div class="absolute left-1/2 top-0 -translate-x-1/2 -translate-y-full bg-amber-200 p-3xsSpace text-xs">
                {{ this._revertRowToTime(placeholderRow) }}
              </div>
              <div class="h-full w-full bg-amber-200"></div>
            </div>
            <div
              class="absolute left-0 top-16 z-10 h-full w-full"
              @dragenter.prevent="handleDragEnter"
              @dragover.prevent="throttledDragOver"
              @dragleave.prevent="handleDragLeave"
              @dragend.prevent="handleDragEnd"
              @drop="handleOnDrop"
            ></div>
            <div
              v-for="(slot, index) in slots"
              :key="`${index}-${slot.col}-${slot.row}`"
              class="absolute z-10 p-px"
              :class="{ 'opacity-50': dragSlot === index }"
              :draggable="isExpanded"
              :style="{
                width: colWidth + 'px',
                height: getSlotHeight(slot.duration) + 'px',
                left: slot.col * colWidth + ($refs.slotTime?.clientWidth || 40) + 'px',
                top: slot.row * rowHeight + ($refs.slotTitle?.clientHeight || 64) + 'px',
              }"
              @dragstart="handleDragStartSlot($event, slot, index)"
              @drop="handleOnDrop"
              @dragenter.prevent="handleDragLeave"
              @dragover.prevent="throttledDragOver"
              @dragleave.prevent="handleDragEnter"
              @dragend.prevent="handleDragEnd"
            >
              <div
                class="relative flex h-full w-full flex-col gap-1 rounded border-2 border-blue-400 bg-white p-xsSpace"
                :class="{ 'py-2xsSpace': !isExpanded, '!py-0': getSlotHeight(slot.duration) <= 48 }"
              >
                <div class="flex flex-1">
                  <div class="flex-1">
                    <div class="text-sm font-semibold" :class="{ '!text-xs !leading-none': !isExpanded }">
                      {{ slot.title }}
                    </div>
                    <div v-if="isExpanded && getSlotHeight(slot.duration) > 48" class="text-xs text-gray-500">
                      {{ slot.duration }} minutes
                    </div>
                  </div>
                  <div class="flex-shrink-0">
                    <v-toggle
                      v-if="isExpanded"
                      :is-disabled="!isExpanded"
                      :ring="false"
                      small
                      :value="slots[index].is_online"
                      @input="slots[index].is_online = !slots[index].is_online"
                    ></v-toggle>
                    <div
                      v-else
                      class="h-2.5 w-2.5 rounded-full bg-gray-300"
                      :class="{ 'bg-green-500': slots[index].is_online }"
                    ></div>
                  </div>
                </div>
                <div v-if="getSlotHeight(slot.duration) > 48" class="flex-shrink-0 text-right">
                  <button type="button" class="btn-outline-secondary" @click.prevent="handleDelete($event, index)">
                    <v-icon icon="TrashIcon" class="!h-4 !w-4 text-red-500" />
                  </button>
                </div>
                <div
                  v-if="isExpanded && slot.col < colNum - 1 && getIsDroppable(slot.col + 1, slot.row, slot.duration)"
                  class="absolute -bottom-0.5 -top-0.5 left-full flex w-2.5 cursor-pointer items-center justify-center rounded-r-xl bg-blue-400 text-sm text-white"
                  @click.prevent="addSiblingSlot($event, slot)"
                >
                  +
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>

    <div class="w-px bg-gray-200"></div>

    <aside class="flex w-1/3 flex-col pl-mdSpace pr-3xsSpace text-sm">
      <form class="flex flex-1 flex-col gap-2xlSpace">
        <div class="space-y-smSpace">
          <input-wrapper field="name" :form="form" :label-text="$t('shift_template.columns.name')">
            <input type="text" v-model="form.name" :placeholder="$t('shift_template.placeholders.name')" />
          </input-wrapper>
          <input-wrapper
            v-show="!useSpecificDates"
            field="day_of_week"
            :form="form"
            :label-text="$t('shift_template.columns.day_of_week')"
          >
            <v-multi-select
              v-model="form.day_of_week"
              :init-options="weekDays"
              :placeholder="$t('shift_template.placeholders.day_of_week')"
              :is-multiple="true"
            />
          </input-wrapper>
          <div class="space-y-1.5">
            <v-checkbox v-model="useSpecificDates" :label-text="$t('shift_template.labels.use_specific_dates')" />
            <p class="text-sm text-gray-500">{{ $t("shift_template.labels.use_specific_dates_description") }}</p>
          </div>
          <input-wrapper
            v-show="useSpecificDates"
            field="specific_dates"
            :form="form"
            :label-text="$t('shift_template.columns.specific_dates')"
          >
            <date-picker
              v-model:value="form.specific_dates"
              multiple
              type="date"
              value-type="format"
              format="DD-MM-YYYY"
              class="flex !w-full"
              :placeholder="$t('shift_template.placeholders.specific_dates')"
            />
          </input-wrapper>
        </div>

        <div class="rounded border border-slate-200 p-smSpace">
          <h3 class="text-base font-medium" v-html="$t('shift_template.headings.appointments')"></h3>
          <p
            class="mb-mdSpace text-sm text-gray-500"
            v-html="$t('shift_template.headings.appointment_description')"
          ></p>
          <div
            v-for="item in availableAppointments"
            :draggable="isExpanded"
            @dragstart="handleDragStart($event, item)"
            class="mb-2xsSpace cursor-pointer rounded bg-blue-200 px-xsSpace py-2xsSpace hover:bg-blue-600 hover:text-white"
          >
            {{ item.name }}
          </div>
        </div>

        <div class="flex items-center justify-end">
          <button type="button" @click="save" class="btn btn-primary">
            {{ $t("generic.buttons.save") }}
          </button>
        </div>
      </form>
    </aside>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import DatePicker from "vue-datepicker-next";
import { throttle } from "lodash";
import "vue-datepicker-next/index.css";

export default {
  name: "ShiftTemplateEdit",
  inject: ["bus", "config", "notificationService"],
  components: {
    DatePicker,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
    shiftTemplate: {
      type: Object,
      default: () => ({}),
    },
    weekDays: {
      type: Array,
      required: true,
    },
    availableAppointments: {
      type: Array,
      required: true,
    },
    listUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    colNum() {
      return this.room.capacity;
    },
    rowNum() {
      return (24 - 6) * 4;
    },
    draggingSlot() {
      return this.dragNewSlot || this.slots[this.dragSlot] || null;
    },
    maxSlotCol() {
      if (this.isExpanded) {
        return this.colNum;
      }

      return this.slots.reduce((acc, slot) => Math.max(acc, slot.col + 1), 0);
    },
  },
  data() {
    return {
      form: new Form(
        {
          id: this.getFieldValue("id"),
          name: this.getFieldValue("name"),
          day_of_week: this.getFieldValue("day_of_week", []),
          specific_dates: this.getFieldValue("specific_dates", []),
          shift_template_items: this.getFieldValue("shift_template_items", []).filter((o) => o.time_slot_type_id === 2),
          active: true,
        },
        { resetOnSuccess: false },
      ),
      useSpecificDates: this.shiftTemplate?.id && Boolean(this.getFieldValue("specific_dates", [])?.length),

      colWidth: 210,
      rowHeight: 24,

      isExpanded: true,

      throttledDragOver: throttle(this.handleDragOver, 50),

      isDragging: false,
      isDraggingLeave: false,
      dragNewSlot: null,
      dragSlot: null,

      placeholderCol: null,
      placeholderRow: null,

      capacityRanges: this.getCapacityRanges(),
      slots: [],
    };
  },
  watch: {
    isExpanded(expanded) {
      this.rowHeight = expanded ? 24 : 14;
      this.reCalculateSlotPositions();
      nextTick(() => {
        if (!expanded) {
          this.$refs.scrollable.scrollTop = 0;
          this.$refs.scrollable.scrollLeft = 0;
        }
      });
    },
    useSpecificDates(useSpecificDates) {
      this.form.day_of_week = useSpecificDates ? [] : this.getFieldValue("day_of_week", []);
      this.form.specific_dates = useSpecificDates ? this.getFieldValue("specific_dates", []) : [];
    },
  },
  created() {
    this.slots = (
      (this.shiftTemplate?.shift_template_items || []).filter(
        (o) => o.time_slot_type_id === 2 && o.shift_template_roster,
      ) || []
    ).map(this.initSlotPositions);
  },
  methods: {
    getFieldValue(field, defaultValue = null) {
      if (this.shiftTemplate?.hasOwnProperty(field)) {
        return this.shiftTemplate[field];
      }

      return defaultValue;
    },

    getCapacityRanges() {
      if (!this.shiftTemplate?.id) {
        return new Array(this.room.capacity).fill(null).map((_, index) => ({
          id: null,
          capacityIndex: index + 1,
          minRow: this._timeToRow("06:00"),
          maxRow: this._timeToRow("22:00"),
        }));
      }

      const rosters = this.shiftTemplate.shift_template_rosters || [];
      rosters.sort((a, b) => a.capacity_index - b.capacity_index);

      return rosters.map((roster) => ({
        id: roster.id,
        capacityIndex: roster.capacity_index,
        minRow: this._timeToRow(roster.start),
        maxRow: this._timeToRow(roster.stop),
      }));
    },

    getSlotHeight(duration) {
      return this.isExpanded ? this.rowHeight * (duration / 15) : 16;
    },

    _rosterToCol(rosterId) {
      const roster = this.shiftTemplate.shift_template_rosters.find((roster) => roster.id === rosterId);
      if (!roster) return 0;

      return Math.max(0, roster.capacity_index - 1);
    },

    _timeToRow(time) {
      const [hour, minute] = time.split(":");
      if (hour === undefined || minute === undefined) return 0;

      return Math.max(0, parseInt(hour) - 6) * 4 + Math.floor(parseInt(minute) / 15);
    },

    _revertRowToTime(row) {
      const hour = Math.floor(row / 4) + 6;
      const minute = (row % 4) * 15;

      return `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
    },

    initSlotPositions(item) {
      const col = this._rosterToCol(item.shift_template_roster_id);

      return {
        ...item,
        col,
        row: this._timeToRow(item.start),
        capacity_index: col + 1,
      };
    },

    reCalculateSlotPositions() {
      if (this.isExpanded) {
        this.slots = [
          ...this.slots.map((slot) => {
            const row = this._timeToRow(slot.start);
            return {
              ...slot,
              col: slot.capacity_index - 1,
              row,
            };
          }),
        ];
        return;
      }

      const grouped = this.slots.reduce((acc, slot) => {
        if (!acc[slot.row]) {
          acc[slot.row] = [];
        }

        acc[slot.row].push(slot);

        return acc;
      }, []);

      this.slots = [
        ...grouped.reduce((acc, slots) => {
          slots
            .sort((a, b) => a.col - b.col)
            .forEach((slot, index) => {
              acc.push({
                ...slot,
                col: index,
              });
            });

          return acc;
        }, []),
      ];
    },

    save() {
      this.form.populate({
        shift_template_items: this.slots,
      });
      if (this.shiftTemplate.hasOwnProperty("id")) {
        this.form
          .patch(`/api/admin/rooms/${this.room.id}/shift-templates/${this.shiftTemplate.id}`)
          .then(({}) => {
            window.location = this.listUrl;
          })
          .catch(({ response }) => {
            this.notificationService.notify(response.data.message, "error");
          });

        return;
      }

      this.form
        .post(`/api/admin/rooms/${this.room.id}/shift-templates`)
        .then(({ data }) => {
          window.location = this.listUrl;
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },

    handleExpandedChange() {
      this.isExpanded = !this.isExpanded;
    },

    handleDragStart(event, item) {
      this.dragNewSlot = {
        shift_template_id: this.shiftTemplate?.id,
        title: item.name,
        duration: item.duration,
        is_online: true,
        time_slot_type_id: item.time_slot_type_id,
      };

      const shadow = document.createElement("div");
      shadow.id = "shift_template_shadow_id";
      shadow.classList.add("absolute", "border-2", "border-blue-400", "rounded", "p-smSpace", "bg-white", "-left-full");
      shadow.style.width = this.colWidth + "px";
      shadow.style.height = (item.duration / 15) * this.rowHeight + "px";
      shadow.innerHTML =
        '<div class="text-sm font-semibold">' +
        item.name +
        '</div><div class="text-xs text-gray-500">' +
        item.duration +
        " minutes</div>";

      document.body.appendChild(shadow);

      event.dataTransfer.setDragImage(shadow, this.colWidth / 2, this.rowHeight / 2);
    },

    handleDragStartSlot(event, slot, index) {
      this.dragSlot = index;
      this.isDragging = true;

      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.setDragImage(event.target, this.colWidth / 2, this.rowHeight / 2);
    },

    _calculatePlaceholderCol(x) {
      return Math.max(0, Math.min(this.colNum - 1, Math.floor(x / this.colWidth)));
    },

    _calculatePlaceholderRow(col, y, duration) {
      return Math.max(
        this.capacityRanges[col]?.minRow || 0,
        Math.min((this.capacityRanges[col]?.maxRow || this.rowNum) - duration / 15, Math.floor(y / this.rowHeight)),
      );
    },

    getIsDroppable(col, row, duration, slotIndex = null) {
      return this.slots.every(
        (slot, index) =>
          slotIndex === index || // skip the slot being dragged
          slot.col !== col || // skip the same column
          slot.row + slot.duration / 15 <= row || // skip the slot that ends before the new slot
          row + duration / 15 <= slot.row, // skip the slot that starts after the new slot
      );
    },

    handleDragOver(event) {
      event.preventDefault();
      if (!this.draggingSlot || this.isDraggingLeave) return;

      const x =
        event.clientX +
        this.$refs.scrollable.scrollLeft -
        this.$refs.container.offsetLeft -
        this.$refs.slotTime.clientWidth;
      const y =
        event.clientY +
        this.$refs.scrollable.scrollTop -
        this.$refs.container.offsetTop -
        this.$refs.slotTitle.clientHeight;
      const col = this._calculatePlaceholderCol(x);
      const row = this._calculatePlaceholderRow(col, y, this.draggingSlot.duration);
      if (!this.getIsDroppable(col, row, this.draggingSlot.duration, this.dragSlot)) return;

      this.isDragging = true;
      this.placeholderCol = col;
      this.placeholderRow = row;
    },

    handleOnDrop() {
      if (!this.isDragging || this.placeholderCol === null || this.placeholderRow === null) {
        this.handleDragEnd();
        return;
      }

      if (this.dragNewSlot) {
        this.slots.push({
          ...this.dragNewSlot,
          col: this.placeholderCol,
          row: this.placeholderRow,
          start: this._revertRowToTime(this.placeholderRow),
          stop: this._revertRowToTime(this.placeholderRow + this.dragNewSlot.duration / 15),
          capacity_index: this.placeholderCol + 1,
          shift_template_roster_id: this.capacityRanges[this.placeholderCol]?.id,
        });
      }

      if (this.dragSlot !== null && this.slots?.[this.dragSlot]) {
        this.slots[this.dragSlot].col = this.placeholderCol;
        this.slots[this.dragSlot].row = this.placeholderRow;
        this.slots[this.dragSlot].start = this._revertRowToTime(this.placeholderRow);
        this.slots[this.dragSlot].stop = this._revertRowToTime(
          this.placeholderRow + this.slots[this.dragSlot].duration / 15,
        );
        this.slots[this.dragSlot].capacity_index = this.placeholderCol + 1;
        this.slots[this.dragSlot].shift_template_roster_id = this.capacityRanges[this.placeholderCol]?.id;
      }

      this.handleDragEnd();
    },

    handleDragEnter() {
      this.isDraggingLeave = false;
    },

    handleDragLeave() {
      this.isDraggingLeave = true;
    },

    handleDragEnd() {
      this.isDragging = false;
      this.isDraggingLeave = false;
      this.dragSlot = null;
      this.dragNewSlot = null;
      this.placeholderCol = null;
      this.placeholderRow = null;

      document.getElementById("shift_template_shadow_id")?.remove();
    },

    addSiblingSlot(event, slot) {
      if (this.getIsDroppable(slot.col + 1, slot.row, slot.duration)) {
        this.slots.push({
          ...slot,
          id: undefined,
          col: slot.col + 1,
          capacity_index: slot.capacity_index + 1,
          shift_template_roster_id: this.capacityRanges[slot.col + 1]?.id,
        });
      }
    },

    handleDelete(event, index) {
      this.bus.$emit("openModal", {
        componentData: this.$t("shift_template.headings.do_you_want_to_remove_slot_item"),
        cancelTitle: this.$t("generic.words.no"),
        confirmTitle: this.$t("generic.words.yes"),
        callback: () => {
          this.slots.splice(index, 1);
        },
      });
    },
  },
};
</script>
