<template>
  <side-panel ref="sidePanel" :disable-listeners="true"></side-panel>
  <form class="flex w-full flex-col justify-between text-sm">
    <div class="grow space-y-mdSpace">
      <div class="font-semibold text-neutral-900">
        <div class="flex justify-between border-b py-smSpace">
          <div>{{ $t("booking.columns.order_id") }}</div>
          <div>{{ booking.order_id }}</div>
        </div>
        <div class="flex justify-between border-b py-smSpace">
          <div>{{ $t("booking.columns.order_date") }}</div>
          <div>{{ booking.date }}</div>
        </div>
        <div class="flex justify-between border-b py-smSpace">
          <div>{{ $t("booking.labels.total_paid") }}</div>
          <div>{{ toAUD(booking.payment_details.total_paid) }}</div>
        </div>
        <div class="flex justify-between border-b py-smSpace">
          <div>{{ $t("booking.columns.order_items") }}</div>
          <div>{{ booking.order_bookings.length }}</div>
        </div>
      </div>
      <div class="pb-smSpace text-neutral-700">
        <div class="flex justify-between pb-smSpace">
          <div class="font-semibold">{{ $t("booking.columns.select_bookings") }}</div>
          <a
            class="btn-secondary btn-sm btn-icon !rounded-lg"
            :class="{
              'btn-disabled': !hasItemsSelected,
              'btn-primary !text-white': hasItemsSelected,
            }"
            @click="cancelBooking"
          >
            <v-icon icon="XCircleIcon"></v-icon>
            <span class="pl-xsSpace">{{ $t("booking.buttons.cancel_booking") }}</span>
          </a>
        </div>
        <v-notification
          type="notice"
          :is-show-icon="false"
          :content="
            $t(isAdminOrManager ? 'booking.fields.cancel_notification' : 'booking.fields.staff_cancel_notification')
          "
        />
        <v-checkbox
          class="flex items-center space-x-xsSpace pb-xsSpace font-semibold"
          v-model="isSelectAll"
          @click="selectAll($event.target.checked)"
          :label-text="$t('generic.words.select_all')"
        />
        <div class="mb-xsSpace flex items-center justify-center pr-smSpace" v-for="booking in cancellableBookings">
          <v-checkbox
            :disabled="shouldDisableCheckbox(booking)"
            class="flex items-center space-x-xsSpace py-mdSpace"
            v-model="selectedBookings[booking.id]"
          />
          <booking-summary
            class="cursor-pointer"
            @click="selectBooking($event, booking)"
            :booking="booking"
            :with-addon="false"
            :current-user="currentUser"
          >
          </booking-summary>
        </div>
      </div>
      <v-notification v-if="message" type="success" :content="message" />
    </div>
  </form>
</template>
<script>
import BookingSummary from "@/js/vue/components/bookings/details/BookingSummary.vue";

export default {
  inject: ["bus", "config"],
  components: { BookingSummary },
  props: {
    booking: {
      type: Object,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
    inPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: null,
      mailContent: null,
      isSelectAll: false,
      selectedBookings: {},
    };
  },
  created() {
    this.bus.$on("closePanelInPanel", () => {
      this.$refs.sidePanel && this.$refs.sidePanel.close();
    });

    this.booking.order_bookings.forEach((o) => {
      if ((this.isAdminOrManager && !o.is_cancelled) || (!o.is_cancelled && !o.is_past)) {
        this.selectedBookings[o.id] = false;
      }
    });
  },
  computed: {
    hasItemsSelected() {
      const select = [];
      Object.keys(this.selectedBookings).forEach((id, index) => {
        if (this.selectedBookings[id]) {
          select.push(true);
        }
      });

      this.isSelectAll = select.length > 0 && Object.keys(this.selectedBookings).length === select.length;

      return select.length;
    },
    isAdminOrManager() {
      return (
        this.currentUser &&
        (this.currentUser.roles || []).some((role) =>
          [this.config.roles.admin.toLowerCase(), this.config.roles.manager.toLowerCase()].includes(role.name),
        )
      );
    },
    cancellableBookings() {
      return this.booking.order_bookings.filter((booking) => !booking.is_cancelled);
    },
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        if (!newValue.is_confirmed) {
          this.form.message = null;
          return;
        }

        if (this.form.message) {
          return;
        }

        this.form.message = this.mailContent;
      },
      deep: true,
    },
  },
  methods: {
    shouldDisableCheckbox(booking) {
      return !this.isAdminOrManager && (booking.is_cancelled || booking.is_past);
    },
    selectBooking($event, booking) {
      if (this.shouldDisableCheckbox(booking)) {
        return;
      }
      this.selectedBookings[booking.id] = !this.selectedBookings[booking.id];
    },
    selectAll(isSelect) {
      if (typeof isSelect === "boolean") {
        Object.keys(this.selectedBookings).forEach((id) => {
          this.selectedBookings[id] = isSelect;
        });

        this.selectedBookings = Object.assign({}, this.selectedBookings);
      }
    },
    cancelBooking() {
      this.$refs.sidePanel.open(
        "BookingCancel",
        {
          booking: this.booking,
          cancelBookings: this.booking.order_bookings.filter((booking) => {
            return this.selectedBookings[booking.id];
          }),
          currentUser: this.currentUser,
          inPanel: true,
        },
        `${this.$t("booking.headings.cancel_booking_title")}`,
        () => {},
      );
    },
  },
};
</script>
