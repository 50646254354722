<template>
  <div class="space-y-lgSpace p-lgSpace" v-if="!loading">
    <form class="grid grid-cols-1 gap-smSpace">
      <div class="py-mdSpace">
        <h3 class="text-base font-semibold leading-6 text-gray-900">{{ $t("square_device.headings.title") }}</h3>
        <p class="mt-xsSpace text-sm text-gray-500">{{ $t("square_device.headings.description") }}</p>
      </div>
      <input-wrapper field="name" :form="form" :label-text="$t('square_device.columns.name')">
        <input type="text" v-model="form.name" :placeholder="$t('square_device.placeholders.name')" />
      </input-wrapper>
    </form>
    <v-footer :save="save">
      <a class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')" @click.prevent="cancel"></a>
    </v-footer>
  </div>
  <div v-show="loading" aria-label="Loading..." role="status" class="flex h-full items-center justify-center space-x-2">
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>
<script>
import Form from "form-backend-validation";

export default {
  name: "DeviceEdit",
  inject: ["bus", "notificationService"],
  props: {
    device: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      item: this.device,
      loading: false,
      form: new Form(),
    };
  },

  mounted() {
    this.form = new Form(
      {
        name: this.getFieldValue("name"),
      },
      { resetOnSuccess: false },
    );
  },
  methods: {
    getFieldValue(field, children = null, defaultValue = null) {
      if (!this.item) {
        return defaultValue;
      }

      if (this.item.hasOwnProperty(field)) {
        return this.item[field];
      }
    },
    cancel() {
      this.bus.$emit("closePanel");
    },
    save() {
      this.loading = true;
      if (this.item.hasOwnProperty("id")) {
        this.send(this.form.patch(`/api/admin/square/square-devices/${this.item.id}`));
        return;
      }

      this.send(this.form.post(`/api/admin/square/square-devices`));
    },
    send(request) {
      request
        .then((res) => {
          this.bus.$emit("showNotification", {
            content: res.statusText,
            type: "success",
          });

          this.bus.$emit("closePanel");
          this.bus.$emit("reloadTable");
          this.loading = false;
        })
        .catch(({ response }) => {
          this.loading = false;
          this.notificationService.notify(response.data.message, "error");
        });
    },
  },
};
</script>
