const config = {
  TYPE_ROSTER: "roster",
  TYPE_APPOINTMENT: "appointment",
  TYPE_BREAK: "break",
  TYPE_FREE_SLOT: "free_slot",
  OPTION_REFUND: "Refund",
  OPTION_PROVIDE_VOUCHER: "Provide Voucher",
  OPTION_REFUNDED_ORIGINAL: "Refund Original",
  OPTION_NO_ACTION_REQUIRED: "No Action Required",
  REFUND_DEFAULT_VOUCHER: "refund_default_voucher",
  REFUND_USED_VOUCHER: "refund_used_voucher",
  OPTION_REFUNDED_CASH: "Refund Cash",

  PAYMENT_CASH: "cash",
  PAYMENT_CARD: "square",
  PAYMENT_VOUCHER: "gift voucher",
  PAYMENT_LATER: "pay later",
  PAYMENT_TERMINAL: "terminal",

  SQUARE_APP_ID: import.meta.env.VITE_SQUARE_CLIENT_ID,
  SQUARE_LOCATION_ID: import.meta.env.VITE_SQUARE_LOCATION_ID,
  DATETIME_FORMAT: "DD-MM-YYYY",
  FULL_DATETIME_FORMAT: "DD-MM-YYYY H:m:s",
  NUMBERS_OF_INDEX: 68,

  defaultFilterDisplay: 7,

  paymentMethods: [
    {
      id: "cash",
      label: "Cash",
    },
    {
      id: "square",
      label: "Square",
    },
    {
      id: "gift voucher",
      label: "Gift voucher",
    },
  ],
  paymentMethodsStart: [
    {
      id: "square",
      label: "Square",
    },
    {
      id: "gift voucher",
      label: "Gift voucher",
    },
    {
      id: "cash",
      label: "Cash",
    },
    {
      id: "pay later",
      label: "Pay later",
    },
  ],
  entitySearch: [
    {
      id: "customers",
      label: "Customer",
    },
    {
      id: "gift-voucher-instance",
      label: "Voucher",
    },
  ],
  taskTypes: {
    custom: null,
    customer: "App\\Models\\Customer",
    waitList: "App\\Models\\WaitList",
    giftVoucher: "App\\Models\\GiftVouchers\\GiftVoucher",
    giftVoucherInstance: "App\\Models\\GiftVouchers\\GiftVoucherInstance",
    booking: "App\\Models\\Bookings\\Booking",
    appointment: "App\\Models\\Shift\\Appointment",
  },
  modelClasses: {
    product: "App\\Models\\Product\\Product",
    category: "App\\Models\\Product\\ProductCategory",
    giftVoucherInstance: "App\\Models\\GiftVouchers\\GiftVoucherInstance",
  },

  notificationTypes: [
    {
      id: "email",
      label: "Email",
    },
    {
      id: "sms",
      label: "SMS",
    },
  ],

  notificationKeys: [
    { id: "booking_confirmation", label: "Booking Confirmation" },
    { id: "booking_reschedule", label: "Booking Reschedule" },
    { id: "booking_reminder", label: "Booking Reminder" },
    { id: "booking_cancellation", label: "Booking Cancellation" },
    { id: "booking_paid", label: "Booking Paid" },
    { id: "booking_on_hold", label: "Booking On Hold" },
    { id: "notify_waitlist", label: "Notify Waitlist" },
    { id: "waitlist_confirmation", label: "Waitlist Confirmation" },
    { id: "voucher_confirmation", label: "Voucher Confirmation" },
    { id: "voucher_giftee", label: "Voucher for Giftee" },
    { id: "customer_card_saved", label: "Card Saved" },
    { id: "waiver_notification", label: "Waiver Notification" },
  ],

  roles: {
    administrator: "Administrator", // Alias for admin
    admin: "Admin",
    manager: "Manager",
    staff: "Staff",
  },
  roleIdMapping: {
    admin: 1,
    manager: 2,
    customer: 3,
    client: 4,
    staff: 5,
  },
};

export { config };
