<template>
  <div
    class="mx-auto mb-lgSpace space-y-lgSpace text-white2 sm:w-[640px]"
  >
    <template v-if="!success">
      <p v-if="cart.quantity === 1" class="flex flex-col space-y-xsSpace mt-lgSpace">
        <p class="space-x-reverse text-sm font-semibold text-white2">
          {{ $t("gift_voucher.headings.giftee_message") }}
        </p>
        <v-checkbox
            class="flex items-center space-x-xsSpace"
            v-model="form.send_to_someone_else"
            :label-text="$t('gift_voucher.headings.send_to_someone_else')"
            dark-theme
        />
      </p>
      <div v-show="form.send_to_someone_else" class="space-y-mdSpace">
        <input-wrapper
            class="text-white2"
            field="giftee_name"
            :form="form"
            :label-text="$t('gift_voucher.fields.giftee_name')"
            :dark-theme="true"
        >
          <input
              class="bg-brand"
              type="text"
              v-model="form.giftee_name"
              :placeholder="$t('gift_voucher.placeholders.customer_name')"
          />
        </input-wrapper>
        <input-wrapper
            class="text-white2"
            field="giftee_email"
            :form="form"
            :label-text="$t('gift_voucher.fields.giftee_email')"
            dark-theme
        >
          <input
              class="bg-brand"
              type="text"
              v-model="form.giftee_email"
              :placeholder="$t('gift_voucher.placeholders.giftee_email')"
          />
        </input-wrapper>
        <input-wrapper
            class="text-white2"
            field="giftee_phone"
            :form="form"
            :label-text="$t('gift_voucher.fields.giftee_phone')"
            dark-theme
        >
          <phone-number v-model="form.giftee_phone" :placeholder="$t('gift_voucher.placeholders.phone')" dark-mode />
        </input-wrapper>
        <input-wrapper
            class="text-white2"
            field="message"
            :form="form"
            :label-text="$t('gift_voucher.fields.message')"
            dark-theme
        >
          <v-textarea
              class="bg-brand"
              v-model="form.message"
              :placeholder="$t('gift_voucher.placeholders.message')"
          ></v-textarea>
        </input-wrapper>
        <input-wrapper
            class="text-white2"
            field="sent_from"
            :form="form"
            :label-text="$t('gift_voucher.fields.sent_from')"
            dark-theme
        >
          <input
              class="bg-brand"
              type="text"
              v-model="form.sent_from"
              :placeholder="$t('gift_voucher.placeholders.sent_from')"
          />
        </input-wrapper>
        <hr/>
      </div>
      <p class="space-x-reverse text-sm font-semibold text-white2">
        {{ $t("gift_voucher.headings.purchase_summary") }}
      </p>
      <div class="rounded-lg bg-white p-mdSpace">
        <p class="text-lg font-semibold text-brand">
          {{ cart.vouchers[0].title }}
        </p>
      </div>
      <div class="border-b pb-xlSpace text-white2">
        <div class="mt-lgSpace flex justify-between font-semibold">
          <span class="text-lg">{{ $t("booking.labels.subtotal") }}</span>
          <span class="text-2xl font-normal">${{ cart.total.toFixed(2) }}</span>
        </div>
        <p class="flex flex-row-reverse text-sm">
          {{ $t("booking.labels.gst") }} ${{ (cart.total - cart.total / 1.1).toFixed(2) }}
        </p>
        <div class="mt-lgSpace flex justify-between font-semibold">
          <span class="text-lg">{{ $t("booking.labels.total") }}</span>
          <span class="text-2xl font-normal">${{ cart.total.toFixed(2) }}</span>
        </div>
      </div>
      <div class="!mt-mdSpace font-semibold text-white2">
        <span class="text-lg" v-if="cart.total > 0">{{ $t("booking.headings.payment_method") }}</span>
        <div class="mt-smSpace">
          <square-payment v-if="cart.total > 0" ref="square" />
          <div class="mb-2xlSpace flex justify-center">
            <button
              class="btn-secondary !block items-center !bg-brand !text-white2"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                aria-label="Loading..."
                role="status"
                class="flex items-center justify-center space-x-2 text-white2"
              >
                <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
                <span class="text-lg font-medium text-tertiary-500 text-white2">{{
                  $t("generic.words.processing")
                }}</span>
              </div>
              <span v-else>{{ $t("generic.buttons.confirm_payment") }}</span>
            </button>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="pt-lgSpace text-center text-lg text-white2">
      <p>{{ $t("gift_voucher_instance.response.success") }}</p>
      <p class="py-lgSpace">{{ $t("gift_voucher_instance.headings.voucher_list") }}</p>
      <table
        class="min-w-full border-collapse rounded-sm border-none pl-xsSpace text-left text-sm shadow-[0_0_0_1px_white]"
      >
        <thead>
          <tr>
            <th scope="col" class="border p-xsSpace">Name</th>
            <th scope="col" class="border p-xsSpace">Description</th>
            <th scope="col" class="border p-xsSpace">Code</th>
            <th scope="col" class="border p-xsSpace">Balance</th>
            <th scope="col" class="border p-xsSpace">Expiry</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="voucher in vouchers" :key="voucher.code">
            <td class="border p-xsSpace">{{ voucher.title }}</td>
            <td class="border p-xsSpace">{{ voucher.description }}</td>
            <td class="border p-xsSpace font-bold">{{ voucher.code }}</td>
            <td class="border p-xsSpace">{{ toAUD(voucher.balance) }}</td>
            <td class="border p-xsSpace">{{ voucher.expiry }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import VTextarea from "@/js/vue/components/common/form/VTextarea.vue";

export default {
  name: "VoucherCheckout",
  inject: ["notificationService", "config"],
  components: { VTextarea },
  props: {
    cart: {
      required: true,
    },
  },
  data() {
    return {
      form: new Form(
        {
          giftee_email: this.cart.hasOwnProperty("giftee_email") ? this.cart.giftee_email : "",
          giftee_phone: this.cart.hasOwnProperty("giftee_phone") ? this.cart.giftee_phone : "",
          giftee_name: this.cart.hasOwnProperty("giftee_name") ? this.cart.giftee_name : "",
          send_to_someone_else: this.cart.hasOwnProperty("send_to_someone_else")
            ? this.cart.send_to_someone_else
            : this.cart.quantity === 1,
          message: this.cart.hasOwnProperty("message") ? this.cart.message : "",
          sent_from: this.cart.hasOwnProperty("sent_from") ? this.cart.sent_from : "",
          payment: {
            type: this.config.PAYMENT_CARD,
            token: null,
            amount: null,
          },
        },
        { resetOnSuccess: false },
      ),
      success: false,
      loading: false,
      vouchers: [],
    };
  },
  methods: {
    async submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      try {
        this.form.payment.token = await this.$refs.square.submit();
        this.form.payment.amount = this.cart.total;
        const res = await this.form.post("/api/voucher/checkout");
        this.success = true;
        this.vouchers = res.vouchers;
      } catch (e) {
        this.notificationService.notify(e.message, "error");
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
input::placeholder,
textarea::placeholder {
  @apply !text-white2 opacity-60;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}
</style>
