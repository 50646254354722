<template>
  <div class="px-lgSpace py-mdSpace">
    <div>
      <p class="text-lg font-semibold">{{ appointment.room_name }}</p>
      <p class="text-lg font-semibold">{{ appointment.date }}</p>
      <div class="flex gap-smSpace">
        <p class="text-lg font-semibold">{{ appointment.start }} - {{ appointment.stop }}</p>
        <v-badge
          class="!text-xs font-medium"
          :value="appointment.is_online ? $t('generic.online') : $t('generic.offline')"
          :type="appointment.is_online ? 'active' : 'inactive'"
        />
      </div>
      <p class="text-lg font-semibold">{{ appointment.duration }} mins</p>
    </div>
    <v-activity-log
      :filter-event="`activityTableFilterChange_${appointment.id}`"
      :model-id="appointment.id"
      :model-class="appointment.class"
      :allow-comment="true"
      :current-user="currentUser"
    >
      <v-filter
        :filterEmitsTo="`activityTableFilterChange_${appointment.id}`"
        filter-endpoint="/api/generic/activity-logs/filters"
        entity="activity-logs"
        class="flex flex-row-reverse space-x-2 space-x-reverse"
      >
      </v-filter>
    </v-activity-log>
  </div>
</template>
<script>
export default {
  props: {
    appointment: {
      type: Object,
    },
    currentUser: {
      type: Object,
    },
  },
};
</script>
