<template>
  <input-wrapper class="text-white2" field="vouchers" :form="new Form()" :dark-theme="true">
    <label v-if="!hideLabel" class="text-sm font-normal leading-normal text-white2">
      <span>{{ $t("booking.columns.voucher") }}</span>
      <br />
      <span>{{ $t("booking.columns.please_enter_your_code_here") }}</span>
    </label>
    <div class="relative space-y-xsSpace">
      <template v-for="(item, index) in displayedVouchers">
        <div class="relative w-full" :class="{ '!z-10': tooltips[index] }">
          <input
            :class="{
              'bg-brand !placeholder-white !placeholder-opacity-60 focus:border-white focus:ring focus:ring-white focus:ring-opacity-50':
                darkTheme,
              'bg-white text-gray-900': !darkTheme,
              'line-through': cartTotal > 0 && !isDiscounting(index),
            }"
            type="text"
            v-model="vouchers[index]"
            :placeholder="$t('booking.placeholders.voucher')"
            :disabled="Object.keys(vouchersInfo).length && vouchersInfo[index] && vouchersInfo[index].is_valid"
            @keydown="clearVoucherInfo(index)"
          />
          <v-icon
            v-if="
              (vouchers[index] && Object.keys(vouchersInfo).length && vouchersInfo[index]) ||
              (vouchers.length && !vouchers[index])
            "
            icon="XMarkIcon"
            :class="{
              'absolute right-xsSpace top-xsSpace h-5 w-5 cursor-pointer font-bold': true,
              'text-tertiary-300 hover:text-white2': darkTheme,
              'text-tertiary-400 hover:text-tertiary-900': !darkTheme,
            }"
            @click.stop="removeVoucher(index)"
          />
          <button
            v-else-if="vouchers[index]"
            class="btn-secondary absolute right-xsSpace top-xsSpace !block h-5 w-auto cursor-pointer items-center !bg-transparent !py-0 !text-xs"
            :class="{ '!text-white2': darkTheme, '!text-tertiary-400': !darkTheme }"
            @click.prevent="submit(item, index)"
          >
            {{ $t("generic.buttons.apply") }}
          </button>

          <div
            v-if="Object.keys(vouchersInfo).length && vouchersInfo[index] && vouchersInfo[index].message"
            class="relative !mt-2xsSpace"
          >
            <div
              :class="{
                'block text-sm': true,
                'text-white2': darkTheme,
                'text-black': !darkTheme,
                'flex rounded bg-pink-100 p-smSpace': !vouchersInfo[index]?.is_valid,
              }"
            >
              <v-icon
                v-if="!vouchersInfo[index].is_valid"
                icon="SolidExclamationCircleIcon"
                classes="w-5 h-5 text-red-400 mr-2xsSpace"
              ></v-icon>
              <v-tooltip side="bottom" align="start">
                <span
                  class="opacity-50"
                  v-html="vouchersInfo[index].message"
                  :class="{
                    'cursor-pointer': tooltip,
                    '!text-red-500 !opacity-100': !vouchersInfo[index]?.is_valid,
                    'line-through': cartTotal > 0 && !isDiscounting(index),
                  }"
                >
                </span>
                <template #tooltip>
                  <div
                    v-if="(vouchersInfo[index].applicable_product_variants || []).length"
                    class="rounded-lg p-xsSpace text-xs text-white2"
                  >
                    <template v-if="cartTotal === 0 || isDiscounting(index)">
                      <p class="pb-xsSpace">{{ $t("gift_voucher.labels.applicable_product_variants") }}</p>
                      <ul class="flex flex-col gap-2xsSpace">
                        <li
                          v-for="productVariant in (vouchersInfo[index].applicable_product_variants || []).slice(0, 2)"
                        >
                          * {{ productVariant }}
                        </li>
                        <li v-if="(vouchersInfo[index].applicable_product_variants || []).length > 2">
                          {{
                            (vouchersInfo[index].applicable_product_variants || []).length - 2 > 1
                              ? $t("gift_voucher.labels.and_more_product_variants").replace(
                                  ":count",
                                  (vouchersInfo[index].applicable_product_variants || []).length - 2,
                                )
                              : $t("gift_voucher.labels.and_one_more_product_variant")
                          }}
                        </li>
                      </ul>
                    </template>
                    <p v-else>{{ $t("gift_voucher.labels.voucher_is_not_applied") }}</p>
                  </div>
                </template>
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>
      <a
        v-if="canAddVoucher"
        class="absolute bottom-0 right-0 z-10 ml-auto cursor-pointer text-sm font-bold"
        :class="{
          'text-white2 hover:text-tertiary-300': darkTheme,
          'text-tertiary-400 hover:text-tertiary-900': !darkTheme,
        }"
        @click="addVoucher"
      >
        {{ $t("booking.buttons.add_another") }}
      </a>
    </div>
  </input-wrapper>
</template>

<script>
import axios from "axios";
import Form from "form-backend-validation";
import { cloneDeep } from "lodash";

export default {
  inject: ["config", "bus"],
  props: {
    venueId: {
      type: Number,
    },
    categoryId: {
      type: Number,
    },
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    info: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    darkTheme: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
    cartTotal: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
  },
  data() {
    return {
      vouchersInfo: [],
      tooltips: [],
      vouchers: [],
    };
  },
  mounted() {
    this.updateVouchers();
    this.bus.$on("reloadCart", () => {
      this.updateVouchers();
    });
  },
  computed: {
    Form() {
      return Form;
    },
    displayedVouchers() {
      return this.vouchers.length === 0 ? [""] : this.vouchers;
    },
    outstandingBalance() {
      return Math.max(0, this.cartTotal - this.vouchersInfo.reduce((acc, info) => acc + parseFloat(info.balance), 0));
    },
    canAddVoucher() {
      return (
        this.outstandingBalance > 0 &&
        this.vouchers.length &&
        this.vouchers.every(Boolean) &&
        this.vouchers.length === this.vouchersInfo.filter((vi) => vi.is_valid).length
      );
    },
  },
  watch: {
    categoryId() {
      this.updateVouchers();
    },
  },
  methods: {
    updateVouchers() {
      setTimeout(() => {
        this.vouchers = cloneDeep(this.modelValue);
        this.vouchers.forEach((code, index) => {
          if (code) {
            this.submit(code, index, true);
          }
        });
      });
    },
    addVoucher() {
      this.vouchers[this.vouchers.length] = null;
      this.$emit("update:modelValue", this.vouchers);
    },
    removeVoucher(index) {
      this.vouchers.splice(index, 1);
      this.vouchersInfo.splice(index, 1);
      this.$emit("update:modelValue", this.vouchers);
      this.$emit("update:info", this.vouchersInfo);
    },
    clearVoucherInfo(index) {
      if (this.vouchersInfo[index]) {
        this.vouchersInfo.splice(index, 1);
        this.$emit("update:info", this.vouchersInfo);
      }
    },
    submit(code, key, onload = false) {
      this.vouchersInfo[key] = {
        message: null,
        is_valid: false,
        code: "",
        balance: 0,
        applicable_product_variants: this.vouchersInfo[key]?.applicable_product_variants,
      };

      if (this.vouchersInfo.filter((info) => info.code === code).length && !onload) {
        this.vouchersInfo[key].message = this.$t("gift_voucher_instance.response.inuse");
        return;
      }

      if (code) {
        axios
          .post(`/api/generic/gift-voucher-instance/${code}`, {
            venue_id: this.venueId,
            category_id: this.categoryId,
          })
          .then(({ data }) => {
            this.tooltips[key] = false;
            this.vouchersInfo[key].message = data.description;
            this.vouchersInfo[key].code = code;
            this.vouchersInfo[key].is_valid = true;
            this.vouchersInfo[key].balance = data.balance;
            this.vouchersInfo[key].applicable_product_variants = data.applicable_product_variants;
            this.$emit("update:info", this.vouchersInfo);
            this.$emit("update:modelValue", this.vouchers);
          })
          .catch((error) => {
            this.vouchersInfo[key].message = error.response.data.message;
            this.vouchersInfo[key].is_valid = false;
          });
      }
    },
    showToolTip($event, index) {
      if (!this.tooltip || !this.vouchersInfo[index]?.is_valid) {
        return;
      }

      this.tooltips.forEach((o, i) => {
        this.tooltips[i] = false;
      });
      this.tooltips[index] = true;
      setTimeout(() => {
        this.tooltips[index] = false;
      }, 10000);
    },
    isDiscounting(index) {
      let internalCartTotal = this.cartTotal;
      (this.vouchersInfo || []).slice(0, index).forEach((info) => (internalCartTotal -= parseFloat(info.balance)));

      return internalCartTotal > 0;
    },
  },
};
</script>
<style scoped>
input::placeholder {
  @apply !text-inherit opacity-60;
}
</style>
