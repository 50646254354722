<template>
  <div class="flex items-center justify-between gap-x-2 md:p-mdSpace">
    <p class="text-sm font-semibold md:text-white">{{ $t("client.headings.customer") }}</p>
    <select v-model="id" class="bg-transparent md:text-white" @change="onSelectCustomer($event)">
      <option v-for="customer in customers" :value="customer.id" class="text-brand">
        {{ customer.display_name }}
      </option>
    </select>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  inject: ["bus"],
  name: "CustomerSelect",
  computed: {
    ...mapState({
      currentCustomer: (state) => state.customer.currentCustomer,
      customers: (state) => state.customer.items,
    }),
  },
  data() {
    return {
      id: this.currentCustomer ? this.currentCustomer.id : null,
    };
  },
  mounted() {
    this.$store.dispatch("customer/fetchCustomers").then(() => {
      const id = parseInt(localStorage.getItem("customerId"));
      if (id) {
        this.id = id;
        this.onSelectCustomer();
      } else if (this.customers.length !== 0) {
        this.id = this.customers[0].id;
        this.onSelectCustomer();
      }
    });
  },
  methods: {
    onSelectCustomer() {
      const customer = this.customers.find((o) => o.id === this.id);
      this.$store.dispatch("customer/setCustomer", customer ?? {});
      localStorage.setItem("customerId", customer ? customer.id : null);
      this.bus.$emit("customerSelected");
    },
  },
};
</script>
