<template>
  <form class="flex w-full flex-col justify-between p-lgSpace" v-if="!loading">
    <side-panel ref="sidePanel" :disable-listeners="true"></side-panel>
    <h3 class="mb-mdSpace text-lg">{{ $t("booking.labels.summary") }}</h3>
    <div class="absolute right-4 top-6 space-x-xsSpace">
      <a @click="clickAddTask(data)" class="btn-sm btn-secondary bg-white">
        <span>{{ $t("task.buttons.add") }}</span>
      </a>
    </div>
    <div class="space-y-xsSpace rounded p-smSpace text-sm ring-1">
      <div class="flex gap-smSpace">
        <p class="w-1/4">{{ $t("booking.labels.location") }}</p>
        <strong class="w-3/4">{{ data.venue.name }}</strong>
      </div>
      <div class="flex gap-smSpace">
        <p class="w-1/4">{{ $t("booking.labels.date_time") }}</p>
        <strong class="w-3/4">{{ data.appointments[0].readable_period }}</strong>
      </div>
      <div class="flex gap-smSpace">
        <p class="w-1/4">{{ $t("generic.words.product") }}</p>
        <div class="w-3/4 overflow-hidden text-ellipsis whitespace-nowrap">
          <strong class="text-green-600">{{ data.quantity }}</strong>
          <strong> x </strong> {{ data.product.name }} ({{ data.product_variant.name }})
        </div>
      </div>
    </div>
    <h3 class="mb-smSpace mt-lgSpace text-lg">{{ $t("generic.words.customer") }}</h3>
    <div class="space-y-xsSpace">
      <customer-input
        :form="form"
        v-model:customer-id="form.customer_id"
        v-model:first-name="form.first_name"
        v-model:last-name="form.last_name"
        @selectClient="selectClient($event)"
      />
      <input-wrapper field="phone" :form="form" :label-text="$t('booking.columns.phone')">
        <phone-number v-model="form.phone" :placeholder="$t('booking.placeholders.phone')" />
      </input-wrapper>
      <input-wrapper field="email" :form="form" :label-text="$t('booking.columns.email')">
        <input type="text" v-model="form.email" :placeholder="$t('booking.placeholders.email')" />
      </input-wrapper>
      <input-wrapper field="address" :form="form" :label-text="$t('booking.columns.address')">
        <input type="text" v-model="form.address" :placeholder="$t('booking.placeholders.address')" />
      </input-wrapper>
      <v-checkbox
        v-if="data.quantity > 1"
        class="flex items-center space-x-xsSpace py-mdSpace"
        v-model="form.use_customer_info"
        :label-text="$t('booking.fields.use_customer_info')"
      />
    </div>
    <h3 class="my-smSpace text-lg" v-if="form.users.length">{{ $t("generic.words.clients") }}</h3>
    <div class="mb-xsSpace space-y-xsSpace rounded p-smSpace text-sm ring-1" v-for="(_client, i) in form.users">
      <input-wrapper :field="`users.${i}.name`" :form="form" :label-text="$t('booking.columns.client') + ' ' + (i + 1)">
        <div class="relative flex w-full gap-2">
          <v-input-search
            v-model="form.users[i].first_name"
            :placeholder="$t('booking.placeholders.client')"
            search-url="/api/admin/quick-search/customers?filter[active]=1"
            filter-key="term"
            @selectItem="selectClient($event, true, i)"
            create-url="/api/admin/customers?quick=1"
            :create-placeholder="$t('customer.placeholders.quick_create')"
          ></v-input-search>
          <v-input-search
            v-model="form.users[i].last_name"
            :placeholder="$t('booking.placeholders.client')"
            search-url="/api/admin/quick-search/customers?filter[active]=1"
            filter-key="term"
            @selectItem="selectClient($event, true, i)"
          ></v-input-search>
        </div>
      </input-wrapper>
      <input-wrapper :field="`users.${i}.phone`" :form="form" :label-text="$t('booking.columns.phone') + ' ' + (i + 1)">
        <input type="text" v-model="form.users[i].phone" :placeholder="$t('booking.placeholders.phone')" />
      </input-wrapper>
      <input-wrapper :field="`users.${i}.email`" :form="form" :label-text="$t('booking.columns.email') + ' ' + (i + 1)">
        <input type="text" v-model="form.users[i].email" :placeholder="$t('booking.placeholders.email')" />
      </input-wrapper>
    </div>
    <div>
      <div class="space-y-smSpace py-xsSpace" :class="{ 'py-mdSpace': form.payment.type !== config.PAYMENT_CARD }">
        <payment
          ref="payment"
          :disabled-methods="disabledPaymentMethods"
          :form="form"
          :customer-id="form.customer_id"
          :product-id="data.product.id"
          :product-variant-id="data.product_variant_id"
          :quantity="data.quantity"
          :amount="productAmount"
          endpoint="/api/admin/bookings/cart"
        />
      </div>

      <template v-if="isPayLater">
        <input-wrapper field="expired_at" :form="form" :label-text="$t('booking.columns.expiry_date')">
          <date-picker
            v-model:value="form.expired_at"
            :placeholder="$t('booking.placeholders.select_date')"
            value-type="format"
            class="w-full"
            format="DD-MM-YYYY"
            type="date"
            :disabled-date="disableDateNotAllowOnHoldBooking"
          ></date-picker>
        </input-wrapper>
        <input-wrapper field="send_on_hold_information" :form="form">
          <div class="mt-mdSpace rounded bg-slate-100 leading-10 [&_span]:!text-xs">
            <v-checkbox
              class="flex select-none items-center space-x-xsSpace p-mdSpace [&_span]:!text-xs"
              name="send_on_hold_information"
              v-model="form.send_on_hold_information"
              :label-text="$t('booking.headings.send_on_hold_information')"
            />
          </div>
        </input-wrapper>
      </template>
    </div>
    <div
      class="mt-xlSpace flex flex-col justify-end rounded sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
    >
      <a @click.prevent="back" class="btn-secondary btn-sm" v-text="$t('generic.buttons.back')"></a>
      <a @click.prevent="confirm" class="btn-primary btn-sm">
        <div
          v-if="loading"
          aria-label="Loading..."
          role="status"
          class="flex items-center justify-center space-x-2 text-white"
        >
          <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
          <span class="font-medium text-tertiary-500 text-white">{{ $t("generic.words.processing") }}</span>
        </div>
        <span v-else>{{ $t("generic.buttons.continue") }}</span>
      </a>
    </div>
  </form>
  <div v-else aria-label="Loading..." role="status" class="flex h-full items-center justify-center space-x-2">
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>
<script>
import Form from "form-backend-validation";
import moment from "moment";
import Payment from "~vue/mixins/Payment";

export default {
  inject: ["config", "bus", "notificationService"],
  mixins: [Payment],
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentUser: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      productAmount: this.data.product_variant.price_per_unit * this.data.quantity,
      form: new Form(
        {
          id: this.id,
          use_customer_info: true,
          customer_id: false,
          users: [],
          first_name: null,
          last_name: null,
          phone: null,
          address: null,
          email: null,
          appointment_id: this.data.appointment_ids[0],
          product_id: this.data.product_id,
          product_variant_id: this.data.product_variant_id,
          payment: {
            type: null,
            amount: null,
          },
          expired_at: null,
          voucher: null,
          send_on_hold_information: true,
        },
        { resetOnSuccess: false },
      ),
    };
  },
  computed: {
    isAdminOrManager() {
      const user = this.$store.state.user;
      return user && (user.role === this.config.roles.administrator || user.role === this.config.roles.manager);
    },
    disabledPaymentMethods() {
      if (this.data.date && moment(this.data.date).isBefore(moment().add(1, "days")) && !this.isAdminOrManager) {
        return [this.config.PAYMENT_LATER];
      }

      return [];
    },
    isPayLater() {
      return this.form.payment.type === this.config.PAYMENT_LATER;
    },
  },
  watch: {
    "form.use_customer_info": {
      handler(newValue) {
        if (!newValue && this.data.quantity > 1) {
          for (const index of Array(this.data.quantity).keys()) {
            this.form.users[index] = {
              client_id: null,
              first_name: null,
              last_name: null,
              email: null,
              phone: null,
            };
          }
        }

        if (newValue) {
          this.form.users = [];
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getUser();
    this.bus.$on("closePanelInPanel", () => {
      this.$refs.sidePanel && this.$refs.sidePanel.close();
    });

    if (Array.isArray(this.data.appointments) && this.data.appointments.length > 0) {
      const appointment = this.data.appointments[0];
      this.$store.dispatch("bookingCreate/setAppointment", appointment);
      this.$store.dispatch("bookingCreate/setRoom", appointment.room);
      this.$store.dispatch("bookingCreate/setCurrentDate", appointment.date);
    }
  },
  beforeUnmount() {
    this.bus.$off("closePanelInPanel");
  },
  methods: {
    async getUser() {
      await this.$store.dispatch("user/fetchUser");
      this.$store.dispatch("bookingCreate/setCurrentUser", this.$store.state.user);
    },
    back() {
      this.bus.$emit("openSidePanel", {
        componentName: "FindAvailabilities",
        componentData: {
          id: this.id,
        },
        closeCallback: () => {},
      });
    },
    async confirm() {
      if (!Array.isArray(this.data.appointments) || this.data.appointments.length === 0) {
        return;
      }

      this.loading = true;
      try {
        await this.$refs.payment.submit();
        this.bus.$emit("openSidePanel", {
          componentName: "BookingConfirm",
          title: "",
          componentData: {
            id: this.id,
          },
          closeCallback: "",
        });
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    selectClient(client, forClient = false, index = null) {
      if (forClient) {
        if (client) {
          this.form.users[index].client_id = client.id;
          this.form.users[index].first_name = client.first_name;
          this.form.users[index].last_name = client.last_name;
          this.form.users[index].phone = client.phone;
          this.form.users[index].email = client.email;
          return;
        }

        this.form.users[index].client_id = null;
        this.form.users[index].first_name = null;
        this.form.users[index].last_name = null;
        this.form.users[index].phone = null;
        this.form.users[index].email = null;

        return;
      }

      if (client) {
        this.form.customer_id = client.id;
        this.form.first_name = client.first_name;
        this.form.last_name = client.last_name;
        this.form.phone = client.phone;
        this.form.email = client.email;
        this.form.address = client.address;
        return;
      }

      this.form.customer_id = null;
      this.form.first_name = null;
      this.form.last_name = null;
      this.form.phone = null;
      this.form.email = null;
      this.form.address = null;
    },
    clickAddTask(data) {
      this.$refs.sidePanel.open(
        "TaskEdit",
        {
          taskable: {
            class: this.config.taskTypes.appointment,
            id: data.appointments[0].id,
          },
          inPanel: true,
        },
        `${this.$t("task.labels.add_for")} ${(this.form.first_name + " " + this.form.last_name).trim() || ""} - ${
          data.product_variant.name
        }`,
        () => {},
      );
    },
    disableDateNotAllowOnHoldBooking(date) {
      const now = moment();
      const calenderDate = moment(date);
      let bookingDate = moment(this.data.appointments[0].start_at);
      if (!this.isAdminOrManager) {
        bookingDate = bookingDate.subtract(1, "days");
      }

      return calenderDate.isBefore(now, "date") || calenderDate.isAfter(bookingDate, "date");
    },
  },
};
</script>
