<template>
  <div>
    <div class="flex justify-end p-mdSpace">
      <open-side-panel-button component-name="DeviceEdit" :component-data="{ device: {} }" classes="flex -space-x-0.5">
        <a class="btn-secondary btn-sm flex-row-reverse space-x-reverse" type="button" @click="add">
          <span>{{ $t("square_device.buttons.add") }}</span>
          <div class="btn-icon-left">
            <v-icon icon="PlusIcon"></v-icon>
          </div>
        </a>
      </open-side-panel-button>
    </div>
    <v-table :get-url="getUrl" :fields="fields"> </v-table>
  </div>
</template>

<script>
import DeviceEdit from "./DeviceEdit.vue";
export default {
  name: "DeviceManagement",
  inject: ["bus"],
  components: { DeviceEdit },
  props: {
    fields: {
      type: Object,
      required: true,
    },
    getUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showTable: true,
      device: {},
    };
  },
  methods: {
    add() {
      this.showTable = false;
    },
  },
};
</script>
