<template>
  <div class="flex items-center justify-between space-x-smSpace">
    <a
      @click.prevent.stop="decreaseQty"
      class="flex h-[20px] w-[20px] cursor-pointer items-center justify-center rounded-full border !border-brand !text-brand"
      :class="{ 'opacity-50': decreaseDisabled }"
    >
      <v-icon icon="MinusIcon" class="h-[12px] w-[12px] text-brand" />
    </a>
    <span class="text-center text-[15px] font-medium text-brand">{{ modelValue }}</span>
    <a
      @click.prevent.stop="increaseQty"
      class="flex h-[20px] w-[20px] cursor-pointer items-center justify-center rounded-full border !border-brand !text-brand"
      :class="{ 'opacity-50': increaseDisabled }"
    >
      <v-icon icon="PlusIcon" class="h-[12px] w-[12px] text-brand" />
    </a>
  </div>
</template>

<script>
export default {
  name: "QuantityStepper",
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    decreaseDisabled() {
      return this.modelValue <= this.min;
    },
    increaseDisabled() {
      return this.modelValue >= this.max;
    },
  },
  methods: {
    increaseQty() {
      this.$emit("update:modelValue", Math.min(this.modelValue + this.step, this.max));
    },
    decreaseQty() {
      this.$emit("update:modelValue", Math.max(this.modelValue - this.step, this.min));
    },
  },
};
</script>
