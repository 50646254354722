<template>
  <div class="relative" :class="$attrs.class">
    <input-wrapper field="customer_id" :form="form" :label-text="hideLabel ? `&nbsp` : $t('booking.columns.client')">
      <v-input-search
        v-model="customerFullName"
        :placeholder="$t('booking.placeholders.client')"
        search-url="/api/admin/quick-search/customers?filter[active]=1"
        filter-key="term"
        @selectItem="selectClient($event)"
        create-url="/api/admin/customers?quick=1"
        :create-placeholder="$t('customer.placeholders.quick_create')"
        input-class="flex-1"
      ></v-input-search>
    </input-wrapper>
  </div>
  <div v-if="selectedCustomer" class="flex flex-col gap-mdSpace md:flex-row">
    <input-wrapper
      field="first_name"
      :form="form"
      class="flex-1"
      :label-text="hideLabel ? `&nbsp` : $t('customer.fields.name')"
    >
      <input type="text" v-model="internalFirstName" :placeholder="$t('customer.placeholders.first_name')" />
    </input-wrapper>

    <input-wrapper field="last_name" :form="form" class="flex-1" label-text="&nbsp;">
      <input type="text" v-model="internalLastName" :placeholder="$t('customer.placeholders.last_name')" />
    </input-wrapper>
  </div>
</template>

<script>
export default {
  name: "CustomerInput",
  props: {
    form: {
      type: Object,
      required: true,
    },
    customerId: {
      type: Number,
      default: null,
    },
    firstName: {
      type: String,
      default: null,
    },
    lastName: {
      type: String,
      default: null,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:customerId", "update:firstName", "update:lastName", "selectClient"],
  data() {
    return {
      selectedCustomer: null,
    };
  },
  computed: {
    customerFullName: {
      get() {
        return this.selectedCustomer?.heading;
      },
      set(value) {
        this.selectedCustomer = value;
      },
    },
    internalFirstName: {
      get() {
        return this.firstName;
      },
      set(value) {
        this.$emit("update:firstName", value);
      },
    },
    internalLastName: {
      get() {
        return this.lastName;
      },
      set(value) {
        this.$emit("update:lastName", value);
      },
    },
  },
  methods: {
    selectClient(client) {
      this.internalFirstName = client.first_name;
      this.internalLastName = client.last_name;
      this.selectedCustomer = client;

      this.$emit("update:customerId", client.id);
      this.$emit("selectClient", client);
    },
  },
};
</script>
