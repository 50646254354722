import axios from "axios";
import { orderBy } from "lodash";

export default {
  methods: {
    isUseTerminal(payment, paymentConfig) {
      return ![
        paymentConfig.PAYMENT_CASH,
        paymentConfig.PAYMENT_CARD,
        paymentConfig.PAYMENT_LATER,
        paymentConfig.PAYMENT_VOUCHER,
      ].includes(payment);
    },
    async getPayments(paymentConfig) {
      const { data } = await axios.get("/api/admin/square/square-devices?per_page=1000&filter[status]=PAIRED");
      return [
        paymentConfig.paymentMethodsStart[0],
        ...orderBy(
          data.data.map((o) => ({ id: o.id, label: o.name })),
          ["label"],
        ),
        ...paymentConfig.paymentMethodsStart.slice(1),
      ];
    },
    getRefundPayments(booking, paymentConfig) {
      const allowMethods = [paymentConfig.PAYMENT_CARD];

      if (booking.payment_details.is_fully_paid_by_cash) {
        allowMethods.push(paymentConfig.PAYMENT_CASH);
      }

      return paymentConfig.paymentMethodsStart.filter((payment) => {
        return allowMethods.includes(payment.id);
      });
    },
    checkRefund(bookingId) {
      return new Promise((resolve) => {
        const interval = setInterval(async () => {
          const { data } = await axios.get(`/api/admin/bookings/${bookingId}/check-refund`);
          if (data.pending_payments.length) {
            return;
          }
          clearInterval(interval);
          resolve();
        }, 1500);
      });
    },
    checkPayments(orderId) {
      return new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
          const response = await axios.get(`/api/admin/orders/${orderId}/payments`);

          if (!response.data.keep_checking) {
            resolve(response);
            clearInterval(interval);
          }
        }, 1500);
      });
    },
  },
};
