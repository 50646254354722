export default {
  props: {
    media: {
      type: Object,
      required: true,
    },

    displayStyle: {
      type: String,
      default: "list",
    },
  },

  data: () => {
    return {
      error: false,
    };
  },

  methods: {
    replaceByDefault(e) {
      this.error = true;

      setTimeout(() => {
        this.error = false;
      }, 1000);
    },
  },
};
