<template>
  <td>
    <span v-if="currentValue.hasOwnProperty('link')">
      <a :href="currentValue.link">
        <v-badge
          :type="currentValue.type"
          :value="currentValue.value"
          :class="{ 'hover:bg-success-300 hover:text-black': currentValue.hasOwnProperty('link') }"
        />
      </a>
    </span>
    <span v-else>
      <v-badge :type="currentValue.type" :value="currentValue.value" />
    </span>
  </td>
</template>
<script>
export default {
  name: "VTableStatus",
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
    rowField: {
      type: [String, Object],
    },
  },

  computed: {
    currentValue() {
      return this.rowData[this.rowField.field];
    },
  },
};
</script>
