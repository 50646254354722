<template>
  <div class="space-y-8 pb-12">
    <contact-detail v-model="contact" :form="form" always-open />

    <!--    <div v-if="cart.quantity === 1">-->
    <!--      <v-checkbox-->
    <!--        v-model="form.send_to_someone_else"-->
    <!--        :form="form"-->
    <!--        :label-text="$t('gift_voucher.labels.for_giftee')"-->
    <!--        dark-theme-->
    <!--      />-->

    <!--      <div-->
    <!--        v-if="form.send_to_someone_else"-->
    <!--        class="my-smSpace space-y-lgSpace rounded-md bg-white bg-opacity-10 px-mdSpace py-lgSpace text-white2"-->
    <!--      >-->
    <!--        <div class="flex flex-col gap-1 md:flex-row">-->
    <!--          <input-wrapper-->
    <!--            class="flex-1 text-white2"-->
    <!--            field="giftee_first_name"-->
    <!--            :form="form"-->
    <!--            :is-required="true"-->
    <!--            :label-text="$t('gift_voucher.fields.giftee_name')"-->
    <!--            :dark-theme="true"-->
    <!--          >-->
    <!--            <input-->
    <!--              class="bg-brand bg-opacity-10 placeholder-white placeholder-opacity-50"-->
    <!--              type="text"-->
    <!--              v-model="form.giftee_first_name"-->
    <!--              :placeholder="$t('customer.placeholders.first_name')"-->
    <!--            />-->
    <!--          </input-wrapper>-->
    <!--          <input-wrapper-->
    <!--            class="flex-1 text-white2"-->
    <!--            field="giftee_last_name"-->
    <!--            :form="form"-->
    <!--            label-text="&nbsp;"-->
    <!--            :dark-theme="true"-->
    <!--          >-->
    <!--            <input-->
    <!--              class="bg-brand bg-opacity-10 placeholder-white placeholder-opacity-50"-->
    <!--              type="text"-->
    <!--              v-model="form.giftee_last_name"-->
    <!--              :placeholder="$t('customer.placeholders.last_name')"-->
    <!--            />-->
    <!--          </input-wrapper>-->
    <!--        </div>-->

    <!--        <input-wrapper-->
    <!--          class="text-white2"-->
    <!--          field="giftee_email"-->
    <!--          :form="form"-->
    <!--          :is-required="true"-->
    <!--          :label-text="$t('gift_voucher.fields.giftee_email')"-->
    <!--          :dark-theme="true"-->
    <!--        >-->
    <!--          <div class="flex flex-col">-->
    <!--            <input-->
    <!--              class="bg-brand bg-opacity-10 placeholder-white placeholder-opacity-50"-->
    <!--              type="text"-->
    <!--              v-model="form.giftee_email"-->
    <!--              :placeholder="$t('gift_voucher.placeholders.email')"-->
    <!--            />-->
    <!--          </div>-->
    <!--        </input-wrapper>-->

    <!--        <input-wrapper-->
    <!--          class="text-white2"-->
    <!--          field="giftee_phone"-->
    <!--          :form="form"-->
    <!--          :is-required="true"-->
    <!--          :label-text="$t('gift_voucher.fields.giftee_phone')"-->
    <!--          :dark-theme="true"-->
    <!--        >-->
    <!--          <div class="flex flex-col">-->
    <!--            <phone-number v-model="form.giftee_phone" :placeholder="$t('gift_voucher.placeholders.phone')" dark-mode />-->
    <!--            <span class="my-xsSpace text-white2 opacity-50">{{ $t("booking.fields.phone_notification") }}</span>-->
    <!--          </div>-->
    <!--        </input-wrapper>-->

    <!--        <input-wrapper-->
    <!--          class="text-white2"-->
    <!--          field="message"-->
    <!--          :form="form"-->
    <!--          :label-text="$t('gift_voucher.fields.message')"-->
    <!--          :dark-theme="true"-->
    <!--        >-->
    <!--          <div class="flex flex-col">-->
    <!--            <textarea-->
    <!--              class="bg-brand bg-opacity-10 placeholder-white placeholder-opacity-50"-->
    <!--              type="text"-->
    <!--              v-model="form.message"-->
    <!--              :placeholder="$t('gift_voucher.placeholders.message')"-->
    <!--            />-->
    <!--          </div>-->
    <!--        </input-wrapper>-->

    <!--        <input-wrapper-->
    <!--          class="text-white2"-->
    <!--          field="sent_from"-->
    <!--          :form="form"-->
    <!--          :label-text="$t('gift_voucher.fields.sent_from')"-->
    <!--          :dark-theme="true"-->
    <!--        >-->
    <!--          <div class="flex flex-col">-->
    <!--            <input-->
    <!--              class="bg-brand bg-opacity-10 placeholder-white placeholder-opacity-50"-->
    <!--              type="text"-->
    <!--              v-model="form.sent_from"-->
    <!--              :placeholder="$t('gift_voucher.placeholders.sent_from')"-->
    <!--            />-->
    <!--          </div>-->
    <!--        </input-wrapper>-->
    <!--      </div>-->
    <!--    </div>-->
    <p class="rounded-md bg-white bg-opacity-10 p-mdSpace text-white2">
      {{ $t("gift_voucher.headings.checkout_intro") }}
    </p>
    <div class="flex justify-center">
      <button class="btn-secondary !block w-full items-center !bg-white !text-brand" @click="continueBooking">
        {{ $t("generic.buttons.next") }}: {{ $t("generic.words.payments") }}
      </button>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import ContactDetail from "../../bookings/checkout/ContactDetail.vue";

export default {
  components: { ContactDetail },
  props: {
    cart: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customerId: {
      type: String,
    },
  },
  data() {
    return {
      form: new Form(
        {
          email: this.cart.hasOwnProperty("email") ? this.cart.email : null,
          phone: this.cart.phone ?? null,
          first_name: this.cart.first_name ?? null,
          last_name: this.cart.last_name ?? null,
          send_to_someone_else: false,
          // giftee_email: this.cart.giftee_email ?? null,
          // giftee_phone: this.cart.giftee_phone ?? null,
          // giftee_first_name: this.cart.giftee_first_name ?? null,
          // giftee_last_name: this.cart.giftee_last_name ?? null,
          // message: this.cart.message ?? null,
          // sent_from: this.cart.sent_from ?? null,
        },
        { resetOnSuccess: false },
      ),
    };
  },
  computed: {
    contact: {
      get() {
        return {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email,
          phone: this.form.phone,
        };
      },
      set(value) {
        this.form.populate(value);
      },
    },
  },
  methods: {
    continueBooking() {
      this.form.post("/api/voucher/cart?step=detail").then(() => {
        window.location.href = `/voucher/payment`;
      });
    },
  },
};
</script>
<style scoped>
input::placeholder,
textarea::placeholder {
  @apply !text-white2 !opacity-50;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}
</style>
