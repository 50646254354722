<template>
  <td>
    <div v-if="products.length" class="h-full w-full">
      <template v-if="products.length === 1">
        <p class="whitespace-wrap text-sm">{{ products[0] }}</p>
      </template>
      <template v-else>
        <collapsible>
          <div class="flex justify-between">
            <collapsible-trigger>
              <template v-slot="{ collapsed }">
                <p class="text-sm">{{ products[0] }}</p>
                <transition>
                  <v-icon
                    icon="ChevronDownIcon"
                    class="ml-auto h-4 w-4 transform cursor-pointer text-gray-400 transition-transform duration-300 ease-in-out hover:text-gray-800"
                    :class="collapsed ? 'rotate-180' : ''"
                  />
                </transition>
              </template>
            </collapsible-trigger>
          </div>
          <collapsible-content>
            <p v-for="(product, index) in Array.from(products.slice(1))" :key="index" class="text-sm">
              {{ product }}
            </p>
          </collapsible-content>
        </collapsible>
      </template>
    </div>
  </td>
</template>

<script>
import Collapsible from "@/js/vue/components/common/collapsible/Collapsible.vue";
import CollapsibleTrigger from "@/js/vue/components/common/collapsible/CollapsibleTrigger.vue";
import CollapsibleContent from "@/js/vue/components/common/collapsible/CollapsibleContent.vue";

export default {
  name: "VCellCollapsibleProduct",
  components: { CollapsibleContent, CollapsibleTrigger, Collapsible },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
    rowField: {
      type: [String, Object],
    },
  },
  computed: {
    products() {
      return this.rowData[this.rowField.field];
    },
  },
};
</script>
