<template>
  <form class="flex w-full flex-col justify-between px-lgSpace py-smSpace text-sm text-neutral-700">
    <div class="grow space-y-mdSpace">
      <h3 class="text-sm text-neutral-900">{{ $t("booking.headings.booking_tobe_canceled") }}</h3>
      <span class="text-xs text-neutral-600">
        {{
          $t(
            isAdminOrManager
              ? "booking.headings.booking_cancel_message"
              : "booking.headings.booking_cancel_message_with_refund_only",
          )
        }}
      </span>
      <div class="flex flex-col gap-xsSpace border-b pb-lgSpace">
        <booking-summary
          v-for="booking in cancelBookings"
          :class="{ 'opacity-60': booking.deleted_at }"
          :booking="booking"
          :with-addon="false"
          :current-user="currentUser"
        >
        </booking-summary>
      </div>
      <div class="font-semibold">{{ $t("booking.headings.cancellation_options") }}</div>
      <template v-if="cancelStep === 'select_code'">
        <template v-if="Object.keys(options).length">
          <div
            v-if="options[this.config.OPTION_REFUNDED_ORIGINAL]"
            class="mb-smSpace flex cursor-pointer flex-row justify-between rounded bg-slate-100 px-mdSpace py-smSpace leading-10 hover:bg-slate-200"
            @click="onSelectOption(config.OPTION_REFUNDED_ORIGINAL)"
            :class="{ '!cursor-not-allowed hover:bg-slate-100': !booking.payment_details.is_refundable }"
            @mouseover="showToolTip($event, 'refunded-original')"
            @mouseout="hideToolTip($event, 'refunded-original')"
          >
            <span> {{ $t("booking.buttons.refund_to_original") }}</span>
            <v-icon icon="ChevronRightIcon" class="my-auto h-5 w-5 text-slate-400"></v-icon>
            <div
              id="refunded-original"
              class="-right-50 absolute hidden w-[400px] -translate-x-1.5 -translate-y-full items-center justify-center rounded-md bg-gray-800 p-smSpace text-xs text-white"
            >
              {{ $t("booking.headings.cannot_refund") }}
            </div>
          </div>
          <div
            v-else-if="options[this.config.OPTION_PROVIDE_VOUCHER]"
            class="mb-smSpace flex cursor-pointer flex-row justify-between rounded bg-slate-100 px-mdSpace py-smSpace leading-10 hover:bg-slate-200"
            @click="onSelectOption(config.OPTION_PROVIDE_VOUCHER)"
            :class="{ '!cursor-not-allowed hover:bg-slate-100': !booking.payment_details.is_refundable }"
            @mouseover="showToolTip($event, 'provide-voucher')"
            @mouseout="hideToolTip($event, 'provide-voucher')"
          >
            <span> {{ $t("booking.buttons.provide_voucher") }}</span>
            <v-icon icon="ChevronRightIcon" class="my-auto h-5 w-5 text-slate-400"></v-icon>
            <div
              id="provide-voucher"
              class="-right-50 absolute hidden w-[400px] -translate-x-1.5 -translate-y-full items-center justify-center rounded-md bg-gray-800 p-smSpace text-xs text-white"
            >
              {{ $t("booking.headings.cannot_refund") }}
            </div>
          </div>
          <div
            v-else-if="options[this.config.OPTION_REFUND]"
            class="mb-smSpace flex cursor-pointer flex-row justify-between rounded bg-slate-100 px-mdSpace py-smSpace leading-10 hover:bg-slate-200"
            @click="onSelectOption(config.OPTION_REFUND)"
            :class="{ '!cursor-not-allowed hover:bg-slate-100': !booking.payment_details.is_refundable }"
            @mouseover="showToolTip($event, 'refund')"
            @mouseout="hideToolTip($event, 'refund')"
          >
            <span> {{ $t("booking.buttons.refund_to_square") }}</span>
            <v-icon icon="ChevronRightIcon" class="my-auto h-5 w-5 text-slate-400"></v-icon>
            <div
              id="refund"
              class="-right-50 absolute hidden w-[400px] -translate-x-1.5 -translate-y-full items-center justify-center rounded-md bg-gray-800 p-smSpace text-xs text-white"
            >
              {{ $t("booking.headings.cannot_refund") }}
            </div>
          </div>
        </template>
        <div
          v-if="options[this.config.OPTION_REFUNDED_CASH]"
          class="mb-smSpace flex cursor-pointer flex-row justify-between rounded bg-slate-100 px-mdSpace py-smSpace leading-10 hover:bg-slate-200"
          @click="onSelectOption(config.OPTION_REFUNDED_CASH)"
        >
          <span> {{ $t("booking.buttons.refund_to_cash") }}</span>
          <v-icon icon="ChevronRightIcon" class="my-auto h-5 w-5 text-slate-400"></v-icon>
        </div>
        <div
          v-if="options[this.config.OPTION_NO_ACTION_REQUIRED]"
          class="mb-smSpace flex cursor-pointer flex-row justify-between rounded bg-slate-100 px-mdSpace py-smSpace leading-10 hover:bg-slate-200"
          @click="onSelectOption(config.OPTION_NO_ACTION_REQUIRED)"
        >
          <span class="text-lowercase first-letter:uppercase">{{
            $t(
              booking.payment_details.total_paid === 0
                ? "booking.buttons.cancel_booking"
                : "booking.buttons.no_action_required",
            )
          }}</span>
          <v-icon icon="ChevronRightIcon" class="my-auto h-5 w-5 text-slate-400"></v-icon>
        </div>
      </template>
      <template v-if="cancelStep === 'confirm'">
        <div
          class="mb-smSpace flex cursor-pointer flex-row justify-between rounded bg-slate-100 px-mdSpace py-smSpace leading-10"
          @click="cancelStep = 'select_code'"
        >
          <span v-text="getTitle"> </span>
          <v-icon icon="CheckIcon" class="my-auto h-5 w-5 text-slate-400"></v-icon>
        </div>
        <template v-if="form.option === config.OPTION_REFUND">
          <refund-input
            ref="refundInput"
            v-model="form"
            :initial-method="config.PAYMENT_CARD"
            :max-amount="booking.payment_details.square_refundable_amount"
            :booking="booking"
            @update:refund-start="loading = true"
          ></refund-input>
        </template>
        <template v-if="form.option === config.OPTION_PROVIDE_VOUCHER">
          <div
            class="mb-smSpace flex cursor-pointer flex-row justify-between rounded bg-slate-100 px-mdSpace py-smSpace leading-5"
          >
            <span>{{ $t("booking.headings.refund_gift_voucher") }}</span>
          </div>
        </template>
        <template v-if="form.option === config.OPTION_REFUNDED_CASH">
          <refund-input
            ref="refundInput"
            v-model="form"
            :initial-method="config.PAYMENT_CASH"
            :max-amount="booking.payment_details.total_paid"
            :booking="booking"
            @update:refund-start="loading = true"
          ></refund-input>
        </template>
        <template v-if="form.option === config.OPTION_REFUNDED_ORIGINAL">
          <refund-input
            ref="refundInput"
            v-model="form"
            :initial-method="config.PAYMENT_CARD"
            :max-amount="booking.payment_details.square_refundable_amount"
            :booking="booking"
            @update:refund-start="loading = true"
          ></refund-input>
        </template>
        <div class="rounded bg-slate-100 text-sm leading-10">
          <v-checkbox
            class="flex items-center space-x-xsSpace p-mdSpace"
            v-model="form.is_confirmed"
            :label-text="$t('booking.headings.send_confirmation_email')"
          />
          <div v-if="form.is_confirmed" class="p-mdSpace pt-0">
            <input-wrapper field="message" :form="form">
              <ckeditor :editor="classicEditor" v-model="form.message" name="message"></ckeditor>
            </input-wrapper>
          </div>
        </div>
      </template>

      <v-notification v-if="message" type="success" :content="message" />
    </div>
    <div
      class="flex items-end justify-end rounded p-lgSpace sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
    >
      <div class="flex space-x-xsSpace">
        <a @click="back" class="btn-secondary btn-sm !rounded-lg" v-text="$t('generic.buttons.back')"></a>
        <a v-if="cancelStep === 'confirm'" @click.prevent="save" class="btn-primary btn-sm !rounded-lg">
          <span v-if="!loading">
            {{ $t("generic.buttons.confirm") }}
          </span>
          <p v-else class="flex items-center gap-3xsSpace">
            <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
            <span>{{ $t("generic.words.processing") }}</span>
          </p>
        </a>
      </div>
    </div>
  </form>
</template>
<script>
import Form from "form-backend-validation";
import axios from "axios";
import Payment from "~vue/mixins/Payment";
import BookingSummary from "@/js/vue/components/bookings/details/BookingSummary.vue";

export default {
  inject: ["bus", "config"],
  components: { BookingSummary },
  mixins: [Payment],
  props: {
    booking: {
      type: Object,
      required: true,
    },
    cancelBookings: {
      type: Array,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
    inPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      message: null,
      mailContent: null,
      cancelStep: "select_code",
      tooltipTimeout: null,
      refundPaymentMethods: [],
      options: {},
      form: new Form(
        {
          booking_ids: this.cancelBookings.map((booking) => booking.id),
          cancel: true,
          option: null,
          is_confirmed: true,
          message: null,
          refund_method: null,
          refund_amount: 0,
          token: null,
        },
        { resetOnSuccess: false },
      ),
    };
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        if (!newValue.is_confirmed) {
          this.form.message = null;
          return;
        }

        if (this.form.message) {
          return;
        }

        this.form.message = this.mailContent;
      },
      deep: true,
    },
  },
  computed: {
    isAdminOrManager() {
      return (
        this.currentUser &&
        (this.currentUser.roles || []).some((role) =>
          [this.config.roles.admin.toLowerCase(), this.config.roles.manager.toLowerCase()].includes(role.name),
        )
      );
    },
    getTitle() {
      const optionMapping = {
        [this.config.OPTION_REFUND]: "refund_to_square",
        [this.config.OPTION_PROVIDE_VOUCHER]: "provide_voucher",
        [this.config.OPTION_REFUNDED_ORIGINAL]: "refund_to_original",
        [this.config.OPTION_REFUNDED_CASH]: "refund_to_cash",
      };

      let t = optionMapping[this.form.option] || "no_action_required";
      if (this.form.option === this.config.OPTION_NO_ACTION_REQUIRED && this.booking.payment_details.total_paid === 0) {
        t = "cancel_booking";
      }

      return this.$t("booking.buttons." + t);
    },
  },
  mounted() {
    this.bus.$on("refundEnd", this.onRefundEnd);
  },
  created() {
    this.initEmailContent();
    this.fetchOptions();
  },
  methods: {
    fetchOptions() {
      axios
        .get(`/api/admin/bookings/${this.booking.id}/refund-options`, {
          params: {
            booking_ids: this.cancelBookings.map((booking) => booking.id),
          },
        })
        .then(({ data }) => {
          this.options = data.options;
        });
    },
    showToolTip($event, id) {
      if (this.booking.payment_details.is_refundable) {
        return;
      }

      const el = document.getElementById(id);
      let reason = `${this.$t("booking.responses.cannot_refund")}<br>`;
      Object.keys(this.booking.payment_details.refundable_reasons).forEach((r) => {
        if (this.booking.payment_details.refundable_reasons[r]) {
          reason = reason + this.$t(`booking.responses.${r}`) + "<br>";
        }
      });

      this.tooltipTimeout = setTimeout(() => {
        el.style = "display: inline;";
        el.innerHTML = reason;

        setTimeout(() => {
          el.style = "display: hidden;";
          clearTimeout(this.tooltipTimeout);
        }, 2000);
      }, 100);
    },
    hideToolTip($event, id) {
      const el = document.getElementById(id);
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }

      el.style = "display: hidden;";
    },

    initEmailContent() {
      axios
        .get("/api/admin/notifications/show", {
          params: {
            type: "booking_cancellation",
            product_id: this.booking.appointment.product_id,
            product_category_id: this.booking.product_category_id,
          },
        })
        .then(({ data }) => {
          if (data.data.hasOwnProperty("content")) {
            this.mailContent = data.data.content;
          }
        });
    },
    close() {
      if (this.inPanel) {
        this.bus.$emit("closePanelInPanel", true);
        return;
      }
      this.bus.$emit("closePanel");
    },
    back() {
      if (this.cancelStep === "confirm") {
        this.cancelStep = "select_code";

        return;
      }
      this.close();
    },
    onSelectOption(option) {
      if (!this.booking.payment_details.is_refundable && option !== this.config.OPTION_NO_ACTION_REQUIRED) {
        return;
      }

      this.cancelStep = "confirm";
      this.form.option = option;
      if (option === this.config.OPTION_PROVIDE_VOUCHER) {
        this.form.refund_method = this.config.PAYMENT_VOUCHER;
      } else {
        this.form.refund_method = null;
      }

      const refundableAmounts = {
        [this.config.OPTION_REFUND]: this.booking.payment_details.square_refundable_amount,
        [this.config.OPTION_REFUNDED_ORIGINAL]: this.booking.payment_details.square_refundable_amount,
        [this.config.OPTION_REFUNDED_CASH]: this.booking.payment_details.total_paid,
      };
      if (refundableAmounts.hasOwnProperty(option)) {
        this.form.refund_amount = refundableAmounts[option];
      }
    },
    async save() {
      if (this.form.option === this.config.OPTION_REFUND || this.form.option === this.config.OPTION_REFUNDED_ORIGINAL) {
        return this.$refs.refundInput.refund();
      }

      this.loading = true;
      try {
        const data = await this.form.post(`/api/admin/bookings/${this.booking.appointment.booking_id}/refund`);
        this.onRefundEnd(data);
      } catch {
        this.loading = false;
      }
    },
    onRefundEnd(data) {
      this.loading = false;
      this.message = data.statusText;
      setTimeout(() => {
        this.bus.$emit("closePanel");
        this.bus.$emit("tableFilterChange", { params: {} });
      }, 1500);
    },
  },
};
</script>
