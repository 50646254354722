<template>
  <draggable
    v-model="innerCategories"
    :group="`category-in-${parent}`"
    item-key="id"
    handle=".category-dragging-cursor"
    class="space-y-xsSpace"
    @change="handleCategoryChange"
  >
    <template #item="{ element: category, index }">
      <div :key="category.id">
        <collapsible :context="`category_${category.id}`">
          <collapsible-trigger :context="`category_${category.id}`" :disabled="collapseDisabled">
            <template v-slot="{ collapsed }">
              <div class="flex flex-grow cursor-pointer flex-row items-center rounded bg-tertiary-100 px-3 py-smSpace">
                <div class="flex flex-grow items-center gap-x-2 text-left">
                  <div class="category-dragging-cursor text-gray-500 hover:text-gray-800">
                    <v-icon icon="ArrowsUpDownIcon" class="cursor-move"></v-icon>
                  </div>
                  <v-table-title
                    :row-data="category"
                    row-field="name"
                    :row-index="index"
                    editable
                    @update:model-value="updateCategoryName($event, category.id)"
                    @editing="collapseDisabled = $event"
                  >
                    <span class="text-sm text-gray-500">({{ category.products.length }})</span>
                  </v-table-title>
                  <v-table-actions align="bottom" :data="{ rowData: category, rowIndex: index }"></v-table-actions>
                </div>
                <transition type="transition">
                  <v-icon
                    icon="ChevronDownIcon"
                    class="h-6 w-6 transform cursor-pointer text-gray-400 transition-transform duration-300 ease-in-out hover:text-gray-800"
                    :class="collapsed ? 'rotate-180' : ''"
                  ></v-icon>
                </transition>
              </div>
            </template>
          </collapsible-trigger>
          <collapsible-content :context="`category_${category.id}`" class="pl-lgSpace">
            <product-list
              :category-id="category.id"
              :products="category.products"
              :fields="productFields"
              @onMove="handleProductMove"
              @onReorder="handleProductReorder"
            >
            </product-list>
          </collapsible-content>
        </collapsible>
      </div>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import ProductList from "./ProductList.vue";
import VTableActions from "@/js/vue/components/common/table/VTableActions.vue";
import { isNumber } from "lodash";
import EditableNameElement from "~vue/mixins/EditableNameElement";

export default {
  name: "CategoryListItem",
  mixins: [EditableNameElement],
  inject: ["notificationService", "config"],
  components: { draggable, ProductList, VTableActions },
  props: {
    parent: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    productFields: {
      type: Array,
      required: true,
    },
  },
  watch: {
    productMove: {
      handler: function (newValue, _oldValue) {
        const { id, toCategory, newIndex } = newValue;
        if (id !== null && toCategory !== null && newIndex !== null) {
          this.attachOrDetachThenReorderProducts({
            has_attach_or_detach: true,
            id,
            product_category_id: toCategory,
            new_index: newIndex,
          });
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      innerCategories: this.categories,
      productMove: {
        id: null,
        toCategory: null,
        newIndex: null,
      },
      collapseDisabled: false,
    };
  },
  methods: {
    handleCategoryChange(changes) {
      if (changes.moved) {
        this.reorderCategories({
          has_attach_or_detach: false,
          parent_id: changes.moved.element.parent_id,
          ids: this.innerCategories.map((category) => category.id),
        });
      }
    },

    handleProductMove(categoryId, productId, newIndex) {
      const isAttach = isNumber(newIndex);
      if (this.productMove.id === null || this.productMove.id === productId) {
        this.productMove = {
          id: productId,
          toCategory: isAttach ? categoryId : this.productMove.toCategory,
          newIndex: isAttach ? newIndex : this.productMove.newIndex,
        };
      }
    },
    handleProductReorder(categoryId, newOrderIds) {
      this.attachOrDetachThenReorderProducts({
        has_attach_or_detach: false,
        product_category_id: categoryId,
        ids: newOrderIds,
      });
    },

    reorderCategories(data) {
      axios.post("/api/admin/categories/reorder", data).then((response) => {
        this.notificationService.notify(response.data.statusText, "success");
      });
    },
    attachOrDetachThenReorderProducts(data) {
      axios.post("/api/admin/products/reorder", data).then((response) => {
        this.notificationService.notify(response.data.statusText, "success");
        this.productMove = {
          id: null,
          toCategory: null,
          newIndex: null,
        };
      });
    },

    updateCategoryName(newName, id) {
      if (!id) return;

      this.nameForm
        .populate({ update_data: { name: newName }, model_id: id, model_class: this.config.modelClasses.category })
        .put("/api/admin/generic/update")
        .then(() => {
          this.innerCategories = [
            ...this.innerCategories.map((cat) => {
              if (cat.id === id) {
                cat.name = newName;
              }

              return cat;
            }),
          ];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
