const state = () => ({
  room: null,
  appointment: null,
  currentDate: null,
  currentUser: null,
});

const getters = {};

const actions = {
  setRoom({ commit }, value) {
    commit("setRoom", value);
  },
  setAppointment({ commit }, value) {
    commit("setAppointment", value);
  },
  setCurrentDate({ commit }, value) {
    commit("setCurrentDate", value);
  },
  setCurrentUser({ commit }, value) {
    commit("setCurrentUser", value);
  },
};

const mutations = {
  setRoom(state, value) {
    state.room = value;
  },
  setAppointment(state, value) {
    state.appointment = value;
  },
  setCurrentDate(state, value) {
    state.currentDate = value;
  },
  setCurrentUser(state, value) {
    state.currentUser = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
