<template>
  <a class="rounded text-xs font-semibold hover:bg-tertiary-50" @click="showDropDown" @mouseleave="hideDropdown">
    <v-icon v-if="!status" icon="UserIcon" :classes="classes" />
    <span v-else :style="`background:${colour}`" class="rounded px-3xsSpace text-white">
      {{ status.charAt(0) }}
    </span>
    <ul
      class="absolute right-0 top-4 z-40 space-y-3xsSpace rounded bg-tertiary-100 px-2xsSpace py-2xsSpace text-xs"
      v-if="show"
    >
      <li
        v-for="s in arrivalStatuses"
        :key="s.name"
        class="cursor-pointer rounded px-xsSpace text-white opacity-60 hover:opacity-100"
        :class="{ '!opacity-100': s.name === status }"
        :style="`background:${s.colour}`"
        @click="select(s)"
      >
        {{ word(s.name) }}
      </li>
    </ul>
  </a>
</template>

<script>
export default {
  inject: ["bus", "notificationService"],
  props: {
    currentStatus: {
      type: String,
      default: "",
    },
    appointment: {
      type: Object,
      default: "",
    },
    /**
     * Reason of not using capacity_index in appointment because in collapsed screen,
     * appointment's capacity_index won't be correct
     */
    capacityIndex: {
      type: Number,
    },
    zoom: {
      type: Number,
    },
    arrivalStatuses: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      show: false,
      status: this.currentStatus,
    };
  },
  computed: {
    classes() {
      if (this.zoom === 0.4) {
        return "w-3.5 h-3.5";
      }

      if (this.zoom === 0.6) {
        return "w-4 h-4";
      }

      return "h-5 w-5";
    },
    colour() {
      if (!this.status) {
        return null;
      }

      return this.arrivalStatuses.find((o) => o.name === this.status).colour;
    },
  },
  methods: {
    showDropDown() {
      this.show = true;
    },
    hideDropdown() {
      if (this.show) {
        setTimeout(() => {
          this.show = false;
        }, 100);
      }
    },
    select(option) {
      axios
        .post(`/api/admin/bookings/${this.appointment.booking_id}/toggle-client-arrival-status`, {
          status: option.name,
        })
        .then(({ data }) => {
          this.notificationService.notify(data.statusText);
          this.status = option.name !== this.status ? option.name : null;
          this.show = false;
        })
        .finally(() => {
          this.hideDropdown();
        });
    },
  },
};
</script>
