<template>
  <div>
    <p class="space-x-reverse py-mdSpace text-lg font-semibold text-white">
      {{ $t("booking.headings.booking_summary") }}
    </p>
    <div class="mb-lgSpace space-y-xsSpace">
      <div class="rounded-lg bg-white p-mdSpace">
        <p class="text-lg font-semibold text-orange-900">
          {{ booking.title }}
        </p>
        <p class="text-lg font-light text-orange-900">
          {{ booking.date }} - {{ booking.start_at }} - {{ booking.stop_at }}
        </p>
      </div>
      <div class="rounded-lg bg-white p-mdSpace text-orange-900">
        <div class="flex justify-between text-lg font-semibold">
          <span>{{ $t("booking.columns.client") }}:</span>
          <span class="font-normal">{{ booking.client }}</span>
        </div>
        <div class="flex justify-between text-lg font-semibold">
          <span>{{ $t("booking.columns.phone") }}:</span>
          <span class="font-normal">{{ booking.phone }}</span>
        </div>
        <div class="flex justify-between text-lg font-semibold">
          <span>{{ $t("booking.columns.email") }}:</span>
          <span class="font-normal">{{ booking.email }}</span>
        </div>
      </div>
      <div class="border-b pb-xlSpace text-white">
        <div class="mt-lgSpace flex justify-between font-semibold">
          <span class="text-lg">{{ $t("booking.labels.total") }}</span>
          <span class="text-2xl font-normal">{{ toAUD(booking.payment_details.total) }}</span>
        </div>
        <p class="flex flex-row-reverse text-sm">
          {{ $t("booking.labels.gst") }}
          {{ toAUD(booking.payment_details.total - booking.payment_details.total / 1.1) }}
        </p>
        <div class="mt-lgSpace flex justify-between font-semibold">
          <span class="text-lg">{{ $t("booking.labels.total_paid") }}</span>
          <span class="text-2xl font-normal">{{ toAUD(booking.payment_details.total_paid) }}</span>
        </div>
        <div class="mt-lgSpace flex justify-between font-semibold">
          <span class="text-lg">{{ $t("booking.labels.balance_outstanding") }}</span>
          <span class="text-2xl font-normal">{{ toAUD(remaining) }}</span>
        </div>
      </div>
      <div v-if="message" class="p-xlSpace text-center text-xl text-white">
        <p>{{ message }}</p>
      </div>
      <div
        class="!mt-mdSpace space-y-mdSpace pb-12 font-semibold text-white"
        v-else-if="booking.payment_details.balance > 0"
      >
        <voucher-select v-if="payable" v-model="vouchers" v-model:info="info"></voucher-select>
        <div v-if="remaining > 0">
          <span class="text-sm font-semibold">{{ $t("generic.words.card") }}</span>
          <square-payment ref="square" />
        </div>
        <div class="mb-2xlSpace flex justify-center pt-mdSpace">
          <button
            class="btn-secondary !block items-center !text-white hover:!text-orange-900"
            @click="submit"
            :disabled="loading"
          >
            <div
              v-if="loading"
              aria-label="Loading..."
              role="status"
              class="flex items-center justify-center space-x-2 text-white"
            >
              <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
              <span class="text-lg font-medium text-tertiary-500 text-white">
                {{ $t("generic.words.processing") }}
              </span>
            </div>
            <span v-else>{{ buttonTitle }}</span>
          </button>
        </div>
      </div>
      <div v-else class="p-xlSpace text-center text-xl text-white">
        <p>{{ $t("booking.responses.paid_complete") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import { sumBy } from "lodash";

export default {
  inject: ["notificationService", "config"],
  props: {
    booking: {
      required: true,
    },
    payable: {
      type: Boolean,
      default: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
  },
  computed: {
    buttonTitle() {
      if (this.payable) {
        return this.$t("generic.buttons.confirm_payment");
      }

      return this.$t("booking.buttons.save_card_link");
    },
    cardPayment() {
      return this.form.payments.find((o) => o.type === this.config.PAYMENT_CARD);
    },
    remaining() {
      const remaining = Math.max(0, this.booking.payment_details.balance - sumBy(this.info, "balance"));
      if (remaining <= 0) {
        this.form.payments = this.form.payments.filter((o) => o.type === this.config.PAYMENT_VOUCHER);
        return remaining;
      }

      if (this.cardPayment) {
        this.cardPayment.amount = remaining;
        return remaining;
      }

      this.form.payments.push({
        type: this.config.PAYMENT_CARD,
        amount: remaining,
        token: null,
      });

      return remaining;
    },
  },
  data() {
    return {
      loading: false,
      message: null,
      vouchers: [],
      info: [],
      form: new Form(
        {
          payments: [
            {
              type: this.config.PAYMENT_CARD,
              amount: this.booking.payment_details.balance,
              token: null,
            },
          ],
        },
        { resetOnSuccess: false },
      ),
    };
  },
  watch: {
    vouchers: {
      handler(newValue, oldValue) {
        this.form.payments = this.form.payments.filter((o) => o.type !== this.config.PAYMENT_VOUCHER);
        newValue.forEach((voucher) => {
          this.form.payments.push({
            type: this.config.PAYMENT_VOUCHER,
            voucher: voucher,
          });
        });
      },
      deep: true,
    },
  },
  methods: {
    async submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        if (this.cardPayment) {
          this.cardPayment.token = await this.$refs.square.submit();
          if (!this.cardPayment.token) {
            this.loading = false;
            return;
          }
        }

        this.form.payments = this.form.payments.filter(
          (o) => (o.hasOwnProperty("voucher") && o.voucher) || (o.hasOwnProperty("token") && o.token),
        );
        const response = await this.form.post(this.endpoint);
        this.message = response.statusText;
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (e) {
        this.notificationService.notify(e.response.data.message || e.message, "error");
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
input::placeholder {
  @apply !text-white opacity-60;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}
</style>
