<template>
  <div>
    <div
      :class="{ 'bg-white': !isDarkTheme, 'bg-brand': isDarkTheme, 'sm:flex-row sm:shadow-xl': horizonMode }"
      class="flex flex-col rounded"
      v-if="!loading"
    >
      <div
        :class="{ 'sm:w-2/3 sm:border-none': horizonMode }"
        class="rounded rounded-b-none border border-white p-smSpace"
      >
        <div class="flex items-center justify-between">
          <a
            v-if="availability.days.length && !isOutOfRanges(`${availability.year}-${availability.month_number}-01`)"
            class="flex cursor-pointer items-center text-gray-400 hover:text-gray-500"
            :class="{ 'text-white': isDarkTheme }"
            @click="previousMonth()"
          >
            <v-icon icon="ChevronLeftIcon" />
          </a>

          <p
            class="mx-auto text-sm font-semibold"
            :class="{ 'text-gray-900': !isDarkTheme, '!text-base text-white': isDarkTheme }"
          >
            {{ availability.month }} {{ availability.year }}
          </p>
          <a
            v-if="availability.days.length && !isOutOfRanges(availability.days[availability.days.length - 1]?.date)"
            class="flex flex-none cursor-pointer items-center p-1.5 text-gray-400 hover:text-gray-500"
            :class="{ 'text-white': isDarkTheme }"
            @click="nextMonth()"
          >
            <v-icon icon="ChevronRightIcon" />
          </a>
        </div>
        <div
          class="mt-lgSpace grid grid-cols-7 text-center text-xs leading-6"
          :class="{ 'text-gray-500': !isDarkTheme, '!text-base text-white': isDarkTheme }"
        >
          <p>Mo</p>
          <p>Tu</p>
          <p>We</p>
          <p>Th</p>
          <p>Fr</p>
          <p>Sa</p>
          <p>Su</p>
        </div>
        <div class="mt-xsSpace grid grid-cols-7 text-sm">
          <div
            v-for="(day, dayIdx) in availability.days"
            :key="day.date"
            :class="[dayIdx > 6 && 'sm:border-t sm:border-gray-200', 'py-2']"
          >
            <button
              type="button"
              class="mx-auto flex h-10 w-10 items-center justify-center rounded-full text-sm hover:bg-gray-200"
              :class="[getClasses(day), horizonMode ? 'sm:h-8 sm:w-8' : '']"
              @click="selectDate(day)"
            >
              {{ day.date.split("-").pop().replace(/^0/, "") }}
            </button>
          </div>
        </div>
      </div>
      <div :class="{ 'sm:w-1/3': horizonMode }">
        <calendar-availability
          v-show="!slotLoading && selectedDate"
          :label="bookingLabel"
          :modelValue="appointmentIds"
          @update:modelValue="(value) => setAppointmentIds(value)"
          :available-slots="availableSlots"
          :is-dark-theme="isDarkTheme"
          :horizon-mode="horizonMode"
        />
        <div
          v-if="slotLoading"
          aria-label="Loading..."
          role="status"
          class="my-smSpace flex h-full items-center justify-center space-x-2"
          :class="{ 'text-white': isDarkTheme }"
        >
          <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
          <span class="text-lg font-medium text-tertiary-500" :class="{ 'text-white': isDarkTheme }">
            Loading . . .
          </span>
        </div>
      </div>
    </div>
    <p class="-mb-smSpace flex justify-between pt-xsSpace" v-if="!loading && selectedDate && !isDarkTheme">
      <span>{{ $t("booking.headings.selected_day_time") }}</span>
      <span class="font-semibold">
        {{ formatDate }} <span v-if="selectedAppointment"> at {{ selectedAppointment.start }}</span>
      </span>
    </p>
    <div
      v-if="loading"
      aria-label="Loading..."
      role="status"
      class="my-smSpace flex h-[500px] items-center justify-center space-x-2"
      :class="{ 'text-white': isDarkTheme }"
    >
      <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
      <span class="text-lg font-medium">Loading . . .</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment/moment";
import CalendarAvailability from "./CalendarAvailability.vue";

export default {
  inject: ["bus", "notificationService"],
  components: { CalendarAvailability },
  props: {
    venueId: {
      type: Number,
      require: null,
    },
    appointmentIds: {
      type: Array,
      require: [],
    },
    productId: {
      type: Number,
      default: null,
    },
    addOnProductId: {
      type: Number,
      default: null,
    },
    selectedDate: {
      type: String,
      default: null,
    },
    quantity: {
      type: Number,
      default: null,
    },
    orderId: {
      type: Number,
      default: null,
    },
    isDarkTheme: {
      type: Boolean,
      default: false,
    },
    horizonMode: {
      type: Boolean,
      default: true,
    },
    bookingLabel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      availability: {
        month: null,
        year: null,
        month_number: null,
        days: [],
      },
      slotLoading: false,
      loading: false,
      availableSlots: {},
    };
  },
  computed: {
    selectedAppointment() {
      if (this.appointmentIds) {
        return Object.values(this.availableSlots)
          .flat()
          .find((o) => o.id === this.appointmentIds[0]);
      }
    },
    formatDate() {
      return moment(this.selectedDate).format("DD MMMM YYYY");
    },
  },
  watch: {
    quantity: {
      handler(newValue, oldValue) {
        if (newValue > 0) {
          this.getCalendar(null, null, this.populateDate);
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.selectedDate) {
      const time = this.selectedDate.split("-");
      return this.getCalendar(time[1], time[0], this.populateDate);
    }
    this.getCalendar(null, null, this.populateDate);
  },
  methods: {
    getClasses(day) {
      if (day.is_hidden || this.isOutOfRanges(day.date)) {
        return ["text-gray-400 hover:bg-gray-200 !cursor-not-allowed text-xs"];
      }

      const classes = [
        day.is_disabled && "text-gray-400 !cursor-not-allowed",
        !day.is_disabled && day.is_available && day.date !== this.selectedDate && "bg-green-200 font-semibold",
        !day.is_disabled && !day.is_available && "bg-red-200 font-semibold !cursor-not-allowed",
        day.date === this.selectedDate && "bg-green-800",
      ];

      if (this.isDarkTheme) {
        return classes.concat([
          !day.is_disabled && day.is_available && day.date !== this.selectedDate && "text-brand",
          day.date === this.selectedDate && "text-brand !bg-white",
        ]);
      }

      return classes;
    },
    populateDate(data) {
      this.availability = Object.assign({}, data);
      if (this.selectedDate) {
        this.selectDate({
          date: this.selectedDate,
          is_disabled: false,
        });
      }
    },
    previousMonth() {
      if (this.isOutOfRanges(`${this.availability.year}-${this.availability.month_number}-01`)) return;

      let year = Number(this.availability.year);
      let month = Number(this.availability.month_number);
      if (month === 1) {
        month = 12;
        year = year - 1;
      } else {
        month = month - 1;
      }

      this.getCalendar(month, year, (data) => {
        this.populateDate(data);
        this.resetSelected();
      });
    },
    nextMonth() {
      if (this.isOutOfRanges(this.availability.days[this.availability.days.length - 1].date)) return;

      let year = Number(this.availability.year);
      let month = Number(this.availability.month_number);
      if (month === 12) {
        month = 1;
        year = year + 1;
      } else {
        month = month + 1;
      }

      this.getCalendar(month, year, (data) => {
        this.populateDate(data);
        this.resetSelected();
      });
    },
    resetSelected() {
      this.$emit("update:selectedDate", null);
      this.availableSlots = [];
    },
    selectDate(day) {
      if (day.is_hidden || day.is_disabled || !day.is_available || this.isOutOfRanges(day.date)) return;

      if (this.selectedDate !== day.date) {
        this.$emit("update:selectedDate", day.date);
        this.fetchAvailability(day.date);
      }
    },
    fetchAvailability(date) {
      if (this.productId) {
        this.slotLoading = true;
        axios
          .get(`/api/booking/products/${this.productId}/availabilities`, {
            params: {
              date: date,
              venue_id: this.venueId,
              ...(this.quantity ? { quantity: this.quantity } : {}),
              ...(this.orderId ? { order_id: this.orderId } : {}),
            },
          })
          .then((data) => {
            this.availableSlots = data.data;
            this.slotLoading = false;
          });
      }
    },
    getCalendar(month = null, year = null, callback = () => {}) {
      this.loading = true;
      axios
        .get(`/api/booking/products/${this.productId}/calendar-availabilities`, {
          params: {
            venue_id: this.venueId,
            ...(month && year ? { month: month, year: year } : {}),
            ...(this.addOnProductId ? { add_on_product_id: this.addOnProductId } : {}),
            ...(this.orderId ? { order_id: this.orderId } : {}),
            ...(this.quantity ? { quantity: this.quantity } : {}),
          },
        })
        .then(({ data }) => {
          this.loading = false;
          callback(data);
        });
    },
    setAppointmentIds(newValue) {
      this.$emit("update:appointmentIds", this.findArrayIDsByKey(newValue));
    },
    findArrayIDsByKey(idKey) {
      const arrayIDs = [];

      Object.keys(this.availableSlots).forEach((key) => {
        const objects = this.availableSlots[key];
        const foundObject = objects.find((obj) => obj.id === idKey);

        if (foundObject) {
          const objectIDs = objects.map((obj) => obj.id);
          arrayIDs.push(...objectIDs);
        }
      });

      if (this.quantity) {
        return arrayIDs.slice(0, this.quantity);
      }

      return arrayIDs;
    },
    isOutOfRanges(date) {
      if (window && window.location.pathname.startsWith("/admin")) {
        return false;
      }

      return (
        moment(date).isAfter(moment().add(125, "days").format("YYYY-MM-DD")) ||
        moment(date).isBefore(moment().format("YYYY-MM-DD"))
      );
    },
  },
};
</script>
