<template>
  <nav class="absolute z-50 h-screen w-full md:sticky md:z-0 md:h-1" :class="{ '!h-1': isOpen }">
    <div class="mx-auto px-4 md:bg-orange-900 lg:px-8" :class="{ 'bg-stone-200': !isOpen }">
      <div class="flex h-16 items-center justify-between">
        <div class="flex items-center">
          <div class="flex-shrink-0 cursor-pointer" @click="goHome">
            <img
              class="h-4 w-auto"
              :class="{ 'max-md:hidden': !isOpen }"
              src="/img/logos/white-horizontal-logo.svg"
              alt="Sense of self"
            />
            <img v-if="!isOpen" class="h-4 w-auto" src="/img/logos/orange-horizontal-logo.svg" alt="Sense of self" />
          </div>
        </div>
        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6">
            <customer-select></customer-select>
            <v-profile :sign-out-url="signOutUrl" :user="user" :is-admin="false"></v-profile>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <button
            @click="isOpen = !isOpen"
            type="button"
            class="rounded-md p-2 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <v-icon icon="Bars3Icon" class="h-8 w-8 text-white2" :class="{ hidden: !isOpen, block: isOpen }"></v-icon>

            <v-icon icon="XMarkIcon" class="h8 w-8" :class="{ hidden: isOpen, block: !isOpen }"></v-icon>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div class="h-full bg-stone-200 md:hidden" :class="{ hidden: isOpen }" v-if="!isOpen">
      <div class="border-b-2 border-t-2 border-orange-900 px-mdSpace py-xlSpace text-orange-900">
        <span class="text-4xl">{{ $t("generic.hi") }} {{ user.full_name }}.</span>
        <customer-select></customer-select>
      </div>
      <div class="border-b-2 border-orange-900 px-mdSpace py-xlSpace text-orange-900">
        <a
          v-for="(item, key) in items"
          v-bind:key="key"
          :href="item.url"
          class="block rounded-md py-xsSpace text-4xl"
          :class="{
            'font-semibold': item.active,
          }"
          aria-current="page"
        >
          {{ item.label }}
        </a>
      </div>
      <div class="px-mdSpace py-xlSpace text-orange-900">
        <a @click="signOut" class="block rounded-md py-xsSpace text-4xl" aria-current="page">
          {{ $t("user.buttons.sign_out") }}
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "axios";

export default {
  props: {
    signOutUrl: {
      type: String,
      required: true,
    },
    homeUrl: {
      type: String,
      required: false,
    },
    items: {
      type: Object,
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    async signOut() {
      await axios
        .post(this.signOutUrl, {})
        .then((response) => {
          localStorage.removeItem("customerId");
          window.location = "/client/login";
        })
        .catch(console.error)
        .finally(() => {});
    },
    goHome() {
      if (this.homeUrl) {
        window.location.href = this.homeUrl;
        return;
      }

      window.location.reload();
    },
  },
};
</script>
