<template>
  <div
    class="reception fixed left-[60px] top-0 z-40 flex w-screen items-center justify-between gap-x-6 bg-white bg-white pb-smSpace pl-mdSpace pr-3xlSpace pt-mdSpace"
  >
    <div class="flex gap-x-2">
      <a @click="showToday" type="button" class="btn-secondary btn-sm">{{ $t("generic.buttons.today") }}</a>
      <date-picker
        v-model:value="filters['filter[date]']"
        :placeholder="$t('reception.placeholders.select_date')"
        format="DD-MM-YYYY"
        value-type="format"
        type="date"
        @change="onChange"
        class="w-full sm:w-[160px]"
      />
      <div class="w-48">
        <v-multi-select
          v-model="filters['filter[venue]']"
          search-url="/api/admin/venues?filter[active]=1"
          :placeholder="$t('booking.placeholders.venue')"
          track-by="id"
          label="name"
          search-key="filter[name]"
          class="sm:w-[200px]"
          @update:modelValue="onChangeVenue"
        />
      </div>
    </div>
    <div class="relative mr-5 w-1/5 rounded-md shadow-sm">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-smSpace">
        <v-icon icon="MagnifyingGlassIcon" classes="h-4 w-4 text-tertiary-400" />
      </div>
      <input
        type="text"
        :placeholder="$t('reception.placeholders.search')"
        v-model="filters['filter[search]']"
        class="pl-xlSpace"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ReceptionFilter",
  inject: ["bus", "config"],
  props: {
    arrivalStatuses: {
      type: Array,
      default: () => {
        return [];
      },
    },
    bookingClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bookings: [],
      filters: {
        "filter[date]": moment().format(this.config.DATETIME_FORMAT),
        "filter[search]": "",
      },
    };
  },
  created() {
    this.filters["filter[venue]"] = Number(localStorage.getItem("venue_id"));
  },
  mounted() {
    this.bus.$emit("tableFilterChange", { params: this.filters });
  },
  methods: {
    onChange() {
      this.bus.$emit("tableFilterChange", { params: this.filters });
    },
    onChangeVenue() {
      localStorage.setItem("venue_id", this.filters["filter[venue]"]);
      this.bus.$emit("tableFilterChange", { params: this.filters });
    },
    showToday() {
      this.filters["filter[date]"] = moment().format(this.config.DATETIME_FORMAT);
      this.filters["filter[search]"] = "";
      this.bus.$emit("tableFilterChange", { params: this.filters });
    },
  },
};
</script>
<style>
.reception .mx-datepicker {
  .mx-input-wrapper {
    .mx-input {
      height: 37px;
    }
  }
}
</style>
