<template>
  <vue-upload-component
    ref="upload"
    class="flex w-full cursor-pointer justify-center rounded border p-mdSpace"
    v-model="files"
    name="file"
    :post-action="uploadEndpoint"
    :headers="{ 'X-CSRF-TOKEN': csrf }"
    accept="image/*,video/mp4,video/x-m4v,video/*,audio/mpeg,audio/wav,audio/x-wav,audio/mp4,audio/mp3,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/rtf,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf"
    :multiple="true"
    :data="{
      modelClass: modelClass,
      modelId: model.id,
    }"
    :drop="true"
    @input-file="inputFile"
    @input-filter="inputFilter"
  >
    <v-icon icon="PlusIcon" />
    <p class="text-xs font-medium">{{ $t("media.phrase.upload_area") }}</p>
    <button
      v-show="$refs.upload && $refs.upload.active"
      type="button"
      class="btn-secondary btn-sm"
      @click.prevent="$refs.upload.active = false"
    >
      {{ $t("media.buttons.cancel") }}
    </button>
  </vue-upload-component>
</template>

<script>
import VueUploadComponent from "vue-upload-component";

export default {
  // This component is copied from staircase
  name: "VUpload",
  components: {
    VueUploadComponent,
  },
  props: {
    csrf: {
      type: String,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    modelClass: {
      type: String,
      required: true,
    },
    uploadEndpoint: {
      type: String,
      default: "/api/generic/media/upload",
    },
    existingEndpoint: {
      type: String,
      default: "/api/generic/media/existing",
    },
    newFileUploaded: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
  },

  data: function () {
    return {
      files: [],
    };
  },

  methods: {
    /**
     * Has changed
     * @param  Object|undefined   newFile   Read only
     * @param  Object|undefined   oldFile   Read only
     * @return undefined
     */
    inputFile: function (newFile, oldFile) {
      if (newFile && !oldFile) {
      }

      if (newFile && oldFile) {
        // Start upload
        if (newFile.active !== oldFile.active) {
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
          // console.log("progress", newFile.progress, newFile);
        }

        // Upload error
        if (newFile.error !== oldFile.error) {
          // console.log("error", newFile.error, newFile);
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          this.newFileUploaded(newFile.response.media);
        }
      }

      if (!newFile && oldFile) {
        // Remove file

        // Automatically delete files on the server
        if (oldFile.success && oldFile.response.id) {
        }
      }

      // Automatic upload
      if (!this.$refs.upload.active) {
        this.$refs.upload.active = true;
      }
    },

    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter: function (newFile, oldFile, prevent) {
      // Filter system files or hide files
      if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
        return prevent();
      }
      // Filter php html js file
      if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
        return prevent();
      }

      if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = "";
        const URL = window.URL || window.webkitURL;
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }

        // Thumbnails
        newFile.thumb = "";
        if (newFile.blob) {
          newFile.thumb = newFile.blob;
        }
      }
    },
  },
};
</script>
<style>
.file-uploads {
  @apply hover:bg-tertiary-100;

  svg {
    @apply inline;
  }

  label {
    @apply cursor-pointer;
  }
}
</style>
