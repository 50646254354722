<template>
  <td :class="rowField.field">
    <a v-if="!currentValue.hasOwnProperty('type')" class="hover:text-blue-600" @click="open">
      {{ currentValue.title }}
    </a>
    <v-badge
      v-else-if="currentValue.type === 'badge'"
      class="cursor-pointer hover:bg-success-300 hover:text-black"
      :value="currentValue.title"
      @click="open"
    />
  </td>
</template>
<script>
export default {
  name: "VTablePanelButton",
  inject: ["bus"],
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
    rowField: {
      type: [String, Object],
    },
  },
  computed: {
    currentValue() {
      return this.rowData[this.rowField.field];
    },
  },
  mounted() {
    if (this.currentValue.hasOwnProperty("open_as_default") && this.currentValue.open_as_default) {
      this.open();
    }
  },
  methods: {
    open() {
      this.bus.$emit("openSidePanel", {
        componentName: this.currentValue.panel,
        title: this.currentValue.panel_title,
        componentData: this.currentValue.panel_data,
        closeCallback: () => {},
      });
    },
  },
};
</script>
