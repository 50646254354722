<template>
  <div class="flex items-center">
    <span class="pr-2xsSpace text-xs" v-if="title">{{ title }}</span>
    <button
      type="button"
      class="relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-300 transition-colors duration-200 ease-in-out"
      role="switch"
      :aria-checked="value"
      @click="toggle"
      :class="classes"
    >
      <span
        aria-hidden="true"
        class="pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        :class="{
          'translate-x-5': value,
          'translate-x-0': !value,
          '!translate-x-3': small && value,
          '!h-2 !w-2': small,
        }"
      >
        <span v-if="forVoucher" class="flex items-center justify-center">
          <span v-if="value" class="text-xs">%</span>
          <span v-else class="text-xs">$</span>
        </span>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, Number],
      required: true,
    },
    title: {
      type: String,
    },
    isDisabled: {
      type: [Boolean],
      required: false,
    },
    small: {
      type: [Boolean],
      required: false,
    },
    ring: {
      type: [Boolean],
      default: true,
    },
    forVoucher: {
      type: [Boolean],
      required: false,
    },
  },
  computed: {
    classes() {
      return {
        "bg-indigo-600 hover:bg-indigo-600": this.value,
        "!h-3 !w-6": this.small,
        "focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2": this.ring,
      };
    },
  },
  methods: {
    toggle() {
      if (this.isDisabled) {
        return;
      }

      this.$emit("input", !this.value);
    },
  },
};
</script>
