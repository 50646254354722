<template>
  <div v-click-outside="onClickOutside">
    <div class="relative ml-3">
      <button
        type="button"
        class="flex rounded-full bg-transparent text-sm"
        id="user-menu-button"
        aria-expanded="false"
        aria-haspopup="true"
        @click="toggleDropdown"
      >
        <span class="sr-only">Open user menu</span>
        <span
          :class="{
            'bg-tertiary-200': showDropdown,
            'bg-transparent text-white': !isAdmin,
            'text-tertiary-700': isAdmin,
          }"
          class="flex items-center gap-2 rounded-md p-1 text-sm font-semibold leading-none"
        >
          <v-avatar :user="user"></v-avatar>
          <span v-text="user.full_name"></span>
          <span class="transition-transform" :class="{ 'rotate-180': showDropdown }">
            <v-icon classes="w-4 h-4" icon="ChevronDownIcon"></v-icon>
          </span>
        </span>
      </button>

      <div v-if="showDropdown" class="absolute right-0 z-20 mt-xsSpace">
        <div
          class="z-10 origin-top-right overflow-hidden rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu-button"
          tabindex="-1"
        >
          <ul class="text-sm font-medium text-tertiary-500 [&>a]:whitespace-nowrap">
            <li>
              <a
                :href="isAdmin ? `/admin/settings/users/${user.id}/edit` : '/client/details'"
                class="flex px-smSpace py-xsSpace hover:bg-tertiary-100 hover:text-tertiary-800"
              >
                <v-icon class="mr-xsSpace ring-0" icon="PencilSquareIcon"></v-icon>
                {{ $t("user.buttons.your_profile") }}
              </a>
            </li>
            <li @click="signOut" class="cursor-pointer">
              <a class="flex px-smSpace py-xsSpace hover:bg-tertiary-100 hover:text-tertiary-800">
                <v-icon class="mr-xsSpace ring-0" icon="ArrowLeftOnRectangleIcon"></v-icon>
                {{ $t("user.buttons.sign_out") }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VProfile",
  props: {
    user: {
      type: Object,
      required: true,
    },
    signOutUrl: {
      type: String,
      required: true,
      default: "#",
    },
    isAdmin: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },

  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    onClickOutside(ev) {
      if (this.showDropdown) {
        this.showDropdown = false;
      }
    },
    async signOut() {
      await axios
        .post(this.signOutUrl, {})
        .then((response) => {
          localStorage.removeItem("customerId");
          window.location = this.isAdmin ? "/login" : "/client/login";
        })
        .catch(console.error)
        .finally(() => {});
    },
  },
};
</script>
