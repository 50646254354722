<template>
  <form class="flex w-full flex-col justify-between" v-if="payment">
    <side-panel ref="sidePanel" :disable-listeners="true"></side-panel>
    <div class="grow space-y-lgSpace p-smSpace xl:p-lgSpace">
      <div class="grid grid-cols-1 gap-smSpace">
        <div class="text-xl font-bold">{{ $t("customer.headings.payment_details") }}</div>

        <div class="space-y-lgSpace">
          <ul role="list" class="divide-y divide-gray-100">
            <li class="flex justify-between gap-x-6 py-2mdSpace">
              <div class="flex min-w-0 flex-auto gap-x-4">
                <p class="text-sm font-semibold leading-6 text-gray-900">
                  {{ $t("customer.headings.transaction_id") }}
                </p>
              </div>
              <div class="hidden sm:flex sm:flex-col sm:items-end">
                <p class="text-sm leading-6 text-gray-900">
                  <span>{{ payment.id }}</span>
                </p>
              </div>
            </li>
            <template v-for="(value, index) in orderItems" :key="index">
              <li
                class="flex justify-between gap-x-6 py-2mdSpace"
                :class="{ 'cursor-pointer': !!value.with_trashed_booking }"
                @click="openPanel(value)"
              >
                <div class="flex min-w-0 flex-auto gap-x-4">
                  <p class="text-sm font-semibold leading-6 text-gray-900">
                    {{ value.name }}
                    -
                    <a
                      @click="openVourcherInstanceDetail(value)"
                      href="#"
                      class="text-blue-500 underline hover:text-blue-700"
                      v-if="value.isGiftVoucherInstance"
                    >
                      {{ value.itemable?.code ?? "NA" }}</a
                    >
                  </p>
                </div>
                <div class="hidden sm:flex sm:flex-col sm:items-end">
                  <p class="text-sm leading-6 text-gray-900">
                    <span
                      >{{ toAUD(value.itemable?.price_per_unit || value.price) }}
                      <span v-if="value.quantity > 1">X {{ value.quantity }}</span></span
                    >
                  </p>
                </div>
              </li>
            </template>
            <li class="flex justify-between gap-x-6 py-2mdSpace">
              <div class="flex min-w-0 flex-auto gap-x-4">
                <p class="text-sm font-semibold leading-6 text-gray-900">
                  {{ $t("customer.headings.tax") }}
                </p>
              </div>
              <div class="hidden sm:flex sm:flex-col sm:items-end">
                <p class="flex flex-row-reverse text-sm">
                  {{ toAUD((Number(payment.amount) - Number(payment.amount) / 1.1).toFixed(2)) }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  inject: ["bus", "config"],
  props: {
    paymentId: {
      type: Number,
    },
    endpoint: {
      type: String,
    },
    currentUser: {
      type: Object,
    },
  },
  computed: {
    orderItems() {
      return this.payment.order.order_items.reduce((acc, item) => {
        const isPaymentBooking = !!item.with_trashed_booking;
        const key = isPaymentBooking
          ? `${item.itemable_type}::${item.itemable_id}::${item.with_trashed_booking?.client.id}`
          : `${item.itemable_type}::${item.itemable_id}`;

        const name = isPaymentBooking
          ? `${item.itemable?.name} - ${item.with_trashed_booking?.client.email}`
          : item.itemable?.name;
        if (acc[key]) {
          acc[key].quantity += item.quantity || 1;
        } else {
          acc[key] = {
            ...item,
            name,
            quantity: item.quantity || 1,
            isGiftVoucherInstance: item.itemable_type === this.config.modelClasses.giftVoucherInstance,
          };
        }

        return acc;
      }, {});
    },
  },
  data() {
    return {
      payment: null,
    };
  },
  mounted() {
    axios.get(`/api/admin/payments/${this.paymentId}`).then(({ data }) => {
      this.payment = data.data;
    });
  },
  methods: {
    openVourcherInstanceDetail(orderItem) {
      if (orderItem.isGiftVoucherInstance) {
        this.$refs.sidePanel.open(
          "VoucherInstanceDetails",
          {
            voucherCode: orderItem.itemable?.code ?? "NA",
            classes: "p-lgSpace space-y-lgSpace",
            currentUser: this.currentUser,
            showRefundable: true,
          },
          null,
          () => {},
          true,
        );
      }
    },
    openPanel(order_items) {
      if (!order_items.with_trashed_booking) {
        return;
      }
      this.bus.$emit("openSidePanel", {
        componentName: "BookingDetail",
        title: "",
        componentData: {
          bookingId: order_items.with_trashed_booking.id,
          bookingClass: order_items.with_trashed_booking.class,
          currentUser: this.currentUser,
          showAddTask: true,
        },
      });
    },
  },
};
</script>
