<template>
  <div class="mb-12 space-y-4 rounded-lg bg-white/10 p-4">
    <img src="/img/add-on-massage-banner.png" alt="Add-on Massage" class="h-auto w-full" />
    <p class="text-base text-white">{{ $t("booking.headings.suggest_bathhouse") }}</p>
    <product-list
      :venue-id="cart.venue_id"
      :category-id="addOnCategoryId"
      v-model="form.add_on_product_id"
      :is-weekday="isWeekday"
      :hide-others="true"
      :show-quantity-stepper="false"
    />
    <variant-list
      class="!mt-xsSpace"
      ref="variantList"
      :for-add-on="true"
      v-if="form.add_on_product_id"
      v-model="form.add_on_product_variant_id"
      :product-id="form.add_on_product_id"
    />
    <div class="!mt-4 flex justify-center">
      <button
        v-if="form.add_on_product_id && form.add_on_product_variant_id"
        class="btn-secondary !flex w-full !items-center !justify-center"
        :class="{
          '!border-transparent !bg-transparent !text-white': !form.add_on_product_id || !form.add_on_product_variant_id,
          '!bg-white !text-brand': form.add_on_product_id && form.add_on_product_variant_id,
        }"
        @click="continueBooking"
      >
        <span v-if="!isUpdating">
          {{ $t("booking.buttons.next_details") }}
        </span>
        <v-icon v-else icon="ArrowPathIcon" class="animate-spin" />
      </button>

      <button
        v-else
        class="btn-secondary !flex w-full !items-center !justify-center !bg-white !text-brand"
        @click="noThank"
      >
        <span>
          {{ $t("booking.buttons.no_thanks") }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import { debounce } from "lodash";

export default {
  name: "BookingAddonProduct",
  inject: ["bus"],
  props: {
    cart: {
      type: Object,
      required: true,
    },
    addOnCategoryId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isWeekday() {
      return ![6, 0].includes(new Date(this.cart.date).getDay());
    },
  },
  data() {
    return {
      form: new Form(
        {
          add_on_product_id: this.cart.add_on_product_id,
          add_on_product_variant_id: this.cart.add_on_product_variant_id,
        },
        { resetOnSuccess: false },
      ),
      updateCartDebounce: debounce(this.updateCart, 500),
      isUpdating: false,
    };
  },

  watch: {
    "form.add_on_product_id": {
      handler() {
        this.form.add_on_product_variant_id = null;
        this.updateCartDebounce();
      },
    },
    "form.add_on_product_variant_id": {
      handler() {
        this.updateCartDebounce();
      },
    },
  },

  methods: {
    noThank() {
      if (this.form.add_on_product_id) {
        this.form.add_on_product_id = null;
        if (!this.isUpdating) {
          setTimeout(() => {
            this.continueBooking();
          }, 1000);
        }
      } else {
        this.continueBooking();
      }
    },
    updateCart() {
      if (this.isUpdating) {
        return;
      }

      this.isUpdating = true;
      this.form.post("/api/booking/cart/add-on").then(() => {
        this.isUpdating = false;
        this.bus.$emit("reloadCart");
      });
    },

    continueBooking() {
      this.isUpdating = true;
      this.form.post("/api/booking/cart/add-on").then(() => {
        this.isUpdating = false;
        window.location.href = `/booking/detail`;
      });
    },
  },
};
</script>
