<template>
  <div class="max-w-xl space-y-lgSpace py-xlSpace" :class="{ 'space-x-lgSpace px-xlSpace': inPanel }">
    <form class="grid grid-cols-1 gap-smSpace">
      <div class="flex flex-col gap-mdSpace md:flex-row">
        <input-wrapper field="first_name" :form="form" class="flex-1" :label-text="$t('customer.fields.name')">
          <input type="text" v-model="form.first_name" :placeholder="$t('customer.placeholders.first_name')" />
        </input-wrapper>

        <input-wrapper field="last_name" :form="form" class="flex-1" label-text="&nbsp;">
          <input type="text" v-model="form.last_name" :placeholder="$t('customer.placeholders.last_name')" />
        </input-wrapper>
      </div>

      <input-wrapper field="email" :form="form" :label-text="$t('customer.fields.email')">
        <input type="text" v-model="form.email" :placeholder="$t('customer.placeholders.email')" />
      </input-wrapper>

      <input-wrapper field="user_id" :form="form" :label-text="$t('customer.fields.user')">
        <v-multi-select
          v-model="form.user_id"
          search-url="/api/admin/users?filter[active]=1"
          :placeholder="$t('customer.placeholders.user')"
          track-by="id"
          label="full_name"
          search-key="filter[full_name]"
        />
      </input-wrapper>

      <input-wrapper field="phone" :form="form" :label-text="$t('customer.fields.phone')">
        <phone-number v-model="form.phone" :placeholder="$t('customer.placeholders.phone')" />
      </input-wrapper>

      <input-wrapper field="address" :form="form" :label-text="$t('customer.fields.address')">
        <input type="text" v-model="form.address" :placeholder="$t('customer.placeholders.address')" />
      </input-wrapper>

      <input-wrapper field="city" :form="form" :label-text="$t('customer.fields.city')">
        <input type="text" v-model="form.city" :placeholder="$t('customer.placeholders.city')" />
      </input-wrapper>

      <input-wrapper field="state" :form="form" :label-text="$t('customer.fields.state')">
        <input type="text" v-model="form.state" :placeholder="$t('customer.placeholders.state')" />
      </input-wrapper>

      <input-wrapper field="postcode" :form="form" :label-text="$t('customer.fields.postcode')">
        <input type="text" v-model="form.postcode" :placeholder="$t('customer.placeholders.postcode')" />
      </input-wrapper>

      <input-wrapper field="note" :form="form" :label-text="$t('customer.fields.note')">
        <v-textarea v-model="form.note" :placeholder="$t('customer.fields.note')"></v-textarea>
      </input-wrapper>

      <input-wrapper field="labels" :form="form" :label-text="$t('customer.fields.labels')">
        <v-tag-cloud :tags="form.labels" :filters="labelFilters" @input="onUpdatedLabels" />
      </input-wrapper>

      <v-checkbox v-model="form.active" :label-text="$t('customer.fields.active')"></v-checkbox>
    </form>
    <v-footer :in-panel="inPanel" :list-url="listUrl" :save="save"></v-footer>
  </div>
</template>

<script>
import Form from "form-backend-validation";

export default {
  name: "CustomerEdit",
  inject: ["bus", "notificationService"],
  components: {},
  props: {
    customer: {
      type: Object,
      default: () => {
        return {};
      },
    },
    listUrl: {
      type: String,
      required: true,
    },
    inPanel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: new Form(
        {
          id: this.getFieldValue("id"),
          first_name: this.getFieldValue("first_name"),
          last_name: this.getFieldValue("last_name"),
          phone: this.getFieldValue("phone"),
          email: this.getFieldValue("email"),
          user_id: this.getFieldValue("user_id"),
          postcode: this.getFieldValue("postcode"),
          state: this.getFieldValue("state"),
          address: this.getFieldValue("address"),
          city: this.getFieldValue("city"),
          active: Boolean(Number(this.getFieldValue("active"))),
          labels: this.getFieldValue("labels", null, []),
          note: this.getFieldValue("note"),
        },
        { resetOnSuccess: false },
      ),
      labelFilters: {
        "filter[active]": 1,
        "filter[type]": "customer",
      },
    };
  },

  mounted() {},

  methods: {
    onUpdatedLabels(labels) {
      this.form.labels = labels;
    },
    getFieldValue(field, children = null, defaultValue = null) {
      if (children && this.customer.hasOwnProperty(children) && this.customer[children].hasOwnProperty(field)) {
        return this.customer[children][field];
      }

      if (this.customer.hasOwnProperty(field)) {
        return this.customer[field];
      }

      return defaultValue;
    },
    close() {
      if (this.inPanel) {
        this.bus.$emit("closePanelInPanel");
        return;
      }

      location.href = this.listUrl;
    },
    save() {
      if (this.customer.hasOwnProperty("id")) {
        this.form
          .patch(`/api/admin/customers/${this.customer.id}`)
          .then(({}) => {
            window.location.reload();
          })
          .catch(({ response }) => {
            this.notificationService.notify(response.data.message, "error");
          });

        return;
      }

      this.form
        .post(`/api/admin/customers`)
        .then(({}) => {
          window.location.reload();
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
  },
};
</script>
