<template>
  <div class="relative min-w-[320px] p-xsSpace">
    <h2 v-html="header" class="pb-smSpace text-xl font-semibold" />
    <div class="rounded border p-smSpace">
      <p class="mb-mdSpace text-base font-medium">{{ $t("payment.headings.payment_information") }}</p>
      <div class="flex flex-col gap-2xsSpace text-sm font-light">
        <div class="flex">
          <label class="w-1/4">
            {{ $t("generic.words.order") }}
          </label>
          <p>{{ payment.order_id }}</p>
        </div>
        <div class="flex">
          <label class="w-1/4">
            {{ $t("generic.words.customer") }}
          </label>
          <p>{{ payment.customer_id }}</p>
        </div>
        <div class="flex">
          <label class="w-1/4">
            {{ $t("generic.words.payment") }}
          </label>
          <p>{{ payment.id }}</p>
        </div>
        <div class="flex">
          <label class="w-1/4">
            {{ $t("generic.words.date") }}
          </label>
          <p>{{ moment(payment.created_at).format(config.FULL_DATETIME_FORMAT) }}</p>
        </div>
        <div class="flex">
          <label class="w-1/4">
            {{ $t("generic.words.reference") }}
          </label>
          <p>{{ payment.reference_id }}</p>
        </div>
        <div class="flex">
          <label class="w-1/4">
            {{ $t("generic.words.note") }}
          </label>
          <p class="w-3/4">{{ payment.note }}</p>
        </div>
        <div class="flex">
          <label class="w-1/4">
            {{ $t("generic.words.receipt") }}
          </label>
          <a class="text-blue-600 hover:text-blue-400" :href="payment.receipt_url" target="_blank">{{
            payment.receipt_number
          }}</a>
        </div>
        <div class="flex">
          <label class="w-1/4">
            {{ $t("generic.words.card") }}
          </label>
          <p>[{{ card.card_brand }}] xxxx xxxx xxxx {{ card.last_4 }} - {{ card.exp_month }}/{{ card.exp_year }}</p>
        </div>
        <div class="flex">
          <label class="w-1/4">
            {{ $t("generic.words.total_paid") }}
          </label>
          <p class="text-green-700">{{ toAUD(payment.total_money.amount / 100) }}</p>
        </div>
        <div class="flex">
          <label class="w-1/4">
            {{ $t("generic.words.refunded") }}
          </label>
          <p class="text-red-700">{{ toAUD(payment.refunded_money?.amount / 100) }}</p>
        </div>
      </div>
    </div>
    <div class="mt-lgSpace flex flex-col items-center justify-center">
      <p class="mb-mdSpace flex items-center gap-xsSpace text-lg font-medium text-orange-400">
        <v-icon icon="ExclamationTriangleIcon" />{{ $t("payment.headings.refund_confirm") }}
        <span class="text-red-700">({{ toAUD(amount) }})</span>
      </p>
      <div class="flex gap-mdSpace">
        <a class="btn-secondary btn-sm" @click.prevent="no">{{ $t("generic.words.no") }}</a>
        <a class="btn-primary btn-sm" @click.prevent="yes">{{ $t("generic.words.yes") }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  inject: ["config", "bus"],
  props: {
    header: {
      type: String,
      default: "",
    },
    payment: {
      type: Object,
      default: () => {
        return {};
      },
    },
    amount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    card() {
      return this.payment.card_details.card;
    },
  },
  methods: {
    moment,
    yes() {
      if (this.amount <= 0) {
        return;
      }

      this.$emit("confirm");
    },
    no() {
      this.$emit("closeModal");
    },
  },
};
</script>
