<template>
  <div class="v--multi-select" :class="className">
    <multiselect
      ref="multiselect"
      v-model="input"
      :model-value="input"
      :options="options"
      :disabled="disabled"
      :track-by="trackBy"
      :label="showValueWhenSelected && modelValue && !$refs?.['multiselect']?.isOpen ? trackBy : label"
      :close-on-select="!isMultiple"
      :preserve-search="true"
      :show-labels="isMultiple"
      :multiple="isMultiple"
      :loading="loading"
      :taggable="isTaggable"
      :tag-position="tagPosition"
      :placeholder="placeholder"
      :allow-empty="allowEmpty"
      :tag-placeholder="tagPlaceholder"
      :internal-search="false"
      @tag="create"
      @search-change="debounceSearch"
      @update:modelValue="emitValue"
    />
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import { debounce } from "lodash";

export default {
  name: "VMultiSelect",
  inject: ["bus"],
  components: {
    multiselect: Multiselect,
  },
  props: {
    name: {
      type: String,
    },
    modelValue: {
      default: null,
      required: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    trackBy: {
      type: String,
      default: "id",
    },
    tagPosition: {
      type: String,
      default: "bottom",
    },
    label: {
      type: String,
      default: "label",
    },
    createUrl: {
      type: String,
      required: false,
    },
    searchUrl: {
      type: String,
      required: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isTaggable: {
      type: Boolean,
      default: false,
    },
    isFormInput: {
      type: Boolean,
      default: false,
    },
    initOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    searchKey: {
      type: String,
      default: "s",
    },
    tagPlaceholder: {
      type: String,
      default: "Press enter to create a tag",
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    showValueWhenSelected: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
    sortOptions: {
      type: Function,
      default(options) {
        return options;
      },
    },
  },
  data() {
    return {
      input: [],
      options: [],
      loading: false,
      debounceSearch: debounce(this.search, 200),
    };
  },
  created() {
    this.initSearch();
  },
  watch: {
    modelValue: {
      handler(newValue, oldValue) {
        if (this.isMultiple) {
          this.assignSelected();
          return;
        }

        if (!this.options.find((o) => o[this.trackBy] === newValue)) {
          this.initSearch();
        } else {
          this.assignSelected();
        }
      },
      deep: true,
    },
    searchUrl() {
      this.initSearch();
    },
  },
  methods: {
    initSearch() {
      if (this.initOptions.length) {
        this.options = this.sortOptions(this.initOptions);
        this.assignSelected();
        return;
      }

      this.debounceSearch(
        "",
        () => {
          this.assignSelected();
        },
        this.modelValue ? this.modelValue : null,
      );
    },
    emitValue(change) {
      this.$emit("selectOption", change);
      this.$emit(
        "update:modelValue",
        (() => {
          if (!change) {
            return null;
          }

          if (Array.isArray(change) && this.isMultiple) {
            return change.map((o) => o[this.trackBy]);
          }

          return change[this.trackBy];
        })(),
      );
    },
    assignSelected() {
      if (!this.isMultiple) {
        const option = this.options.find((o) => {
          return o.hasOwnProperty(this.trackBy) && o[this.trackBy] === this.modelValue;
        });

        if (option) {
          this.input = option;
          this.$emit("selectOption", this.input);
        }
        return;
      }
      this.input = [];
      this.options.forEach((o) => {
        this.modelValue.forEach((s) => {
          if (s === o[this.trackBy] && this.input.every((i) => i[this.trackBy] !== o[this.trackBy])) {
            this.input.push(o);
          }
        });
      });

      this.$emit("selectOption", this.input);
    },

    async create(newOption) {
      if (this.createUrl) {
        this.loading = true;
        await axios
          .post(this.createUrl, {
            name: newOption,
          })
          .then((response) => {
            const modelValue = response.data;
            this.options.push(modelValue);
            if (this.isMultiple) {
              this.input.push(modelValue);
            } else {
              this.input = modelValue;
            }
          })
          .catch(console.error)
          .finally(() => {
            this.$emit("selectOption", this.input);
            this.$emit("update:modelValue", this.input);
            this.loading = false;
          });
      }
    },
    async search(search = "", callback = null, ids = null) {
      if (!this.searchUrl) {
        await new Promise((resolve) => {
          this.options = this.sortOptions(
            this.initOptions.filter((o) => {
              return o[this.label].toLowerCase().includes(search.toLowerCase());
            }),
          );
        });

        return;
      }

      this.loading = true;
      const params = {};
      params[this.searchKey] = search;
      const filters = [axios.get(this.searchUrl, { params })];
      if (ids) {
        if (this.trackBy !== "id") {
          params["filter[" + this.trackBy + "]"] = ids;
        } else {
          params["filter[ids]"] = ids;
        }

        filters.push(axios.get(this.searchUrl, { params }));
      }

      Promise.all(filters)
        .then((responses) => {
          const [normalResponse, idsResponse] = responses;
          this.options = this.sortOptions(
            [
              ...(this.searchKey === "s" ? normalResponse.data : normalResponse.data.data),
              ...(idsResponse ? (this.searchKey === "s" ? idsResponse.data : idsResponse?.data.data) : []),
            ].filter((o, i, self) => self.findIndex((s) => s[this.trackBy] === o[this.trackBy]) === i),
          );
          if (callback) {
            callback();
            this.$emit("optionLoaded", true);
          }
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
.client {
  input::placeholder {
    @apply !text-white opacity-60;
  }

  input,
  select {
    @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
  }

  .multiselect__tags,
  .multiselect__input,
  .multiselect__option--highlight,
  .multiselect__option--highlight::after {
    @apply !bg-brand;
  }
}

.multiselect {
  @apply block min-h-max w-full rounded-lg border border-tertiary-300 text-sm;
}

.multiselect__tags {
  @apply min-h-max border-0;
}

.multiselect__select {
  @apply absolute right-1 top-1/2 h-lg w-lg -translate-y-1/2 border-0 p-0;
}

.multiselect__placeholder,
.multiselect__single {
  @apply mb-0 overflow-hidden text-ellipsis whitespace-nowrap px-3 py-xsSpace text-sm;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  @apply text-sm;
}

.multiselect__single,
.multiselect__placeholder {
  @apply block cursor-pointer;
}

.multiselect__spinner {
  @apply hidden;
}

.v--multi-select .multiselect__tags .multiselect__input {
  @apply mb-0.5 mt-0 border-0 py-2 shadow-none;
}

.v--multi-select .multiselect__tags {
  @apply overflow-hidden rounded-lg p-0;
}

.multiselect__content-wrapper {
  @apply mt-0.5 max-h-2xlSpace border-t border-[#e8e8e8];
}

.multiselect__content .multiselect__option {
  @apply flex items-center;
}

.multiselect__element {
  @apply hover:bg-tertiary-100;
}

.multiselect__option {
  @apply block cursor-pointer px-3 py-xsSpace;
}

.multiselect__content-wrapper {
  @apply max-h-[264px];
}

.v--multi-select .multiselect__tags .multiselect__tag {
  @apply mb-0.5 mt-1.5;
}

.v--multi-select .multiselect__tags .multiselect__tags-wrap {
  @apply ml-1;
}

.v--multi-select .multiselect--active .multiselect__tags .multiselect__input {
  @apply rounded-lg;
}

.v--multi-select .multiselect__select {
  @apply z-20;
}

.v--multi-select .multiselect--active .multiselect__select {
  @apply top-2;
}
</style>
