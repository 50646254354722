<template>
  <div class="relative space-y-6">
    <div class="mx-auto space-y-6 pb-20 sm:w-[560px]">
      <v-notification />
      <div class="space-y-4 rounded-xl bg-white/10 p-4 text-base leading-[21px] text-white2">
        <p>{{ $t("gift_voucher.headings.checkout_intro") }}</p>
      </div>
      <input-wrapper
        class="text-white2"
        field="email"
        :form="form"
        :label-text="$t('gift_voucher.fields.email')"
        :dark-theme="true"
        is-required
      >
        <input class="bg-brand" type="text" v-model="form.email" :placeholder="$t('gift_voucher.placeholders.email')" />
      </input-wrapper>
      <voucher-list
        v-model="form.voucher_ids"
        v-model:isBathPass="form.is_bath_pass"
        v-model:quantity="form.quantity"
        @update:is-bath-pass="updateHeaderTitle"
      ></voucher-list>
      <div class="flex justify-center py-xsSpace">
        <button @click="continueBooking" class="btn-secondary !block w-full items-center !bg-white !text-brand">
          {{ $t("gift_voucher.buttons.next_details") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "form-backend-validation";
import VoucherList from "@/js/vue/components/gift-vouchers/checkout/VoucherList.vue";
import { mapState } from "vuex";

export default {
  name: "VoucherSelection",
  components: { VoucherList },
  inject: ["notificationService"],
  props: {
    cart: {
      type: Object,
      default: () => {
        return {};
      },
    },
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      form: new Form(
        {
          email: this.cart.hasOwnProperty("email") ? this.cart.email : "",
          quantity: this.cart.hasOwnProperty("quantity") ? this.cart.quantity : 1,
          voucher_ids: this.cart.hasOwnProperty("voucher_ids") ? this.cart.voucher_ids : null,
          is_bath_pass: this.cart.hasOwnProperty("is_bath_pass") ? this.cart.is_bath_pass : false,
        },
        { resetOnSuccess: false },
      ),
    };
  },
  mounted() {
    const id = parseInt(localStorage.getItem("customerId"));
    if (id) {
      this.$store.dispatch("customer/fetchCustomer", id);
    }
    this.initializeHeaderTitle();
  },
  computed: {
    ...mapState({
      currentCustomer: (state) => state.customer.currentCustomer,
    }),
  },
  watch: {
    currentCustomer: {
      handler(newValue, oldValue) {
        if (this.currentCustomer.hasOwnProperty("email") && !this.form.email) {
          this.form.email = this.currentCustomer.email;
        }
      },
      deep: true,
    },
    form: {
      handler(newValue, oldValue) {
        this.$emit("update:cart", newValue);
      },
      deep: true,
    },
    "form.is_bath_pass": function (newValue) {
      this.$emit("update:is-bath-pass", newValue);
    },
  },
  methods: {
    continueBooking() {
      this.form
        .post("/api/voucher/cart?step=product")
        .then(() => {
          window.location.href = "/voucher/details";
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
    updateHeaderTitle(isBathPass) {
      this.form.is_bath_pass = isBathPass;
      this.$emit("update:is-bath-pass", isBathPass);
      const headerTitle = document.getElementById("headerTitle");
      if (headerTitle) {
        headerTitle.textContent = isBathPass
          ? this.$t("gift_voucher.headings.bath_passes")
          : this.$t("gift_voucher.headings.slogan");
      }
    },
    initializeHeaderTitle() {
      const urlParams = new URLSearchParams(window.location.search);
      const isBathPass = urlParams.get("is_bath_pass") === "1";
      this.updateHeaderTitle(isBathPass);
    },
  },
};
</script>
<style scoped>
input::placeholder {
  @apply !text-white2 !opacity-50;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}
</style>
