import { createStore, createLogger } from "vuex";
import customer from "./modules/customer";
import product from "./modules/product";
import booking from "./modules/booking";
import bookingCreate from "./modules/booking-create";
import user from "./modules/user";
const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    customer,
    product,
    booking,
    bookingCreate,
    user,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
