<template>
  <span>
    <v-toggle :value="currentValue.value" @input="submitDate"></v-toggle>
  </span>
</template>
<script>
import VToggle from "../ui/VToggle.vue";
import axios from "axios";

export default {
  name: "VTableToggle",
  components: { VToggle },
  inject: ["bus", "notificationService"],
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowField: {
      type: [String, Object],
    },
  },
  data: function () {
    return {
      currentValue: this.rowData[this.rowField.field],
    };
  },
  methods: {
    submitDate(value) {
      this.currentValue.value = value;
      axios
        .put(this.currentValue.endpoint, { id: this.rowData.id, is_waiver: this.currentValue.value })
        .then(({ data }) => {
          this.bus.$emit("reloadTable");
          this.notificationService.notify(data.statusText);
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
  },
};
</script>
