<template>
  <div v-if="isDisplayed" class="mb-mdSpace">
    <div class="notification" :class="`notification--${this.notificationType}`" role="alert">
      <div class="flex">
        <div class="notification__icon" v-if="isShowIcon">
          <v-icon :icon="icon"></v-icon>
        </div>
        <div class="notification__content" v-html="contentData"></div>
        <div class="ml-auto pl-mdSpace" v-if="isShowIcon">
          <div class="-m-2xsSpace">
            <button type="button" :class="`notification__btn notification__btn--${notificationType}`">
              <span class="sr-only">Dismiss</span>
              <v-icon icon="XMarkIcon" @click="this.isDisplayed = false"></v-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VNotification",
  inject: ["bus"],
  components: {},
  props: {
    type: {
      type: String,
    },
    content: {
      type: String,
    },
    isShowIcon: {
      type: Boolean,
      default: true,
    },
    emitEventTo: {
      type: String,
      default: "showNotification",
    },
  },
  data() {
    return {
      isDisplayed: !!this.type,
      contentData: this.content,
      notificationType: this.type,
    };
  },
  watch: {
    type: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.isDisplayed = true;
        }
      },
      deep: true,
    },
  },
  created() {
    this.bus.$on(this.emitEventTo, (payload) => {
      this.contentData = payload.content;
      this.notificationType = payload.type;
      this.isDisplayed = true;

      setTimeout(() => {
        this.isDisplayed = false;
      }, 3000);
    });
  },
  computed: {
    icon() {
      if (this.notificationType === "error") {
        return "ExclamationTriangleIcon";
      }

      if (this.notificationType === "info") {
        return "InformationCircleIcon";
      }

      if (this.notificationType === "notice") {
        return "ExclamationCircleIcon";
      }

      if (this.notificationType === "warning") {
        return "InformationCircleIcon";
      }

      return "CheckCircleIcon";
    },
  },
};
</script>
<style>
.notification {
  @apply space-x-smSpace rounded-md border p-smSpace;

  &__content {
    @apply ml-mdSpace text-sm;
  }

  &__icon {
    @apply flex-shrink-0;
  }

  &--error {
    @apply border-error-500 bg-error-100 text-error-500;
  }

  &--info {
    @apply border-info-500 bg-info-100 text-info-500;
  }

  &--notice {
    @apply border-amber-700 bg-amber-50 p-xsSpace text-amber-700;
  }

  &--success {
    @apply border-success-500 bg-success-100 text-success-500;
  }

  &--warning {
    @apply border-warning-500 bg-warning-100 text-warning-500;
  }

  &__btn {
    @apply inline-flex rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2;

    &--error {
      @apply bg-error-100 text-error-500 hover:bg-error-100 focus:ring-error-600 focus:ring-offset-error-50;
    }

    &--notice {
      @apply bg-amber-50 text-amber-700 hover:bg-amber-50 focus:ring-amber-700 focus:ring-offset-amber-50;
    }

    &--info {
      @apply bg-info-100 text-info-500 hover:bg-info-100 focus:ring-info-600 focus:ring-offset-info-50;
    }

    &--success {
      @apply bg-success-100 text-success-500 hover:bg-success-100 focus:ring-success-600 focus:ring-offset-success-50;
    }

    &--warning {
      @apply bg-warning-100 text-warning-500 hover:bg-warning-100 focus:ring-warning-600 focus:ring-offset-warning-50;
    }
  }
}
</style>
