<template>
  <div class="relative w-full flex-1">
    <input
      v-bind="$attrs"
      :class="inputClass"
      :disabled="!searchUrl"
      type="text"
      v-model="value"
      :placeholder="placeholder"
      @input="onChangeInput"
      v-on:keyup.enter="enterInput"
    />
    <div
      v-if="createUrl && showPlaceholder"
      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
    >
      <span v-if="creatingState === 'creating'" class="text-gray-500 sm:text-sm">{{ $t("generic.creating") }}...</span>
      <span v-else-if="creatingState === 'created'" class="text-gray-500 sm:text-sm">{{ $t("generic.created") }}</span>
      <span v-else class="text-gray-500 sm:text-sm">{{ createPlaceholder }}</span>
    </div>
  </div>
  <div
    class="absolute top-full z-50 mt-0.5 max-h-[400px] w-full overflow-auto rounded border bg-white"
    v-if="displayList && listItems.length"
    v-on:mouseleave="leave"
    v-on:mouseenter="enter"
  >
    <div
      v-for="item in listItems"
      class="flex w-full cursor-pointer items-center border-b border-gray-200 bg-white px-mdSpace py-2xsSpace hover:bg-blue-200"
      @click="selectItem(item)"
    >
      <div
        v-if="item.logo"
        class="relative mr-4 mt-1.5 inline-flex h-10 w-10 items-center justify-center rounded-full bg-blue-50"
      >
        <span class="font-medium text-gray-600 dark:text-gray-300">
          {{ item.heading.charAt(0).toUpperCase() + item.heading.charAt(1).toUpperCase() }}
        </span>
      </div>
      <div>
        <h3 class="text-base font-semibold">{{ item.heading }}</h3>
        <div class="flex flex-col">
          <p class="mt-2xsSpace flex items-center text-xs" v-for="line in item.lines">
            <v-icon :icon="line.icon"></v-icon>
            <span class="pl-smSpace"> {{ line.value ? line.value : "N/A" }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";

export default {
  name: "VInputSearch",
  inject: ["bus", "notificationService"],
  inheritAttrs: false,
  components: {},
  emits: {
    selectItem: null,
  },
  props: {
    modelValue: {
      default: null,
      required: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    searchUrl: {
      type: String,
      required: false,
    },
    filterKey: {
      type: String,
      default: null,
    },
    inputClass: {
      type: String,
      default: null,
    },
    createUrl: {
      type: String,
      required: false,
    },
    createPlaceholder: {
      type: String,
      default: "Press enter to create a tag",
    },
  },
  data() {
    return {
      value: this.modelValue,
      displayList: false,
      listItems: [],
      timeout: null,
      onChangeInput: debounce(this.updateInput, 300),
      showPlaceholder: false,
      creatingState: null,
    };
  },
  watch: {
    modelValue() {
      this.value = this.modelValue;
    },
  },
  created() {
    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.onClickOutside();
      }
    });

    if (this.modelValue) {
      this.search(this.modelValue);
    }
  },
  mounted() {},
  methods: {
    updateInput($event) {
      this.$emit("update:modelValue", $event.target.value);
      this.search($event.target.value);

      this.showPlaceholder = !!this.value;
    },
    onClickOutside(e) {
      this.displayList = false;
      this.showPlaceholder = false;
    },
    enter() {
      clearTimeout(this.timeout);
    },
    leave() {
      this.timeout = setTimeout(() => {
        this.displayList = false;
      }, 300);
    },
    search(value) {
      if (!this.searchUrl) {
        return;
      }
      const params = {};
      params[`filter[${this.filterKey}]`] = value;
      axios
        .get(this.searchUrl, { params })
        .then((response) => {
          this.listItems = response.data;
          this.displayList = Boolean(this.listItems.length);
        })
        .catch(console.error);
    },
    selectItem(item) {
      this.displayList = false;
      this.showPlaceholder = false;
      this.$emit("selectItem", item);
    },
    enterInput() {
      if (this.creatingState) {
        return;
      }

      this.displayList = false;
      this.$emit("enterInput");
      if (this.createUrl) {
        this.create();
      }
    },
    async create() {
      if (this.creatingState) {
        return;
      }

      this.creatingState = "creating";
      this.showPlaceholder = true;
      await axios
        .post(this.createUrl, {
          name: this.value,
        })
        .then((response) => {
          this.$emit("selectItem", response.data);
          this.showPlaceholder = true;
        })
        .catch((error) => {
          this.notificationService.notify(error.response.data.message, "error");
        })
        .finally(() => {
          this.creatingState = "created";
          setTimeout(() => {
            this.creatingState = null;
            this.showPlaceholder = false;
          }, 5000);
        });
    },
  },
};
</script>
