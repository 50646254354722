<template>
  <div>
    <div class="flex justify-end p-mdSpace" v-if="showTable">
      <button class="btn-secondary btn-sm btn-icon flex-row-reverse space-x-reverse" type="button" @click="add">
        <span>{{ $t("variant.buttons.add") }}</span>
        <div class="btn-icon-left">
          <v-icon icon="PlusIcon"></v-icon>
        </div>
      </button>
    </div>
    <v-table v-if="showTable" :get-url="getUrl" :fields="fields"> </v-table>
    <variant-edit :product="product" :variant="variant" v-else> </variant-edit>
  </div>
</template>

<script>
import VariantEdit from "./VariantEdit.vue";
export default {
  name: "VariantManagement",
  inject: ["bus"],
  components: { VariantEdit },
  props: {
    fields: {
      type: Object,
      required: true,
    },
    getUrl: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showTable: true,
      variant: {},
    };
  },

  created() {
    this.bus.$on("onEditVariant", (variant) => {
      this.showTable = false;
      this.variant = variant;
    });

    this.bus.$on("backToIndex", () => {
      this.showTable = true;
      this.variant = {};
    });
  },

  methods: {
    add() {
      this.showTable = false;
    },
  },
};
</script>
