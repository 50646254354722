<template>
  <side-panel ref="sidePanel" :disable-listeners="true"></side-panel>
  <form class="flex w-full flex-col justify-between">
    <div class="space-y-smSpace p-mdSpace">
      <h4 class="text-lg">{{ $t("customer.headings.detail") }}</h4>
      <div class="space-y-2xsSpace rounded p-xsSpace text-sm ring-1">
        <div class="flex justify-between">
          <p>
            {{ $t("customer.headings.email") }}
          </p>
          <strong>{{ customer.email }}</strong>
        </div>
        <div class="flex justify-between">
          <p>
            {{ $t("customer.headings.phone") }}
          </p>
          <strong>{{ customer.phone }}</strong>
        </div>
        <div class="flex justify-between">
          <p>
            {{ $t("customer.fields.address") }}
          </p>
          <strong>{{ customer.address }}</strong>
        </div>
      </div>

      <h4>{{ $t("customer.headings.booking_history") }}</h4>
      <template v-for="(value, field) in customer.bookings">
        <h4>{{ field }}</h4>
        <template v-for="booking in value">
          <div class="space-y-2xsSpace rounded p-xsSpace text-sm ring-1">
            <div class="flex justify-between">
              <p>
                {{ $t("booking.fields.title") }}
              </p>
              <span>{{ booking.title }}</span>
            </div>
            <div class="flex justify-between">
              <p>
                {{ $t("booking.fields.date") }}
              </p>
              <span>{{ booking.date }}</span>
            </div>
            <div class="flex justify-between">
              <p>
                {{ $t("booking.fields.booking_time") }}
              </p>
              <span>{{ booking.booking_time }}</span>
            </div>
            <div class="flex justify-between">
              <p>
                {{ $t("booking.fields.is_gift_voucher") }}
              </p>
              <span>{{ booking.is_gift_voucher }}</span>
            </div>
            <div class="flex justify-between">
              <p>
                {{ $t("booking.fields.status") }}
              </p>
              <span>{{ booking.client_arrival_status }}</span>
            </div>
          </div>
        </template>
      </template>
    </div>
  </form>
</template>
<script>
import moment from "moment";

export default {
  inject: ["config", "bus"],
  props: {
    customer: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    this.bus.$on("closePanelInPanel", () => {
      this.$refs.sidePanel && this.$refs.sidePanel.close();
    });
  },
  methods: {
    formatDate(date) {
      return moment(date).format(this.config.DATETIME_FORMAT);
    },
  },
};
</script>
