<template>
  <div class="mb-10 mt-5 space-y-smSpace">
    <collapsible>
      <collapsible-trigger class="cursor-pointer justify-between border-b py-smSpace">
        <template v-slot="{ collapsed }">
          <span class="text-md flex-none font-semibold text-tertiary-600">
            {{ $t("customer.headings.upcoming") }} ({{ upcomingCount }})
          </span>
          <transition type="transition">
            <VIcon
              icon="ChevronDownIcon"
              class="h-6 w-6 transform cursor-pointer text-gray-400 transition-transform duration-300 ease-in-out hover:text-gray-800"
              :class="collapsed ? 'rotate-180' : ''"
            ></VIcon>
          </transition>
        </template>
      </collapsible-trigger>
      <collapsible-content>
        <v-table
          ref="upcoming"
          :get-url="getBookingsUrl('upcoming')"
          :default-sort-order="[{ field: 'date', direction: 'asc' }]"
          :fields="fields"
          @loading="updateCounts"
          hide-pagination
        ></v-table>
      </collapsible-content>
    </collapsible>
    <collapsible>
      <collapsible-trigger class="cursor-pointer justify-between border-b py-smSpace">
        <template v-slot="{ collapsed }">
          <span class="text-md flex-none font-semibold text-tertiary-600">
            {{ $t("customer.headings.past") }} ({{ pastCount }})
          </span>
          <transition type="transition">
            <VIcon
              icon="ChevronDownIcon"
              class="h-6 w-6 transform cursor-pointer text-gray-400 transition-transform duration-300 ease-in-out hover:text-gray-800"
              :class="collapsed ? 'rotate-180' : ''"
            ></VIcon>
          </transition>
        </template>
      </collapsible-trigger>
      <collapsible-content>
        <v-table
          ref="past"
          :get-url="getBookingsUrl('past')"
          :fields="fields"
          @loading="updateCounts"
          hide-pagination
        ></v-table>
      </collapsible-content>
    </collapsible>
    <collapsible>
      <collapsible-trigger class="cursor-pointer justify-between border-b py-smSpace">
        <template v-slot="{ collapsed }">
          <span class="text-md flex-none font-semibold text-tertiary-600">
            {{ $t("customer.headings.cancelled") }} ({{ cancelledCount }})
          </span>
          <transition type="transition">
            <VIcon
              icon="ChevronDownIcon"
              class="h-6 w-6 transform cursor-pointer text-gray-400 transition-transform duration-300 ease-in-out hover:text-gray-800"
              :class="collapsed ? 'rotate-180' : ''"
            ></VIcon>
          </transition>
        </template>
      </collapsible-trigger>
      <collapsible-content>
        <v-table
          ref="cancelled"
          :get-url="getBookingsUrl('cancelled')"
          :fields="fields"
          @loading="updateCounts"
          hide-pagination
        ></v-table>
      </collapsible-content>
    </collapsible>
  </div>
</template>

<script>
export default {
  name: "CollapsibleCustomerBookingList",
  props: {
    getUrl: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      upcomingCount: 0,
      pastCount: 0,
      cancelledCount: 0,
    };
  },
  mounted() {
    this.updateCounts();
  },
  methods: {
    getBookingsUrl(state) {
      return this.getUrl + (state ? `?filter[state]=${state}` : "");
    },
    updateCounts() {
      this.upcomingCount = this.$refs?.upcoming?.$refs?.vuetable?.countTableData || 0;
      this.pastCount = this.$refs?.past?.$refs?.vuetable?.countTableData || 0;
      this.cancelledCount = this.$refs?.cancelled?.$refs?.vuetable?.countTableData || 0;
    },
  },
};
</script>
