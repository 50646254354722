<template>
  <nav
    class="bg-white"
    aria-label="Sidebar"
    :class="{
      '!bg-transparent lg:border-b lg:border-tertiary-200': forClient,
      'space-y-2xsSpace rounded': !forClient,
      'flex !space-y-0': isHorizontal,
    }"
  >
    <template v-for="item in menu" :key="item.label">
      <div class="relative">
        <a
          v-if="!item.hasOwnProperty('panel')"
          :href="item.url"
          @click="toggle(item)"
          class="group flex items-center space-x-xsSpace p-2 text-sm font-medium"
          :class="{
            'bg-tertiary-100': item.active && !item.children.length && !forClient,
            'rounded text-tertiary-600 hover:bg-tertiary-50 hover:text-tertiary-900': !forClient,
            '!my-0 mr-2xsSpace justify-center !px-2 px-mdSpace': isHorizontal,
            '!hover:text-gray-300 font-semibold lg:border-b-2': forClient && item.active,
            'hidden lg:block lg:text-gray-400 ': forClient && !item.active,
          }"
        >
          <span class="h-6 w-6 flex-shrink-0 text-tertiary-400 group-hover:text-tertiary-500" v-if="item.icon">
            <v-icon :icon="item.icon" classes="h-6 w-6"></v-icon>
          </span>
          <span class="flex-1">{{ item.label }}</span>
        </a>
        <open-side-panel-button
          v-else
          :component-name="item.panel"
          :component-data="item.panel_data"
          classes="cursor-pointer items-center hover:text-secondary-200"
        >
          <a
            class="group flex items-center space-x-xsSpace rounded px-2 py-2 text-sm font-medium text-tertiary-600 hover:bg-tertiary-50 hover:text-tertiary-900"
          >
            {{ item.label }}
          </a>
        </open-side-panel-button>
        <ul
          v-if="item.children.length && item.active"
          class="ml-xlSpace"
          :class="{ 'absolute top-[48px] !ml-0 rounded bg-white': isHorizontal }"
          @mouseleave="onClickOutside(item)"
        >
          <li v-for="child in item.children" :key="child.label">
            <a
              :href="child.url"
              class="group flex items-center rounded p-xsSpace text-sm font-medium text-tertiary-600 hover:bg-tertiary-50 hover:text-tertiary-900"
              :class="{ 'bg-tertiary-100': child.active, 'px-mdSpace': isHorizontal }"
            >
              <v-icon :icon="child.icon" v-if="child.icon"></v-icon>
              <span class="flex-1">{{ child.label }}</span>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </nav>
</template>
<script>
export default {
  name: "VActions",
  props: {
    items: {
      type: Object,
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    forClient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: this.items,
    };
  },
  methods: {
    toggle(item) {
      item.active = !item.active;
    },
    onClickOutside(item) {
      setTimeout(() => {
        item.active = false;
      }, 1000);
    },
  },
};
</script>
