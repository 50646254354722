<template>
  <div class="space-y-8 pb-12">
    <contact-detail v-model="contact" :form="form" always-open />

    <!--    <template v-if="cart.quantity > 1">-->
    <!--      <v-checkbox v-model="forOthers" :form="form" dark-theme :label-text="$t('booking.labels.booking_for_others')" />-->

    <!--      <template v-if="!form.use_customer_info">-->
    <!--        <contact-detail-->
    <!--          v-for="(_, index) in users"-->
    <!--          :key="index"-->
    <!--          v-model="users[index]"-->
    <!--          :form="form"-->
    <!--          :label="`${$t('generic.guest')} #${index + 1}`"-->
    <!--          :form-path="`users.${index}.`"-->
    <!--          has-copy-btn-->
    <!--          :copied="copies[index]"-->
    <!--          @onCopy="copies[index] = $event"-->
    <!--        />-->
    <!--      </template>-->
    <!--    </template>-->

    <div class="flex justify-center">
      <button class="btn-secondary !block w-full items-center !bg-white !text-brand" @click="continueBooking">
        {{ $t("generic.buttons.next") }}: {{ $t("generic.words.payments") }}
      </button>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import ContactDetail from "./ContactDetail.vue";
import { debounce } from "lodash";

export default {
  inject: ["config", "bus"],
  components: { ContactDetail },
  props: {
    cart: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customerId: {
      type: String,
    },
  },
  data() {
    return {
      debounceUpdate: debounce(this.update, 500),
      form: new Form(
        {
          email: this.cart.hasOwnProperty("email") ? this.cart.email : "",
          phone: this.cart.phone ?? "",
          first_name: this.cart.first_name ?? "",
          last_name: this.cart.last_name ?? "",
          // users: this.cart.hasOwnProperty("users") ? this.cart.users : [],
          users: [],
          use_customer_info: true, // this.cart.quantity <= 1,
        },
        { resetOnSuccess: false },
      ),
      // forOthers: this.cart.quantity > 1,
      // users: this.getInitiateUsers(),
      // copies: new Array(this.cart.quantity || 1).fill(false),
    };
  },
  computed: {
    contact: {
      get() {
        return {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email,
          phone: this.form.phone,
        };
      },
      set(value) {
        this.form.populate(value);
        // this.copies.forEach((copied, index) => {
        //   if (copied) {
        //     this.users[index] = { ...value };
        //   }
        // });
      },
    },
  },
  watch: {
    "form.email": "debounceUpdate",
    "form.phone": "debounceUpdate",
    "form.first_name": "debounceUpdate",
    "form.last_name": "debounceUpdate",
    "form.use_customer_info": "debounceUpdate",
    // "form.use_customer_info": "debounceUpdate",
    // users: {
    //   handler() {
    //     this.debounceUpdate();
    //   },
    //   deep: true,
    // },
    // copies: {
    //   handler(copies) {
    //     copies.forEach((copied, index) => {
    //       if (copied) {
    //         this.users[index] = { ...this.contact };
    //       }
    //     });
    //   },
    //   deep: true,
    // },
    // forOthers: {
    //   handler(change) {
    //     if (!this.forOthers) {
    //       this.users = [];
    //     } else {
    //       this.users = this.getInitiateUsers(true);
    //     }
    //
    //     this.form.use_customer_info = !this.forOthers;
    //   },
    // },
  },
  methods: {
    // getInitiateUsers(initiate = false) {
    //   if (initiate) {
    //     return new Array(this.cart.quantity).fill({
    //       email: "",
    //       phone: "",
    //       first_name: "",
    //       last_name: "",
    //     });
    //   }
    //
    //   return this.cart.hasOwnProperty("users") && Array.isArray(this.cart.users)
    //     ? this.cart.users
    //     : new Array(this.cart.quantity).fill({
    //         email: "",
    //         phone: "",
    //         first_name: "",
    //         last_name: "",
    //       });
    // },
    update() {
      // this.form.users = this.users;
      this.form.post("/api/booking/cart/detail?skip_validation").then(() => {
        this.bus.$emit("update-cart");
      });
    },
    continueBooking() {
      // this.form.users = this.users;
      this.form.post("/api/booking/cart/detail").then(() => {
        window.location.href = this.customerId
          ? `/booking/payment` + `?customer=${this.customerId}`
          : `/booking/payment`;
      });
    },
  },
};
</script>
<style scoped>
input::placeholder {
  @apply !text-white opacity-60;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}
</style>
