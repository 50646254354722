<template>
  <div class="mt-smSpace flex flex-col px-2xsSpace">
    <h3>{{ $t("waiver.headings.associated_items") }}</h3>
    <div class="mt-xsSpace">
      <v-multi-select
        v-model="form.product_ids"
        :search-url="getProductUrl"
        :placeholder="$t('booking.placeholders.product')"
        track-by="id"
        label="name"
        search-key="filter[name]"
        v-click-outside="save"
        is-multiple
      />
    </div>
  </div>
</template>
<script>
import Form from "form-backend-validation";

export default {
  inject: ["bus", "config"],
  components: {},
  props: {
    waiveId: {
      type: Number,
      require: true,
    },
    intProductIds: {
      type: Array,
      require: true,
    },
    getProductUrl: {
      type: String,
      default: `/api/admin/products?filter[active]=1`,
    },
    saveUrl: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      loading: false,
      form: new Form(
        {
          product_ids: this.intProductIds,
        },
        { resetOnSuccess: false },
      ),
    };
  },
  methods: {
    save() {
      this.loading = true;
      this.form.post(this.saveUrl).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
