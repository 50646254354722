<template>
  <div class="flex flex-col space-y-lgSpace py-2lgSpace text-white">
    <p class="text-center font-sloganSerif text-6xl font-bold">
      {{ $t(title) }}
    </p>
    <p class="text-center text-xl font-normal">
      {{ $t(subTitle) }}
    </p>
    <div class="flex flex-col space-y-lgSpace pb-lgSpace">
      <voucher-cart-summary :init-cart="cart" :load-on-start="false" />
      <div
        v-if="cart.giftee_email"
        class="flex flex-col space-y-mdSpace rounded-lg border border-white bg-brand p-mdSpace"
      >
        <div class="flex flex-col space-y-smSpace text-white">
          <span class="text-[19px] font-semibold">{{ $t("gift_voucher.headings.giftee_details") }}</span>
          <div class="flex flex-col space-y-smSpace rounded-lg bg-opacity-10">
            <div>
              <p class="m-0 text-xs text-white text-opacity-50">{{ $t("gift_voucher.fields.giftee_details") }}</p>
              <p class="m-0 text-base">{{ cart.giftee_name }}</p>
              <p class="m-0 text-base">{{ cart.giftee_email }}</p>
              <p class="m-0 text-base">{{ cart.giftee_phone }}</p>
            </div>
            <div v-if="cart.message">
              <p class="m-0 text-xs text-white text-opacity-50">{{ $t("gift_voucher.fields.message") }}</p>
              <p class="m-0 text-base">{{ cart.message }}</p>
            </div>
            <div v-if="cart.sent_from">
              <p class="m-0 text-xs text-white text-opacity-50">{{ $t("gift_voucher.fields.sent_from") }}</p>
              <p class="m-0 text-base">{{ cart.sent_from }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VoucherCartSummary from "./VoucherCartSummary.vue";

export default {
  name: "VoucherCheckoutConfirmSummary",
  components: [VoucherCartSummary],
  props: {
    cart: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      if (this.cart.hasOwnProperty("is_bath_pass") && this.cart.is_bath_pass) {
        if (this.cart.giftee_email) {
          return "gift_voucher.responses.gift_bath_pass_completed";
        }

        return "gift_voucher.responses.bath_pass_completed";
      }

      if (this.cart.giftee_email) {
        return "gift_voucher.responses.gift_completed";
      }

      return "gift_voucher.responses.completed";
    },
    subTitle() {
      if (this.cart.giftee_email) {
        return "gift_voucher.responses.gift_completed_subtitle";
      }

      return "gift_voucher.responses.completed_subtitle";
    },
  },
};
</script>

<style scoped>
input::placeholder {
  @apply !text-white opacity-60;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}
</style>
