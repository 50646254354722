<template>
  <div class="flex flex-nowrap items-center gap-1">
    <select
      v-model="countryCode"
      class="inline-flex !w-auto flex-shrink-0"
      :class="{
        'bg-transparent text-white': darkMode,
      }"
    >
      <option v-for="(callingCode, code) in countries" :key="code" :value="code">
        {{ code }} (+{{ callingCode }})
      </option>
    </select>
    <input
      v-model="nationalNumber"
      type="text"
      name="phone"
      class="!flex-1"
      :placeholder="$attrs.placeholder || $t('generic.words.phone')"
      :class="{
        'rounded border border-white bg-transparent text-white': darkMode,
      }"
    />
  </div>
</template>

<script>
import parsePhoneNumberFromString, { getCountries, getCountryCallingCode } from "libphonenumber-js";

export default {
  name: "PhoneNumber",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    defaultCountryCode: {
      type: String,
      default: "AU",
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      countries: getCountries().reduce((acc, code) => {
        acc[code] = getCountryCallingCode(code);
        return acc;
      }, {}),
      nationalNumber: "",
      countryCode: this.defaultCountryCode,
    };
  },
  created() {
    this.parsePhoneNumber(this.modelValue);
  },
  watch: {
    modelValue(value) {
      this.parsePhoneNumber(value);
    },
    countryCode(value) {
      if (value && this.countries[value]) {
        this.$emit("update:modelValue", `+${this.countries[value]}${this.nationalNumber}`);
      }
    },
    nationalNumber(value) {
      this.$emit("update:modelValue", `+${this.countries[this.countryCode]}${value}`);
    },
  },
  methods: {
    parsePhoneNumber(number) {
      const phoneNumber = parsePhoneNumberFromString(number || "", this.defaultCountryCode);
      if (phoneNumber) {
        this.countryCode = phoneNumber.country || this.countryCode;
        this.nationalNumber = phoneNumber.nationalNumber;
      }
    },
  },
};
</script>
