<template>
  <div>
    <button :class="classes" @click="showConfirm">
      <span>{{ label }}</span>
    </button>
    <form ref="form" :action="link" class="hidden" method="POST">
      <input :value="$root.csrf_token" name="_token" type="hidden" />
      <input name="_method" type="hidden" value="DELETE" />
      <button ref="submit" type="submit"></button>
    </form>
  </div>
</template>

<script>
export default {
  name: "DeleteConfirmationButton",
  inject: ["bus"],
  props: {
    link: {
      type: String,
    },
    content: {
      type: String,
    },
    classes: {
      type: String,
      default: "flex w-full cursor-pointer items-center space-x-xsSpace p-xsSpace hover:bg-neutral-100",
    },
    label: {
      type: String,
      default: "Delete",
    },
  },

  methods: {
    showConfirm() {
      this.bus.$emit("openModal", {
        componentData: this.content,
        callback: this.save,
      });
    },
    save() {
      this.$refs.submit.click();
    },
  },
};
</script>
