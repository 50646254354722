import "./bootstrap";
import $bus from "./lib/Vue3EventBus";
import NotificationService from "./vue/services/NotificationService";
import SessionStorageService from "./vue/services/SessionStorageService";

import Alpine from "alpinejs";
import * as Vue from "vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import store from "./vue/store";
import { createI18n } from "vue-i18n";
import { clickOutside } from "~vue/directives/ClickOutSide";
import Formatter from "./vue/helpers/Formatter.js";
import Scroller from "./vue/helpers/Scroller";
import DatePicker from "vue-datepicker-next";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import InputWrapper from "./vue/components/common/form/InputWrapper.vue";
import VFilter from "./vue/components/common/ui/VFilter.vue";
import VMenu from "./vue/components/common/ui/Menu.vue";
import VHeader from "./vue/components/common/ui/Header.vue";
import VProfile from "./vue/components/common/ui/VProfile.vue";
import VSearch from "./vue/components/common/ui/VSearch.vue";
import VBadge from "./vue/components/common/ui/VBadge.vue";
import VTablePanelButton from "./vue/components/common/table/VTablePanelButton.vue";
import VTableStatus from "./vue/components/common/table/VTableStatus.vue";
import VMultiSelect from "./vue/components/common/form/VMultiSelect.vue";
import SidePanel from "./vue/components/common/ui/SidePanel.vue";
import VTable from "./vue/components/common/table/VTable.vue";
import VIcon from "./vue/components/common/ui/VIcon.vue";
import VTabs from "./vue/components/common/ui/VTabs.vue";
import VFooter from "./vue/components/common/form/VFooter.vue";
import VTextarea from "./vue/components/common/form/VTextarea.vue";
import VModal from "./vue/components/common/ui/VModal.vue";
import AjaxDeleteButton from "./vue/components/common/ui/AjaxDeleteButton.vue";
import DeleteConfirmationButton from "./vue/components/common/ui/DeleteConfirmationButton.vue";
import VNotification from "./vue/components/common/ui/VNotification.vue";
import VCheckbox from "./vue/components/common/form/VCheckbox.vue";
import VRadio from "./vue/components/common/form/VRadio.vue";
import VToggle from "./vue/components/common/ui/VToggle.vue";
import VTableToggle from "./vue/components/common/table/VTableToggle.vue";
import OpenSidePanelButton from "./vue/components/common/ui/OpenSidePanelButton.vue";
import CopyButtonButton from "./vue/components/common/ui/CopyButton.vue";
import VTagCloud from "./vue/components/common/ui/VTagCloud.vue";
import VFilterInfo from "./vue/components/common/ui/VFilterInfo.vue";
import VMediaGrid from "./vue/components/common/media/VMediaGrid.vue";
import UploadMedia from "./vue/components/common/media/UploadMedia.vue";
import VInputSearch from "./vue/components/common/form/VInputSearch.vue";
import VenueFilter from "./vue/components/common/booking/VenueFilter.vue";
import CustomerSelect from "./vue/components/common/booking/CustomerSelect.vue";
import VActions from "./vue/components/common/ui/VActions.vue";
import SummaryPreview from "./vue/components/venues/SummaryPreview.vue";
import RoomSummaryPreview from "./vue/components/rooms/SummaryPreview.vue";
import VGlobalSearch from "./vue/components/common/form/VGlobalSearch.vue";
import NotifyChangeModal from "./vue/components/common/ui/NotifyChangeModal.vue";
import SendWaiverConfirmationModal from "./vue/components/common/ui/SendWaiverConfirmationModal.vue";
import VoucherSelect from "./vue/components/common/gift-voucher/VoucherSelect.vue";
import SquarePayment from "./vue/components/common/booking/SquarePayment.vue";
import RefundInput from "./vue/components/common/booking/RefundInput.vue";
import DelayTeleport from "./vue/components/common/ui/DelayTeleport.vue";
import Payment from "~vue/components/common/payment/Payment.vue";
import VActionDropdownItem from "~vue/components/common/ui/VActionDropdownItem.vue";
import VTableTitle from "./vue/components/common/table/VTableTitle.vue";
import VTableEditableTitle from "./vue/components/common/table/VTableEditableTitle.vue";
import Percentage from "~vue/components/common/report/Percentage.vue";
import Collapsible from "./vue/components/common/collapsible/Collapsible.vue";
import CollapsibleTrigger from "./vue/components/common/collapsible/CollapsibleTrigger.vue";
import CollapsibleContent from "./vue/components/common/collapsible/CollapsibleContent.vue";
import Editable from "./vue/components/common/ui/Editable.vue";
import VActivityLog from "~vendor/activity-log/resources/js/components/VActivityLog.vue";
import ActivityEmail from "~vendor/activity-log/resources/js/components/ActivityEmail.vue";
import VTooltip from "~vue/components/common/ui/VTooltip.vue";
import VAvatar from "./vue/components/common/ui/Avatar.vue";
import ClientExportButton from "./vue/components/common/ui/ClientExportButton.vue";
import PhoneNumber from "~vue/components/common/form/PhoneNumber.vue";

import UserEdit from "./vue/components/users/UserEdit.vue";
import RoomEdit from "./vue/components/rooms/RoomEdit.vue";
import VenueEdit from "./vue/components/venues/VenueEdit.vue";
import ProductEdit from "./vue/components/products/ProductEdit.vue";
import VariantManagement from "./vue/components/products/VariantManagement.vue";
import OpenVariantEditButton from "./vue/components/products/OpenVariantEditButton.vue";
import LabelEdit from "./vue/components/labels/LabelEdit.vue";
import WaitListEdit from "./vue/components/wait-lists/WaitListEdit.vue";
import IntegrationEdit from "./vue/components/integration/Edit.vue";
import ShiftTemplateEdit from "~vue/components/shift-templates/ShiftTemplateEdit.vue";
import GiftVoucherEdit from "./vue/components/gift-vouchers/GiftVoucherEdit.vue";
import RefundConfirmation from "~vue/components/orders/RefundConfirmation.vue";
import CustomerEdit from "./vue/components/customers/CustomerEdit.vue";
import Availabilities from "./vue/components/availabilities/Availabilities.vue";
import FindAvailabilities from "./vue/components/availabilities/quick/FindAvailabilities.vue";
import QuickBookingDetails from "./vue/components/availabilities/quick/Details.vue";
import GiftVoucherFindAvailabilities from "./vue/components/gift-vouchers/quick/FindAvailabilities.vue";
import QuickGiftVoucherDetails from "./vue/components/gift-vouchers/quick/Details.vue";
import CategoryEdit from "./vue/components/products/CategoryEdit.vue";
import Bookings from "./vue/components/bookings/Bookings.vue";
import BookingCreate from "./vue/components/bookings/BookingCreate.vue";
import BookingConfirm from "./vue/components/bookings/BookingConfirm.vue";
import CancelBookingSelector from "./vue/components/bookings/CancelBookingSelector.vue";
import BookingCancel from "./vue/components/bookings/BookingCancel.vue";
import BookingReschedule from "./vue/components/bookings/BookingReschedule.vue";
import BookingDetail from "~vue/components/bookings/details/Details.vue";
import BookingNoteDetail from "./vue/components/bookings/BookingNoteDetail.vue";
import NotifyWaitlist from "./vue/components/wait-lists/NotifyWaitlist.vue";
import BookingFilters from "~vue/components/bookings/BookingFilters.vue";
import ReceptionFilter from "./vue/components/reception/Filters.vue";
import ShiftEdit from "./vue/components/rosters/ShiftEdit.vue";
import WaitListCreate from "~vue/components/wait-lists/WaitListCreate.vue";
import Tasks from "./vue/components/tasks/Tasks.vue";
import TaskEdit from "./vue/components/tasks/Edit.vue";
import PaymentDetails from "./vue/components/payments/PaymentDetails.vue";
import OrderDetails from "./vue/components/orders/Details.vue";
import GiftVoucherInstanceEdit from "./vue/components/gift-vouchers/GiftVoucherInstanceEdit.vue";
import DollarValuesGiftVoucherInstanceCreate from "~vue/components/gift-vouchers/DollarValuesGiftVoucherInstanceCreate.vue";
import BathPassGiftVoucherInstanceCreate from "./vue/components/gift-vouchers/BathPassGiftVoucherInstanceCreate.vue";
import VoucherInstanceDetails from "./vue/components/gift-vouchers/customers/VoucherInstanceDetails.vue";
import GiftVoucherReports from "./vue/components/reports/GiftVoucherReports.vue";
import AppointmentReports from "./vue/components/reports/AppointmentReports.vue";
import CustomerDuplicatesManagement from "./vue/components/customers/Duplicates.vue";
import CustomerBookings from "./vue/components/customers/Bookings.vue";
import DeviceManagement from "./vue/components/square-devices/DeviceManagement.vue";
import DeviceEdit from "./vue/components/square-devices/DeviceEdit.vue";
import BookingPay from "./vue/components/bookings/BookingPay.vue";
import VoucherPay from "./vue/components/gift-vouchers/VoucherPay.vue";
import NotificationEdit from "./vue/components/notifications/NotificationEdit.vue";
import WaivesAssociatedProduct from "./vue/components/waives/AssociatedProduct.vue";
import SortableCollapseProductList from "~vue/components/products/SortableCollapseProductList.vue";
import VCellCollapsibleProduct from "~vue/components/notifications/VCellCollapsibleProduct.vue";
import CollapsibleCustomerBookingList from "~vue/components/customers/CollapsibleCustomerBookingList.vue";
import SortableCollapseVoucherList from "~vue/components/gift-vouchers/SortableCollapseVoucherList.vue";
import ConfigurationForm from "~vue/components/configurations/ConfigurationForm.vue";
import AppointmentDetail from "~vue/components/appointments/Details.vue";
import CustomerInput from "~vue/components/customers/CustomerInput.vue";

import QuantityStepper from "~vue/components/common/booking/QuantityStepper.vue";
import StartBooking from "./vue/components/bookings/checkout/StartBooking.vue";
import BookingProduct from "./vue/components/bookings/checkout/Product.vue";
import BookingAddonProduct from "./vue/components/bookings/checkout/AddonProduct.vue";
import ProductList from "./vue/components/bookings/checkout/ProductList.vue";
import VariantList from "./vue/components/bookings/checkout/VariantList.vue";
import CheckoutDetail from "./vue/components/bookings/checkout/Detail.vue";
import BookingAvailability from "./vue/components/bookings/checkout/BookingAvailability.vue";
import VenueSelect from "./vue/components/bookings/checkout/VenueSelect.vue";
import Checkout from "./vue/components/bookings/checkout/Checkout.vue";
import CheckoutCartSummary from "./vue/components/bookings/checkout/CheckoutCartSummary.vue";
import CheckoutConfirmSummary from "./vue/components/bookings/checkout/CheckoutConfirmSummary.vue";

import VoucherSelection from "./vue/components/gift-vouchers/checkout/VoucherSelection.vue";
import VoucherList from "./vue/components/gift-vouchers/checkout/VoucherList.vue";
import VoucherCheckout from "./vue/components/gift-vouchers/checkout/VoucherCheckout.vue";
import VoucherCartSummary from "./vue/components/gift-vouchers/checkout/VoucherCartSummary.vue";
import VoucherDetail from "./vue/components/gift-vouchers/checkout/Detail.vue";
import VoucherPayment from "./vue/components/gift-vouchers/checkout/Payment.vue";
import VoucherCheckoutConfirmSummary from "./vue/components/gift-vouchers/checkout/CheckoutConfirmSummary.vue";

import ClientDetail from "./vue/components/client/Details.vue";
import ClientBookings from "./vue/components/client/Bookings.vue";
import ClientWaitLists from "./vue/components/client/WaitLists.vue";
import ClientPayments from "./vue/components/client/Payments.vue";
import ClientVouchers from "./vue/components/client/Vouchers.vue";
import ClientWaivers from "./vue/components/client/Waivers.vue";
import ClientHeader from "./vue/components/client/sections/Header.vue";
import ClientUserMenu from "./vue/components/client/sections/UserMenu.vue";
import ClientMobileMenu from "./vue/components/client/sections/MobileMenu.vue";
import ClientWaitListBooking from "~vue/components/client/ClientWaitListBooking.vue";
import ConfirmCancellationModal from "~vue/components/client/ConfirmCancellationModal.vue";

import "vue3-colorpicker/style.css";
import "../css/app.pcss";
import { config } from "~vue/configs";

const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: LARAVEL_TRANSLATIONS,
});

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
});
window.Vue = Vue;
window.Alpine = Alpine;
Alpine.start();
window.Bugsnag = Bugsnag;

const app = window.Vue.createApp({
  data() {
    return {
      csrf_token: document.head.querySelector('meta[name="csrf-token"]').content,
    };
  },
});

const bugsnagVue = Bugsnag.getPlugin("vue");
app.use(bugsnagVue);

app.use(store);
app.provide("bus", $bus);
app.provide("notificationService", new NotificationService($bus));
app.provide("sessionStorageService", new SessionStorageService());
app.provide("config", config);
app.directive("click-outside", clickOutside);

app.config.globalProperties.appConfigs = window.appConfigs;
$bus.$on("reloadAppConfigs", () => {
  axios.get("/api/generic/configs").then((response) => {
    app.config.globalProperties.appConfigs = response.data;
  });
});
app.config.globalProperties.toAUD = (amount) => {
  return Formatter.toAUD(amount);
};
app.config.globalProperties.formatDate = (amount, withHour = false) => {
  return Formatter.formatDate(amount, withHour);
};
app.config.globalProperties.numberFormat = (amount) => {
  return Formatter.numberFormat(amount);
};
app.config.globalProperties.slug = (name) => {
  return Formatter.slug(name);
};
app.config.globalProperties.word = (string) => {
  return Formatter.word(string);
};
app.config.globalProperties.classicEditor = ClassicEditor;
app.config.globalProperties.scroll = (checker, targetElement) => {
  return Scroller.scroll(checker, targetElement);
};

app.use(i18n);
app.use(CKEditor);

app.component("DatePicker", DatePicker);
app.component("VTable", VTable);
app.component("VSearch", VSearch);
app.component("VFilter", VFilter);
app.component("InputWrapper", InputWrapper);
app.component("VBadge", VBadge);
app.component("VTableStatus", VTableStatus);
app.component("VMultiSelect", VMultiSelect);
app.component("SidePanel", SidePanel);
app.component("VTablePanelButton", VTablePanelButton);
app.component("VMenu", VMenu);
app.component("VHeader", VHeader);
app.component("VProfile", VProfile);
app.component("VTabs", VTabs);
app.component("VIcon", VIcon);
app.component("VFooter", VFooter);
app.component("VTextarea", VTextarea);
app.component("VModal", VModal);
app.component("AjaxDeleteButton", AjaxDeleteButton);
app.component("DeleteConfirmationButton", DeleteConfirmationButton);
app.component("VNotification", VNotification);
app.component("VCheckbox", VCheckbox);
app.component("VRadio", VRadio);
app.component("VToggle", VToggle);
app.component("OpenSidePanelButton", OpenSidePanelButton);
app.component("CopyButton", CopyButtonButton);
app.component("VTableToggle", VTableToggle);
app.component("VTagCloud", VTagCloud);
app.component("VFilterInfo", VFilterInfo);
app.component("VMediaGrid", VMediaGrid);
app.component("UploadMedia", UploadMedia);
app.component("VActivityLog", VActivityLog);
app.component("ActivityEmail", ActivityEmail);
app.component("VInputSearch", VInputSearch);
app.component("VenueFilter", VenueFilter);
app.component("CustomerSelect", CustomerSelect);
app.component("VActions", VActions);
app.component("VActionDropdownItem", VActionDropdownItem);
app.component("VGlobalSearch", VGlobalSearch);
app.component("NotifyChangeModal", NotifyChangeModal);
app.component("SendWaiverConfirmationModal", SendWaiverConfirmationModal);
app.component("VoucherSelect", VoucherSelect);
app.component("SquarePayment", SquarePayment);
app.component("Payment", Payment);
app.component("RefundInput", RefundInput);
app.component("DelayTeleport", DelayTeleport);
app.component("VTableTitle", VTableTitle);
app.component("VTableEditableTitle", VTableEditableTitle);
app.component("Percentage", Percentage);
app.component("Collapsible", Collapsible);
app.component("CollapsibleTrigger", CollapsibleTrigger);
app.component("CollapsibleContent", CollapsibleContent);
app.component("Editable", Editable);
app.component("VTooltip", VTooltip);
app.component("VAvatar", VAvatar);
app.component("ClientExportButton", ClientExportButton);
app.component("PhoneNumber", PhoneNumber);

app.component("VenueSummaryPreview", SummaryPreview);
app.component("RoomSummaryPreview", RoomSummaryPreview);
app.component("VenueEdit", VenueEdit);
app.component("LabelEdit", LabelEdit);
app.component("NotificationEdit", NotificationEdit);
app.component("UserEdit", UserEdit);
app.component("ProductEdit", ProductEdit);
app.component("VariantManagement", VariantManagement);
app.component("OpenVariantEditButton", OpenVariantEditButton);
app.component("RoomEdit", RoomEdit);
app.component("IntegrationEdit", IntegrationEdit);
app.component("ShiftTemplateEdit", ShiftTemplateEdit);
app.component("WaitListEdit", WaitListEdit);
app.component("GiftVoucherEdit", GiftVoucherEdit);
app.component("CustomerEdit", CustomerEdit);
app.component("Availabilities", Availabilities);
app.component("FindAvailabilities", FindAvailabilities);
app.component("QuickBookingDetails", QuickBookingDetails);
app.component("GiftVoucherFindAvailabilities", GiftVoucherFindAvailabilities);
app.component("QuickGiftVoucherDetails", QuickGiftVoucherDetails);
app.component("CategoryEdit", CategoryEdit);
app.component("Bookings", Bookings);
app.component("BookingCreate", BookingCreate);
app.component("BookingConfirm", BookingConfirm);
app.component("BookingDetail", BookingDetail);
app.component("CancelBookingSelector", CancelBookingSelector);
app.component("BookingCancel", BookingCancel);
app.component("BookingReschedule", BookingReschedule);
app.component("BookingNoteDetail", BookingNoteDetail);
app.component("NotifyWaitlist", NotifyWaitlist);
app.component("BookingFilters", BookingFilters);
app.component("ReceptionFilter", ReceptionFilter);
app.component("ShiftEdit", ShiftEdit);
app.component("Tasks", Tasks);
app.component("WaitListCreate", WaitListCreate);
app.component("TaskEdit", TaskEdit);
app.component("PaymentDetails", PaymentDetails);
app.component("OrderDetails", OrderDetails);
app.component("RefundConfirmation", RefundConfirmation);
app.component("GiftVoucherInstanceEdit", GiftVoucherInstanceEdit);
app.component("DollarValuesGiftVoucherInstanceCreate", DollarValuesGiftVoucherInstanceCreate);
app.component("BathPassGiftVoucherInstanceCreate", BathPassGiftVoucherInstanceCreate);
app.component("VoucherInstanceDetails", VoucherInstanceDetails);
app.component("GiftVoucherReports", GiftVoucherReports);
app.component("AppointmentReports", AppointmentReports);
app.component("CustomerDuplicatesManagement", CustomerDuplicatesManagement);
app.component("CustomerBookings", CustomerBookings);
app.component("DeviceManagement", DeviceManagement);
app.component("DeviceEdit", DeviceEdit);
app.component("BookingPay", BookingPay);
app.component("VoucherPay", VoucherPay);
app.component("WaivesAssociatedProduct", WaivesAssociatedProduct);
app.component("SortableCollapseProductList", SortableCollapseProductList);
app.component("VCellCollapsibleProduct", VCellCollapsibleProduct);
app.component("CollapsibleCustomerBookingList", CollapsibleCustomerBookingList);
app.component("SortableCollapseVoucherList", SortableCollapseVoucherList);
app.component("ConfigurationForm", ConfigurationForm);
app.component("AppointmentDetail", AppointmentDetail);
app.component("CustomerInput", CustomerInput);

app.component("QuantityStepper", QuantityStepper);
app.component("StartBooking", StartBooking);
app.component("BookingProduct", BookingProduct);
app.component("BookingAddonProduct", BookingAddonProduct);
app.component("ProductList", ProductList);
app.component("VariantList", VariantList);
app.component("CheckoutDetail", CheckoutDetail);
app.component("BookingAvailability", BookingAvailability);
app.component("VenueSelect", VenueSelect);
app.component("Checkout", Checkout);
app.component("CheckoutCartSummary", CheckoutCartSummary);
app.component("CheckoutConfirmSummary", CheckoutConfirmSummary);

app.component("VoucherSelection", VoucherSelection);
app.component("VoucherList", VoucherList);
app.component("VoucherCheckout", VoucherCheckout);
app.component("VoucherCartSummary", VoucherCartSummary);
app.component("VoucherDetail", VoucherDetail);
app.component("VoucherPayment", VoucherPayment);
app.component("VoucherCheckoutConfirmSummary", VoucherCheckoutConfirmSummary);

app.component("ClientDetail", ClientDetail);
app.component("ClientBookings", ClientBookings);
app.component("ClientWaitLists", ClientWaitLists);
app.component("ClientPayments", ClientPayments);
app.component("ClientVouchers", ClientVouchers);
app.component("ClientWaivers", ClientWaivers);
app.component("ClientHeader", ClientHeader);
app.component("ClientUserMenu", ClientUserMenu);
app.component("ClientMobileMenu", ClientMobileMenu);
app.component("ClientWaitListBooking", ClientWaitListBooking);
app.component("ConfirmCancellationModal", ConfirmCancellationModal);

app.mount("#vueApp");
