<template>
  <label class="flex items-center space-x-xsSpace">
    <input
      class="cursor-pointer rounded border border-tertiary-300"
      type="radio"
      :class="inputClass"
      :name="name"
      :value="value"
      :checked="value === modelValue"
    />
    <span class="cursor-pointer text-xs text-tertiary-700">{{ labelText }}</span>
  </label>
</template>

<script>
export default {
  name: "VRadio",
  props: {
    labelText: {
      type: String,
    },
    modelValue: {
      type: [Number, String],
      default: null,
    },
    value: {
      type: [Number, String],
    },
    name: {
      type: String,
    },
    inputClass: {
      type: String,
    },
  },
};
</script>
