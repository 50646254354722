<template>
  <div class="flex flex-col space-y-lgSpace py-2lgSpace text-white">
    <p class="text-center font-sloganSerif text-6xl font-bold">{{ $t("booking.responses.completed") }}</p>
    <div class="text-center">
      <p class="text-xl font-normal">{{ $t("booking.responses.completed_subtitle") }}</p>
      <p class="text-xl font-normal">{{ subtitle }}</p>
    </div>
    <div class="flex flex-col space-y-lgSpace pb-lgSpace">
      <div class="flex flex-col space-y-mdSpace rounded-lg border border-white bg-brand p-smSpace">
        <div class="flex flex-col space-y-smSpace text-white">
          <span class="text-[19px] font-semibold">{{ $t("booking.headings.order_summary") }}</span>
          <div class="flex flex-col space-y-smSpace rounded-lg bg-white bg-opacity-10 p-smSpace">
            <span class="text-[19px] font-semibold">{{ cart.product.name }}</span>
            <div class="flex flex-col">
              <span class="text-xs font-normal text-white/80">{{ $t("booking.labels.option") }}:</span>
              <span class="text-base"
                >{{ cart.product_variant.name }} (${{ cart.product_variant.price_per_unit }})</span
              >
            </div>
            <div class="flex flex-col" v-if="cart.appointment">
              <span class="text-xs font-normal text-white/80">{{ $t("booking.labels.schedule") }}:</span>
              <span class="text-base">{{ cart.appointment.readable_period }}</span>
            </div>
          </div>
          <div
            v-if="cart.hasOwnProperty('add_on_appointment') && cart.hasOwnProperty('add_on_product')"
            class="flex flex-col space-y-smSpace rounded-lg bg-white bg-opacity-10 p-smSpace"
          >
            <div class="flex flex-col">
              <span class="text-xs font-normal text-white/80">{{ $t("booking.labels.add_on") }}:</span>
              <span class="text-[19px] font-semibold">{{ cart.add_on_product?.name }}</span>
            </div>
            <div class="flex flex-col">
              <span class="text-xs font-normal text-white/80">{{ $t("booking.labels.option") }}:</span>
              <span class="text-base"
                >{{ cart.add_on_product_variant?.name }} (${{ cart.add_on_product_variant?.price_per_unit }})</span
              >
            </div>
            <div class="flex flex-col">
              <span class="text-xs font-normal text-white/80">{{ $t("booking.labels.schedule") }}:</span>
              <span class="text-base">{{ cart.add_on_appointment?.readable_period }}</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col space-y-smSpace divide-y text-sm text-white">
          <div class="flex flex-col space-y-xsSpace">
            <span class="text-lg font-bold">{{ $t("booking.columns.total_price") }}</span>
            <div class="flex justify-between">
              <span>{{ $t("booking.labels.unit_price") }}</span>
              <span>{{ toAUD(cart.product_variant.price_per_unit) }}</span>
            </div>
            <div v-if="cart.hasOwnProperty('add_on_appointment')" class="flex justify-between">
              <span>{{ $t("booking.labels.add_on") }}</span>
              <span>{{ toAUD(cart.add_on_product_variant?.price_per_unit) }}</span>
            </div>
            <div class="flex justify-between">
              <span>{{ $t("booking.labels.quantity") }}</span>
              <span>{{ cart.quantity }}</span>
            </div>
            <div class="flex justify-between">
              <span>{{ $t("booking.labels.subtotal") }}</span>
              <span>{{ toAUD(cart.sub_total) }}</span>
            </div>
          </div>
          <div v-if="cart.vouchers_info.length" class="flex flex-col space-y-xsSpace pt-smSpace">
            <div :key="index" v-for="(voucher, index) in cart.vouchers_info" class="flex justify-between">
              <span>{{ $t("booking.labels.voucher") }}({{ voucher.code }})</span>
              <span v-if="!voucher.is_bath_pass">-{{ toAUD(voucher.discount) }}</span>
              <span v-else
                >{{ voucher.applicable_quantity }}
                {{ $t("generic.use") + (voucher.applicable_quantity > 1 ? "s" : "") }}
                {{ $t("gift_voucher.labels.redeemed") }}</span
              >
            </div>
          </div>
          <div v-else class="flex flex-col space-y-xsSpace pt-smSpace">
            <div class="flex justify-between">
              <span>{{ $t("booking.labels.voucher") }}</span>
              <span class="text-xs text-white/50">{{ $t("booking.labels.no_voucher_applied") }}</span>
            </div>
          </div>
          <div class="flex flex-col pt-smSpace">
            <div class="flex justify-between font-semibold">
              <span>{{ $t("booking.labels.total") }}</span>
              <span>{{ toAUD(cart.total) }}</span>
            </div>
            <div class="flex justify-end">
              <span class="text-xs text-white/50"
                >{{ $t("booking.labels.includes_gst") }} {{ toAUD(cart.sub_total - cart.sub_total / 1.1) }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckoutCartSummary from "./CheckoutCartSummary.vue";

export default {
  name: "CheckoutConfirmSummary",
  extends: CheckoutCartSummary,
  props: {
    initCart: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cart: this.initCart,
    };
  },
  computed: {
    subtitle() {
      const isBathing = !!this.cart.product?.is_belonged_to_bathhouse;
      if (this.cart.add_on_product_variant) {
        return this.$t("booking.responses.massage_and_bathhouse_completed_subtitle");
      } else if (isBathing) {
        return this.$t("booking.responses.bathhouse_completed_subtitle");
      } else {
        return this.$t("booking.responses.massage_completed_subtitle");
      }
    },
  },
  methods: {
    async getCart() {},
  },
};
</script>

<style scoped>
input::placeholder {
  @apply !text-white opacity-60;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}
</style>
