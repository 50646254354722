<template>
  <draggable
    v-model="innerProducts"
    group="product"
    item-key="id"
    handle=".dragging-cursor"
    class="mt-xsSpace table w-full"
    @change="handleChange"
  >
    <template #header>
      <tr key="header" class="table-row w-full bg-tertiary-100 font-medium">
        <div
          v-for="(field, index) in fields"
          class="table-cell"
          :class="index === 0 ? field.dataClass.replace('border-t', '') : field.dataClass"
          :key="index"
          :style="{ width: field?.width }"
        >
          <span class="flex">{{ field.title }}</span>
        </div>
      </tr>
    </template>
    <template #item="{ element }" :key="element.id">
      <tr :key="element.id" :id="`draggable-product-${element.id}`" class="draggable-item relative table-row divide-y">
        <td v-for="(field, index) in fields" :class="field.dataClass" :key="index">
          <template v-if="field.hasOwnProperty('name')">
            <v-table-actions
              v-if="field.name === 'actions'"
              :key="`${index} + ${Math.floor(Math.random() * 100)}`"
              :data="{ rowData: element, rowIndex: index }"
            />
            <div class="dragging-cursor" v-else-if="field.name === 'icon'">
              <v-icon icon="ArrowsUpDownIcon" class="cursor-move text-gray-400 hover:text-gray-800"></v-icon>
            </div>
            <v-table-title
              v-else-if="field.field === 'name'"
              :row-data="element"
              :row-field="field"
              :row-index="index"
              editable
              @update:model-value=""
            ></v-table-title>
            <component
              v-else
              :is="field.name.replace('__component:', '')"
              :row-data="element"
              :row-field="field"
              :row-index="index"
            ></component>
          </template>
          <div v-else-if="field.hasOwnProperty('field')">
            <span class="flex">{{ element[field.field] }}</span>
          </div>
          <div v-else>
            <span class="flex"></span>
          </div>
        </td>
      </tr>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import VTableActions from "@/js/vue/components/common/table/VTableActions.vue";
import VTableEditableTitle from "~vue/components/common/table/VTableEditableTitle.vue";
import Form from "form-backend-validation";

export default {
  name: "ProductList",
  inject: ["config"],
  components: { VTableEditableTitle, VTableActions, draggable },
  props: {
    categoryId: {
      type: [String, Number],
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dragging: false,
      innerProducts: this.products,
      nameForm: new Form(
        {
          update_data: {},
          model_class: this.config.modelClasses.product,
          model_id: null,
        },
        { resetOnSuccess: false },
      ),
    };
  },
  methods: {
    handleChange(changes) {
      if (changes.added) {
        this.$emit("onMove", this.categoryId, changes.added.element.id, changes.added.newIndex);
      }

      if (changes.removed) {
        this.$emit("onMove", this.categoryId, changes.removed.element.id);
      }

      if (changes.moved) {
        this.$emit(
          "onReorder",
          this.categoryId,
          this.innerProducts.map((p) => p.id),
        );
      }
    },
    handleRenameProduct(newName, id) {
      if (!id) return;

      this.nameForm
        .populate({ update_data: { name: newName }, model_id: id, model_class: this.config.modelClasses.product })
        .put("/api/admin/generic/update")
        .then(() => {
          this.innerProducts = [
            ...this.innerProducts.map((product) => {
              if (product.id === id) {
                product.name = newName;
              }

              return product;
            }),
          ];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
