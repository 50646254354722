const Scroller = {
  scroll(checker, targetElement) {
    if (!checker) {
      return;
    }

    if (!targetElement) {
      return;
    }

    setTimeout(() => {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }, 500);
  },
};

export default Scroller;
