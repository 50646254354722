<template>
  <div class="relative -ml-4" :style="`height:${screenHeight}`">
    <div
      v-if="venueId"
      class="absolute left-0 top-24 grid divide-y-2 divide-tertiary-300 bg-tertiary-200 pb-6 pl-xsSpace"
      :class="{ '!top-0 pl-0 pt-[18px]': forPreview }"
      :style="`grid-template-rows: repeat(34, ${zoom * 5}rem) auto`"
    >
      <div class="row-end-1 h-10"></div>
      <template v-for="(item, index) in timeLine">
        <div :id="'time_line' + item.id" :style="`width: ${width}px`">
          <p
            class="sticky -my-smSpace w-10 rounded border-2 border-blue-400 bg-white text-center text-xs leading-5"
            :class="'-left-0'"
            style="z-index: 34"
            v-text="item.value"
          ></p>
        </div>
        <div v-if="index !== Object.keys(timeLine).length - 1" :style="`width: ${width}px`">
          <p class="sticky left-0 -my-smSpace w-10 bg-white text-xs leading-5" style="z-index: 33"></p>
        </div>
      </template>
    </div>
    <div
      v-if="venueId"
      class="sticky top-[144px] grid bg-tertiary-200 pl-[56px]"
      :class="{ '!top-0': forPreview }"
      :style="{
        'z-index': '34',
        width: width + 12 + 'px',
        'column-gap': columnGap,
        'grid-template-columns': !isExpanded
          ? `repeat( ${roomTimeSlot.length}, ${headerWidth}px)`
          : 'repeat(' + roomTimeSlot.length + ', max-content)',
      }"
    >
      <template v-for="item in roomTimeSlotHeader">
        <div
          v-if="item.name"
          class="relative mr-6 flex w-[325px] items-center justify-center rounded border-2 border-blue-400 bg-white py-3xsSpace text-sm font-semibold"
          :class="{
            '!mr-0.5 !w-[210px]': zoom <= 0.4,
            '!mr-0.5 !w-[250px]': zoom === 0.6,
            '!py-0': item.staff,
            '!w-full': !isExpanded,
          }"
          :style="!isExpanded ? { 'grid-column': item.start_column + '/ span ' + item.end_column } : ''"
        >
          <div class="flex h-[33px] items-center gap-1 text-xs">
            <span
              class="absolute left-0 top-0 rounded-br bg-blue-400 pl-[3px] pr-2xsSpace text-center text-white"
              v-if="item.slot_no && isExpanded"
              >{{ item.slot_no }}
            </span>
            <span v-if="item.room_admin_only" class="w-6 flex-shrink-0 rounded bg-red-500 px-0.5 text-[6px] text-white">
              {{ $t("generic.words.admin") }}
            </span>
            <span
              class="flex-1 cursor-default overflow-hidden text-ellipsis whitespace-nowrap text-center"
              :class="{ 'pr-6': item.room_admin_only }"
              v-html="item.name"
            ></span>
            <div
              class="absolute right-0 top-0 flex h-full w-[30px] flex-col items-center justify-center border-l-2 border-blue-400"
              v-if="forDefault"
            >
              <v-toggle
                class="flex items-center justify-center px-3xsSpace"
                :class="{ 'h-full border-b-0': !isAdminOrManager }"
                :ring="false"
                :value="item.is_online"
                :small="true"
                @input="onChangeRoomStatus($event, item)"
              ></v-toggle>
            </div>
          </div>
        </div>
      </template>
    </div>
    <main class="mx-auto min-h-screen flex-1" :style="`padding-top:${position}px;`">
      <div
        v-if="venueId"
        class="grid flex-auto"
        :class="{
          'absolute top-0 h-full w-full cursor-not-allowed rounded-md bg-slate-50 opacity-50': loading,
        }"
        :style="`width: ${width}px`"
      >
        <ol class="absolute left-14 grid" :style="`grid-template-rows: repeat(70, ${zoom * 2.5}rem) auto`">
          <template v-for="item in roomTimeSlot">
            <template v-for="slot in item.slots">
              <li
                :id="`${item.room_id}-${item.capacity_index}-${slot.index}-appointment`"
                class="relative z-10 mr-6 w-[325px]"
                :class="wrapperClasses(slot, item)"
                :style="'grid-row: ' + (slot.index + 2) + '/ span 1;'"
                @click="resizeable($event, slot, item)"
              >
                <div
                  class="h-full w-full"
                  @dragstart="dragSlot($event, slot)"
                  @dragend="dragEnd($event)"
                  @dragleave="dragLeave($event, slot)"
                  @drop="drop($event, slot, item)"
                  :draggable="forDefault && currentDrag && currentDrag.id === slot.id"
                  @dragover="dragOver($event, slot, item)"
                >
                  <span class="time absolute text-sm" style="opacity: 0">
                    {{ slot.value_full || slot.value }}
                  </span>
                  <div
                    :id="`cell-${item.room_id}-${slot.index}-tooltip`"
                    class="flex hidden w-[200px] -translate-x-1.5 -translate-y-full items-center justify-center rounded-md bg-gray-800 p-smSpace text-xs text-white opacity-100 transition-opacity"
                  >
                    <p>{{ $t("booking.validation.product_is_not_available") }}</p>
                  </div>
                  <div
                    v-if="slot.position === 'top' && forDefault && (isExpanded || !slot.is_belonged_to_bathhouse)"
                    class="absolute -top-10 w-full flex-row items-center justify-between bg-tertiary-200 px-xsSpace text-sm"
                    :class="{
                      '!-top-8 !text-xs': zoom === 0.6,
                      '!-top-6 !px-3xsSpace !text-xs': zoom <= 0.4,
                    }"
                  >
                    <!--
                     // This code block has been updated based on Ticket: https://kanopi.live/app/ticket/47360
                    <div
                      v-else-if="slot.hasOwnProperty('uncovered') && !slot.uncovered"
                      class="flex w-full items-center justify-between"
                    >
                      <p v-if="forDefault && slot.staff_name" class="w-9 px-3xsSpace font-semibold">
                        {{
                          slot.staff_name
                            .split(" ")
                            .map((word) => word.charAt(0))
                            .join("")
                        }}
                      </p>
                      <p class="block truncate">{{ slot.shift_name }}</p>
                      <div v-if="!forPreview">
                        <v-toggle>
                          :value="slot.is_shift_online" :title="slot.is_shift_online ? 'Online' : 'Offline'"
                          :small="zoom === 0.4" @input="onChangeRoomStatus($event, slot)" ></v-toggle
                        >
                        <v-actions
                          v-if="slot.is_shift_has_booking && !slot.is_shift_rescheduled"
                          :classes="'-left-[62px] top-[6px] w-[80px] flex justify-center text-xs'"
                        >
                          <template v-slot:dropdown>
                            <ul class="divide-y font-medium">
                              <li>
                                <a
                                  @click="reschedule(slot)"
                                  class="flex w-full cursor-pointer items-center space-x-xsSpace rounded px-xsSpace py-2xsSpace text-tertiary-600 hover:text-tertiary-900"
                                >
                                  {{ $t("generic.buttons.reschedule") }}
                                </a>
                              </li>
                            </ul>
                          </template>
                        </v-actions>
                      </div>
                    </div> -->
                  </div>
                  <div
                    v-if="
                      (slot.name || slot.type === 'appointment') &&
                      (!currentDrag.id || (currentDrag.id && !slot.uncovered))
                    "
                    class="group relative"
                  >
                    <card
                      :booking-class="bookingClass"
                      :current-user="currentUser"
                      :appointment="slot"
                      :item="item"
                      :current-zoom="zoom"
                      :current-date="filters['filter[date]']"
                      :arrival-statuses="arrivalStatuses"
                      @onMoveCard="moveCard($event)"
                    >
                      <div
                        v-if="item.is_last_capacity && slot.type === 'appointment'"
                        @click="addAppointment(slot, item, true)"
                        class="absolute -right-2 h-full w-2 cursor-cell rounded-r-full bg-blue-400 hover:bg-indigo-500"
                      ></div>
                    </card>
                    <span
                      class="absolute -top-5 left-1/2 hidden -translate-x-1/2 rounded-sm bg-neutral-700 p-3xsSpace text-xs text-white group-hover:block"
                    >
                      {{ slot.value_full || slot.value }}
                    </span>
                  </div>
                  <div
                    v-else-if="allowToAdd(slot, item.slots)"
                    class="absolute -top-[10px] left-1/2 flex -translate-x-1/2 cursor-pointer flex-row rounded bg-tertiary-100 opacity-0 hover:bg-white hover:text-tertiary-800 hover:opacity-100"
                  >
                    <span class="absolute -left-10 top-1 text-xs" :class="{ '!-left-16': Boolean(slot.value_full) }">
                      {{ slot.value_full || slot.value }}
                    </span>
                    <a
                      class="rounded-l px-2xsSpace hover:bg-primary-200"
                      :class="{ 'rounded-r': !isExpanded && item.is_bathhouse_room }"
                    >
                      <v-icon icon="PlusIcon" @click="addAppointment(slot, item)"></v-icon>
                    </a>
                    <a
                      class="rounded-r px-2xsSpace hover:bg-primary-200"
                      :class="{ 'rounded-l': !isAdminOrManager }"
                      v-if="isExpanded || (!item.is_bathhouse_room && !isExpanded)"
                    >
                      <v-icon icon="PencilSquareIcon" @click="addBookingNote(slot, item)"></v-icon>
                    </a>
                  </div>
                </div>
              </li>
            </template>
          </template>
        </ol>
      </div>
      <div v-else class="flex min-h-screen items-center justify-center">
        {{ $t("booking.headings.null_venue") }}
      </div>
    </main>
  </div>
</template>

<script>
import moment from "moment/moment";
import axios from "axios";
import { cloneDeep, debounce, pick, sumBy } from "lodash";
import Card from "./Card.vue";
import { mapState } from "vuex";

export default {
  inject: ["bus", "notificationService", "config"],
  components: { Card },
  props: {
    bookingClass: {
      type: String,
      default: "",
    },
    currentUser: {
      type: Object,
    },
    mode: {
      type: String,
      default: "default",
    },
    arrivalStatuses: {
      type: Array,
      default: () => {
        return [];
      },
    },
    previewVenueId: {
      type: String,
      required: false,
    },
    previewRoomId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      active: false,
      startTime: "6:00",
      endTime: "23:00",
      filters: {
        "filter[date]": moment().format(this.config.DATETIME_FORMAT),
      },
      timeLine: [],
      venueId: null,
      roomId: null,
      loading: false,
      addingAppointment: false,
      defaultTimeSlot: [],
      roomTimeSlot: [],
      shifts: {},
      currentDrag: {
        id: null,
        purpose: null,
        original_capacity_index: null,
        original_room_id: null,
        startIndex: null,
        stopIndex: null,
        bookingId: null,
        item: null,
      },
      currentResize: {
        resizeType: null,
        bookingData: null,
        rowData: null,
        fromTopPosition: null,
        fromBottomPosition: null,
        toResizeableIndex: null,
      },
      search: debounce(this.getBookings, 200),
      checkOverlappingTypes: ["break", "appointment", "note"],
      openBookingId: new URL(location.href).searchParams.get("booking_id"),
    };
  },
  computed: {
    ...mapState({
      isExpanded: (state) => state.booking.isExpanded,
      zoom: (state) => state.booking.zoom,
      forDefault: (state) => state.booking.forDefault,
      forPreview: (state) => state.booking.forPreview,
    }),
    columnGap() {
      if (this.isExpanded) {
        return 0;
      }

      switch (this.zoom) {
        case 1:
        case 0.8:
          return "24px";
        case 0.6:
          return "1.6px";
        case 0.4:
          return "2px";
        default:
          return "0";
      }
    },
    roomTimeSlotHeader() {
      if ((this.forDefault || this.forPreview) && !this.isExpanded && this.roomTimeSlot.length) {
        return this.roomTimeSlot.reduce((m, o) => {
          const found = m.find((p) => p.room_id === o.room_id);
          if (found) {
            found.end_column = found.end_column + 1;
          } else {
            o.start_column = o.columnIndex === 0 ? 1 : o.columnIndex + 1;
            o.end_column = 1;
            m.push(o);
          }
          return m;
        }, []);
      }
      return this.roomTimeSlot;
    },
    width() {
      let unit = this.roomTimeSlot.length;
      if (!unit || unit < 10) {
        unit = 10;
      }

      return (this.zoom <= 0.4 ? 220 : 360) * (unit + 3);
    },
    screenHeight() {
      switch (this.zoom) {
        case 1:
          return "2500px";
        case 0.8:
          return "2360px";
        case 0.6:
          return "1810px";
        case 0.4:
          return "1200px";
        default:
          return "100%";
      }
    },
    headerWidth() {
      switch (this.zoom) {
        case 1:
        case 0.8:
          return 325;
        case 0.6:
          return 250.4;
        default:
          return 210;
      }
    },
    position() {
      if (this.forPreview) {
        return 0;
      }

      switch (this.zoom) {
        case 0.8:
          return 64;
        case 0.6:
          return 72;
        case 0.4:
          return 81;
        default:
          return 56;
      }
    },
    endpoint() {
      return `/api/admin/bookings?mode=${this.mode}&expanded=${this.isExpanded}`;
    },
    lengthDuration() {
      switch (this.zoom) {
        case 0.8:
          return 32;
        case 0.6:
          return 24;
        case 0.4:
          return 16;
        default:
          return 40.125;
      }
    },
    roles() {
      return this.currentUser?.roles.map((role) => role.name) || [];
    },
    isAdminOrManager() {
      if (!this.roles) {
        return false;
      }

      return (
        this.roles.includes(this.config.roles.admin.toLowerCase()) ||
        this.roles.includes(this.config.roles.manager.toLowerCase())
      );
    },
  },
  mounted() {
    this.$store.dispatch("booking/setMode", this.mode);
    this.createDefaultTimeLine();
    if (this.openBookingId) {
      this.bus.$emit("openSidePanel", {
        componentName: "BookingDetail",
        title: "",
        componentData: {
          bookingId: parseInt(this.openBookingId, 10),
          bookingClass: this.config.taskTypes.booking,
          currentUser: this.currentUser,
          showNewScheduledBooking: true,
          showAddTask: true,
        },
      });
      this.openBookingId = null;
    }
  },
  beforeUnmount() {
    this.bus.$off("tableFilterChange");
    this.bus.$off("bookingsChange");
  },
  created() {
    this.bus.$on("bookingsChange", (params) => {
      if (params.hasOwnProperty("column") && this.isExpanded) {
        this.reloadColumn(params.column.room_id, params.column.capacity_index);
        return;
      }
      if (params.hasOwnProperty("column") && !this.isExpanded) {
        this.search();
        return;
      }

      if (!params.hasOwnProperty("columnIndex")) {
        return;
      }
      const index = params.columnIndex;
      if (params.hasOwnProperty("appointment")) {
        this.allocateAppointment(params.appointment, index);
      }
      if (params.hasOwnProperty("note")) {
        this.allocateNote(params.note, index);
      }
      if (params.hasOwnProperty("delete")) {
        this.deleteAppointment(params.delete, index);
      }
      if (params.hasOwnProperty("toggleStatus")) {
        this.toggleStatusAppointment(params.toggleStatus, index);
      }
    });

    this.venueId =
      this.forPreview && !!this.previewVenueId
        ? this.previewVenueId
        : localStorage.getItem("venue_id")
        ? Number(localStorage.getItem("venue_id"))
        : null;
    this.filters["filter[venue]"] = this.venueId;

    this.roomId = this.forPreview && !!this.previewRoomId ? this.previewRoomId : null;
    this.filters["filter[rooms]"] = this.roomId;

    this.bus.$on("tableFilterChange", ({ params }) => {
      if (Object.keys(params).length) {
        this.filters = params;
        this.venueId = params.hasOwnProperty("filter[venue]") ? params["filter[venue]"] : this.venueId;
        this.roomId = params.hasOwnProperty("filter[rooms]") ? params["filter[rooms]"] : this.roomId;
        this.filters["filter[venue]"] = this.venueId;
        this.filters["filter[rooms]"] = this.roomId;
      }

      this.search();
    });
    this.createDefaultTimeLine();
  },
  watch: {
    isExpanded(newValue, oldValue) {
      this.search();
    },
  },
  methods: {
    addAppointment(slot, item, newCapacity = false) {
      if (this.addingAppointment) {
        return;
      }

      this.addingAppointment = true;
      axios
        .post(`/api/admin/rooms/${item.room_id}/appointments`, {
          capacity_index:
            !this.isExpanded && (!slot.hasOwnProperty("is_belonged_to_bathhouse") || slot.is_belonged_to_bathhouse)
              ? null
              : item.capacity_index,
          venue_id: item.venue_id,
          start_at: this.filters["filter[date]"] + " " + slot.value,
          new_capacity: newCapacity,
          duration: slot.duration,
        })
        .then(({ data }) => {
          this.addingAppointment = false;
          this.notificationService.notify(this.$t("generic.responses.create_success"));
          this.bus.$emit("openSidePanel", {
            componentName: "BookingCreate",
            componentData: {
              useDuration: false,
              room: item,
              appointment: data,
              currentDate: this.filters["filter[date]"],
              currentUser: this.currentUser,
            },
            closeCallback: () => {
              this.bus.$emit("openModal", {
                componentData: this.$t("booking.headings.do_you_want_to_remove_appointment"),
                cancelTitle: this.$t("generic.words.no"),
                confirmTitle: this.$t("generic.words.yes"),
                callback: () => {
                  axios.get(`/api/admin/appointments/${data.id}`).then(({ data }) => {
                    if (!data.data.is_booked) {
                      axios.delete(`/api/admin/appointments/${data.data.id}`).then(() => {
                        this.deleteAppointment(data.data, item.columnIndex);
                      });
                    }
                  });
                },
              });
            },
          });

          if (newCapacity || !this.isExpanded) {
            this.bus.$emit("tableFilterChange", { params: {} });
            return;
          }
          this.reloadColumn(data.room_id, data.capacity_index);
        })
        .catch(() => {
          this.addingAppointment = false;
        });
    },
    addBookingNote(slot, item) {
      axios
        .post(`/api/admin/rooms/${item.room_id}/booking-notes`, {
          capacity_index: item.capacity_index,
          venue_id: item.venue_id,
          start_at: this.filters["filter[date]"] + " " + slot.value,
        })
        .then(() => {
          this.notificationService.notify(this.$t("generic.responses.create_success"));
          this.getBookings();
        });
    },
    allowToAdd(slot, slots) {
      let visible = true;
      slots
        .filter((o) => {
          return o.type === "appointment";
        })
        .map((o) => {
          if (!this.isExpanded && o.hasOwnProperty("is_belonged_to_bathhouse") && o.is_belonged_to_bathhouse) {
            return [o.index, o.index + 1];
          }
          return [o.index - 3, o.index + o.duration / 15];
        })
        .every((o) => {
          if (o[0] <= slot.index && slot.index <= o[1]) {
            visible = false;
            return false;
          }
          return true;
        });

      return (
        visible &&
        !this.forPreview &&
        (this.forDefault || !this.isExpanded) &&
        slot.index > 0 &&
        slot.index < 65 &&
        !this.currentDrag.id
      );
    },
    moveCard($event) {
      if ($event) {
        this.clearResizeItem();
        this.currentDrag.id = $event.id;
      }
    },
    showCurrentTimeline() {
      setTimeout(() => {
        const d = new Date();
        const hour = d.getHours();
        const el = document.getElementById(`time_line${hour}`);
        if (el && d.toLocaleDateString() === moment(this.filters["filter[date]"]).format(this.config.DATETIME_FORMAT)) {
          el.style = "border-color: #6366F1;";
          el.querySelector("p").style = "border-color: #6366F1;";
        }
      });
    },
    wrapperClasses(slot, item) {
      if (!this.isExpanded && slot.is_belonged_to_bathhouse) {
        return {
          "!z-20 hover:!z-[31]": slot.type === "appointment" && slot.title,
          "!z-30 hover:!z-[31]": slot.name,
          "!w-[210px] !mr-0.5": this.zoom <= 0.4,
          "!w-[250px] !mr-0.5": this.zoom === 0.6,
        };
      }

      let from = null;
      let to = null;
      if (this.currentResize.resizeType) {
        const toIndex = this.currentResize.toResizeableIndex;
        const fromIndex =
          this.currentResize.resizeType === "top"
            ? this.currentResize.bookingData.startIndex
            : this.currentResize.bookingData.stopIndex;
        from = fromIndex >= toIndex ? toIndex : fromIndex;
        to = fromIndex >= toIndex ? fromIndex : toIndex;
      }

      return {
        "text-slate-400": !slot.is_online && !slot.is_shift_online && !slot.is_booked,
        // This code block has been updated based on Ticket: https://kanopi.live/app/ticket/47360
        // "border-x-2 border-dotted border-tertiary-400":
        //   slot.is_roster && (!slot.uncovered || (slot.uncovered && slot.is_existed_shift) || this.currentDrag.id),
        "-mt-3 rounded border-t-2 pt-2": slot.position === "roster_top" && (!slot.uncovered || this.currentDrag.id),
        "rounded border-b-2": slot.position === "bottom" && !slot.uncovered,
        "!z-20 hover:!z-[31]": slot.type === "appointment" && slot.title,
        "!z-30 hover:!z-[31]": slot.name,
        "!w-[210px] !mr-0.5": this.zoom <= 0.4,
        "!w-[250px] !mr-0.5": this.zoom === 0.6,
        "!bg-amber-100 [&>div]:!bg-transparent":
          this.currentResize.rowData &&
          from &&
          to &&
          slot.index >= from &&
          slot.index < to &&
          item.capacity_index === this.currentResize.rowData.capacity_index &&
          item.room_id === this.currentResize.rowData.room_id,
      };
    },
    reschedule(slot) {
      this.bus.$emit("openModal", {
        componentData: this.$t("shift.headings.reschedule"),
        callback: () => {
          this.pushToTasks(slot);
        },
      });
    },
    pushToTasks(slot) {
      axios
        .post(`/api/admin/bookings/reschedule-tasks`, {
          shift_id: slot.shift_id,
        })
        .then((response) => {
          this.notificationService.notify(response.data.statusText);
          this.reloadColumn(slot.room_id, slot.capacity_index);
        });
    },
    createDefaultTimeLine() {
      let index = 0;
      const startTime = moment(this.startTime, "h:mm");
      const endTime = moment(this.endTime, "h:mm");
      this.timeLine = [
        {
          index: index,
          value: startTime.format("h A"),
          id: startTime.hour(),
        },
      ];
      this.defaultTimeSlot = [
        {
          index: index,
          value: startTime.format("HH:mm"),
          value_full: startTime.format("HH:mm A"),
        },
      ];
      while (startTime.isBefore(endTime)) {
        index += 1;
        startTime.add(15, "minutes");
        this.defaultTimeSlot.push({
          index: index,
          value: startTime.format("HH:mm"),
          value_full: startTime.format("HH:mm A"),
        });
        if (index % 4 === 0) {
          this.timeLine.push({
            index: index,
            value: startTime.format("h A"),
            id: startTime.hour(),
          });
        }
      }
    },
    async reloadColumn(roomId, capacityIndex) {
      try {
        const res = await axios.get(this.endpoint, {
          params: {
            filter: {
              rooms: roomId,
              capacity_index: capacityIndex,
              date: this.filters["filter[date]"],
              venue: this.venueId,
            },
          },
        });
        const data = res.data;
        const room = this.roomTimeSlot.find((room) => {
          return room.room_id === roomId && room.capacity_index === capacityIndex;
        });

        this.roomTimeSlot[room.columnIndex].slots = cloneDeep(this.defaultTimeSlot);
        this.allocateShiftAndNotes(data.data[0], room.columnIndex);
      } catch (error) {}
    },
    getBookings() {
      if (!this.venueId) {
        return;
      }
      this.loading = true;
      this.clearResizeItem();
      axios.get(this.endpoint, { params: this.filters }).then(({ data }) => {
        this.roomTimeSlot = [];
        data.data.forEach((timeSlotItem, index) => {
          this.roomTimeSlot.push(
            Object.assign(timeSlotItem, { columnIndex: index, slots: cloneDeep(this.defaultTimeSlot) }),
          );

          this.allocateShiftAndNotes(timeSlotItem, index);
        });

        this.dispatchBookingSummary(data.data);

        this.loading = false;
        if (this.zoom > 0.6) {
          this.scrollToElement();
        }

        if (this.forDefault && !this.isExpanded) {
          this.showCurrentTimeline();
        }
      });
    },
    allocateShiftAndNotes(timeSlotItem, index) {
      Object.values(timeSlotItem.shifts).forEach((shift) => {
        this.shifts[shift.id] = shift;
        this.allocateShift(shift, index);
      });

      Object.values(timeSlotItem.notes).forEach((note) => {
        this.allocateNote(note, index, timeSlotItem);
      });
    },
    dispatchBookingSummary(data) {
      this.bus.$emit("bookingSummary", {
        total: sumBy(data, (o) => {
          return sumBy(o.shifts, (i) => {
            return i.appointments.length;
          });
        }),
        min: sumBy(data, (o) => {
          return sumBy(o.shifts, (i) => {
            return sumBy(i.appointments, (a) => {
              return a.min;
            });
          });
        }),
        max: sumBy(data, (o) => {
          return sumBy(o.shifts, (i) => {
            return sumBy(i.appointments, (a) => {
              return a.max;
            });
          });
        }),
      });
    },
    fillUpSlotIntoShift(shift, index) {
      const shiftStart = this.defaultTimeSlot.find((slot) => slot.value === shift.start);
      const shiftStop = this.defaultTimeSlot.find((slot) => slot.value === shift.stop);
      this.roomTimeSlot[index].slots[shiftStart.index].position = "top";
      this.roomTimeSlot[index].slots[Math.max(0, shiftStart.index - 1)].position = "roster_top";
      this.roomTimeSlot[index].slots[Math.max(0, shiftStart.index - 1)].is_roster = true;
      this.roomTimeSlot[index].slots[Math.max(0, shiftStart.index - 1)].is_belonged_to_bathhouse = shift.appointments
        .length
        ? shift.appointments[0].is_belonged_to_bathhouse
        : false;
      this.roomTimeSlot[index].slots[Math.max(0, shiftStart.index - 1)].uncovered = shift.uncovered;

      this.roomTimeSlot[index].slots[shiftStart.index].shift_name = shift.name;
      this.roomTimeSlot[index].slots[shiftStart.index].is_shift_online = shift.is_online;
      this.roomTimeSlot[index].slots[shiftStart.index].is_shift_has_booking = shift.has_booking;
      this.roomTimeSlot[index].slots[shiftStart.index].is_shift_rescheduled = shift.is_rescheduled;
      this.roomTimeSlot[index].slots[shiftStart.index].shift_id = shift.id;
      this.roomTimeSlot[index].slots[shiftStart.index].staff_name = shift.staff_name;
      this.roomTimeSlot[index].slots[shiftStart.index].staff_id = shift.user_id;
      this.roomTimeSlot[index].slots[shiftStop.index].position = "bottom";
      for (let i = shiftStart.index; i <= shiftStop.index; i++) {
        this.roomTimeSlot[index].slots[i].is_roster = true;
        this.roomTimeSlot[index].slots[i].is_belonged_to_bathhouse = shift.appointments.length
          ? shift.appointments[0].is_belonged_to_bathhouse
          : false;
        this.roomTimeSlot[index].slots[i].is_existed_shift = !shift.uncovered;
        if (typeof this.roomTimeSlot[index].slots[i].uncovered === "undefined") {
          this.roomTimeSlot[index].slots[i].uncovered = shift.uncovered;
        }
        this.roomTimeSlot[index].slots[i].shift_id = shift.id;
        this.roomTimeSlot[index].slots[i].slotIndex = index;
      }
    },
    allocateShift(shift, index) {
      this.fillUpSlotIntoShift(shift, index);

      shift.appointments.forEach((appointment) => {
        this.allocateAppointment(appointment, index);
      });
      shift.shift_breaks.forEach((shiftBreak) => {
        this.allocateBreak(shiftBreak, index);
      });
    },
    allocateAppointment(item, index) {
      const start = item.start_at;
      const stop = item.stop_at;
      const refShift = this.getShift(item, index);

      const startSlot = this.roomTimeSlot[index].slots.find((itemList) => itemList.value === start);
      const stopSlot = this.roomTimeSlot[index].slots.find((itemList) => itemList.value === stop);

      /** move out of shift */
      if (refShift && (refShift.start !== item.shift.start || refShift.stop !== item.shift.stop)) {
        const shiftStart = this.roomTimeSlot[index].slots.find((slot) => slot.position === "top");
        const rosterTop = this.roomTimeSlot[index].slots.find((slot) => slot.position === "roster_top");
        const shiftStop = this.roomTimeSlot[index].slots.find((slot) => slot.position === "bottom");
        delete shiftStart.position;
        delete rosterTop.position;
        delete shiftStop.position;

        refShift.start = item.shift.start;
        refShift.stop = item.shift.stop;

        this.fillUpSlotIntoShift(refShift, index);
      }

      if (item.booking_id) {
        startSlot.is_shift_has_booking = true;
      }

      const slot = item;
      Object.assign(slot, {
        name: item.client ? item.client : item.is_online ? "Available" : "Unavailable",
        type: "appointment",
        isDragOver: false,
        startIndex: startSlot.index,
        stopIndex: stopSlot.index,
        slotIndex: index,
        start: moment(start, "h:mm").format("h:mma"),
        stop: moment(stop, "h:mm").format("h:mma"),
        is_bottom: false,
        uncovered: slot.uncovered,
      });

      this.roomTimeSlot[index].slots[stopSlot.index - 1] = Object.assign(
        this.roomTimeSlot[index].slots[stopSlot.index - 1],
        {
          is_bottom: true,
        },
      );

      this.roomTimeSlot[index].slots[startSlot.index] = Object.assign(
        this.roomTimeSlot[index].slots[startSlot.index],
        slot,
      );
    },
    allocateBreak(item, index) {
      const startSlot = this.roomTimeSlot[index].slots.find((itemList) => itemList.value === item.start_at);
      const stopSlot = this.roomTimeSlot[index].slots.find((itemList) => itemList.value === item.stop_at);
      for (let i = startSlot.index; i < stopSlot.index; i++) {
        this.roomTimeSlot[index].slots[i].type = "break";
        this.roomTimeSlot[index].slots[i].duration = item.duration;
        if (i === startSlot.index) {
          this.roomTimeSlot[index].slots[i].name = item.title;
        }
        if (i === stopSlot.index - 1) {
          this.roomTimeSlot[index].slots[i].is_bottom = true;
        }
      }
    },
    allocateNote(item, index, timeSlotItem) {
      const { room_id, venue_id } = timeSlotItem;
      const startSlot = this.roomTimeSlot[index].slots.find((itemList) => itemList.value === item.start);
      const stopSlot = this.roomTimeSlot[index].slots.find((itemList) => itemList.value === item.stop);
      for (let i = startSlot.index; i < stopSlot.index; i++) {
        this.roomTimeSlot[index].slots[i].type = "note";
        this.roomTimeSlot[index].slots[i].duration = item.duration;
        this.roomTimeSlot[index].slots[i].start_at = item.start;
        this.roomTimeSlot[index].slots[i].stop_at = item.stop;
        if (i === startSlot.index) {
          this.roomTimeSlot[index].slots[i].name = "Note";
          this.roomTimeSlot[index].slots[i].id = item.id;
          this.roomTimeSlot[index].slots[i].start = item.start;
          this.roomTimeSlot[index].slots[i].stop = item.stop;
          this.roomTimeSlot[index].slots[i].start_full = item.start_full;
          this.roomTimeSlot[index].slots[i].date = item.date;
          this.roomTimeSlot[index].slots[i].description = item.description;
          this.roomTimeSlot[index].slots[i].uncovered = item.uncovered;
          this.roomTimeSlot[index].slots[i].room_id = room_id;
          this.roomTimeSlot[index].slots[i].venue_id = venue_id;
          this.roomTimeSlot[index].slots[i].timeSlots = this.roomTimeSlot[index].slots;
          this.roomTimeSlot[index].slots[i].startIndex = startSlot.index;
          this.roomTimeSlot[index].slots[i].stopIndex = stopSlot.index;
        }
        if (i === stopSlot.index - 1) {
          this.roomTimeSlot[index].slots[i].is_bottom = true;
        }
      }
    },
    getShift(item, index) {
      let refShift = this.shifts[item.shift ? item.shift.id : ""];

      if (!refShift) {
        refShift = item.shift;
        this.shifts[item.shift ? item.shift.id : ""] = refShift;

        if (refShift) {
          this.fillUpSlotIntoShift(refShift, index);
        }
      }

      return refShift;
    },
    deleteAppointment(appointment, _index) {
      this.roomTimeSlot.forEach((room, rIndex) => {
        if (room.slots.some((slot) => slot.id === appointment.id)) {
          this.roomTimeSlot[rIndex].slots = this.roomTimeSlot[rIndex].slots.map((slot) => {
            if (slot.id === appointment.id) {
              return pick(slot, ["index", "is_roster", "is_belonged_to_bathhouse", "shift_id", "slotIndex", "value"]);
            }
            return slot;
          });

          return false;
        }
      });
    },
    toggleStatusAppointment(param, index) {
      const slots = this.roomTimeSlot[index].slots.filter((slot) => slot.id === param.appointment_id);
      slots.forEach((slot) => {
        slot.is_online = param.online;
        slot.name = slot.client ? slot.client : param.online ? "Available" : "Unavailable";
      });
    },
    onChangeRoomStatus(value, slot) {
      // Room toggle
      if (slot.hasOwnProperty("is_online")) {
        return axios
          .post(`/api/admin/bookings/rooms/toggle-status`, {
            room_id: slot.room_id,
            date: this.filters["filter[date]"],
            venue_id: slot.venue_id,
            online: value,
            ...(this.isExpanded ? { capacity_index: slot.capacity_index } : {}),
          })
          .then(() => {
            this.search();
          });
      }

      // Shift toggle
      if (slot.is_shift_rescheduled) {
        return;
      }

      axios
        .post(`/api/admin/bookings/rooms/toggle-status`, {
          shift_id: slot.shift_id,
          online: value,
        })
        .then(() => {
          this.search();
        });
    },
    dragSlot(event, slot) {
      this.currentDrag.bookingId = slot.booking_id;
      this.currentDrag.item = slot;
      this.currentDrag.original_capacity_index = slot.capacity_index;
      this.currentDrag.original_room_id = slot.room_id;
    },
    dragLeave(event, slot) {
      this.removeDragAndDropBackground();
      const stopIndex = slot.is_belonged_to_bathhouse && !this.isExpanded ? slot.startIndex + 1 : slot.stopIndex;
      for (let j = slot.startIndex; j < stopIndex; j++) {
        this.roomTimeSlot[slot.slotIndex].slots[j].isDragOver = false;
      }
    },
    dragEnd($event) {
      // On ESC hit
      if ($event.dataTransfer.dropEffect === "none") {
        this.clearResizeItem();
      }
      if (!this.currentDrag.error_allow_products_at) {
        return;
      }

      const drop = this.currentDrag.error_allow_products_at;
      this.showToolTip(`#cell-${drop.room_id}-${drop.index}-tooltip`);
    },
    async drop(event, slot, row) {
      if (!this.canBeDraggable(slot, row, event)) {
        return this.clearResizeItem();
      }

      const currentDragItem = this.currentDrag.item;
      if (!currentDragItem.hasOwnProperty("booking_id") || currentDragItem.type === "note") {
        this.dragLeave(event, slot);
        await this.dropBooking(slot, row, { notify: false });
        return;
      }

      const openNotifyModal = (content, actions = []) => {
        this.bus.$emit("openModal", {
          componentName: "NotifyChangeModal",
          componentData: {
            header: this.$t("notification.headings.notify_drag_and_drop"),
            content: content && this.$t(content),
            actionDropdown: actions,
          },
          scrollable: false,
          isAsyncCallback: true,
          callback: async (confirmed) => {
            this.dragLeave(event, slot);
            await this.dropBooking(slot, row, confirmed || { notify: false });
          },
          cancelCallback: () => {
            this.dragLeave(event, slot);
            this.clearResizeItem();
          },
        });
      };

      const notifyActions = [
        { label: this.$t("notification.buttons.save_and_not_notify"), event: { notify: false } },
        { label: this.$t("notification.buttons.save_and_notify"), event: { notify: true, notifyType: "both" } },
      ];
      if (slot.booking_id) {
        notifyActions.push(
          {
            label: `${this.$t("notification.buttons.save_and_notify")} <strong>${
              this.currentDrag.item.client
            }</strong>`,
            event: { notify: true, notifyType: "drag" },
          },
          {
            label: `${this.$t("notification.buttons.save_and_notify")} <strong>${slot.client}</strong>`,
            event: { notify: true, notifyType: "drop" },
          },
        );
      }

      openNotifyModal(
        slot.booking_id
          ? "notification.headings.send_client_notify_of_change"
          : "notification.headings.confirm_drag_and_drop_booking",
        notifyActions,
      );
    },
    dragOver(event, slot, row) {
      if (this.canBeDraggable(slot, row, event)) {
        const isReschedule = !slot.id || slot.id === this.currentDrag.id;
        if (!isReschedule) {
          const stopIndex = slot.is_belonged_to_bathhouse && !this.isExpanded ? slot.startIndex + 1 : slot.stopIndex;
          for (let j = slot.startIndex; j < stopIndex; j++) {
            this.roomTimeSlot[slot.slotIndex].slots[j].isDragOver = true;
          }
        }
      } else {
        this.currentDrag.startIndex = null;
        this.currentDrag.stopIndex = null;
      }
      event.preventDefault();
    },
    productIsAllowed(dropSlot, room) {
      if (this.currentDrag.item.type === "note") {
        this.currentDrag.error_allow_products_at = null;
        return true;
      }

      if (dropSlot.allow_products && dropSlot.allow_products.includes(this.currentDrag.item.product_id)) {
        this.currentDrag.error_allow_products_at = null;
        return true;
      }

      const allowProducts = room.product_ids.includes(this.currentDrag.item.product_id);
      this.currentDrag.error_allow_products_at = allowProducts
        ? null
        : {
            ...dropSlot,
            room_id: room.room_id,
          };
      return allowProducts;
    },
    isSwap(dropSlot) {
      return this.currentDrag.item.id && dropSlot.id && dropSlot.id > 0 && this.currentDrag.item.id !== dropSlot.id;
    },
    isUpdateTime(row) {
      return (
        row.room_id === this.currentDrag.original_room_id &&
        this.currentDrag.original_capacity_index === row.capacity_index
      );
    },
    showDropPlaceholders: function (startIndex, stopIndex, row) {
      for (let j = 0; j < this.config.NUMBERS_OF_INDEX; j++) {
        if (
          j >= startIndex &&
          j < stopIndex &&
          this.currentDrag.item.room_id === row.room_id &&
          this.currentDrag.item.capacity_index === row.capacity_index
        ) {
          const el = document.getElementById(`${row.room_id}-${row.capacity_index}-${j}-appointment`);

          el.classList.add("!bg-amber-100");
          const time = el.querySelector(".time");
          if (time && startIndex === j) {
            time.style.cssText = "opacity: 100;";
          }
        }
      }
    },
    canBeDraggable(dropSlot, row, event) {
      const currentAppointment = this.currentDrag.item;
      if (
        !this.isExpanded &&
        currentAppointment.is_belonged_to_bathhouse &&
        (!dropSlot.id || dropSlot.id === currentAppointment.id)
      ) {
        return false;
      }
      const isNote = currentAppointment.type === "note";
      const targetRoom = this.roomTimeSlot.find((room) => {
        return room.room_id === row.room_id && room.capacity_index === row.capacity_index;
      });

      const productIsAllowed = this.productIsAllowed(dropSlot, targetRoom);
      if (!productIsAllowed) {
        return false;
      }

      if (this.isSwap(dropSlot)) {
        if (isNote) {
          return false;
        }
        this.currentDrag.purpose = "swap";
        const canSwap =
          (Boolean(dropSlot.is_online) || dropSlot.hasOwnProperty("booking_id")) &&
          productIsAllowed &&
          currentAppointment.duration === dropSlot.duration;
        if (!canSwap) {
          return false;
        }

        this.currentDrag.item.room_id = row.room_id;
        return true;
      }

      const isUpdateTime = this.isUpdateTime(row);
      const targetElement = document.getElementById(`card-${this.currentDrag.id}`);
      const durationChange =
        Math.round((targetElement.getBoundingClientRect().top - event.clientY) / this.lengthDuration) + 1;
      const startIndex = currentAppointment.index - durationChange;
      const appointmentDuration =
        currentAppointment.is_belonged_to_bathhouse && !this.isExpanded ? 1 : currentAppointment.duration / 15;
      const stopIndex = startIndex + appointmentDuration;
      if (!targetRoom.slots[startIndex] || !targetRoom.slots[stopIndex]) {
        return false;
      }
      const newTimeRange = {
        start_at: targetRoom.slots[startIndex].value,
        stop_at: targetRoom.slots[stopIndex].value,
      };

      /**
       *  Check if enough space or not
       */
      for (let i = startIndex; i <= stopIndex - 1; i++) {
        const roomSlot = targetRoom.slots[i];
        if (!roomSlot.start_at || !roomSlot.type || !this.checkOverlappingTypes.includes(roomSlot.type)) {
          continue;
        }
        if (roomSlot.slotIndex !== currentAppointment.slotIndex) {
          continue;
        }
        if (this.overlapping(newTimeRange, roomSlot)) {
          if (roomSlot.booking_id && roomSlot.booking_id === currentAppointment.booking_id) {
            continue;
          }

          if (isNote && roomSlot.id && roomSlot.id === currentAppointment.id) {
            continue;
          }

          if (roomSlot.uncovered) {
            continue;
          }

          return false;
        }
      }
      this.showDropPlaceholders(startIndex, stopIndex, row);
      if (isUpdateTime && durationChange === 0) {
        return false;
      }
      this.currentDrag.dropValue = targetRoom.slots[startIndex].value;
      this.currentDrag.startIndex = startIndex;
      this.currentDrag.stopIndex = stopIndex;
      this.currentDrag.item.room_id = row.room_id;
      this.currentDrag.item.capacity_index = row.capacity_index;
      this.currentDrag.purpose = isUpdateTime ? "update_time" : "to_another_room";
      return true;
    },

    removeDragAndDropBackground() {
      for (let j = 0; j <= this.config.NUMBERS_OF_INDEX; j++) {
        this.removeDraggableElement(this.currentDrag.item.room_id, this.currentDrag.item.capacity_index, j);
        this.removeDraggableElement(this.currentDrag.original_room_id, this.currentDrag.original_capacity_index, j);
      }
    },
    overlapping(a, b) {
      const getMinutes = (s) => {
        const p = s.split(":").map(Number);
        return p[0] * 60 + p[1];
      };
      return getMinutes(a.stop_at) > getMinutes(b.start_at) && getMinutes(b.stop_at) > getMinutes(a.start_at);
    },

    removeDraggableElement(roomId, capacityId, slot) {
      const el = document.getElementById(`${roomId}-${capacityId}-${slot}-appointment`);
      const time = el ? el.querySelector(".time") : null;

      if (time && time.style.cssText === "opacity: 100;") {
        time.style.cssText = "opacity: 0;";
      }

      if (el && el.classList.contains("!bg-amber-100")) {
        el.classList.remove("!bg-amber-100");
      }
    },
    scrollToElement() {
      const d = new Date();
      const hour = d.getHours();
      const el = document.getElementById(`time_line${hour - 2}`);

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    async dropBooking(slot, row, notifyStatus) {
      const currentAppointment = this.currentDrag.item;
      currentAppointment.type === "note"
        ? await this.dragDropBookingNote(row, currentAppointment)
        : await this.dragDropBooking(row, currentAppointment, slot, notifyStatus);
    },
    async dragDropBooking(row, currentAppointment, slot, notifyStatus) {
      const is_swapped = this.currentDrag.purpose === "swap";
      const to_another_room = this.currentDrag.purpose === "to_another_room";
      const params = {
        booking_id: this.currentDrag.bookingId,
        is_swapped,
        to_another_room,
        appointment_id: this.currentDrag.id,
        is_collapse_bathhouse: this.currentDrag.item.is_belonged_to_bathhouse && !this.isExpanded,
        is_notified: notifyStatus.notify,
        notify_type: notifyStatus.notifyType,
        start: this.currentDrag.dropValue,
      };
      if (this.currentDrag.purpose === "update_time") {
        params.stop = this.roomTimeSlot[currentAppointment.slotIndex].slots[this.currentDrag.stopIndex].value;
      } else if (is_swapped) {
        params.target_appointment_id = slot.id;
      } else if (to_another_room) {
        params.room_id = row.room_id;
        params.to_capacity_index = row.capacity_index;
      }

      const res = await axios.post(`/api/admin/bookings/drag-drop-bookings`, params);
      const data = res.data;
      this.notificationService.notify(this.$t("booking.responses.reschedule"));
      this.clearResizeItem();
      if (!this.isExpanded) {
        this.bus.$emit("tableFilterChange", { params: {} });
        return;
      }
      if (
        (data.hasOwnProperty("dragAppointment") && data.dragAppointment === null) ||
        data.hasOwnProperty("dropAppointment")
      ) {
        await this.reloadColumn(row.room_id, row.capacity_index);
      }

      if (data.hasOwnProperty("dragAppointment") && data.dragAppointment !== null) {
        await this.reloadColumn(data.dragAppointment.room_id, data.dragAppointment.capacity_index);
      }
    },
    async dragDropBookingNote(row, currentAppointment) {
      try {
        const url = `/api/admin/rooms/${row.room_id}/booking-notes/${currentAppointment.id}/drag-drop-bookings`;
        await axios.post(url, {
          room_id: row.room_id,
          to_capacity_index: row.capacity_index,
          start: this.currentDrag.dropValue,
        });
        await this.search();
        this.clearResizeItem();
        this.notificationService.notify(this.$t("booking.responses.drag_and_drop_note"));
      } catch (error) {
        this.notificationService.notify(this.$t("booking.responses.drag_and_drop_note_error"), "error");
      }
    },

    resizeable(e, slot, item) {
      if (!this.isExpanded || this.forPreview || !slot.id || slot.uncovered) {
        return;
      }
      this.clearResizeItem();
      this.currentResize.bookingData = slot;
      this.currentResize.rowData = item;

      const targetElement = document.getElementById(`card-${slot.id}`);

      const resizerBottom = document.createElement("div");
      resizerBottom.id = "resizerBottom";
      resizerBottom.classList.add("h-0.5", "absolute", "cursor-ns-resize", "inset-x-0", "bg-blue-500", "bottom-0");
      targetElement.appendChild(resizerBottom);
      this.currentResize.fromBottomPosition = resizerBottom.getBoundingClientRect().top;

      const resizerTop = document.createElement("div");
      resizerTop.id = "resizerTop";
      resizerTop.classList.add("h-0.5", "absolute", "cursor-ns-resize", "inset-x-0", "bg-blue-500", "top-0");
      targetElement.appendChild(resizerTop);
      this.currentResize.fromTopPosition = resizerTop.getBoundingClientRect().top;

      resizerBottom.addEventListener("mousedown", this.initResizable, false);
      resizerTop.addEventListener("mousedown", this.initResizable, false);
    },
    initResizable(e) {
      document.documentElement.addEventListener("mousemove", this.doResize, false);
      document.documentElement.addEventListener("mouseup", this.stopResize, false);
    },
    doResize(e) {
      if (e.target.id === "resizerTop") {
        this.currentResize.resizeType = "top";
        if (document.getElementById("resizerBottom")) {
          document.getElementById("resizerBottom").remove();
        }
      }

      if (e.target.id === "resizerBottom") {
        this.currentResize.resizeType = "bottom";
        if (document.getElementById("resizerTop")) {
          document.getElementById("resizerTop").remove();
        }
      }

      const durationChange =
        this.currentResize.resizeType === "bottom"
          ? Math.round((e.clientY - this.currentResize.fromBottomPosition) / this.lengthDuration)
          : Math.round((this.currentResize.fromTopPosition - e.clientY) / this.lengthDuration);
      if (durationChange === 0) {
        this.currentResize.toResizeableIndex = null;
        return;
      }

      if (this.currentResize.resizeType === "top") {
        const toIndex = this.currentResize.bookingData.startIndex - durationChange;
        if (durationChange > 0) {
          for (let i = this.currentResize.bookingData.startIndex; i >= toIndex; i--) {
            if (
              this.roomTimeSlot[this.currentResize.bookingData.slotIndex].slots[i].type === "break" ||
              this.roomTimeSlot[this.currentResize.bookingData.slotIndex].slots[i].is_bottom
            ) {
              return;
            }
            this.currentResize.toResizeableIndex = Number(i);
            if (
              this.roomTimeSlot[this.currentResize.bookingData.slotIndex].slots[i].type === "appointment" &&
              !this.roomTimeSlot[this.currentResize.bookingData.slotIndex].slots[i].hasOwnProperty("id")
            ) {
              return;
            }
          }
        } else {
          for (let i = this.currentResize.bookingData.startIndex; i <= toIndex; i++) {
            if (i + 4 <= this.currentResize.bookingData.stopIndex) {
              this.currentResize.toResizeableIndex = Number(i);
            }
          }
        }
        return;
      }
      const toIndex = this.currentResize.bookingData.stopIndex + durationChange;
      if (durationChange > 0) {
        for (let i = this.currentResize.bookingData.stopIndex; i <= toIndex; i++) {
          this.currentResize.toResizeableIndex = Number(i);
          if (
            this.roomTimeSlot[this.currentResize.bookingData.slotIndex].slots[i].hasOwnProperty("id") ||
            this.roomTimeSlot[this.currentResize.bookingData.slotIndex].slots[i].type === "break"
          ) {
            return;
          }
        }
      } else {
        for (let i = this.currentResize.bookingData.stopIndex; i >= toIndex; i--) {
          if (i - 4 >= this.currentResize.bookingData.startIndex) {
            this.currentResize.toResizeableIndex = Number(i);
          }
        }
      }
    },
    stopResize(e) {
      if (this.currentResize.toResizeableIndex) {
        const fromIndex =
          this.currentResize.resizeType === "top"
            ? this.currentResize.toResizeableIndex
            : this.currentResize.bookingData.startIndex;
        const toIndex =
          this.currentResize.resizeType === "top"
            ? this.currentResize.bookingData.stopIndex
            : this.currentResize.toResizeableIndex;

        const param = {
          resize_type: this.currentResize.resizeType,
          new_duration: toIndex - fromIndex,
        };

        const { type, room_id, id } = this.currentResize.bookingData;
        const url =
          type === "note"
            ? `/api/admin/rooms/${room_id}/booking-notes/${id}/resize`
            : `/api/admin/appointments/${id}/resize`;
        axios.post(url, param).then((res) => {
          this.search();
        });
      }
      document.documentElement.removeEventListener("mousemove", this.doResize, false);
      document.documentElement.removeEventListener("mouseup", this.stopResize, false);
      this.clearResizeItem();
    },
    clearResizeItem() {
      if (document.getElementById("resizerBottom")) {
        document.getElementById("resizerBottom").remove();
      }
      if (document.getElementById("resizerTop")) {
        document.getElementById("resizerTop").remove();
      }
      this.currentDrag.id = null;
      this.currentDrag.purpose = null;
      this.currentDrag.original_capacity_index = null;
      this.currentDrag.original_room_id = null;
      this.currentDrag.startIndex = null;
      this.currentDrag.stopIndex = null;
      this.currentDrag.bookingId = null;
      this.currentDrag.item = null;

      this.currentResize.resizeType = null;
      this.currentResize.fromBottomPosition = null;
      this.currentResize.fromTopPosition = null;
      this.currentResize.toResizeableIndex = null;
      this.currentResize.bookingData = null;
      this.currentResize.rowData = null;
    },
    showToolTip(elQuery) {
      const el = document.querySelector(elQuery);
      el.style = "display: block;";

      setTimeout(() => {
        el.style = "display: hidden;";
      }, 1500);
    },
  },
};
</script>
