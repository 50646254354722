<template>
  <div class="w-full rounded border-x px-xsSpace py-smSpace">
    <div @click="toggle" class="flex justify-between">
      <div
        class="flex items-center gap-x-2"
        :class="{
          'cursor-pointer hover:text-primary-600': allowRefund && booking.payment_details.is_refundable,
          '!cursor-default hover:text-slate-900': !allowRefund || booking.payment_details.square_refundable_amount <= 0,
        }"
      >
        <strong class="text-sm leading-6">{{ $t("booking.labels.total_paid") }}</strong>
        <v-badge
          v-if="
            allowRefund && booking.payment_details.is_refundable && booking.payment_details.square_refundable_amount > 0
          "
          type="active"
          :value="$t('booking.buttons.refund')"
        ></v-badge>

        <v-icon
          v-if="
            allowRefund && booking.payment_details.is_refundable && booking.payment_details.square_refundable_amount > 0
          "
          :icon="show ? 'ChevronUpIcon' : 'ChevronDownIcon'"
        ></v-icon>
      </div>
      <div class="flex text-sm leading-6">
        <strong>{{ toAUD(booking.payment_details.total_paid) }}</strong>
      </div>
    </div>
    <div v-if="allowRefund && booking.payment_details.square_refundable_amount > 0 && show">
      <refund-input
        ref="refundInput"
        :can-update-refund-amount="true"
        v-model="refundForm"
        :booking="booking"
        :max-amount="booking.payment_details.square_refundable_amount"
        @update:refund-start="refundLoading = true"
        @update:refund-end="onRefundEnd"
      >
        <a @click.prevent="refund" class="btn-primary btn-sm float-right">
          <span v-if="!refundLoading">
            {{ $t("generic.buttons.refund") }}
          </span>
          <p v-else class="flex items-center gap-3xsSpace">
            <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
            <span>{{ $t("generic.words.processing") }}</span>
          </p>
        </a>
      </refund-input>
    </div>
  </div>
</template>
<script>
import Payment from "@/js/vue/mixins/Payment";
import Form from "form-backend-validation";
import axios from "axios";
export default {
  inject: ["config"],
  mixins: [Payment],
  props: {
    booking: {
      type: Object,
    },
    allowRefund: {
      type: Boolean,
      default: false,
    },
    onPaySuccess: {
      type: Function,
    },
    updateBookingPayments: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      refundLoading: false,
      refundForm: new Form(
        {
          refund_method: null,
          refund_amount:
            this.booking.payment_details.balance < 0
              ? -this.booking.payment_details.balance
              : Math.abs(this.booking.payment_details.square_refundable_amount),
          token: null,
        },
        { resetOnSuccess: false },
      ),
    };
  },
  watch: {
    booking: {
      handler(newValue) {
        this.refundForm.refund_amount = newValue.payment_details.square_refundable_amount;
        this.refundForm.token = null;
      },
      deep: true,
    },
  },
  methods: {
    toggle() {
      if (this.booking.payment_details.is_cancelled || !this.booking.payment_details.is_refundable) {
        return;
      }

      this.show = !this.show;
    },
    refund() {
      this.refundLoading = true;
      this.$refs.refundInput.refund();
    },
    onRefundEnd(res) {
      if (res) {
        this.show = false;
        this.checkPayments(this.booking.order_id).then((response) => {
          this.onPaySuccess(response);
        });
      }

      axios.get(`/api/admin/orders/${this.booking.order_id}/payments`).then((response) => {
        this.updateBookingPayments(response);
      });
      this.refundLoading = false;
    },
  },
};
</script>
