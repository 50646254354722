<template>
  <div class="sm:block">
    <div class="border-b border-tertiary-200" v-if="!autoMinify || items.length > 1">
      <nav :class="navClass" aria-label="Tabs">
        <a
          v-for="tab in items"
          :key="tab.name"
          :href="tab.href"
          @click="onChange(tab)"
          :class="[
            tab.active
              ? 'border-primary-500 text-primary-600'
              : 'border-transparent text-tertiary-500 hover:border-tertiary-300 hover:text-tertiary-700',
            `cursor-pointer whitespace-nowrap border-b-2 px-2xsSpace py-mdSpace text-sm font-medium`,
          ]"
          :aria-current="tab.active ? 'page' : undefined"
          >{{ tab.name }}</a
        >
      </nav>
    </div>
    <template v-for="tab in items">
      <slot v-if="tab.active" :name="name(tab.name)"></slot>
    </template>
  </div>
</template>

<script>
export default {
  inject: ["bus"],
  name: "VTabs",
  props: {
    tabs: {
      type: Object,
      required: true,
    },
    navClass: {
      type: String,
      default: "-mb-px flex space-x-smSpace xl:space-x-xlSpace",
    },
    autoMinify: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: this.tabs,
    };
  },
  methods: {
    onChange(tab) {
      this.tabs.forEach((item) => {
        item.active = false;
      });

      tab.active = true;
      this.bus.$emit("tabChanged", tab);
    },
    name(name) {
      return this.slug(name);
    },
  },
};
</script>
