<template>
  <div class="ml-mdSpace flex items-center justify-between">
    <div class="w-48">
      <v-multi-select
        v-model="venueId"
        search-url="/api/admin/venues?filter[active]=1"
        :placeholder="$t('venue.placeholders.venue')"
        track-by="id"
        label="name"
        search-key="filter[name]"
        @update:modelValue="onSelectVenue"
      ></v-multi-select>
    </div>
  </div>
</template>

<script>
export default {
  name: "VenueFilter",
  inject: ["bus"],
  data() {
    return {
      filters: {},
      venueId: null,
    };
  },
  beforeUnmount() {
    this.bus.$off("tableFilterChange");
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("filter[venue]")) {
      this.venueId = Number(urlParams.get("filter[venue]"));
      localStorage.setItem("venue_id", this.venueId);
    } else {
      this.venueId = Number(localStorage.getItem("venue_id"));
    }
    this.bus.$on("tableFilterChange", ({ params }) => {
      if (Object.keys(params).length) {
        this.filters = params;
      }
    });
  },
  methods: {
    onSelectVenue() {
      this.filters["filter[venue]"] = this.venueId;
      const params = this.filters;
      localStorage.setItem("venue_id", this.venueId);
      this.bus.$emit("tableFilterChange", { params });
    },
  },
};
</script>
