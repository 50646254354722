<template>
  <li>
    <component
      :is="as"
      @click.prevent="handleClick"
      class="flex cursor-pointer p-2xsSpace hover:bg-neutral-100 hover:text-blue-600"
      :class="{ 'cursor-wait opacity-50': isLoading }"
    >
      <slot></slot>
    </component>
  </li>
</template>

<script>
export default {
  name: "VActionDropdownItem",
  props: {
    as: {
      type: String,
      default: "li",
    },
    onClick: {
      type: Function,
      default: async () => {},
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async handleClick() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      if (this.onClick) {
        await this.onClick();
      }
      this.isLoading = false;
    },
  },
};
</script>
