<template>
  <nav>
    <div class="pt-2xlSpace text-white" v-if="showLoginUser">
      <div class="pb-xlSpace font-sloganSerif text-4xl font-bold leading-10">
        Hi {{ currentCustomer.display_name }}.
      </div>
    </div>
    <v-menu :items="menu" :is-horizontal="true" :for-client="!isAdmin" />
  </nav>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "VHeader",
  components: {},
  props: {
    items: {
      type: Object,
    },
    isAdmin: {
      type: Boolean,
      default: true,
    },
    showLoginUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: this.items,
    };
  },
  computed: {
    ...mapState({
      currentCustomer: (state) => state.customer.currentCustomer,
    }),
  },
  methods: {
    toggle(item) {
      item.active = !item.active;
    },
  },
};
</script>
