const state = () => ({
  role: null,
});

const getters = {
  role: (state) => state.role,
};

const actions = {
  setRole({ commit }, role) {
    commit("setRole", role);
  },
  async fetchUser({ commit }) {
    await axios(`/api/admin/profile`).then(({ data }) => {
      commit("setRole", data.data.role);
    });
  },
};

const mutations = {
  setRole(state, role) {
    state.role = role;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
