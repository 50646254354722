<template>
  <div class="px-lgSpace py-mdSpace" v-if="!loading">
    <div class="flex justify-between pb-lgSpace">
      <h3>{{ $t("order.headings.details") }}</h3>
      <div class="flex justify-between gap-smSpace">
        <a class="btn btn-secondary btn-sm" @click="sendBookingConfirmationEmail">
          <v-icon v-if="loading" icon="ArrowPathIcon" class="animate-spin" />
          <span v-else>{{ $t("booking.buttons.resend_confirmation_email") }}</span>
        </a>
        <a class="flex cursor-pointer items-center justify-center rounded hover:bg-tertiary-100">
          <v-icon icon="XMarkIcon" class="h8 w-8"></v-icon>
        </a>
      </div>
    </div>
    <v-notification v-if="notificationMessage" class="" :type="notificationType" :content="notificationMessage" />
    <form class="flex w-full flex-col justify-between space-y-smSpace" v-if="order">
      <ul role="list" class="space-y-smSpace rounded p-mdSpace text-sm ring-1">
        <li class="flex justify-between">
          <p class="font-semibold text-gray-900">
            {{ $t("order.columns.id") }}
          </p>
          <p class="text-gray-900">
            <span>#{{ order.id }}</span>
          </p>
        </li>
        <li class="flex justify-between">
          <p class="font-semibold text-gray-900">
            {{ $t("order.columns.customer_name") }}
          </p>
          <p>
            {{ order.customer.display_name }}
          </p>
        </li>
        <li class="flex justify-between">
          <p class="font-semibold text-gray-900">
            {{ $t("order.columns.customer_email") }}
          </p>
          <p>
            {{ order.customer.email }}
          </p>
        </li>
        <template v-for="(attributes, index) in orderItems">
          <template v-for="value in attributes">
            <li class="flex justify-between">
              <p class="font-semibold text-gray-900">
                {{ index + 1 }}. {{ value.name }}
                <v-badge v-if="value.deleted" :value="$t('generic.words.deleted')" type="warning"> </v-badge>
              </p>
              <p>
                {{ value.value }}
              </p>
            </li>
          </template>
        </template>
        <li class="flex justify-between border-t border-t-blue-300 pt-smSpace">
          <p class="font-semibold">
            {{ $t("generic.words.total") }}
          </p>
          <p>
            {{ toAUD(Number(order.total)) }}
          </p>
        </li>
        <li class="flex justify-between">
          <p class="font-semibold">
            {{ $t("customer.headings.tax") }}
          </p>
          <p>
            {{ toAUD((Number(order.total) - Number(order.total) / 1.1).toFixed(2)) }}
          </p>
        </li>
        <li class="flex justify-between">
          <p class="font-semibold">
            {{ $t("generic.words.paid") }}
          </p>
          <p>
            {{ toAUD(Number(order.total_paid)) }}
          </p>
        </li>
      </ul>
      <template v-if="!paymentLoading">
        <p class="font-semibold">{{ $t("generic.words.payments") }}</p>
        <div v-if="order.payment_details.payments.length">
          <ul role="list" class="space-y-smSpace rounded p-mdSpace text-sm ring-1">
            <li class="flex justify-between" v-for="(payment, index) in order.payment_details.payments">
              <div class="flex items-center gap-smSpace">
                <div
                  class="relative cursor-pointer"
                  @mouseover="showToolTip($event, payment)"
                  @mouseout="hideToolTip($event, payment)"
                >
                  <span v-html="payment.title"></span>
                  <div
                    :id="`payment-${payment.id}`"
                    class="-right-50 absolute hidden w-[250px] -translate-x-1.5 -translate-y-full items-center justify-center rounded-md bg-gray-800 p-smSpace text-xs text-white"
                    v-html="payment.tooltip"
                  ></div>
                </div>
                <div v-if="payment.payment_method === 'square' && payment.amount > 0" class="flex gap-xsSpace">
                  <input
                    type="number"
                    class="!w-[80px] py-3xsSpace"
                    v-model="payment.actual_legacy_square_refundable_amount"
                  />
                  <a
                    :class="legacyRefundButtonClasses(payment)"
                    @click="confirmAndRemove(payment)"
                    class="btn-secondary flex !w-[64px] justify-center !rounded-lg !px-xsSpace !py-3xsSpace !text-sm"
                  >
                    <v-icon v-if="refundLoading" icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
                    <span v-else>{{ $t("generic.buttons.refund") }}</span>
                  </a>
                </div>
                <v-badge
                  v-if="
                    (payment.payment_method === 'square' || payment.payment_method === 'terminal') &&
                    payment.amount <= 0 &&
                    payment.square_status !== 'COMPLETED'
                  "
                  :value="payment.square_status"
                  :type="payment.square_status === 'CANCELED' ? 'warning' : 'inactive'"
                >
                </v-badge>
                <span
                  v-else-if="
                    payment.payment_method === config.PAYMENT_CARD &&
                    payment.amount < 0 &&
                    payment.square_status === 'COMPLETED'
                  "
                >
                  <a target="_blank" :href="payment.square_link">
                    <v-icon class="text-blue-400 hover:text-blue-600" icon="EyeIcon"></v-icon>
                  </a>
                </span>
              </div>
              <p class="flex items-center">
                {{ toAUD(Number(payment.amount)) }}
              </p>
            </li>
          </ul>
        </div>
        <span v-else>{{ $t("generic.words.no_item") }}</span>
      </template>
      <div
        v-else
        aria-label="Loading..."
        role="status"
        class="absolute left-1/2 top-1/2 flex -translate-x-1/2 items-center justify-center space-x-2"
      >
        <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
        <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
      </div>
      <template v-if="order.journals.length">
        <p class="font-semibold">{{ $t("order.columns.journals") }}</p>
        <ul role="list" class="space-y-smSpace rounded p-mdSpace text-sm ring-1">
          <li class="flex justify-between" v-for="(journal, index) in order.journals">
            <p class="font-semibold">{{ index + 1 }}. #{{ journal.id + " Date: " + journal.date }}</p>
            <p>
              <span class="font-normal"> {{ $t("generic.words.sent_at") }} </span> {{ journal.sent_at }}
            </p>
          </li>
        </ul>
      </template>
    </form>
    <v-activity-log
      v-if="order"
      :filter-event="`activityTableFilterChange_${order.id}`"
      :model-id="order.id"
      :model-class="order.class"
      :allow-comment="true"
      :current-user="currentUser"
    >
      <v-filter
        :filterEmitsTo="`activityTableFilterChange_${order.id}`"
        filter-endpoint="/api/generic/activity-logs/filters"
        entity="activity-logs"
        class="flex flex-row-reverse space-x-2 space-x-reverse"
      >
      </v-filter>
    </v-activity-log>
  </div>
  <div
    v-else
    aria-label="Loading..."
    role="status"
    class="absolute left-1/2 top-1/2 flex -translate-x-1/2 items-center justify-center space-x-2"
  >
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>
<script>
import axios from "axios";
import Payment from "@/js/vue/mixins/Payment";

export default {
  inject: ["bus", "config"],
  mixins: [Payment],
  props: {
    orderId: {
      type: Number,
    },
    currentUser: {
      type: Object,
      default: {},
    },
  },
  computed: {
    orderItems() {
      return this.order.order_items.map((item) => {
        return [
          {
            name: item.itemable?.hasOwnProperty("gift_voucher")
              ? item.itemable.gift_voucher.title + " (" + item.itemable.code + ")"
              : item.itemable?.name,
            value: this.toAUD(item.price),
            deleted: Boolean(item.itemable?.deleted_at),
          },
        ];
      });
    },
  },
  data() {
    return {
      refundLoading: false,
      paymentLoading: false,
      order: null,
      loading: false,
      notificationMessage: null,
      notificationType: "success",
      tooltipTimeout: null,
    };
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    getOrder(loading = true) {
      if (loading) {
        this.loading = true;
      }

      this.paymentLoading = true;

      axios
        .get(`/api/admin/orders/${this.orderId}`)
        .then(({ data }) => {
          this.order = data;
          if (loading) {
            this.loading = false;
          }
          this.paymentLoading = false;
        })
        .catch(() => {
          if (loading) {
            this.loading = false;
          }
          this.paymentLoading = false;
        });
    },
    remove(payment) {
      if (
        payment.payment_method !== "square" ||
        this.refundLoading ||
        payment.legacy_square_refundable_amount <= 0 ||
        payment.actual_legacy_square_refundable_amount <= 0
      ) {
        return;
      }

      this.refundLoading = true;
      let url = `/api/admin/payments/${payment.id}?amount=${payment.actual_legacy_square_refundable_amount}`;
      if (this.order.is_gift_voucher) {
        url = url + `&is_gift_voucher=true`;
      }

      axios
        .post(url)
        .then(() => {
          this.getOrder(false);
          this.checkPayments(this.order.id).then((response) => {
            if (response && Object.keys(response).length) {
              this.order.payment_details = response.data.payment_details;
              this.notificationMessage = this.$t("booking.responses.payment_updated");

              setTimeout(() => {
                this.notificationMessage = null;
              }, 1500);
            }
          });
          this.refundLoading = false;
        })
        .catch(({ response }) => {
          setTimeout(() => {
            this.notificationType = "success";
            this.notificationMessage = null;
          }, 2000);
          this.refundLoading = false;
          this.notificationType = "error";
          this.notificationMessage = response.data.message;
        });
    },
    legacyRefundButtonClasses(payment) {
      return {
        "!bg-gray-200 !cursor-not-allowed": this.refundLoading || payment.legacy_square_refundable_amount <= 0,
        "hover:bg-neutral-100 cursor-pointer": payment.legacy_square_refundable_amount > 0,
      };
    },
    confirmAndRemove(payment) {
      if (
        payment.actual_legacy_square_refundable_amount <= 0 ||
        payment.legacy_square_refundable_amount <= 0 ||
        payment.actual_legacy_square_refundable_amount * 100 > payment.legacy_square_refundable_amount ||
        this.refundLoading
      ) {
        return;
      }

      this.bus.$emit("openModal", {
        componentName: "RefundConfirmation",
        componentData: {
          header: this.$t("payment.headings.refund"),
          payment: payment.raw_data,
          amount: payment.actual_legacy_square_refundable_amount,
        },
        callback: () => {
          this.remove(payment);
        },
      });
    },
    showToolTip($event, payment) {
      const el = document.getElementById(`payment-${payment.id}`);
      this.tooltipTimeout = setTimeout(() => {
        el.style = "display: inline;";

        setTimeout(() => {
          el.style = "display: hidden;";
          clearTimeout(this.tooltipTimeout);
        }, 10000);
      }, 100);
    },
    hideToolTip($event, payment) {
      const el = document.getElementById(`payment-${payment.id}`);
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }

      el.style = "display: hidden;";
    },
    async sendBookingConfirmationEmail() {
      this.loading = true;
      return axios
        .post(`/api/admin/bookings/${this.order.order_items[0].with_trashed_booking.id}/send-confirmation`)
        .then(({ data }) => {
          this.notificationMessage = data.statusText;
          setTimeout(() => {
            this.notificationMessage = null;
          }, 1500);
          this.loading = false;
        });
    },
  },
};
</script>
