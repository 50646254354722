<template>
  <div v-click-outside="onClickOutside" class="relative">
    <button
      :class="{ 'btn-primary': selections, 'btn-secondary': !selections }"
      class="btn-icon btn-sm flex-row-reverse space-x-reverse !rounded-lg"
      type="button"
      @click="opened = !opened"
    >
      <span
        >Filter<span v-if="selections">: {{ selections }}</span></span
      >
      <div class="btn-icon-left">
        <v-icon icon="AdjustmentsHorizontalIcon"></v-icon>
      </div>
    </button>
    <slot></slot>
    <div v-if="opened" class="absolute right-0 top-[36px] z-20">
      <div
        :class="{
          'absolute z-20 h-full w-[250px] cursor-not-allowed rounded-md bg-slate-50 opacity-50 ring-1 ring-neutral-100':
            loading,
        }"
      />
      <div class="z-20 w-[250px] rounded-md bg-white p-mdSpace shadow-xl ring-1 ring-neutral-100">
        <header class="flex justify-between">
          <h3 class="mb-smSpace">Filters</h3>
          <transition>
            <a
              v-if="selections && clearable"
              class="cursor-pointer text-sm hover:text-blue-700"
              @click.stop.prevent="clearAll"
            >
              Clear All
            </a>
          </transition>
        </header>
        <ul class="-my-smSpace divide-y text-sm [&>li]:py-smSpace">
          <template v-for="(facet, index) in facets">
            <li v-if="facet.type === 'refines' && facet.visible" :key="[facet.name] + [index]">
              <div class="flex justify-between hover:text-blue-700" @click.prevent="toggleFacet(facet)">
                <span class="cursor-pointer text-sm">{{ facet.name }}</span>
                <v-icon v-show="facet.active" icon="MinusIcon" class="cursor-pointer"></v-icon>
                <v-icon v-show="!facet.active" icon="PlusIcon" class="cursor-pointer"></v-icon>
              </div>
              <div v-show="facet.active" class="max-h-[400px] space-y-smSpace overflow-auto">
                <div class="flex space-x-2xsSpace" v-if="facet.api_mode">
                  <label :for="facet.name" class="block">
                    <input
                      :id="facet.name"
                      v-model="facet.searchTerm"
                      :name="facet.name"
                      :placeholder="`Search for ${facet.name}`"
                      autocomplete="off"
                      class="block w-full rounded-md border px-xsSpace py-2xsSpace text-sm"
                      type="text"
                      @keyup="searchFacet(facet)"
                      @click.stop.prevent
                    />
                  </label>
                  <button
                    v-if="facet.noResults"
                    class="btn-secondary btn-sm"
                    type="button"
                    @click.stop.prevent="clearNoResult(facet)"
                  >
                    Search
                  </button>
                </div>
                <div v-show="facet.noResults" class="no-results">
                  <p>
                    There were not results for
                    {{ facet.searchTerm }}.
                  </p>
                </div>

                <ul>
                  <template v-for="(option, index) in facet.refines">
                    <li v-if="index < facet.displaying && option.visible">
                      <label
                        :key="`${facet.name}-${option.label}`"
                        :for="`${facet.name}-${option.label}`"
                        class="flex cursor-pointer items-center rounded px-xsSpace py-2xsSpace hover:bg-neutral-100"
                        @click.stop
                      >
                        <input
                          :id="`${facet.name}-${option.label}`"
                          v-model="option.selected"
                          class="form-checkbox cursor-pointer rounded"
                          type="checkbox"
                          :checked="option.selected === option.value"
                          @change="updatePayload(option, facet.key)"
                        />
                        <span class="ml-2">{{ option.label }}</span>
                      </label>
                    </li>
                  </template>
                </ul>
                <footer v-if="!facet.noResults && facet.refines.length > limit">
                  <a
                    v-if="facet.viewAll"
                    class="cursor-pointer hover:bg-neutral-100"
                    @click.stop.prevent="toggleLess(facet)"
                  >
                    <small>Show less...</small>
                  </a>
                  <a
                    v-if="facet.refines.length > limit && facet.viewAll === false"
                    class="cursor-pointer hover:bg-neutral-100"
                    @click.stop.prevent="toggleAll(facet)"
                  >
                    <small>View all</small>
                  </a>
                </footer>
              </div>
            </li>
          </template>
        </ul>
        <template v-for="(facet, index) in facets">
          <ul class="-my-smSpace mt-smSpace divide-y text-sm [&>li]:py-smSpace">
            <li
              v-if="['date_range', 'date'].includes(facet.type)"
              :key="[facet.name] + [index]"
              class="relative space-y-smSpace border-t"
            >
              <span v-html="facet.name" class="text-sm" />
              <date-picker
                v-model:value="facet.value"
                :append-to-body="false"
                :clearable="false"
                :placeholder="facet.type === 'date_range' ? `Range for ${facet.name}` : 'Select a Date'"
                :range="facet.type === 'date_range'"
                format="DD/MM/YYYY"
                type="date"
                @change="updatePayload"
                class="!w-full"
              />
            </li>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import { sortBy, uniqBy } from "lodash";
import moment from "moment";
import axios from "axios";

export default {
  name: "VFilter",
  inject: ["bus", "config"],
  components: { DatePicker },
  props: {
    entity: {
      type: String,
    },
    filterEmitsTo: {
      type: String,
      default: "tableFilterChange",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    filterEndpoint: {
      type: String,
      default: null,
    },
    isUseDefaultDateFormat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      opened: false,
      limit: this.config.defaultFilterDisplay,
      loading: false,
      facets: [],
    };
  },
  computed: {
    selections: function () {
      let count = 0;
      const refines = this.getFacets();
      const dateRanges = this.getFacets("date_range");
      const dates = this.getFacets("date");
      if (refines.length) {
        count += refines
          .filter((facet) => facet.type === "refines")
          .map((facet) => {
            return facet.refines.some((option) => {
              return option.selected;
            });
          })
          .filter((opt) => opt === true).length;
      }
      if (dateRanges.length) {
        dateRanges.forEach((dateRange) => {
          if (dateRange.value) {
            count++;
          }
        });
      }
      if (dates.length) {
        dates.forEach((date) => {
          if (date.value) {
            count++;
          }
        });
      }
      return count;
    },
    filterUrl() {
      return this.filterEndpoint || `/api/admin/${this.entity}/filters`;
    },
  },
  beforeUnmount() {
    this.bus.$off("tableFilterChange");
  },
  mounted() {
    this.bus.$on("loadingItems", (loading) => {
      this.loading = loading;
    });

    this.bus.$on("tableFilterChange", ({ params, field }) => {
      if (Object.keys(params).length && field) {
        let value;
        const newFilter = params[`filter[${field}]`];
        const dataFilter = this.facets.find((obj) => obj.key === field);
        if (field === "date") {
          value = newFilter ? this.prepareDateFilter(newFilter, this.config.DATETIME_FORMAT) : null;
        } else {
          value = newFilter;
        }

        if (dataFilter) {
          dataFilter.value = value;
        }
      }
    });

    let locationSearch = window.location.search;
    if (["appointments", "bookings"].includes(this.entity)) {
      const sessionDate = sessionStorage.getItem("filterDate");
      if (!locationSearch && sessionDate) {
        locationSearch = "?filter[date]=" + sessionDate;
      }
    }
    axios.get(this.filterUrl + locationSearch).then((res) => {
      let needTriggerChange = false;
      this.facets = res.data.map((filter) => {
        if (["date_range", "date"].includes(filter.type)) {
          let value = null;
          if (filter.default) {
            value = this.prepareDateFilter(filter.default);
          }
          if (value) {
            needTriggerChange = true;
          }

          return {
            ...filter,
            value: value,
          };
        }

        if (filter.type === "refines") {
          if (
            filter.refines.filter(function (o) {
              return o.selected;
            })
          ) {
            needTriggerChange = true;
          }

          return {
            ...filter,
            displaying: this.config.defaultFilterDisplay,
            viewAll: false,
            searchTerm: null,
            noResults: false,
            active: false,
          };
        }

        return filter;
      });
      if (needTriggerChange) {
        this.updatePayload();
      }
    });

    this.searchFacet = this.debounce((facet) => {
      if (facet.api_mode) {
        axios
          .get(`/api/admin/${this.entity}/filters/facets/${facet.key}?s=${facet.searchTerm}&all=true`)
          .then((res) => {
            const selected = this.facets[this.facets.indexOf(facet)].refines.filter((o) => {
              return o.selected;
            });
            this.facets[this.facets.indexOf(facet)].refines = Object.assign(
              [],
              sortBy(
                uniqBy(selected.concat(res.data), (x) => x.value),
                (o) => !o.selected,
              ),
            );
            this.facets[this.facets.indexOf(facet)].noResults = !res.data.length;
            this.facets[this.facets.indexOf(facet)].displaying = res.data.length;
          });
      }
    });
  },
  methods: {
    clearDate(facet) {
      facet.value = null;
    },
    toggleFacet(facet) {
      this.closeRefines(facet);
      facet.active = !facet.active;
    },
    padZero(n) {
      return n < 10 ? `0${n}` : n;
    },
    isoFormatDate(date) {
      if (this.isUseDefaultDateFormat) {
        return `${this.padZero(date.getDate())}-${this.padZero(date.getMonth() + 1)}-${date.getFullYear()}`;
      }
      return `${date.getFullYear()}-${this.padZero(date.getMonth() + 1)}-${this.padZero(date.getDate())}`;
    },
    prepareDateFilter(dateFilter, format = "DD/MM/YYYY") {
      return dateFilter.includes(",")
        ? dateFilter.split(",").map((date) => {
            return moment(date, format).toDate();
          })
        : moment(dateFilter, format).toDate();
    },
    updatePayload(selectedOption = null, key = null) {
      const payload = {};
      const refines = this.getFacets();
      const dateRanges = this.getFacets("date_range");
      const dates = this.getFacets("date");

      refines.forEach((facet) => {
        if (facet.value) {
          payload[facet.key] = facet.value;
        } else {
          facet.refines.forEach((option) => {
            if (facet.is_radio && selectedOption && facet.key === key && selectedOption.value !== option.value) {
              option.selected = false;
            }

            if (option.selected) {
              if (!payload[facet.key]) {
                payload[facet.key] = [];
              }

              payload[facet.key].push(option.value);
            }
          });
        }
      });
      dateRanges.forEach((facet) => {
        if (facet.value && facet.value[0] && facet.value[1]) {
          const start = facet.value[0];
          const end = facet.value[1];
          const startDate = this.isoFormatDate(start);
          const endDate = this.isoFormatDate(end);
          payload[`${facet.key}`] = [startDate, endDate];
        }
      });
      dates.forEach((facet) => {
        if (facet.value) {
          payload[`${facet.key}`] = this.isoFormatDate(facet.value);
        }
      });
      const params = {};
      for (let [key, value] of Object.entries(payload)) {
        value = Array.isArray(value) ? value.join(",") : value;
        params[`filter[${key}]`] = value;
      }

      // TODO: apply filter into URL
      // const filterParams = new URLSearchParams(params).toString();
      // if (filterParams) {
      //   window.history.pushState({}, "", "?" + decodeURIComponent(filterParams));
      // }
      this.bus.$emit(
        "infoItems",
        refines
          .map((facet) => {
            return {
              name: facet.name,
              selected: facet.refines.filter((option) => {
                return option.selected;
              }),
            };
          })
          .filter((o) => {
            return o.selected.length;
          }),
      );

      this.bus.$emit(this.filterEmitsTo, { params });
    },
    clearAll() {
      const refines = this.getFacets();
      refines.map((facet) => facet.refines.map((refines) => (refines.selected = false)));
      refines.map((facet) => facet.refines.map((refines) => (refines.visible = true)));
      refines.map((refines) => (refines.searchTerm = null));
      refines.map((refines) => (refines.noResults = null));
      this.closeRefines();
      this.getFacets("date_range").map((range) => (range.value = null));
      this.updatePayload();
      history.pushState({}, "", window.location.pathname);
    },
    getFacets(type = "refines") {
      return this.facets.filter((facet) => facet.type === type);
    },
    closeRefines(facet) {
      if (facet) {
        this.getFacets().forEach((refine) => {
          if (refine !== facet) {
            return (refine.active = false);
          }
        });
        return;
      }
      this.getFacets().map((refine) => (refine.active = false));
    },
    onClickOutside(ev) {
      if (this.opened) {
        this.opened = false;
      }
    },
    clearNoResult(facet) {
      this.facets[this.facets.indexOf(facet)].searchTerm = null;
      this.facets[this.facets.indexOf(facet)].noResults = false;
      this.searchFacet(facet);
    },
    noResults(facet) {
      this.facets[this.facets.indexOf(facet)].noResults = this.facets[this.facets.indexOf(facet)].refines.every(
        (option) => option.visible === false,
      );
    },
    toggleAll(facet) {
      this.searchFacet(facet);
      facet.displaying = facet.refines.length;
      this.facets[this.facets.indexOf(facet)].viewAll = true;
    },
    toggleLess(facet) {
      facet.displaying = this.limit;
      this.facets[this.facets.indexOf(facet)].viewAll = false;
    },
    debounce(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
