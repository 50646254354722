<template>
  <div class="max-w-xl space-y-lgSpace pt-xlSpace">
    <form class="grid grid-cols-1 gap-smSpace">
      <input-wrapper field="venue_id" :form="form" :label-text="$t('room.columns.venue')">
        <v-multi-select
          v-model="form.venue_id"
          search-url="/api/admin/venues?filter[active]=1"
          :placeholder="$t('room.columns.venue')"
          track-by="id"
          label="name"
          search-key="filter[name]"
        />
      </input-wrapper>

      <input-wrapper field="name" :form="form" :label-text="$t('room.columns.name')">
        <input type="text" v-model="form.name" :placeholder="$t('room.placeholders.name')" />
      </input-wrapper>

      <input-wrapper
        field="deputy_operational_unit_id"
        :form="form"
        :label-text="$t('room.columns.deputy_operational_unit_id')"
      >
        <div class="v--multi-select">
          <multiselect
            v-model="operationalUnitSelected"
            :options="operationalUnitOptions"
            :value="operationalUnitOptions"
            track-by="id"
            label="operational_unit_name"
            :preserve-search="true"
            :loading="operationalUnitOptionsLoading"
            :placeholder="$t('room.columns.deputy_operational_unit_id')"
          />
        </div>
      </input-wrapper>

      <input-wrapper field="selected_products" :form="form" :label-text="$t('room.columns.product')">
        <v-multi-select
          v-model="form.selected_products"
          search-url="/api/admin/products"
          :placeholder="$t('room.placeholders.select_products')"
          :is-multiple="true"
          track-by="id"
          label="name"
          search-key="filter[product]"
        />
      </input-wrapper>
      <input-wrapper field="capacity" :form="form" :label-text="$t('room.columns.capacity')">
        <input type="number" min="1" v-model="form.capacity" :placeholder="$t('room.placeholders.capacity')" />
      </input-wrapper>

      <input-wrapper field="forecast_days" :form="form" :label-text="$t('room.columns.forecast_days')">
        <input type="text" v-model="form.forecast_days" :placeholder="$t('room.placeholders.forecast_days')" />
      </input-wrapper>

      <v-checkbox v-model="form.gift_voucher_only" :label-text="$t('room.columns.gift_voucher_only')"></v-checkbox>
      <v-checkbox v-model="form.admin_only" :label-text="$t('room.columns.admin_only')"></v-checkbox>
      <v-checkbox v-model="form.active" :label-text="$t('generic.words.active')"></v-checkbox>
    </form>
    <v-footer :list-url="listUrl" :save="save"></v-footer>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import multiselect from "vue-multiselect";
import axios from "axios";

export default {
  name: "RoomEdit",
  inject: ["notificationService"],
  components: { multiselect },
  props: {
    room: {
      type: Object,
      default: () => {
        return {};
      },
    },
    listUrl: {
      type: String,
      required: true,
    },
    deputyOrganisationUnitSelectEndpoint: {
      type: String,
      required: true,
    },
    deputyOperationalUnit: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      operationalUnitOptions: [],
      operationalUnitSelected: this.deputyOperationalUnit,
      operationalUnitOptionsLoading: false,
      form: new Form(
        {
          id: this.getFieldValue("id"),
          name: this.getFieldValue("name"),
          forecast_days: this.getFieldValue("forecast_days"),
          selected_products: this.getFieldValue("product_ids"),
          active: Boolean(Number(this.getFieldValue("active"))),
          venue_id: this.getFieldValue("venue_id") || null,
          capacity: this.getFieldValue("capacity") || 1,
          deputy_operational_unit_id: this.getFieldValue("deputy_operational_unit_id") || null,
          gift_voucher_only: Boolean(Number(this.getFieldValue("gift_voucher_only"))),
          admin_only: Boolean(this.getFieldValue("admin_only")),
        },
        { resetOnSuccess: false },
      ),
    };
  },

  computed: {
    deputyOrganisationUnitSelectUrl() {
      return this.deputyOrganisationUnitSelectEndpoint + "?filter[venue_id]=" + this.form.venue_id;
    },
  },

  created() {
    this.findAreas();
  },

  watch: {
    "form.venue_id"() {
      this.form.deputy_operational_unit_id = null;
      this.findAreas();
    },
    operationalUnitSelected(newValue) {
      this.form.deputy_operational_unit_id = newValue?.id || null;
    },
  },

  methods: {
    findAreas() {
      this.operationalUnitOptionsLoading = true;
      axios
        .get(this.deputyOrganisationUnitSelectUrl)
        .then(({ data }) => {
          this.operationalUnitOptions = data.data;
          this.operationalUnitOptionsLoading = false;
        })
        .catch(console.error);
    },
    getFieldValue(field, defaultValue = null) {
      if (this.room.hasOwnProperty(field)) {
        return this.room[field];
      }

      return defaultValue;
    },
    save() {
      if (this.room.hasOwnProperty("id")) {
        this.form
          .patch(`/api/admin/rooms/${this.room.id}`)
          .then(({}) => {
            window.location = this.listUrl;
          })
          .catch(({ response }) => {
            this.notificationService.notify(response.data.message, "error");
          });

        return;
      }

      this.form
        .post(`/api/admin/rooms`)
        .then(({ data }) => {
          window.location = this.listUrl;
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
  },
};
</script>
