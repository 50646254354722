<template>
  <div class="max-w-xl space-y-lgSpace">
    <form class="grid grid-cols-1 gap-smSpace">
      <div class="py-mdSpace">
        <h3 class="text-base font-semibold leading-6 text-gray-900">{{ $t("variant.headings.detail") }}</h3>
        <p class="mt-xsSpace text-sm text-gray-500">{{ $t("variant.headings.description") }}</p>
      </div>
      <input-wrapper field="name" :form="form" :label-text="$t('variant.fields.name')">
        <input type="text" v-model="form.name" :placeholder="$t('variant.placeholders.name')" />
      </input-wrapper>
      <input-wrapper field="price_per_unit" :form="form" :label-text="$t('variant.fields.price_per_unit')">
        <input type="number" v-model="form.price_per_unit" :placeholder="$t('variant.placeholders.price_per_unit')" />
      </input-wrapper>
      <input-wrapper field="description" :form="form" :label-text="$t('variant.fields.description')">
        <v-textarea :placeholder="$t('variant.placeholders.description')" v-model="form.description" />
      </input-wrapper>

      <input-wrapper
        field="form.xero_code_overrides"
        :form="form"
        label-text="Add one or more override xero codes to subtract from the price per unit"
        class="!bottom-5"
      >
        <div class="flex flex-col space-y-mdSpace">
          <template v-for="(item, index) in form.xero_code_overrides">
            <div class="grid grid-cols-2 gap-2">
              <input-wrapper
                field="item.xero_code"
                :form="form"
                :label-text="$t('variant.labels.xero_code')"
                class="justify-between"
              >
                <v-multi-select
                  v-model="item.xero_code"
                  search-url="/api/admin/xero/xero_codes"
                  :placeholder="$t('variant.labels.xero_code')"
                  :is-multiple="false"
                  track-by="code"
                  label="select_option"
                  search-key="filter[full_search]"
                />
              </input-wrapper>
              <input-wrapper field="item.value" :form="form" :label-text="$t('variant.labels.value')" class="relative">
                <input type="text" v-model="item.value" :placeholder="$t('variant.placeholders.value')" />
                <a
                  id="remove_fields"
                  @click="remove(index)"
                  class="absolute -right-8 top-5 cursor-pointer items-center hover:text-red-500"
                >
                  <v-icon icon="TrashIcon"></v-icon>
                </a>
              </input-wrapper>
            </div>
          </template>
        </div>
        <div class="!mt-4">
          <a id="add_more_fields" @click="add" class="cursor-pointer hover:text-green-500">
            <v-icon icon="PlusIcon"></v-icon>
          </a>
        </div>
      </input-wrapper>

      <v-checkbox v-model="gstInclude" :label-text="gstLabel"></v-checkbox>
      <input-wrapper
        v-show="!gstInclude"
        field="gst_per_unit"
        :form="form"
        :label-text="$t('variant.fields.gst_per_unit')"
      >
        <input type="number" v-model="form.gst_per_unit" :placeholder="$t('variant.placeholders.gst_per_unit')" />
      </input-wrapper>
      <v-checkbox v-model="form.public" :label-text="$t('product.fields.public')"></v-checkbox>
    </form>
    <v-footer :save="save">
      <a class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')" @click.prevent="cancel"></a>
    </v-footer>
  </div>
</template>
<script>
import Form from "form-backend-validation";

export default {
  name: "VariantEdit",
  inject: ["bus", "notificationService"],
  props: {
    product: {
      type: Object,
      required: true,
    },
    variant: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      item: this.variant,
      form: new Form(),
      gstInclude: false,
    };
  },
  watch: {
    gstInclude() {
      this.form.gst_per_unit = 0;
    },
  },

  mounted() {
    this.form = new Form(
      {
        id: this.getFieldValue("id"),
        xero_code_overrides: this.getFieldValue("xero_code_overrides", null, [{ xero_code: null, value: null }]),
        name: this.getFieldValue("name"),
        description: this.getFieldValue("description"),
        price_per_unit: this.getFieldValue("price_per_unit"),
        gst_per_unit: this.getFieldValue("gst_per_unit"),
        public: Boolean(Number(this.getFieldValue("public"))),
      },
      { resetOnSuccess: false },
    );
    this.gstInclude = this.form.gst_per_unit === 0;
  },
  computed: {
    gstLabel() {
      if (this.gstInclude) {
        return this.$t("variant.fields.include_gst");
      }
      return this.$t("variant.fields.excludes_gst");
    },
  },

  methods: {
    add() {
      this.form.xero_code_overrides.push({ xero_code: null, value: null });
    },
    remove(index) {
      this.form.xero_code_overrides.splice(index, 1);
    },
    getFieldValue(field, children = null, defaultValue = null) {
      if (!this.item) {
        return defaultValue;
      }

      if (this.item.hasOwnProperty(field)) {
        return this.item[field];
      }
    },
    cancel() {
      this.bus.$emit("backToIndex");
    },
    save() {
      if (this.item.hasOwnProperty("id")) {
        this.form
          .patch(`/api/admin/products/${this.product.id}/variants/${this.item.id}`)
          .then(({}) => {
            this.bus.$emit("backToIndex");
            this.bus.$emit("showNotification", {
              content: this.$t("generic.responses.update_success"),
              type: "success",
            });
          })
          .catch(({ response }) => {
            this.notificationService.notify(response.data.message, "error");
          });

        return;
      }

      this.form
        .post(`/api/admin/products/${this.product.id}/variants`)
        .then(({}) => {
          this.bus.$emit("backToIndex");
          this.bus.$emit("showNotification", {
            content: this.$t("generic.responses.create_success"),
            type: "success",
          });
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
  },
};
</script>
