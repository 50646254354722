<template>
  <div class="mb-10 space-y-smSpace">
    <div v-if="loading" aria-label="Loading..." class="my-auto flex h-[300px] items-center justify-center space-x-2">
      <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
      <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
    </div>
    <collapsible v-for="(category, index) in categories" :key="index" :context="index">
      <collapsible-trigger class="cursor-pointer justify-between border-b py-smSpace" :context="index">
        <template v-slot="{ collapsed }">
          <span class="text-md flex-none font-semibold text-tertiary-600">
            {{ category.name }} ({{ category.vouchers.length }})
          </span>
          <transition type="transition">
            <VIcon
              icon="ChevronDownIcon"
              class="h-6 w-6 transform cursor-pointer text-gray-400 transition-transform duration-300 ease-in-out hover:text-gray-800"
              :class="collapsed ? 'rotate-180' : ''"
            ></VIcon>
          </transition>
        </template>
      </collapsible-trigger>
      <collapsible-content :context="parent">
        <voucher-list
          :category-id="category.id"
          :vouchers="category.vouchers"
          :fields="voucherFields"
          @onReorder="handleVoucherReorder"
        >
        </voucher-list>
      </collapsible-content>
    </collapsible>
  </div>
</template>

<script>
import VoucherList from "@/js/vue/components/gift-vouchers/List/VoucherList.vue";

export default {
  name: "SortableCollapseVoucherList",
  inject: ["config", "notificationService"],
  components: { VoucherList },
  props: {
    voucherFields: {
      type: Array,
      required: true,
    },
  },
  computed: {},
  data() {
    return {
      categories: [],
      voucherMove: {
        id: null,
        toCategory: null,
        newIndex: null,
      },
      loading: null,
    };
  },
  created() {
    this.getVouchers();
  },
  methods: {
    getVouchers() {
      this.loading = true;
      axios
        .get("/api/admin/gift-vouchers/with-category")
        .then((response) => {
          this.categories = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleVoucherReorder(categoryId, newOrderIds) {
      this.attachOrDetachThenReorderVouchers({
        has_attach_or_detach: false,
        is_bath_pass: categoryId,
        ids: newOrderIds,
      });
    },
    attachOrDetachThenReorderVouchers(data) {
      axios.post("/api/admin/gift-vouchers/reorder", data).then((response) => {
        this.notificationService.notify(response.data.statusText, "success");
        this.voucherMove = {
          id: null,
          toCategory: null,
          newIndex: null,
        };
      });
    },
  },
};
</script>
