<template>
  <div class="flex w-full flex-col justify-between" v-if="!loading">
    <div class="grow space-y-lgSpace p-lgSpace">
      <div class="grid grid-cols-1 gap-smSpace">
        <!-- manage staff start -->
        <div class="grid grid-cols-1 font-semibold">
          <div class="flex justify-between text-lg">
            <span> {{ shift.staff_name }}</span>
            <v-icon @click="changeStaff = !changeStaff" icon="PencilSquareIcon"></v-icon>
          </div>
          <div class="py-xsSpace" v-if="changeStaff">
            <v-multi-select
              v-model="staffId"
              search-url="/api/admin/users?filter[active]=1&filter[role_id]=5"
              :placeholder="$t('roster.placeholders.staff')"
              track-by="id"
              label="full_name"
              search-key="filter[full_name]"
              @selectOption="selectStaffOption"
            />
          </div>
          <div>{{ shift.name }}</div>
          <div>{{ shift.start }} - {{ shift.stop }}</div>
        </div>
        <!-- manage staff end -->

        <!-- manage deputy start -->
        <div class="grid grid-cols-1 font-semibold">
          <div class="flex justify-between text-lg">
            <span> {{ $t("deputy_roster.headings.title") }}</span>

            <!-- start  exiting deputy roster -->
            <div class="rounded-md p-mdSpace shadow-lg ring-1 ring-neutral-100" v-if="shift.isDeputyLinked">
              <div class="space-y-smSpace">
                <div class="space-y-mdSpace">
                  <div class="flex flex-wrap items-center justify-between sm:space-x-xsSpace">
                    <div class="flex items-center justify-between space-x-xsSpace">
                      <div class="flex items-center text-sm">
                        <span class="capsize text-sm leading-none"> #{{ shift.deputyRoster.deputy_id }} </span>
                      </div>
                    </div>

                    <div class="flex items-center">
                      <span
                        class="flex h-xlSpace w-xlSpace cursor-pointer items-center justify-center rounded-lg hover:bg-tertiary-100"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="yellow" class="h-6 w-6">
                          <path
                            fill-rule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>

                  <p class="capsize text-base leading-none">
                    {{ $t("deputy_roster.fields.start_time") }}
                    {{ shift.deputyRoster.local_start_time }} |
                    {{ $t("deputy_roster.fields.end_time") }}
                    {{ shift.deputyRoster.local_end_time }}
                  </p>

                  <div class="flex items-start justify-between text-sm">
                    <p class="capsize text-base leading-none">
                      {{ $t("deputy_roster.fields.total_time") }}
                      {{ shift.deputyRoster.total_time }} | {{ $t("deputy_roster.fields.open") }}
                      {{ shift.deputyRoster.open }} |
                      {{ $t("deputy_roster.fields.approval_required") }}
                      {{ shift.deputyRoster.approval_required }} |
                      {{ $t("deputy_roster.fields.published") }}
                      {{ shift.deputyRoster.published }}
                      {{ $t("deputy_roster.fields.operational_unit") }}
                      {{ shift.deputyRoster.deputy_operational_unit_name }} |
                      {{ $t("deputy_roster.fields.employee") }}
                      {{ shift.deputyRoster.employee_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- end exiting deputy roster -->

            <!-- start alternate deputy rosters -->
            <div
              class="rounded-md p-mdSpace shadow-lg ring-1 ring-neutral-100"
              v-for="deputyRoster in deputyRosters"
              :key="deputyRoster.id"
            >
              <div class="space-y-smSpace">
                <div class="space-y-mdSpace">
                  <div class="flex flex-wrap items-center justify-between sm:space-x-xsSpace">
                    <div class="flex items-center justify-between space-x-xsSpace">
                      <div class="flex items-center text-sm">
                        <span class="capsize text-sm leading-none"> #{{ deputyRoster.deputy_id }} </span>
                      </div>
                    </div>

                    <div class="flex items-center">
                      <span
                        class="flex h-xlSpace w-xlSpace cursor-pointer items-center justify-center rounded-lg hover:bg-tertiary-100"
                        @click="UpdateRoster(deputyRoster.id)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="h-6 w-6 hover:text-green-600"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>

                  <p class="capsize text-base leading-none">
                    {{ $t("deputy_roster.fields.start_time") }}
                    {{ deputyRoster.local_start_time }} |
                    {{ $t("deputy_roster.fields.end_time") }}
                    {{ deputyRoster.local_end_time }}
                  </p>

                  <div class="flex items-start justify-between text-sm">
                    <p class="capsize text-base leading-none">
                      {{ $t("deputy_roster.fields.total_time") }} {{ deputyRoster.total_time }} |
                      {{ $t("deputy_roster.fields.open") }} {{ deputyRoster.open }} |
                      {{ $t("deputy_roster.fields.approval_required") }}
                      {{ deputyRoster.approval_required }} |
                      {{ $t("deputy_roster.fields.published") }}
                      {{ deputyRoster.published }}
                      {{ $t("deputy_roster.fields.operational_unit") }}
                      {{ deputyRoster.deputy_operational_unit_name }} |
                      {{ $t("deputy_roster.fields.employee") }}
                      {{ deputyRoster.employee_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- end alternate deputy rosters -->
          </div>
        </div>

        <!-- manage deputy end -->

        <v-notification v-if="message" class="pt-lgSpace" type="success" :content="message"></v-notification>
        <div class="space-y-lgSpace">
          <v-tabs :tabs="tabData">
            <template v-slot:history>
              <h1>History</h1>
              <v-activity-log
                :model-id="shift.id"
                :model-class="shift.modelClass"
                :allow-comment="true"
                :current-user="currentUser"
                :timezone="appConfigs.default_timezone"
              ></v-activity-log>
            </template>
            <template v-slot:notes>
              <h1>Notes</h1>
            </template>
          </v-tabs>
        </div>
      </div>
    </div>

    <div
      class="flex items-end justify-end rounded p-lgSpace sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
    >
      <div class="flex space-x-xsSpace">
        <a @click="cancel" class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')"></a>
        <a
          @click.prevent="saveStaff"
          class="btn-primary btn-sm"
          :class="[shift.staff_id === staffId && 'btn-disabled']"
          v-text="$t('generic.buttons.save_confirm')"
        ></a>
      </div>
    </div>
  </div>
  <div v-else aria-label="Loading..." role="status" class="flex h-full items-center justify-center space-x-2">
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>
<script>
import axios from "axios";

export default {
  inject: ["bus"],
  components: {},
  props: {
    shiftId: {
      type: Number,
    },
    currentUser: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      deputyRosters: [],
      shift: {},
      staffId: null,
      changeStaff: false,
      message: null,
      tabData: [
        { name: "History", active: true },
        { name: "Notes", active: false },
      ],
    };
  },
  mounted() {},
  created() {
    this.getShiftDetail();
  },
  methods: {
    getShiftDetail() {
      this.loading = true;
      axios.get(`/api/admin/shifts/${this.shiftId}`).then(({ data }) => {
        this.shift = data;
        this.staffId = data.staff_id;

        this.getAvailableDeputyRosters();
        this.loading = false;
      });
    },

    getAvailableDeputyRosters() {
      this.loading = true;
      axios
        .get(
          `/api/admin/deputy_rosters?filter[starts_after]=${this.shift.deputy_alternate_start_time}&filter[ends_before]=${this.shift.deputy_alternate_end_time}&filter[exclude_ids]=${this.shift.deputy_roster_id}&filter[deputy_operational_unit_id]=${this.shift.room.deputy_operational_unit_id}&filter[room_id]=${this.shift.room_id}`,
        )
        .then(({ data }) => {
          this.deputyRosters = data.data;
          this.loading = false;
        });
    },

    UpdateRoster(rosterId) {
      this.loading = true;
      axios
        .post(`/api/admin/shifts/${this.shiftId}/assign-deputy-roster`, { deputy_roster_id: rosterId })
        .then((data) => {
          this.loading = false;
          this.message = data.statusText;
          this.getShiftDetail();
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    cancel() {
      this.bus.$emit("closePanel");
    },

    saveStaff() {
      this.loading = true;
      axios
        .post(`/api/admin/shifts/${this.shiftId}/assign-user`, { staff_id: this.staffId })
        .then((data) => {
          this.loading = false;
          this.message = data.statusText;
          setTimeout(() => {
            this.bus.$emit("closePanel");
            this.bus.$emit("tableFilterChange", { params: {} });
          }, 1500);
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    selectStaffOption($event) {
      this.shift.staff_name = $event ? $event.full_name : "Add Staff";
    },
  },
};
</script>
