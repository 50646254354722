<template>
  <div class="mb-10 space-y-smSpace">
    <collapsible v-for="(categories, parent) in groupCategories" :key="parent" :context="parent">
      <collapsible-trigger
        class="cursor-pointer justify-between border-b py-smSpace"
        :context="parent"
        :disabled="rootCollapseDisabled === parent"
      >
        <template v-slot="{ collapsed }">
          <editable
            :model-value="parent"
            @update:model-value="updateParentName($event, categories[0].parent_id)"
            @editing="disableCollapse($event, parent)"
          >
            <template v-slot="{ value }">
              <span class="text-md flex-none font-semibold text-tertiary-600">
                {{ value }} ({{ categories.length }})
              </span>
            </template>
          </editable>
          <transition type="transition">
            <VIcon
              icon="ChevronDownIcon"
              class="h-6 w-6 transform cursor-pointer text-gray-400 transition-transform duration-300 ease-in-out hover:text-gray-800"
              :class="collapsed ? 'rotate-180' : ''"
            ></VIcon>
          </transition>
        </template>
      </collapsible-trigger>
      <collapsible-content :context="parent">
        <category-list-item
          :parent="parent"
          :categories="categories"
          :product-fields="productFields"
        ></category-list-item>
      </collapsible-content>
    </collapsible>
  </div>
</template>

<script>
import CategoryListItem from "@/js/vue/components/products/List/CategoryListItem.vue";
import Editable from "@/js/vue/components/common/ui/Editable.vue";
import Form from "form-backend-validation";

export default {
  name: "SortableCollapseProductList",
  inject: ["config"],
  components: { Editable, CategoryListItem },
  props: {
    productFields: {
      type: Array,
      required: true,
    },
  },
  computed: {
    groupCategories() {
      return this.productCategoriesWithNestedProducts.reduce((carry, category) => {
        if (carry.hasOwnProperty(category.parent)) {
          carry[category.parent].push(category);
        } else {
          carry[category.parent] = [category];
        }

        return carry;
      }, {});
    },
  },
  data() {
    return {
      productCategoriesWithNestedProducts: [],
      productMove: {
        id: null,
        toCategory: null,
        newIndex: null,
      },
      nameForm: new Form(
        {
          update_data: {},
          model_class: this.modelClass,
          model_id: null,
        },
        { resetOnSuccess: false },
      ),
      rootCollapseDisabled: null,
    };
  },
  created() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      axios
        .get("/api/admin/products/with-category")
        .then((response) => {
          this.productCategoriesWithNestedProducts = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    disableCollapse(disabled, parent) {
      this.rootCollapseDisabled = disabled ? parent : null;
    },
    updateParentName(newName, id) {
      if (!id) return;

      this.nameForm
        .populate({ update_data: { name: newName }, model_id: id, model_class: this.config.modelClasses.category })
        .put("/api/admin/generic/update")
        .then(() => {
          this.productCategoriesWithNestedProducts = [
            ...this.productCategoriesWithNestedProducts.map((cat) => {
              if (cat.parent_id === id) {
                cat.parent = newName;
              }
              return cat;
            }),
          ];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
