<template>
  <div class="space-y-lgSpace p-lgSpace">
    <div class="space-y-lgSpace">
      <div class="text-lg font-bold">{{ $t("gift_voucher_instance.headings.gift_voucher_instance") }}</div>
    </div>
    <v-notification v-if="error" type="error" :content="error" />
    <form class="grid gap-smSpace">
      <input-wrapper field="code" :form="form" :label-text="$t('gift_voucher_instance.fields.name')">
        <input type="text" v-model="form.code" :placeholder="$t('gift_voucher_instance.placeholders.code')" />
      </input-wrapper>
      <input-wrapper field="selected_venues" :form="form" :label-text="$t('gift_voucher.fields.venue')">
        <v-multi-select
          v-model="form.selected_venues"
          search-url="/api/admin/venues?filter[active]=1"
          :placeholder="$t('gift_voucher.fields.venue')"
          :is-multiple="true"
          track-by="id"
          label="name"
          search-key="filter[name]"
        />
      </input-wrapper>
      <v-checkbox
        class="flex items-center space-x-xsSpace py-mdSpace"
        v-model="form.buyable"
        :label-text="$t('gift_voucher_instance.labels.buyable')"
      />
    </form>
  </div>
  <div
    class="flex w-full flex-col items-end justify-end rounded p-lgSpace sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
  >
    <div class="flex space-x-xsSpace">
      <a @click="close" class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')"></a>
      <a @click.prevent="save" class="btn-primary btn-sm" v-text="$t('generic.buttons.continue')"></a>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";

export default {
  name: "DollarValuesGiftVoucherInstanceCreate",
  inject: ["notificationService", "bus"],
  props: {
    saveUrl: {
      type: String,
      require: true,
    },
  },

  data() {
    return {
      loading: false,
      error: null,
      form: new Form(
        {
          code: null,
          selected_venues: [],
          buyable: false,
        },
        { resetOnSuccess: false },
      ),
    };
  },

  methods: {
    save() {
      this.error = null;
      this.loading = true;

      this.form
        .post(this.saveUrl)
        .then(() => {
          this.loading = false;
          this.bus.$emit("reloadTable");
          this.close();
          this.notificationService.notify(this.$t("generic.responses.create_success"));
        })
        .catch((e) => {
          this.error = e?.response?.data?.message;
          this.loading = false;
        });
    },
    close() {
      this.bus.$emit("closePanel");
    },
  },
};
</script>
