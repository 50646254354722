<template>
  <div class="space-y-6">
    <input-wrapper
      class="text-white2"
      field="email"
      :form="form"
      :label-text="$t('booking.fields.email_required')"
      :dark-theme="true"
    >
      <input
        v-model="form.email"
        type="text"
        class="bg-brand placeholder-white placeholder-opacity-50"
        autofocus
        :placeholder="$t('booking.placeholders.email')"
      />
      <p class="!mt-1">
        <small class="text-sm text-white2/50">{{ $t("booking.fields.email_notification") }}</small>
      </p>
    </input-wrapper>
    <voucher-select
      v-model="form.vouchers"
      :venue-id="cart.venue_id"
      :category-id="form.category_id"
      :dark-theme="true"
    ></voucher-select>
    <div class="space-y-2">
      <label class="text-sm font-medium text-white2">
        {{ $t("booking.labels.what_would_you_like_to_book") }}
      </label>
      <div class="grid grid-cols-2 gap-6">
        <button
          v-for="category in categories"
          class="btn-secondary !block items-center !bg-brand !py-4 font-sloganSerif !text-[26px] !font-bold !text-white2"
          :class="form.category_id && category.id === form.category_id ? 'opacity-100' : 'opacity-50'"
          @click="form.category_id = category.id"
        >
          {{ category.name }}
        </button>
      </div>
      <div v-if="form.errors.has('category_id')" class="flex w-full rounded-md bg-pink-100 px-xsSpace py-1">
        <v-icon icon="SolidExclamationCircleIcon" classes="w-5 h-5 text-red-400 mr-2xsSpace"></v-icon>
        <small class="block text-sm text-red-500"> {{ form.errors.first("category_id") }}</small>
      </div>
    </div>
    <div class="!mt-4 flex justify-center">
      <button class="btn-secondary !block w-full items-center bg-white !text-brand" @click="continueBooking">
        {{ $t("booking.buttons.next_select_appointment") }}
      </button>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import axios from "axios";

export default {
  inject: ["config", "bus"],
  name: "StartBooking",
  props: {
    cart: {
      type: Object,
      default: () => {
        return {};
      },
    },
    customerId: {
      type: String,
    },
  },
  data() {
    return {
      form: new Form(
        {
          email: this.cart.hasOwnProperty("email") ? this.cart.email : "",
          category_id: this.cart.hasOwnProperty("category_id") ? this.cart.category_id : null,
          vouchers: this.cart.hasOwnProperty("vouchers") ? this.cart.vouchers : [],
        },
        { resetOnSuccess: false },
      ),
      selectedCategory: null,
      categoryIntro: "",
      categories: [],
    };
  },
  mounted() {
    this.initiateCategories();
    if (this.cart.hasOwnProperty("rebookable") && !this.cart.rebookable) {
      this.bus.$emit("openModal", {
        componentName: "NotifyChangeModal",
        componentData: {
          content: this.$t("notification.headings.product_unavailable_content"),
          showAction: false,
        },
      });
    }
  },
  watch: {
    "form.vouchers": {
      handler(newVouchers) {
        if (newVouchers.length === 1 && newVouchers[0] === "") {
          this.form.vouchers = [];
        }
      },
      deep: true,
    },
  },
  methods: {
    initiateCategories() {
      axios.get("/api/generic/product-categories?filter[parent]=true").then(({ data }) => {
        this.categories = data.data.map((o) => {
          o.active = false;

          return o;
        });

        if (this.form.category_id) {
          const category = this.categories.find((o) => o.id === this.form.category_id);
          if (category) {
            this.form.category_id = category.id;
          }
        }
      });
    },
    continueBooking() {
      this.form.post("/api/booking/cart/start").then(() => {
        window.location.href = this.customerId ? `/booking/product?customer=${this.customerId}` : `/booking/product`;
      });
    },
  },
};
</script>
<style scoped>
input::placeholder {
  @apply !text-white2 !opacity-50;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}
</style>
