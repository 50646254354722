<template>
  <div class="relative w-full rounded-lg bg-slate-100 px-smSpace py-smSpace text-sm text-neutral-700">
    <div
      :class="{
        'cursor-not-allowed opacity-60': !isAdminOrManager && (booking.is_cancelled || booking.is_past),
      }"
    >
      <p class="pb-[4px] font-semibold">{{ booking.date }}</p>
      <div class="pb-smSpace text-xs text-neutral-500">
        {{ booking.booking_time }} ({{ booking.with_trashed_appointment.duration }} {{ $t("generic.words.mins") }})
      </div>
      <p class="pb-[4px] font-semibold">{{ booking.title }}</p>
      <p class="border-b pb-smSpace text-xs text-neutral-500">
        {{ booking.variation_title }} (${{ booking.variation_price }})
      </p>
      <div class="absolute right-mdSpace top-smSpace">
        <v-badge
          class="mb-xsSpace !px-xsSpace !py-0.5"
          v-if="booking.is_current"
          type="normal"
          :value="$t('booking.labels.current_bookings')"
        ></v-badge>
        <v-badge class="ml-xsSpace !px-xsSpace !py-0.5" :value="booking.status.value" :type="booking.status.type" />
      </div>
    </div>
    <div
      v-if="booking.add_on && withAddon"
      class="relative"
      :class="{
        'cursor-not-allowed opacity-60': !isAdminOrManager && (booking.add_on.is_cancelled || booking.add_on.is_past),
      }"
    >
      <p class="pt-smSpace text-xs text-neutral-500">{{ $t("booking.labels.add_on") }}</p>
      <p class="pb-[4px] pt-2xsSpace font-semibold">{{ booking.add_on.date }}</p>
      <div class="pb-smSpace text-xs text-neutral-500">
        {{ booking.add_on.booking_time }} ({{ booking.add_on.with_trashed_appointment.duration }}
        {{ $t("generic.words.mins") }})
      </div>
      <p class="pb-[4px] font-semibold">{{ booking.add_on.title }}</p>
      <p class="border-b pb-smSpace text-xs text-neutral-500">
        {{ booking.add_on.variation_title }} (${{ booking.add_on.variation_price }})
      </p>
      <div class="absolute right-1 top-8">
        <v-badge
          class="mb-xsSpace !px-xsSpace !py-0.5"
          v-if="booking.add_on.is_current"
          type="normal"
          :value="$t('booking.labels.current_bookings')"
        ></v-badge>
        <v-badge
          class="ml-xsSpace !px-xsSpace !py-0.5"
          :value="booking.add_on.status.value"
          :type="booking.add_on.status.type"
        />
      </div>
    </div>
    <p class="pb-[4px] pt-smSpace font-semibold">{{ booking.client.display_name }}</p>
    <p class="text-xs text-neutral-500">{{ booking.client.email }}</p>
  </div>
</template>
<script>
export default {
  inject: ["config"],
  props: {
    booking: {
      type: Object,
      required: true,
      default: () => {
        return [];
      },
    },
    withAddon: {
      type: Boolean,
      default: true,
    },
    currentUser: {
      type: Object,
    },
  },
  computed: {
    isAdminOrManager() {
      return (
        this.currentUser &&
        (this.currentUser.roles || []).some((role) =>
          [this.config.roles.admin.toLowerCase(), this.config.roles.manager.toLowerCase()].includes(role.name),
        )
      );
    },
  },
};
</script>
