<template>
  <div class="mt-xsSpace w-full rounded border-x border-t px-xsSpace py-smSpace">
    <div
      class="flex justify-between text-sm"
      :class="{ 'cursor-pointer hover:text-primary-600': isChargeable }"
      @click="toggle"
    >
      <div class="flex items-center gap-x-2">
        <strong class="flex items-center text-sm leading-6">
          {{
            $t(
              booking.payment_details.balance < 0
                ? "booking.labels.amount_extra"
                : "booking.labels.balance_outstanding",
            )
          }}
        </strong>
        <v-badge class="h-[25px]" :type="clientHasCards">
          <v-icon icon="CreditCardIcon" classes="w-4 h-4"></v-icon>
        </v-badge>
        <v-icon :icon="show ? 'ChevronUpIcon' : 'ChevronDownIcon'" v-if="isChargeable"></v-icon>
      </div>
      <strong
        :class="{
          'text-red-600': booking.payment_details.balance > 0,
          'text-yellow-500': booking.payment_details.balance < 0,
        }"
      >
        {{ toAUD(Math.abs(booking.payment_details.balance)) }}
      </strong>
    </div>
    <div v-show="show" class="space-y-smSpace">
      <div v-if="isChargeable" class="mt-smSpace space-x-smSpace">
        <button
          @click.prevent="copyLink(booking.payment_details.quick_pay_url)"
          class="btn-secondary btn-sm my-auto flex h-[25px] cursor-pointer items-center hover:text-blue-600"
        >
          <v-icon icon="LinkIcon" classes="w-4 h-4"></v-icon>
          <span class="px-3xsSpace text-sm">{{ $t("booking.buttons.pay_link") }}</span>
        </button>
        <button
          @click.prevent="copyLink(booking.payment_details.save_card_url)"
          class="btn-secondary btn-sm my-auto flex h-[25px] cursor-pointer items-center hover:text-blue-600"
        >
          <v-icon icon="LinkIcon" classes="w-4 h-4"></v-icon>
          <span class="px-3xsSpace text-sm">{{ $t("booking.buttons.save_card_link") }}</span>
        </button>
      </div>

      <div
        v-if="!booking.payment_details.is_cancelled && booking.payment_details.balance > 0"
        class="space-y-smSpace py-xsSpace"
        :class="{ 'py-mdSpace': paymentForm.payment.type !== config.PAYMENT_CARD }"
      >
        <!-- We dont want to fetch client's cards, this should fetch customer's cards who make the order
        paymentForm.hasOwnProperty('customer_id') ? paymentForm.customer_id : paymentForm.client_id -->

        <payment
          ref="payment"
          :form="paymentForm"
          :customer-id="paymentForm.hasOwnProperty('customer_id') ? paymentForm.customer_id : paymentForm.client_id"
          :product-id="paymentForm.product_id"
          :product-variant-id="paymentForm.product_variant_id"
          :amount="booking.payment_details.balance"
          :quantity="paymentForm.quantity"
          :endpoint="payEndpoint"
          :exclude-methods="excludePaymentMethods"
          v-model:cards="cards"
        />
        <a @click.prevent="pay" class="btn-primary btn-sm float-right rounded">
          <span v-if="!paymentLoading">{{ $t("generic.buttons.pay_now") }}</span>
          <p v-else class="flex items-center gap-3xsSpace">
            <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
            <span>{{ $t("generic.words.processing") }}</span>
          </p>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import Payment from "@/js/vue/mixins/Payment";
import VBadge from "@/js/vue/components/common/ui/VBadge.vue";

export default {
  components: { VBadge },
  inject: ["config"],
  mixins: [Payment],
  props: {
    booking: {
      type: Object,
    },
    notificationMessage: {
      type: String,
    },
    paymentForm: {
      type: Object,
    },
    onPaySuccess: {
      type: Function,
    },
  },
  computed: {
    isChargeable() {
      return this.booking.payment_details.balance > 0 && !this.booking.payment_details.is_cancelled;
    },
    payEndpoint() {
      return `/api/admin/bookings/${this.booking.id}/pay`;
    },
    excludePaymentMethods() {
      return [this.config.PAYMENT_LATER];
    },
    clientHasCards() {
      return this.cards.length > 0 ? "active" : "inactive";
    },
  },
  data() {
    return {
      show: false,
      paymentLoading: false,
      cards: [],
    };
  },
  methods: {
    toggle() {
      if (this.booking.payment_details.is_cancelled) {
        return;
      }

      this.show = !this.show;
    },
    copyLink(url) {
      navigator.clipboard.writeText(url);
      this.$emit("update:notificationMessage", this.$t("generic.responses.copy_success"));
      setTimeout(() => {
        this.$emit("update:notificationMessage", null);
      }, 1500);
    },
    async pay() {
      if (this.paymentLoading) {
        return;
      }

      this.paymentLoading = true;
      if (this.paymentForm.payment.type === this.config.PAYMENT_CARD) {
        try {
          await this.$refs.payment.submit();
          this.onPaySuccess(await this.checkPayments(this.booking.order_id));
          this.show = false;
        } catch (e) {
          console.log(e);
        }

        this.paymentLoading = false;

        return;
      }

      this.paymentForm
        .post(this.payEndpoint)
        .then(async (_data) => {
          this.onPaySuccess(await this.checkPayments(this.booking.order_id));
          this.show = false;
        })
        .finally(() => {
          this.paymentLoading = false;
        });
    },
  },
};
</script>
