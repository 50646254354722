<template>
  <span
    :class="classes"
    class="inline-flex items-center rounded-full px-smSpace py-1 text-xs font-medium"
    :style="style"
  >
    <slot>
      <span v-if="value">
        {{ value }}
      </span>
      <span v-else class="h-4 rounded"></span>
    </slot>
  </span>
</template>
<script>
export default {
  name: "VBadge",
  props: {
    value: {
      default: "Active",
    },
    type: {
      type: String,
      default: "success",
    },
  },
  computed: {
    style() {
      if (this.type && (this.type.includes("#") || this.type.includes("rgb"))) {
        return `background: ${this.type}; color: ${this.getTextColor(this.type)}`;
      }

      return null;
    },
    classes() {
      if (this.type === "inactive") {
        return "bg-neutral-700 text-white border-neutral-700";
      }

      if (this.type === "warning") {
        return "bg-warning-700 text-white";
      }

      if (this.type === "error") {
        return "bg-error-700 text-white";
      }

      if (this.type === "normal") {
        return "text-gray-500 border-gray-500 border";
      }

      if (this.type === "paid") {
        return "bg-success-600 text-white";
      }

      if (this.type === "normal") {
        return "text-gray-500 border-gray-500 border";
      }

      return "bg-success-700 text-white";
    },
  },
  methods: {
    getRGB(c) {
      return parseInt(c, 16) || c;
    },

    getsRGB(c) {
      return this.getRGB(c) / 255 <= 0.03928
        ? this.getRGB(c) / 255 / 12.92
        : Math.pow((this.getRGB(c) / 255 + 0.055) / 1.055, 2.4);
    },

    getLuminance(hexColor) {
      return (
        0.2126 * this.getsRGB(hexColor.substr(1, 2)) +
        0.7152 * this.getsRGB(hexColor.substr(3, 2)) +
        0.0722 * this.getsRGB(hexColor.substr(-2))
      );
    },

    getContrast(f, b) {
      const L1 = this.getLuminance(f);
      const L2 = this.getLuminance(b);
      return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
    },

    getTextColor(bgColor) {
      const whiteContrast = this.getContrast(bgColor, "#ffffff");
      const blackContrast = this.getContrast(bgColor, "#000000");

      return whiteContrast > blackContrast ? "#ffffff" : "#000000";
    },
  },
};
</script>
