<template>
  <div class="grid grid-cols-1 gap-smSpace">
    <input-wrapper field="payment.type" :form="form" :label-text="$t('booking.columns.payment_method')">
      <v-multi-select
        v-if="paymentMethods.length"
        :init-options="paymentMethods"
        placeholder="Select payment method"
        :is-multiple="false"
        :model-value="payment.type"
        @selectOption="selectMethod"
      />
    </input-wrapper>
    <input-wrapper v-if="payment.type === config.PAYMENT_VOUCHER" field="voucher" :form="form">
      <template #label>
        <div class="flex flex-row justify-between">
          <span class="custom-label">{{ $t("booking.columns.gift_voucher") }}</span>
          <v-checkbox
            v-if="isAdminOrManager"
            v-model="useCustomerVoucher"
            :label-text="$t('booking.labels.use_customer_voucher')"
            @click="onUseCustomerVoucher"
          />
        </div>
      </template>
      <div v-if="isAdminOrManager && !useCustomerVoucher" class="relative flex flex-grow items-center">
        <input
          class="no-spinner"
          type="text"
          :placeholder="$t('booking.placeholders.voucher')"
          v-model="payment.voucher"
          @change="vouchersInfo = {}"
        />
        <button
          type="button"
          @click="getVoucherInfo(payment.voucher)"
          class="btn-primary btn-sm absolute right-1 mr-2xsSpace flex h-6 w-16 justify-center !rounded border-2 !text-xs"
        >
          {{ $t("booking.buttons.apply_voucher") }}
        </button>
      </div>
      <v-multi-select
        v-else
        search-key="filter[code]"
        :search-url="searchVoucherUrl"
        placeholder="Select voucher"
        label="detail"
        track-by="code"
        :model-value="payment.voucher"
        :sortOptions="sortVouchers"
        @update:model-value="payment.voucher = $event"
        @selectOption="getVoucherInfo($event?.code)"
      />
      <small
        v-if="vouchersInfo.description"
        @click="applyAmount"
        class="block cursor-pointer text-sm text-green-600"
        v-html="vouchersInfo.description"
      ></small>
      <small v-else-if="voucherError" @click="dismiss" class="block cursor-pointer text-sm text-red-600">
        {{ voucherError }}
      </small>
    </input-wrapper>
    <input-wrapper
      v-if="payment.type !== config.PAYMENT_LATER"
      field="payment.amount"
      :form="form"
      :label-text="$t('booking.columns.payment_value')"
    >
      <input
        type="number"
        v-model="payment.amount"
        :placeholder="$t('booking.placeholders.amount')"
        :readonly="amountDisabled"
      />
    </input-wrapper>
    <input-wrapper v-if="payment.type === config.PAYMENT_CARD" field="payment.token" :form="form">
      <div v-if="cards.length > 0" class="flex items-center justify-end">
        <a
          class="flex cursor-pointer items-center justify-end font-medium text-gray-500 hover:text-gray-700"
          @click="toggleAddNewCard"
        >
          {{ $t(addNewCard ? "payment.buttons.use_existing_card" : "generic.buttons.add_new") }}
        </a>
      </div>
      <square-payment v-if="addNewCard || cards.length === 0" ref="square" />
      <div v-else class="relative">
        <div v-for="card in cards" class="relative mb-smSpace" :key="card.card_id" @click="selectCard(card)">
          <div
            type="text"
            class="wrapper__input input !flex cursor-pointer items-center gap-xsSpace !pt-smSpace font-mono"
            :class="{ '!border !border-gray-300 bg-tertiary-200': payment.token === card.card_id }"
          >
            {{ `**** **** **** ${card.last_4}` }}
            <div class="text-xs">
              {{ card.exp_month.toString().padStart(2, "0") }}/{{ card.exp_year.toString().slice(-2) }}
            </div>
          </div>
          <div class="absolute bottom-0 right-xsSpace top-0 flex items-center text-xs">
            <div class="cursor-pointer rounded border border-gray-400 px-xsSpace py-3xsSpace text-blue-600">
              {{ card.card_brand }}
            </div>
          </div>
        </div>
      </div>
    </input-wrapper>
  </div>
</template>

<script>
import axios from "axios";
import Payment from "~vue/mixins/Payment";
import moment from "moment/moment";

export default {
  inject: ["config"],
  name: "Payment",
  mixins: [Payment],
  props: {
    form: {
      type: Object,
      required: true,
    },
    disabledMethods: {
      type: Array,
      default: () => [],
    },
    excludeMethods: {
      type: Array,
      default: () => [],
    },
    customerId: {
      type: [String, Number],
      default: null,
    },
    productId: {
      type: [String, Number],
      default: null,
    },
    productVariantId: {
      type: [String, Number],
      default: null,
    },
    amount: {
      type: Number,
      default: 0,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    isAmountChangeable: {
      type: Boolean,
      default: true,
    },
    endpoint: {
      type: String,
      default: "/api/booking/checkout",
    },
  },
  data() {
    return {
      useCustomerVoucher: false,
      venueId: localStorage.getItem("venue_id") ? Number(localStorage.getItem("venue_id")) : null,
      paymentMethods: [],
      cards: [],
      addNewCard: false,
      vouchersInfo: {},
      voucherError: null,
      searchVoucherUrl: null,
    };
  },
  computed: {
    isAdminOrManager() {
      const user = this.$store.state.user;
      return user && (user.role === "Administrator" || user.role === "Manager");
    },
    role() {
      return this.$store.getters["user/role"];
    },
    payment() {
      return this.form.payment;
    },
    amountDisabled() {
      return (
        this.vouchersInfo.disable_custom_amount ||
        !this.isAmountChangeable ||
        (this.payment.type === this.config.PAYMENT_VOUCHER &&
          [this.config.roles.staff, this.config.roles.manager].includes(this.role))
      );
    },
  },
  mounted() {
    this.getUser();
    this.applyAmount();
    this.getDisplayPaymentMethods();
    if (this.customerId) {
      this.getCards();
      this.updateSearchUrl();
    }
  },
  watch: {
    customerId: {
      handler(newValue, _oldValue) {
        if (newValue) {
          this.getCards();
          this.updateSearchUrl();
        }
      },
    },
    amount: {
      handler(newValue, _oldValue) {
        this.applyAmount();
      },
    },
    "payment.type": {
      handler(newValue, oldValue) {
        if (newValue === this.config.PAYMENT_CARD && !this.cards.length) {
          this.toggleAddNewCard();
        }

        if (newValue === this.config.PAYMENT_VOUCHER) {
          this.updateSearchUrl();
        }
      },
    },
    cards: {
      handler(newValue, _oldValue) {
        this.$emit("update:cards", newValue);
      },
      deep: true,
    },
  },
  methods: {
    onUseCustomerVoucher() {
      this.vouchersInfo = {};
      this.voucherError = null;
      this.payment.voucher = null;
    },
    updateSearchUrl() {
      if (this.customerId) {
        this.searchVoucherUrl = `/api/client/${this.customerId}/vouchers?filter[venue_id]=${this.venueId}&filter[available]=true`;
      }
    },
    getUser() {
      this.$store.dispatch("user/fetchUser");
    },
    selectMethod($event) {
      this.payment.type = $event.id;
      if (this.payment.type !== this.config.PAYMENT_CARD) {
        this.payment.token = null;
        this.addNewCard = false;
      }
    },
    async getDisplayPaymentMethods() {
      this.paymentMethods = (await this.getPayments(this.config))
        .filter((payment) => this.excludeMethods.indexOf(payment.id) === -1)
        .map((payment) => ({
          ...payment,
          $isDisabled: this.disabledMethods.indexOf(payment.id) !== -1,
        }));
    },
    getCards() {
      if (!this.customerId) return;

      axios.get(`/api/admin/square/${this.customerId}/cards`).then(({ data }) => {
        this.cards = data.data;
        if (!this.cards.length && this.payment.type === this.config.PAYMENT_CARD) {
          this.addNewCard = true;
        }
      });
    },
    transformVoucher(voucher) {
      voucher.detail = `${voucher.detail}, ${voucher.customer}`;
      voucher.isOwnedByCurrentCustomer = voucher.owner_id === this.customerId;
    },
    sortVouchers(vouchers) {
      const dateFormat = "DD/MM/yyyy";
      vouchers.map(this.transformVoucher).sort((a, b) => {
        const aIsExpiredLater = moment(a.expiry, dateFormat).isAfter(moment(b.expiry, dateFormat));

        const aScore = this.getVoucherSortScore(a);
        const bScore = this.getVoucherSortScore(b);

        if (aScore > bScore) {
          return -1;
        }

        if (aScore < bScore) {
          return 1;
        }

        if (Number(a.balance) > Number(b.balance)) {
          return -1;
        }

        if (Number(a.balance) < Number(b.balance)) {
          return 1;
        }

        if (aIsExpiredLater) {
          return -1;
        }

        return 0;
      });

      return vouchers;
    },
    getVoucherSortScore(voucher) {
      const hasBalanceAndActive = voucher.balance && voucher.active.value === "Active";
      const hasBalanceAndExpired = voucher.balance && voucher.active.value === "Expired";
      const hasZeroBalanceAndExpired = voucher.balance === 0 && voucher.active.value === "Expired";

      if (voucher.isOwnedByCurrentCustomer) {
        return 1000000;
      }

      if (hasBalanceAndActive) {
        return 100000;
      }

      if (hasBalanceAndExpired) {
        return 10000;
      }

      if (hasZeroBalanceAndExpired) {
        return 100;
      }

      // hasZeroBalance
      return 1000;
    },
    async getVoucherInfo(code) {
      this.vouchersInfo = {};
      if (code) {
        axios
          .post(`/api/generic/gift-voucher-instance/${code}`, {
            venue_id: this.venueId,
            product_id: this.productId,
            product_variant_id: this.productVariantId,
            quantity: this.quantity || 1,
          })
          .then(({ data }) => {
            this.vouchersInfo = data;
            this.applyAmount();
          })
          .catch((error) => {
            this.voucherError = error.response.data.message || null;
            this.payment.amount = this.amount;
            setTimeout(this.dismiss, 5000);
          });
      }
    },
    selectCard(card) {
      this.form.payment.token = card ? card.card_id : null;
      this.addNewCard = false;
    },
    toggleAddNewCard() {
      this.addNewCard = !this.addNewCard;
    },
    applyAmount() {
      this.payment.amount = Math.min(this.vouchersInfo.balance ?? this.amount, this.amount);
    },
    dismiss() {
      this.voucherError = null;
    },

    /**
     * Use for confirming booking from parent component
     * @returns {Promise<any>}
     * @throws {Error}
     */
    async submit() {
      if (this.$refs.square) {
        this.form.payment.token = await this.$refs.square.submit();
      }

      return this.form.post(this.endpoint);
    },
  },
};
</script>
