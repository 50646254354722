<template>
  <div class="isolate flex flex-auto flex-col" v-if="Object.keys(currentCustomer).length">
    <div v-if="vouchers.length" class="flex flex-auto flex-col">
      <div class="relative my-6 flex items-center justify-between">
        <h1 class="text-xl font-semibold text-white2">{{ allText || $t("client.headings.vouchers.all") }}</h1>

        <a
          :href="createUrl"
          class="btn-emptyState float-right inline-block w-fit !bg-transparent font-semibold !text-white2 hover:!bg-white2 hover:!text-brand"
          >{{ createText || $t("client.headings.purchase_gift_voucher") }}</a
        >
      </div>

      <div class="grid gap-6 md:grid-cols-2">
        <template v-for="voucher in vouchers">
          <div class="relative flex flex-col gap-6 rounded-xl bg-white2 p-6 text-base">
            <div class="flex items-center justify-between">
              <h3 class="font-sloganSerif text-2xl font-normal text-brand">{{ voucher.voucher }}</h3>

              <v-badge
                class="py-1.5 !text-base font-medium"
                :value="voucher.status.value"
                :type="voucher.status.type"
              />
            </div>

            <div class="flex flex-col text-brand">
              <template v-if="voucher.is_bath_pass">
                <span class="text-xs">{{ $t("client.headings.vouchers.remaining_uses") }}</span>
                <span class="font-sloganSerif text-[65px] leading-tight">{{ voucher.remaining_count || 0 }}</span>
              </template>
              <template v-else>
                <span class="text-xs">{{ $t("client.headings.vouchers.balance") }}</span>
                <span class="font-sloganSerif text-[65px] leading-tight">${{ voucher.balance || 0 }}</span>
              </template>
            </div>

            <div class="flex items-center">
              <div class="flex flex-1 flex-col">
                <span class="text-xs text-neutral-400">{{ $t("booking.columns.expiry_date") }}</span>
                <span class="text-neutral-700">{{ voucher.expiry }}</span>
              </div>
              <div class="flex flex-1 flex-col">
                <span class="text-xs text-neutral-400">{{ $t("client.headings.vouchers.code") }}</span>
                <span class="flex justify-start gap-1 text-neutral-700">
                  <span>{{ voucher.code }}</span>
                  <a
                    @click="copyLink(voucher)"
                    class="flex cursor-pointer items-center justify-center p-1 hover:text-brand"
                  >
                    <v-icon icon="Square2StackIcon" classes="w-4 h-4"></v-icon>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="mx-auto flex max-w-[344px] flex-col items-center gap-6 md:mt-12 md:w-[600px] md:max-w-none">
      <h1 class="w-full text-xl font-semibold text-white2 md:hidden">
        {{ allText || $t("client.headings.vouchers.all") }}
      </h1>

      <div class="flex w-full flex-col gap-6 rounded-xl bg-white2 p-6 md:flex-row md:gap-0">
        <img
          :src="emptyImage || '/img/empty_state.jpg'"
          alt="Empty state voucher"
          class="h-60 rounded-none object-cover md:w-60"
        />

        <div class="flex flex-col items-center justify-center gap-6 md:px-6">
          <p class="text-center text-emptyState" v-html="emptyState || $t('client.headings.vouchers.empty_state')"></p>

          <a :href="createUrl" class="btn-emptyState">
            {{ createText || $t("client.headings.purchase_gift_voucher") }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="h-[50vh]">
    <div class="pt-8 text-center text-white2">
      {{ $t("client.headings.null_customer") }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  inject: ["bus"],
  props: {
    createUrl: {
      type: String,
      required: true,
    },
    isBathPass: {
      type: Boolean,
    },
    emptyState: {
      type: String,
    },
    emptyImage: {
      type: String,
    },
    allText: {
      type: String,
    },
    createText: {
      type: String,
    },
  },
  data: function () {
    return {
      vouchers: [],
    };
  },
  computed: {
    ...mapState({
      currentCustomer: (state) => state.customer.currentCustomer,
    }),
  },
  watch: {
    currentCustomer: {
      handler(newValue, oldValue) {
        this.getVouchers();
      },
      deep: true,
    },
  },
  methods: {
    getVouchers() {
      axios
        .get(`/api/client/${this.currentCustomer.id}/vouchers?filter[is_bath_pass]=${this.isBathPass ? 1 : 0}`)
        .then(({ data }) => {
          this.vouchers = this.sortVouchers(data.data);
        });
    },
    sortVouchers(vouchers) {
      const dateFormat = "DD/MM/yyyy";
      vouchers.sort((a, b) => {
        const aIsExpiredLater = moment(a.expiry, dateFormat).isAfter(moment(b.expiry, dateFormat));

        const aScore = this.getVoucherSortScore(a);
        const bScore = this.getVoucherSortScore(b);

        if (aScore > bScore) {
          return -1;
        }

        if (aScore < bScore) {
          return 1;
        }

        if (Number(a.balance) > Number(b.balance)) {
          return -1;
        }

        if (Number(a.balance) < Number(b.balance)) {
          return 1;
        }

        if (aIsExpiredLater) {
          return -1;
        }

        return 0;
      });

      return vouchers;
    },
    getVoucherSortScore(voucher) {
      const hasBalanceAndActive = voucher.balance && voucher.active.value === "Active";
      const hasBalanceAndExpired = voucher.balance && voucher.active.value === "Expired";
      const hasZeroBalanceAndExpired = voucher.balance === 0 && voucher.active.value === "Expired";

      if (hasBalanceAndActive) {
        return 100000;
      }

      if (hasBalanceAndExpired) {
        return 10000;
      }

      if (hasZeroBalanceAndExpired) {
        return 100;
      }

      // hasZeroBalance
      return 1000;
    },
    copyLink(voucher) {
      navigator.clipboard.writeText(voucher.code);
      this.bus.$emit("showNotification", {
        content: this.$t("generic.responses.copy_success"),
        type: "success",
      });
    },
  },
};
</script>
