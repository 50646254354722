<template>
  <section
    :class="{ 'sm:border-none md:h-96': horizonMode }"
    class="mt-0 h-fit overflow-y-auto border border-b-white border-l-white border-r-white"
  >
    <p
      v-if="Object.keys(availableSlots).length"
      class="px-smSpace pt-smSpace"
      :class="{ '!text-base text-white': isDarkTheme }"
    >
      {{ label }}
    </p>
    <ol
      class="m-smSpace mt-xsSpace space-y-xsSpace text-sm leading-6 text-gray-500"
      v-if="Object.keys(availableSlots).length"
      :class="{ '!text-base text-white': isDarkTheme }"
    >
      <li
        v-for="period in Object.keys(availableSlots)"
        :key="period"
        class="group flex cursor-pointer items-center space-x-4 rounded border-2 p-xsSpace"
        :class="[
          selectedAppointment && period === selectedAppointment.period && 'bg-blue-600 text-white',
          selectedAppointment && period !== selectedAppointment.period && 'hover:bg-gray-100',
          selectedAppointment &&
            period === selectedAppointment.period &&
            isDarkTheme &&
            'border-none !bg-white !text-brand',
          isDarkTheme && 'hover:bg-brand',
        ]"
        @click="select(availableSlots[period][0])"
      >
        <div class="flex-auto">
          <span>{{ period }} ({{ availableSlots[period].length }})</span>
        </div>
      </li>
    </ol>
  </section>
</template>

<script>
export default {
  inject: ["bus"],
  props: {
    label: {
      type: String,
      default: "Select",
    },
    modelValue: {
      type: Array,
      require: false,
    },
    availableSlots: {
      type: Object,
      require: false,
    },
    isDarkTheme: {
      type: Boolean,
      default: false,
    },
    horizonMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedAppointment: null,
    };
  },

  mounted() {
    if (this.modelValue) {
      this.update();
    }
  },
  watch: {
    availableSlots: {
      handler(newValue, oldValue) {
        this.update(true);
      },
      deep: true,
    },
  },
  methods: {
    update(reset = false) {
      if (reset) {
        this.selectedAppointment = null;
        this.$emit("update:modelValue", null);
      }

      if (this.modelValue) {
        const appointment = Object.values(this.availableSlots)
          .flat()
          .find((o) => {
            return o.id === this.modelValue[0];
          });

        if (appointment) {
          this.select(appointment);
        }
      }
    },
    select(appointment) {
      if (this.selectedAppointment === appointment) {
        return;
      }

      this.selectedAppointment = appointment;
      this.$emit("update:modelValue", appointment.id);
    },
  },
};
</script>
