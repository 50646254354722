<template>
  <form class="flex w-full flex-col justify-between px-lgSpace py-mdSpace" v-show="!loading">
    <div class="space-y-2xsSpace">
      <v-notification type="warning" :is-show-icon="false" :content="$t('booking.headings.reschedule_intro_add_on')">
      </v-notification>
      <p class="text-sm font-semibold text-neutral-900" :class="{ 'text-white': isClientReschedule }">
        {{ $t("booking.headings.booking_to_be_rescheduled") }}
      </p>
      <p class="pb-2xsSpace text-xs text-neutral-600" :class="{ 'text-white': isClientReschedule }">
        {{ $t("booking.headings.reschedule_intro_add_on") }}
      </p>
      <div v-if="booking">
        <div class="flex flex-col gap-xsSpace border-b pb-mdSpace">
          <booking-summary
            v-for="(booking, key) in bookings"
            :key="key"
            :booking="booking"
            :current-user="currentUser"
          ></booking-summary>
        </div>
        <p
          class="pb-3xsSpace pt-mdSpace text-sm font-semibold text-neutral-900"
          :class="{ 'text-white': isClientReschedule }"
        >
          {{ $t("booking.headings.select_schedule") }}
        </p>
        <p class="text-xs text-neutral-600" :class="{ 'text-white': isClientReschedule }">
          {{ $t("booking.headings.select_new_booking_date_add_on") }}
        </p>
        <div class="mt-smSpace space-y-2 rounded bg-slate-100 px-mdSpace pb-mdSpace text-sm leading-10">
          <span class="text-xs text-tertiary-700" v-text="$t('booking.headings.select_new_booking_date')"> </span>
          <calendar
            v-model:appointmentIds="form.ids"
            :venue-id="booking.venue_id"
            :product-id="booking.product_id"
            :order-id="booking.order_id"
            :add-on-product-id="booking.add_on_product_id"
            :quantity="quantity"
            :selected-date="selectedDate"
            :is-dark-theme="isClientReschedule"
            @update:selected-date="selectDate"
          />
          <div class="flex items-center gap-2">
            <span class="inline-flex h-3 w-3 rounded-full bg-green-200"></span>{{ " " }}
            <span class="text-sm font-medium text-neutral-600">{{ $t("booking.labels.availability") }}</span>
          </div>
          <div class="flex items-center gap-2">
            <span class="inline-flex h-3 w-3 rounded-full bg-red-200"></span>{{ " " }}
            <span class="text-sm font-medium text-neutral-600">{{ $t("booking.labels.no_availability") }}</span>
          </div>
        </div>

        <div class="mt-mdSpace rounded bg-slate-100 text-sm leading-10" v-if="!isClientReschedule">
          <v-checkbox
            class="flex items-center space-x-xsSpace p-mdSpace"
            v-model="form.is_confirmed"
            :label-text="$t('booking.headings.send_confirmation_email')"
          />
          <div v-if="form.is_confirmed" class="p-mdSpace pt-0">
            <input-wrapper field="message" :form="form">
              <ckeditor :editor="classicEditor" v-model="form.message" name="message"></ckeditor>
            </input-wrapper>
          </div>
        </div>
      </div>
      <v-notification v-if="message" class="pt-lgSpace" type="success" :content="message"></v-notification>
    </div>
    <div class="mt-mdSpace flex justify-end space-x-xsSpace pb-mdSpace">
      <a
        v-if="showBackButton"
        @click="close"
        class="btn-secondary btn-sm"
        :class="{ '!bg-transparent !text-white': isClientReschedule }"
        v-text="$t('generic.buttons.cancel')"
      ></a>
      <a
        @click.prevent="confirm"
        class="btn-secondary btn-sm !text-white"
        :class="{
          'btn-disabled': !form.ids.length,
          'btn-primary': !isClientReschedule,
        }"
        v-text="$t('generic.buttons.reschedule')"
      ></a>
    </div>
  </form>
  <div v-show="loading" aria-label="Loading..." role="status" class="flex h-full items-center justify-center space-x-2">
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>
<script>
import Form from "form-backend-validation";
import Calendar from "../common/booking/Calendar.vue";
import moment from "moment";
import axios from "axios";
import BookingSummary from "@/js/vue/components/bookings/details/BookingSummary.vue";

export default {
  inject: ["bus", "config"],
  components: { BookingSummary, Calendar },
  props: {
    bookingId: {
      type: Number,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
    inPanel: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: Number,
    },
    productCategoryId: {
      type: Number,
    },
    cancelUrl: {
      type: String,
    },
    isClientReschedule: {
      type: Boolean,
      default: false,
    },
    showNewScheduledBooking: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
    },
    bookings: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      message: null,
      booking: null,
      mailContent: null,
      selectedDate: null,
      form: new Form({
        ids: [],
        booking_ids: {},
        is_confirmed: true,
        message: null,
      }),
    };
  },
  computed: {
    quantity() {
      return Object.values(this.form.booking_ids).filter(Boolean).length;
    },
    rescheduleEndpoint() {
      return `/api/${this.isClientReschedule ? "client" : "admin"}/bookings/${this.bookingId}/reschedule`;
    },
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        if (!newValue.is_confirmed) {
          this.form.message = null;
          return;
        }

        if (this.form.message) {
          return;
        }

        this.form.message = this.mailContent;
      },
      deep: true,
    },
  },
  created() {
    this.form.booking_ids = this.bookings.reduce((result, o) => {
      result[o.id] = true;
      return result;
    }, {});

    axios.get(`/api/admin/bookings/${this.bookingId}`).then(({ data }) => {
      this.booking = data;
    });

    this.getNotification();
  },
  methods: {
    getNotification() {
      if (!this.isClientReschedule) {
        axios
          .get("/api/admin/notifications/show", {
            params: {
              type: "booking_reschedule",
              product_id: this.productId,
              product_category_id: this.productCategoryId,
            },
          })
          .then(({ data }) => {
            if (data.data.hasOwnProperty("content")) {
              this.mailContent = data.data.content;
              if (this.form.is_confirmed) {
                this.form.message = this.mailContent;
              }
            }
          });
      }
    },
    close({ rescheduleRes }) {
      if (this.isClientReschedule) {
        window.location.href = this.cancelUrl;
      }
      if (this.inPanel) {
        this.bus.$emit("closePanelInPanel");
      }

      this.bus.$emit("closePanel");

      if (!rescheduleRes) {
        return;
      }

      setTimeout(() => {
        this.bus.$emit("openSidePanel", {
          componentName: "BookingDetail",
          title: "",
          componentData: {
            bookingId: this.bookingId,
            bookingClass: rescheduleRes.booking.class,
            currentUser: this.currentUser,
            showAddTask: true,
            showNewScheduledBooking: this.showNewScheduledBooking,
          },
        });
      }, 500);
    },
    confirm() {
      if (!this.form.ids.length) {
        return;
      }
      this.loading = true;
      this.form
        .post(this.rescheduleEndpoint)
        .then((data) => {
          this.loading = false;
          if (this.isClientReschedule) {
            window.location.href = this.cancelUrl;
          }
          this.message = data.statusText;
          setTimeout(() => {
            const filterData = this.showNewScheduledBooking
              ? {
                  "filter[date]": moment(this.selectedDate).format(this.config.DATETIME_FORMAT),
                }
              : {};
            this.bus.$emit("tableFilterChange", { params: filterData });
            this.close({ rescheduleRes: data });
          }, 1500);
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    selectDate(date) {
      this.selectedDate = date;
    },
  },
};
</script>
