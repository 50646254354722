<template>
  <div class="space-y-2">
    <div class="mb-lgSpace space-y-2">
      <label class="text-sm font-medium text-white2">
        {{ $t("booking.labels.select_a_category") }}
      </label>
      <div class="grid grid-cols-2 gap-6">
        <button
          type="button"
          class="btn-secondary !block items-center !bg-brand !py-4 font-sloganSerif !text-[26px] !font-bold !text-white2"
          :class="!isBathPass ? 'opacity-100' : 'opacity-50'"
          @click="selectCategory(false)"
        >
          {{ $t("gift_voucher.headings.slogan") }}
        </button>
        <button
          type="button"
          class="btn-secondary !block items-center !bg-brand !py-4 font-sloganSerif !text-[26px] !font-bold !text-white2"
          :class="isBathPass ? 'opacity-100' : 'opacity-50'"
          @click="selectCategory(true)"
        >
          {{ $t("gift_voucher.headings.bath_passes") }}
        </button>
      </div>
    </div>
    <p class="text-sm font-medium text-white2">
      {{ isBathPass ? $t("gift_voucher.headings.select_bath_pass") : $t("gift_voucher.headings.select_gift_card") }}
    </p>
    <div>
      <div class="relative max-h-[370px] space-y-2 overflow-y-auto" ref="voucherList">
        <template v-for="voucher in vouchers" class="relative">
          <div v-if="!(hide && modelValue != voucher.id)" class="flex flex-col rounded-xl bg-white p-mdSpace">
            <p class="block text-lg font-semibold text-brand">{{ voucher.title }}</p>
            <span class="text-md block pb-2xsSpace text-neutral-700">{{ voucher.description }}</span>
            <div class="flex items-center justify-between gap-4 pt-xsSpace">
              <p class="block text-base text-brand">{{ voucher.value }}</p>
              <div class="flex flex-shrink-0 items-center space-x-mdSpace">
                <template v-if="selectedVoucher && selectedVoucher === voucher.id">
                  <a class="btn-secondary btn-sm border-0 !border-transparent !text-brand" @click.prevent.stop="remove">
                    <v-icon icon="TrashIcon" class="h-4 w-4 text-brand"></v-icon>
                  </a>
                  <quantity-stepper :model-value="quantity" @update:modelValue="$emit('update:quantity', $event)" />
                </template>
                <a
                  v-else
                  class="btn-secondary btn-md border-2 !border-brand !bg-brand !text-white2"
                  @click="selectVoucher(voucher.id)"
                >
                  {{ $t("generic.buttons.buy") }}
                </a>
              </div>
            </div>
          </div>
        </template>
        <div
          class="sticky bottom-0 left-[48%] right-0 w-xlSpace cursor-pointer"
          v-if="vouchers.length > 1 && !modelValue && !hide"
        >
          <v-icon icon="ChevronDownIcon" class="h-8 w-8 rounded-full bg-brand p-1 text-white" @click="scrollDown" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VoucherList",
  props: {
    modelValue: {
      required: true,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    isBathPass: {
      type: Boolean,
    },
  },
  data() {
    return {
      hide: false,
      loading: false,
      vouchers: [],
      selectedVoucher: this.modelValue && this.modelValue.length ? this.modelValue[0] : this.modelValue,
    };
  },
  mounted() {
    this.getVouchers();
  },
  watch: {
    modelValue: {
      handler(newValue, oldValue) {
        this.selectedVoucher = newValue && newValue.length ? newValue[0] : newValue;
      },
      deep: true,
    },
  },
  methods: {
    scrollDown() {
      const voucherList = this.$refs.voucherList;
      if (voucherList) {
        voucherList.scrollTop += 100; // Adjust the scroll amount as needed
      }
    },
    selectCategory(isBathPass) {
      if (this.isBathPass === isBathPass) {
        return;
      }

      this.$emit("update:is-bath-pass", isBathPass);
      this.remove();
      setTimeout(() => {
        this.getVouchers();
      }, 50);
    },
    remove() {
      this.hide = false;
      this.selectedVoucher = null;
      this.$emit("update:modelValue", null);
    },
    selectVoucher(id) {
      this.hide = true;
      this.selectedVoucher = id;
      this.$emit("update:modelValue", [id]);
      if (this.quantity > 1) {
        this.$emit("update:quantity", 1);
      }
    },
    getVouchers() {
      this.loading = true;
      axios
        .get(
          `/api/voucher/gift-vouchers?filter[is_percent]=0&filter[active]=1&filter[public]=1&filter[is_bath_pass]=${
            this.isBathPass ? 1 : 0
          }`,
        )
        .then(({ data }) => {
          this.vouchers = data.data;
          this.loading = false;
        });
    },
  },
};
</script>
