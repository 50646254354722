<template>
  <form class="mt-mdSpace flex w-full flex-col justify-between gap-xsSpace" v-show="!loading">
    <side-panel ref="sidePanel" :disable-listeners="true"></side-panel>
    <v-notification
      class="!mb-0.5"
      type="warning"
      :is-show-icon="false"
      :content="$t('booking.headings.reschedule_intro')"
    >
    </v-notification>
    <div class="flex items-center justify-between">
      <p class="pb-xsSpace text-sm font-semibold text-neutral-900">{{ $t("booking.labels.select_bookings") }}</p>
      <a
        @click.prevent="openReschedule"
        class="btn-primary btn-sm flex items-center gap-xsSpace !rounded-lg"
        :class="{ '!cursor-default !bg-slate-100 !text-slate-400': !hasItemsSelected }"
      >
        <v-icon icon="CalendarDaysIcon"></v-icon>
        {{ $t("generic.buttons.reschedule") }}
      </a>
    </div>
    <v-checkbox
      class="flex items-center space-x-xsSpace font-semibold"
      v-model="isSelectAll"
      @click="selectAll($event.target.checked)"
      :label-text="$t('generic.words.select_all')"
    />
    <div v-for="booking in primaryBookings" class="flex w-full">
      <v-checkbox
        class="flex items-center space-x-xsSpace py-mdSpace"
        :disabled="isDisabled(booking)"
        v-model="selectedBookings[booking.id]"
      />

      <booking-summary :booking="booking" :current-user="currentUser"></booking-summary>
    </div>
  </form>
  <div v-show="loading" aria-label="Loading..." role="status" class="flex h-full items-center justify-center space-x-2">
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>
<script>
import BookingSummary from "@/js/vue/components/bookings/details/BookingSummary.vue";

export default {
  components: { BookingSummary },
  inject: ["bus", "config"],
  props: {
    currentBooking: {
      type: Object,
      required: true,
    },
    bookings: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    currentUser: {
      type: Object,
    },
    showNewScheduledBooking: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      isSelectAll: false,
      selectedBookings: {},
    };
  },
  computed: {
    hasItemsSelected() {
      const select = [];
      Object.keys(this.selectedBookings).forEach((id, index) => {
        if (this.selectedBookings[id]) {
          select.push(true);
        }
      });

      this.isSelectAll = !(Object.keys(this.selectedBookings).length > select.length);

      return select.length;
    },
    hasAddOn() {
      return this.bookings.filter((o) => o.linked_id).length > 0;
    },
    currentPrimaryBooking() {
      if (!this.currentBooking.linked_id) {
        return this.bookings.find((o) => o.id === this.currentBooking.id);
      }

      return this.bookings.find((o) => o.id === this.currentBooking.linked_id);
    },
    primaryBookings() {
      return this.bookings.filter((o) => {
        if (this.hasAddOn) {
          return !o.linked_id;
        }
        return true;
      });
    },
    roles() {
      return (this.currentUser?.roles || []).map((role) => role.name) || [];
    },
    isAdminOrManager() {
      if (!this.roles) {
        return false;
      }

      return (
        this.roles.includes(this.config.roles.admin.toLowerCase()) ||
        this.roles.includes(this.config.roles.manager.toLowerCase())
      );
    },
  },
  created() {
    this.bus.$on("closePanelInPanel", () => {
      this.$refs.sidePanel && this.$refs.sidePanel.close();
    });
  },
  mounted() {
    this.primaryBookings.forEach((o) => {
      if (!this.isDisabled(o)) {
        this.selectedBookings[o.id] = false;
      }
    });
  },
  methods: {
    isDisabled(booking) {
      if (this.isAdminOrManager) {
        return false;
      }

      const primaryDisable = booking.is_past || booking.is_cancelled;
      if (booking.add_on) {
        return primaryDisable && (booking.add_on.is_past || booking.add_on.is_cancelled);
      }

      return primaryDisable;
    },
    selectAll(isSelect) {
      if (isSelect === true || isSelect === false) {
        Object.keys(this.selectedBookings).forEach((id) => {
          this.selectedBookings[id] = isSelect;
        });

        this.selectedBookings = Object.assign({}, this.selectedBookings);
      }
    },
    openReschedule() {
      if (!this.hasItemsSelected) {
        return;
      }

      this.$refs.sidePanel.open(
        "BookingReschedule",
        {
          bookingId: this.currentPrimaryBooking.id,
          inPanel: true,
          showNewScheduledBooking: this.showNewScheduledBooking,
          bookings: this.primaryBookings.filter((o) => {
            return this.selectedBookings[o.id];
          }),
          currentUser: {},
        },
        this.$t("client.headings.reschedule"),
        () => {},
        true,
      );
    },
  },
};
</script>
