<template>
  <div class="space-y-lgSpace p-lgSpace">
    <div class="space-y-lgSpace">
      <div class="text-lg font-bold">{{ $t("gift_voucher_instance.headings.update_expire") }}</div>
    </div>
    <form class="grid gap-smSpace">
      <input-wrapper field="expiry" :form="form" :label-text="$t('gift_voucher_instance.fields.expiry')">
        <date-picker
          v-model:value="form.expiry"
          :placeholder="$t('gift_voucher_instance.placeholders.expiry')"
          format="DD-MM-YYYY"
          value-type="format"
          type="date"
          class="!block !w-full"
        />
      </input-wrapper>
      <input-wrapper
        v-if="isBalance"
        field="balance"
        :form="form"
        :label-text="$t('gift_voucher_instance.fields.balance')"
      >
        <input
          type="number"
          min="0"
          v-model="form.balance"
          :placeholder="$t('gift_voucher_instance.placeholders.balance')"
        />
      </input-wrapper>
      <input-wrapper
        v-else-if="isBathPass && isAdmin"
        field="remaining_count"
        :form="form"
        :label-text="$t('gift_voucher_instance.fields.remaining_count')"
      >
        <input
          type="number"
          min="0"
          v-model="form.remaining_count"
          :placeholder="$t('gift_voucher_instance.placeholders.remaining_count')"
        />
      </input-wrapper>
      <input-wrapper
        field="note"
        :form="form"
        :label-text="$t('gift_voucher_instance.fields.note')"
        :is-required="hasChanges"
      >
        <textarea v-model="form.note" :placeholder="$t('gift_voucher_instance.placeholders.note')" />
      </input-wrapper>
    </form>
  </div>
  <div
    class="flex w-full flex-col items-end justify-end rounded p-lgSpace sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
  >
    <div class="flex space-x-xsSpace">
      <a @click="close" class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')"></a>
      <a @click.prevent="save" class="btn-primary btn-sm" v-text="$t('generic.buttons.continue')"></a>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";

export default {
  name: "GiftVoucherInstanceEdit",
  inject: ["notificationService", "bus", "config"],
  props: {
    giftVoucherInstance: {
      type: Object,
      default: () => {
        return {};
      },
    },
    modelClass: {
      type: String,
      default: "",
    },
    currentUser: {
      type: Object,
    },
    inPanel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    roles() {
      return this.currentUser?.roles.map((role) => role.name) || [];
    },
    isAdmin() {
      return this.roles.includes(this.config.roles.admin.toLowerCase());
    },
    isBalance() {
      return !(this.giftVoucherInstance.gift_voucher.is_percent || this.giftVoucherInstance.gift_voucher.is_bath_pass);
    },

    isBathPass() {
      return this.giftVoucherInstance.gift_voucher.is_bath_pass;
    },

    isPercent() {
      return this.giftVoucherInstance.gift_voucher.is_percent;
    },

    hasChanges() {
      const hasChangeExpiry = this.form.expiry !== this.getFieldValue("expiry");
      const hasChangeBalance = this.isBalance && this.form.balance !== this.getFieldValue("balance");
      const hasChangeRemainingCount =
        this.isBathPass && this.form.remaining_count !== this.getFieldValue("remaining_count");

      return hasChangeExpiry || hasChangeBalance || hasChangeRemainingCount;
    },
  },

  data() {
    const balance = this.getFieldValue("balance");
    return {
      loading: false,
      form: new Form(
        {
          id: this.getFieldValue("id"),
          expiry: this.getFieldValue("expiry"),
          balance: balance === "-" ? undefined : balance,
          remaining_count: this.getFieldValue("remaining_count"),
          note: this.getFieldValue("note"),
        },
        { resetOnSuccess: false },
      ),
    };
  },

  methods: {
    getFieldValue(field, defaultValue = null) {
      if (this.giftVoucherInstance.hasOwnProperty(field)) {
        return this.giftVoucherInstance[field];
      }

      return defaultValue;
    },
    save() {
      this.loading = true;
      this.form
        .patch(
          `/api/admin/gift-vouchers/${this.giftVoucherInstance.gift_voucher_id}/gift-voucher-instances/${this.giftVoucherInstance.id}`,
        )
        .then(({}) => {
          this.loading = false;
          this.bus.$emit("reloadTable");
          this.close();
          this.notificationService.notify(this.$t("generic.responses.update_success"));
        })
        .catch(() => {
          this.loading = false;
        });
    },
    close() {
      if (this.inPanel) {
        this.bus.$emit("closePanelInPanel");
        return;
      }
      this.bus.$emit("closePanel");
    },
  },
};
</script>
