<template>
  <div class="isolate flex flex-auto flex-col overflow-auto bg-white pt-[60px]" v-if="venueId">
    <Transition>
      <div class="flex max-w-full flex-col sm:max-w-none md:max-w-full" v-if="!loading">
        <div class="fixed left-0 z-20 flex w-full bg-white px-mdSpace pb-2xsSpace">
          <div class="left-0 z-10 flex w-14 items-center justify-center bg-white">
            <v-icon icon="ClockIcon" class="mx-auto"></v-icon>
          </div>
          <div
            class="grid w-full text-sm leading-6"
            :style="'grid-template-columns: repeat(' + dateList.length + ', minmax(2.5rem, 1fr))'"
          >
            <template v-for="item in dateList">
              <div
                class="m-2xsSpace flex cursor-pointer flex-col items-center rounded border-2 border-slate-600 p-2xsSpace pt-xsSpace hover:border-blue-400 hover:text-blue-400"
                @click="redirect(item.value)"
              >
                <span class="text-2xs">{{ item.day }}</span>
                <span class="flex items-center justify-center text-xl font-semibold">{{ item.date }}</span>
              </div>
            </template>
          </div>
        </div>
        <div class="flex flex-auto pb-smSpace pt-2xlSpace">
          <div
            class="w-14 flex-none bg-white"
            :style="`grid-template-rows: repeat(${Object.values(availabilities).length}, minmax(1.5rem, 1fr))`"
          ></div>

          <div class="grid flex-auto grid-cols-1 grid-rows-1">
            <div
              class="col-start-1 col-end-2 row-start-1 grid"
              :style="`grid-template-rows: repeat(${Object.values(availabilities).length}, minmax(1.5rem, 1fr))`"
            >
              <div class="row-end-1"></div>
              <template v-for="(item, time) in availabilities">
                <div
                  class="-ml-14 flex w-14 items-center justify-center text-right text-xs"
                  :class="isOdd(time) ? 'text-gray-900' : 'text-gray-400'"
                >
                  {{ toTime(time) }}
                </div>
              </template>
            </div>
            <ol
              class="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
              :style="`grid-template-rows: repeat(${
                Object.values(availabilities).length
              }, minmax(1.5rem, 1fr)); grid-template-columns: repeat(${dateList.length}, minmax(2.5rem, 1fr))`"
            >
              <template v-for="(appointmentsByDate, row) in Object.values(availabilities)">
                <li
                  v-for="(appointment, column) in appointmentsByDate"
                  class="relative m-2xsSpace flex items-center rounded border-2"
                  :class="{
                    'cursor-pointer border-green-600 bg-green-50 text-green-600 hover:bg-green-100': appointment > 0,
                  }"
                  :style="`grid-row: ${row + 1}; grid-column:${column + 1}`"
                  @click="
                    redirect(appointment > 0 ? this.dateList[column].value : '', Object.keys(this.availabilities)[row])
                  "
                >
                  <span class="text-md mx-auto font-normal">{{ appointment > 0 ? appointment : null }}</span>
                </li>
              </template>
            </ol>
          </div>
        </div>
      </div>
      <div
        v-else
        aria-label="Loading..."
        role="status"
        class="absolute left-1/2 top-1/2 flex -translate-x-1/2 items-center justify-center space-x-2"
      >
        <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
        <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
      </div>
    </Transition>
  </div>
  <div v-else class="absolute left-1/2 top-1/2 flex -translate-x-1/2 items-center justify-center space-x-2">
    {{ $t("appointment.headings.null_venue") }}
  </div>
</template>

<script>
import { debounce } from "lodash";
import moment from "moment/moment";

export default {
  inject: ["bus", "config"],
  props: {
    bookingUrl: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      venueId: null,
      dateList: [],
      loading: false,
      currentRange: [
        moment().format(this.config.DATETIME_FORMAT),
        moment().add(1, "months").format(this.config.DATETIME_FORMAT),
      ],
      currentFilter: null,
      availabilities: [],
      search: debounce(this.getAvailabilities, 300),
    };
  },
  beforeUnmount() {
    this.bus.$off("tableFilterChange");
  },
  created() {
    this.venueId = localStorage.getItem("venue_id") ? Number(localStorage.getItem("venue_id")) : null;
    this.bus.$on("tableFilterChange", ({ params }) => {
      this.loading = true;
      this.venueId = localStorage.getItem("venue_id") ? Number(localStorage.getItem("venue_id")) : null;
      this.currentFilter = "";
      if (params.hasOwnProperty("filter[date]")) {
        this.currentRange = params["filter[date]"].split(",");
      }
      if (this.venueId) {
        params["filter[venue]"] = this.venueId;
        this.currentFilter += "&" + "filter[venue]=" + this.venueId;
      }

      if (params.hasOwnProperty("filter[products]")) {
        this.currentFilter += "&" + "filter[products]=" + encodeURIComponent(params["filter[products]"]);
      }

      if (params.hasOwnProperty("filter[categories]")) {
        this.currentFilter += "&" + "filter[categories]=" + encodeURIComponent(params["filter[categories]"]);
      }

      this.initiateDateList();
      this.search(params);
    });
  },
  methods: {
    toTime(time) {
      let format = "h:m";
      if (this.isOdd(time)) {
        format = "ha";
      }

      return moment(time, "HH:mm").format(format);
    },
    isOdd(time) {
      return time.includes("00");
    },
    getAvailabilities(params) {
      axios
        .get("/api/admin/availabilities", { params })
        .then(({ data }) => {
          this.availabilities = data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    initiateDateList(type = "days") {
      this.dateList = [];
      const fromDate = moment(this.currentRange[0], "DD-MM-YYYY");
      const toDate = moment(this.currentRange[1], "DD-MM-YYYY");
      const diff = toDate.diff(fromDate, type);
      for (let i = 0; i <= diff; i++) {
        const nextDate = fromDate.clone().add(i, type);
        this.dateList.push({
          value: nextDate.format("DD/MM/YYYY"),
          day: nextDate.format("ddd").toUpperCase(),
          date: nextDate.format("D"),
        });
      }
    },
    redirect(date, index = null) {
      if (!date) {
        return;
      }
      let url = this.bookingUrl + "?filter[date]=" + date;
      if (this.currentFilter) {
        url += this.currentFilter;
      }
      if (index) {
        url += "#time_line" + moment(index, "h:mm").hour();
      }
      window.location = url;
    },
  },
};
</script>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
