<template>
  <div class="max-w-xl space-y-lgSpace pt-xlSpace" :class="{ '!max-w-full space-x-lgSpace px-xlSpace': inPanel }">
    <form class="grid grid-cols-1 gap-smSpace">
      <input-wrapper field="title" :form="form" :label-text="$t('gift_voucher.fields.title')">
        <input type="text" v-model="form.title" :placeholder="$t('gift_voucher.placeholders.title')" />
      </input-wrapper>
      <input-wrapper field="description" :form="form" :label-text="$t('gift_voucher.fields.description')">
        <v-textarea :placeholder="$t('gift_voucher.placeholders.description')" v-model="form.description"></v-textarea>
      </input-wrapper>

      <input-wrapper field="is_bath_pass" :form="form" :label-text="$t('gift_voucher.labels.bath_pass')">
        <div class="flex space-x-mdSpace">
          <v-radio
            class="flex items-center space-x-xsSpace p-mdSpace pl-0"
            input-class="rounded-full"
            :value="0"
            name="is_bath_pass"
            v-model="form.is_bath_pass"
            @change="($event) => (form.is_bath_pass = Number($event.target.value))"
            :label-text="$t('gift_voucher.labels.dollar_value_gift_cards')"
          />
          <v-radio
            class="flex items-center space-x-xsSpace p-mdSpace"
            input-class="rounded-full"
            :value="1"
            name="is_bath_pass"
            v-model="form.is_bath_pass"
            @change="($event) => (form.is_bath_pass = Number($event.target.value))"
            :label-text="$t('gift_voucher.labels.bathing_multi_passes')"
          />
        </div>
      </input-wrapper>
      <input-wrapper
        v-if="form.is_bath_pass"
        field="sessions"
        :form="form"
        :label-text="$t('gift_voucher.labels.number_of_uses')"
      >
        <input type="number" v-model="form.sessions" :placeholder="$t('gift_voucher.placeholders.number_of_uses')" />
      </input-wrapper>
      <input-wrapper field="value" :form="form" :label-text="$t('gift_voucher.fields.value')">
        <div class="relative flex">
          <input
            type="number"
            v-model="form.value"
            :placeholder="$t('gift_voucher.placeholders.value')"
            :disabled="!!giftVoucher.instances_count"
          />
          <v-toggle
            v-if="!form.is_bath_pass"
            class="absolute bottom-0 right-2 top-0"
            :value="form.is_percent"
            :for-voucher="true"
            :is-disabled="!!giftVoucher.instances_count"
            @input="(value) => (form.is_percent = value)"
          ></v-toggle>
        </div>
      </input-wrapper>
      <input-wrapper field="validity_day" :form="form" :label-text="$t('gift_voucher.fields.validity_day')">
        <input
          type="number"
          v-model="form.validity_day"
          :placeholder="$t('gift_voucher.placeholders.validity_day')"
          :disabled="!!giftVoucher.instances_count"
        />
        <p>
          <small>{{ $t("gift_voucher.placeholders.validity_day_description") }}</small>
        </p>
      </input-wrapper>
      <input-wrapper
        v-if="form.is_bath_pass"
        field="selected_products"
        :form="form"
        :label-text="$t('gift_voucher.fields.product_variant')"
      >
        <v-multi-select
          v-model="form.selected_product_variants"
          search-url="/api/admin/bathhouse-product-variants?filter[for_bathhouse]=1"
          :placeholder="$t('gift_voucher.placeholders.product_variant')"
          :is-multiple="true"
          track-by="id"
          label="name"
          search-key="filter[name]"
        />
      </input-wrapper>
      <input-wrapper v-else field="selected_products" :form="form" :label-text="$t('gift_voucher.fields.product')">
        <v-multi-select
          v-model="form.selected_products"
          search-url="/api/admin/products"
          :placeholder="$t('gift_voucher.placeholders.product')"
          :is-multiple="true"
          track-by="id"
          label="name"
          search-key="filter[product]"
        />
      </input-wrapper>

      <input-wrapper field="selected_venues" :form="form" :label-text="$t('gift_voucher.fields.venue')">
        <v-multi-select
          v-model="form.selected_venues"
          search-url="/api/admin/venues?filter[active]=1"
          :placeholder="$t('gift_voucher.fields.venue')"
          :is-multiple="true"
          track-by="id"
          label="name"
          search-key="filter[name]"
        />
      </input-wrapper>

      <input-wrapper field="xero_code_id" :form="form" :label-text="$t('gift_voucher.fields.xero_code_id')">
        <v-multi-select
          v-model="form.xero_code_id"
          search-url="/api/admin/xero/xero_codes"
          :placeholder="$t('gift_voucher.fields.xero_code_id')"
          :is-multiple="false"
          track-by="id"
          label="select_option"
          search-key="filter[full_search]"
        />
      </input-wrapper>

      <v-checkbox v-model="form.active" :label-text="$t('generic.words.active')"></v-checkbox>
      <v-checkbox v-model="form.public" :label-text="$t('generic.words.public')"></v-checkbox>
    </form>
    <v-footer :in-panel="inPanel" :list-url="listUrl" :save="save"></v-footer>
  </div>
</template>

<script>
import Form from "form-backend-validation";

export default {
  name: "GiftVoucherEdit",
  inject: ["notificationService", "bus"],
  props: {
    giftVoucher: {
      type: Object,
      default: () => {
        return {};
      },
    },
    listUrl: {
      type: String,
      required: true,
    },
    allowEdit: {
      type: Boolean,
      required: true,
    },
    inPanel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: new Form(
        {
          id: this.getFieldValue("id"),
          xero_code_id: this.getFieldValue("xero_code_id"),
          title: this.getFieldValue("title"),
          description: this.getFieldValue("description"),
          value: this.getFieldValue("value"),
          start_date: this.getFieldValue("start_date"),
          selected_products: this.getFieldValue("product_ids"),
          selected_product_variants: this.getFieldValue("product_variant_ids"),
          selected_venues: this.getFieldValue("venue_ids"),
          validity_day: this.getFieldValue("validity_day"),
          active: Boolean(Number(this.getFieldValue("active"))),
          public: Boolean(Number(this.getFieldValue("public"))),
          is_percent: Boolean(Number(this.getFieldValue("is_percent", false))),
          is_bath_pass: Number(this.getFieldValue("is_bath_pass", 0)),
          sessions: Number(this.getFieldValue("sessions", 0)),
        },
        { resetOnSuccess: false },
      ),
    };
  },

  mounted() {},

  methods: {
    getFieldValue(field, defaultValue = null) {
      if (this.giftVoucher.hasOwnProperty(field)) {
        return this.giftVoucher[field];
      }

      return defaultValue;
    },
    save() {
      if (this.giftVoucher.hasOwnProperty("id")) {
        this.form
          .patch(`/api/admin/gift-vouchers/${this.giftVoucher.id}`)
          .then(({}) => {
            if (this.inPanel) {
              this.notificationService.notify(this.$t("generic.responses.update_success"));
              this.bus.$emit("closePanelInPanel");
              return;
            }
            window.location = this.listUrl;
          })
          .catch(({ response }) => {
            this.notificationService.notify(response.data.message, "error");
          });

        return;
      }

      this.form
        .post(`/api/admin/gift-vouchers`)
        .then(({}) => {
          if (this.inPanel) {
            this.notificationService.notify(this.$t("generic.responses.create_success"));
            this.bus.$emit("closePanelInPanel");
            return;
          }
          window.location = this.listUrl;
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
  },
};
</script>
