<template>
  <div>
    <p class="text-tertiary-400">{{ $t("generic.words.no_preview_media_found") }} {{ media.mime_type }}</p>
    {{ media }}
  </div>
</template>

<script>
import MediaElement from "../../../mixins/MediaElement";
export default {
  // This component is copied from staircase
  mixins: [MediaElement],
};
</script>
