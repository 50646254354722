<template>
  <div class="tasks flex-1">
    <side-panel ref="sidePanel" :disable-listeners="true"></side-panel>
    <div class="flex h-2xlSpace items-center justify-end border-b border-b-tertiary-200 px-lgSpace">
      <a @click="close" class="!absolute left-6">
        <v-icon icon="XMarkIcon" classes="cursor-pointer rounded-lg hover:bg-tertiary-100 h-6 w-6" />
      </a>
      <v-search
        name="title"
        event-name="taskTerm"
        :placeholder="$t('task.placeholders.enter_title')"
        class="mr-4 flex space-x-2xsSpace"
      />
      <div class="mr-4 flex space-x-xsSpace">
        <a @click="clickAddTask" class="btn-sm btn-secondary">
          <span>{{ $t("task.buttons.add") }}</span>
        </a>
      </div>
      <v-filter entity="tasks" filter-emits-to="taskFilterChange" class="flex space-x-xsSpace" />
    </div>
    <div class="space-y-smSpace p-2lgSpace">
      <div
        v-if="!loading"
        class="w-full rounded-sm px-smSpace py-xsSpace ring-2 ring-tertiary-200"
        :class="{ 'bg-tertiary-100': selectedTask && selectedTask.id === task.id }"
        v-for="task in tasks"
      >
        <div class="space-y-mdSpace">
          <div class="flex flex-wrap items-center justify-between sm:space-x-xsSpace">
            <div class="flex items-center justify-between space-x-xsSpace">
              <a
                @click="go(task)"
                class="flex h-lgSpace w-xlSpace cursor-pointer items-center justify-center rounded-xl px-[20px] text-sm font-bold text-white hover:bg-green-500"
                :class="task.completed_at ? 'bg-tertiary-600' : 'bg-green-600'"
              >
                #{{ task.id }}
              </a>
              <div
                class="flex items-center text-sm font-bold"
                :class="task.completed_at ? 'text-tertiary-600' : 'text-green-600'"
              >
                <span class="capsize text-sm leading-none">{{
                  task.completed_at ? $t("task.labels.completed") : $t("task.labels.todo")
                }}</span>
              </div>
            </div>
            <i class="text-sm">{{ task.created_at }}</i>
          </div>
          <div class="space-y-xsSpace text-sm">
            <div
              class="cursor-pointer font-bold leading-none hover:text-blue-600"
              v-html="task.title"
              @click="go(task)"
            ></div>
            <div v-html="task.description"></div>
          </div>
          <div class="flex justify-between">
            <v-multi-select
              class="w-[150px]"
              v-model="selectUsers[task.id]"
              :search-url="userFilterUrl"
              :placeholder="$t('task.placeholders.staff')"
              track-by="id"
              label="full_name"
              search-key="filter[full_name]"
              @update:modelValue="onSelect($event, task)"
            >
            </v-multi-select>
            <div class="flex justify-between space-x-xsSpace text-sm">
              <open-side-panel-button
                component-name="TaskEdit"
                :component-data="{ task: task }"
                classes="btn-secondary btn-sm"
              >
                {{ $t("generic.buttons.edit") }}
              </open-side-panel-button>
              <a class="btn-secondary btn-sm" @click="complete(task)">
                {{ $t("generic.buttons.complete") }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-else aria-label="Loading..." role="status" class="flex h-full items-center justify-center space-x-2">
        <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
        <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
      </div>
      <div
        v-if="!tasks.length && !loading"
        class="absolute left-1/2 top-1/2 flex -translate-x-1/2 items-center justify-center space-x-2"
      >
        {{ $t("task.headings.no_task") }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["bus", "config"],
  props: {
    task: {
      type: Object,
      required: false,
    },
    currentUser: {
      type: String,
      default: "Guest",
    },
    weekdays: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    userFilterUrl() {
      const arrayRoleIds = [
        this.config.roleIdMapping.admin,
        this.config.roleIdMapping.manager,
        this.config.roleIdMapping.staff,
      ];
      return `/api/admin/users?filter[active]=1&filter[role_id]=${arrayRoleIds}`;
    },
  },
  data() {
    return {
      tasks: [],
      loading: false,
      selectedTask: this.task,
      selectUsers: [],
      filters: [],
    };
  },
  created() {
    this.bus.$on("taskFilterChange", ({ params }) => {
      this.filters = Object.assign({}, params);
      this.loadTasks();
    });

    this.bus.$on("taskTerm", ({ name, term }) => {
      this.filters[`filter[${name}]`] = term;
      this.loadTasks();
    });

    this.bus.$on("closePanelInPanel", (fromCloseButton = false) => {
      if (!this.$refs.sidePanel) {
        return;
      }

      this.$refs.sidePanel.close();

      if (fromCloseButton) {
        return;
      }

      this.loadTasks();
    });
  },
  methods: {
    loadTasks() {
      this.loading = true;
      axios.get("/api/admin/tasks", { params: this.filters }).then(({ data }) => {
        this.tasks = data.data;
        this.tasks.forEach((task) => {
          this.selectUsers[task.id] = task.user_id;
        });
        this.loading = false;
      });
    },
    complete(task) {
      axios.post(`/api/admin/tasks/${task.id}/complete`).then(() => {
        if (task.action === "reschedule") {
          this.bus.$emit("tableFilterChange", { params: {} });
        }

        this.tasks.splice(
          this.tasks.findIndex((o) => o.id === task.id),
          1,
        );
      });
    },
    close() {
      this.bus.$emit("closePanel");
    },
    onClickOutside() {
      this.bus.$emit("closePanel");
    },
    go(task) {
      this.selectedTask = task;
      if (task.action === "reschedule") {
        this.$refs.sidePanel.open(
          "BookingDetail",
          {
            bookingId: task.taskable.id,
            currentUser: this.currentUser,
            defaultTab: "Reschedule",
            visibleTabs: ["Reschedule"],
            inPanel: true,
          },
          null,
          () => {},
          true,
        );
        return;
      }

      if (task.taskable_type === this.config.taskTypes.custom) {
        this.$refs.sidePanel.open("TaskEdit", { task, inPanel: true }, null, () => {}, true);
        return;
      }

      if (task.taskable_type === this.config.taskTypes.waitList) {
        this.$refs.sidePanel.open(
          "WaitListEdit",
          {
            waitList: task.taskable,
            weekdays: this.weekdays,
            listUrl: location.href,
            inPanel: true,
            classes: "space-y-lgSpace space-x-lgSpace px-xlSpace py-xlSpace",
          },
          null,
          () => {},
          true,
        );
        return;
      }

      if (task.taskable_type === this.config.taskTypes.giftVoucher) {
        this.$refs.sidePanel.open(
          "GiftVoucherEdit",
          { giftVoucher: task.taskable, allowEdit: true, listUrl: location.href, inPanel: true },
          null,
          () => {},
          true,
        );
        return;
      }

      if (task.taskable_type === this.config.taskTypes.giftVoucherInstance) {
        this.$refs.sidePanel.open(
          "VoucherInstanceDetails",
          { voucherCode: task.taskable.code, showAddTask: false, currentUser: this.currentUser },
          null,
          () => {},
          true,
        );
        return;
      }

      if (task.taskable_type === this.config.taskTypes.booking) {
        this.$refs.sidePanel.open(
          "BookingDetail",
          {
            bookingId: task.taskable.id,
            bookingClass: task.taskable_type,
            currentUser: this.currentUser,
            inPanel: true,
          },
          null,
          () => {},
          true,
        );
        return;
      }

      window.open(task.default_url, "_blank");
    },
    clickAddTask() {
      this.$refs.sidePanel.open("TaskEdit", {}, `${this.$t("task.headings.add")}`, () => {}, true);
    },
    onSelect(user, task) {
      axios.post(`/api/admin/tasks/${task.id}/assign`, {
        user_id: user.id,
      });
    },
  },
};
</script>
<style>
.tasks {
  .multiselect__placeholder,
  .multiselect__single {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
}
</style>
