<template>
  <div class="group/tooltip relative overflow-visible">
    <div>
      <slot></slot>
    </div>
    <div
      ref="tooltip"
      class="absolute z-[1000] hidden items-center justify-center whitespace-nowrap rounded-md bg-gray-800 px-xsSpace text-xs text-white opacity-100 transition-opacity group-hover/tooltip:flex"
      :class="tooltipClasses"
    >
      <slot name="tooltip"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "VTooltip",
  props: {
    side: {
      type: String,
      default: "top",
      description: "Tooltip position. top, right, bottom, left",
    },
    align: {
      type: String,
      default: "center",
      description: "Tooltip alignment. start, center, end",
    },
    offset: {
      type: Number,
      default: 0,
      description: "Tooltip offset in pixels from the target element",
    },
    tooltipClass: {
      type: String,
      default: "",
      description: "Tooltip custom class",
    },
  },
  computed: {
    tooltipClasses() {
      return [this.getPositionClasses(), this.tooltipClass].join(" ");
    },
  },
  methods: {
    getPositionClasses() {
      return {
        "top-start": `bottom-full left-0 -translate-y-[${this.offset}px]`,
        "top-center": `bottom-full left-1/2 -translate-x-1/2 -translate-y-[${this.offset}px]`,
        "top-end": `bottom-full right-0 -translate-y-[${this.offset}px]`,
        "right-start": `left-full top-0 -translate-x-[${this.offset}px]`,
        "right-center": `left-full top-1/2 translate-x-[${this.offset}px] -translate-y-1/2`,
        "right-end": `left-full bottom-0 -translate-x-[${this.offset}px]`,
        "bottom-start": `top-full left-0 -translate-y-[${this.offset}px]`,
        "bottom-center": `top-full left-1/2 -translate-x-1/2 translate-y-[${this.offset}px]`,
        "bottom-end": `top-full right-0 -translate-y-[${this.offset}px]`,
        "left-start": `right-full top-0 -translate-x-[${this.offset}px]`,
        "left-center": `top-1/2 right-full -translate-x-[${this.offset}px] -translate-y-1/2`,
        "left-end": `bottom-0 right-full -translate-x-[${this.offset}px]`,
      }[`${this.side || "top"}-${this.align || "center"}`];
    },
  },
};
</script>
