<template>
  <Teleport :to="to" v-if="isMounted">
    <slot></slot>
  </Teleport>
</template>
<script>
export default {
  name: "DelayTeleport",
  props: {
    to: {
      type: String,
      require: true,
    },
    delay: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isMounted: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isMounted = true;
    }, this.delay * 1000);
  },
};
</script>
