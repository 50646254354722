<template>
  <div v-if="!loading">
    <template v-for="product in products">
      <div
        v-if="!modelValue || modelValue === product.id"
        :class="modelValue === product.id ? 'bg-blue-200' : 'bg-blue-50'"
        class="mt-xsSpace flex cursor-pointer items-center justify-between rounded p-2xsSpace text-sm"
      >
        <div class="flex w-full items-center justify-between" @click="selectProduct(product)">
          <p class="block font-semibold">{{ product.name }}</p>
          <a
            class="btn-secondary btn-sm border-2 !border-orange-900 bg-white !text-orange-900"
            :class="!modelValue || modelValue === product.id ? '' : '!bg-white opacity-50'"
          >
            {{ $t("booking.headings.from") }}{{ product.lowest_price }}
          </a>
        </div>
        <a
          @click="remove"
          v-if="modelValue === product.id"
          class="btn-secondary btn-sm ml-xsSpace border-2 !border-orange-900 bg-white !text-orange-900"
          :class="modelValue === product.id ? '' : '!bg-white opacity-50'"
        >
          {{ $t("generic.buttons.remove") }}
        </a>
      </div>
    </template>
  </div>
  <div
    v-else
    aria-label="Loading..."
    role="status"
    class="my-auto flex h-[300px] items-center justify-center space-x-2"
  >
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>
<script>
export default {
  inject: ["config", "bus"],
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
    venueId: {
      type: Number,
      required: true,
    },
    categoryId: {
      type: Number,
      required: null,
    },
    onSelectProduct: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      products: [],
    };
  },
  mounted() {
    this.getProducts();
  },
  watch: {
    venueId: {
      handler() {
        this.getProducts();
      },
    },
    categoryId: {
      handler() {
        this.getProducts();
      },
    },
  },
  methods: {
    selectProduct(product) {
      this.$emit("update:modelValue", product.id);
      this.onSelectProduct(product);
    },
    remove() {
      this.$emit("update:modelValue", null);
      this.onSelectProduct(null);
    },
    getProducts() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      axios
        .get(`/api/admin/products`, {
          params: {
            filter: {
              venue_id: this.venueId,
              parent_category_id: this.categoryId,
              active: true,
              bookable: true,
            },
            sort: "category.order,order",
            order: "asc",
          },
        })
        .then(({ data }) => {
          this.products = data.data;
          this.loading = false;
        });
    },
  },
};
</script>
