<template>
  <div class="space-y-mdSpace pb-smSpace">
    <h1 class="text-xl">{{ $t("report.headings.voucher_report") }}</h1>
    <div class="flex items-center justify-between">
      <date-picker
        v-model:value="filters['filter[date_range]']"
        :append-to-body="false"
        :clearable="false"
        placeholder="Select a range"
        :range="true"
        format="DD/MM/YYYY"
        type="date"
        class="!w-[220px]"
        @change="loadSummary"
      />
      <div class="flex flex-col justify-end space-x-xsSpace sm:flex-row sm:items-center max-sm:space-y-smSpace">
        <v-filter entity="gift-voucher-instances" class="flex justify-end"></v-filter>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-mdSpace">
      <percentage :data="numberOfVouchers"></percentage>
      <percentage :data="valueOfVouchers"></percentage>
    </div>

    <v-tabs :tabs="tabs">
      <template v-slot:summary>
        <table class="min-w-full">
          <thead class="bg-tertiary-100">
            <tr>
              <th
                class="py-smSpace pl-mdSpace pr-3 text-left text-sm font-medium text-tertiary-700 max-lg:hidden"
                v-for="column in summary.columns"
              >
                {{ column }}
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-tertiary-200 bg-white">
            <tr class="align-top lg:align-middle" v-for="item in summary.summary">
              <td class="whitespace-nowrap py-4 pl-smSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ item.gift_voucher_name }}
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ numberFormat(item.purchased) }}
              </td>

              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ numberFormat(item.used) }}
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ numberFormat(item.unused) }}
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ parseFloat(item.used_percentage).toFixed(2) }}%
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ toAUD(item.purchased_value) }}
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ toAUD(item.used_value) }}
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ toAUD(item.balance) }}
              </td>
              <td class="whitespace-nowrap py-4 pl-mdSpace pr-3 text-sm font-medium text-tertiary-900">
                {{ parseFloat(item.used_value_percentage).toFixed(2) }}%
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-slot:gift-voucher-instances>
        <div class="flex justify-end">
          <client-export-button
            class="mt-smSpace"
            :header="headers"
            :file-name="`${new Date().toLocaleDateString() + '_gift_voucher_instances'}.csv`"
            :get-url="exportUrl"
            :default-params="filters"
          ></client-export-button>
        </div>
        <v-table
          get-url="/api/admin/reports/gift-voucher-instances"
          class="my-lgSpace"
          :fields="instanceFields"
          :load-on-start="false"
        >
        </v-table>
      </template>
    </v-tabs>
  </div>
</template>

<script>
import moment from "moment/moment";
import DatePicker from "vue-datepicker-next";

export default {
  components: { DatePicker },
  inject: ["bus"],
  props: {
    fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    instanceFields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tabs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      summary: {},
      filters: {
        "filter[term]": null,
        "filter[date_range]": [moment().subtract(4, "week").toDate(), moment().toDate()],
      },
    };
  },
  computed: {
    exportUrl() {
      return window.location.origin + "/api/admin/reports/gift-voucher-instances";
    },
    headers() {
      const columns = {};
      this.instanceFields.forEach((o) => {
        let name = o.name;
        if (o.name === "__component:v-table-status") {
          name = "status_name";
        }
        columns[name] = o.title;
      });

      return columns;
    },
    numberOfVouchers() {
      return {
        chart: {
          datasets: [
            {
              backgroundColor: ["#81aa82", "#f1f1f1"],
              data: [
                this.summary.number_of_vouchers?.used_percentage,
                100 - this.summary.number_of_vouchers?.used_percentage,
              ],
            },
          ],
        },
        title: this.$t("report.headings.number_of_vouchers"),
        percentage_title: this.$t("report.labels.used"),
        percentage_value: this.summary.number_of_vouchers?.used_percentage,
        statistic: [
          {
            title: this.$t("report.labels.purchased"),
            value: this.numberFormat(this.summary.number_of_vouchers?.purchased),
          },
          {
            title: this.$t("report.labels.used"),
            value: this.numberFormat(this.summary.number_of_vouchers?.used),
          },
          {
            title: this.$t("report.labels.unused"),
            value: this.numberFormat(this.summary.number_of_vouchers?.unused),
          },
        ],
      };
    },
    valueOfVouchers() {
      return {
        chart: {
          datasets: [
            {
              backgroundColor: ["#a481cf", "#f1f1f1"],
              data: [
                this.summary.value_of_vouchers?.used_percentage,
                100 - this.summary.value_of_vouchers?.used_percentage,
              ],
            },
          ],
        },
        title: this.$t("report.headings.value_of_vouchers"),
        percentage_title: this.$t("report.labels.used"),
        percentage_value: this.summary.value_of_vouchers?.used_percentage,
        statistic: [
          {
            title: this.$t("report.labels.purchased"),
            value: this.toAUD(this.summary.value_of_vouchers?.purchased),
          },
          {
            title: this.$t("report.labels.used"),
            value: this.toAUD(this.summary.value_of_vouchers?.used),
          },
          {
            title: this.$t("report.labels.balance"),
            value: this.toAUD(this.summary.value_of_vouchers?.balance),
          },
        ],
      };
    },
  },
  beforeUnmount() {
    this.bus.$off("tableFilterChange");
  },
  created() {
    this.bus.$on("tableFilterChange", ({ params }) => {
      this.filters["filter[status]"] = params["filter[status]"];
      this.filters["filter[gift_vouchers]"] = params["filter[gift_vouchers]"];
      this.filters["filter[products]"] = params["filter[products]"];
      if (!params.hasOwnProperty("trigger_summary")) {
        this.loadSummary();
      }
    });
    this.bus.$on("tabChanged", (tab) => {
      if (this.slug(tab.name) === "gift-voucher-instances") {
        this.loadInstances();
      }
    });
  },
  methods: {
    loadInstances() {
      setTimeout(() => {
        this.bus.$emit("tableFilterChange", { params: Object.assign({}, this.filters, { trigger_summary: false }) });
      });
    },
    loadSummary() {
      this.loadInstances();

      axios
        .get("/api/admin/reports/gift-voucher-instances/summary", {
          params: this.filters,
        })
        .then(({ data }) => {
          this.summary = data;
        });
    },
  },
};
</script>
