<template>
  <div class="max-w-xl space-y-lgSpace pt-xlSpace">
    <form class="grid grid-cols-1 gap-smSpace">
      <input-wrapper field="name" :form="form" :label-text="$t('label.fields.name')">
        <input type="text" v-model="form.name" :placeholder="$t('label.placeholders.name')" />
      </input-wrapper>
      <input-wrapper field="type" :form="form" :label-text="$t('label.fields.type')" v-if="types.length">
        <v-multi-select v-model="form.type" :init-options="types" :placeholder="$t('label.placeholders.type')" />
      </input-wrapper>
      <input-wrapper field="colour" :form="form" :label-text="$t('label.fields.colour')">
        <color-picker v-model:pure-color="form.colour" shape="circle" format="hex"></color-picker>
      </input-wrapper>

      <v-checkbox v-model="form.active" :label-text="$t('generic.words.active')"></v-checkbox>
    </form>
    <v-footer :list-url="listUrl" :save="save"></v-footer>
  </div>
</template>
<style>
.vc-color-wrap {
  @apply ml-2;
}
</style>
<script>
import Form from "form-backend-validation";
import { ColorPicker } from "vue3-colorpicker";

export default {
  name: "LabelEdit",
  inject: ["notificationService"],
  components: { ColorPicker },
  props: {
    label: {
      type: Object,
      default: () => {
        return {};
      },
    },
    listUrl: {
      type: String,
      required: true,
    },
    types: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      form: new Form(
        {
          id: this.getFieldValue("id"),
          name: this.getFieldValue("name"),
          type: this.getFieldValue("type") || "generic",
          colour: this.getFieldValue("colour") || "rgb(0,0,0)",
          active: Boolean(Number(this.getFieldValue("active"))),
        },
        { resetOnSuccess: false },
      ),
    };
  },

  methods: {
    getFieldValue(field, defaultValue = null) {
      if (this.label.hasOwnProperty(field)) {
        return this.label[field];
      }

      return defaultValue;
    },
    close() {},
    save() {
      if (this.label.hasOwnProperty("id")) {
        this.form
          .patch(`/api/admin/labels/${this.label.id}`)
          .then(({}) => {
            window.location = this.listUrl;
          })
          .catch(({ response }) => {
            this.notificationService.notify(response.data.message, "error");
          });

        return;
      }

      this.form
        .post(`/api/admin/labels`)
        .then(({}) => {
          window.location = this.listUrl;
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
  },
};
</script>
