<template>
  <div class="max-w-xl space-y-lgSpace pt-xlSpace">
    <v-media-grid
      v-if="modelId"
      :media="media"
      :model="{ id: modelId }"
      :model-class="modelClass"
      :upload-endpoint="uploadMediaEndpoint"
      :allow-deleting="true"
      :allow-uploading="true"
      :allow-set-feature="true"
      :compact="false"
      @media-uploaded="appendToMedia"
      @media-deleted="showConfirm"
      @media-set-feature="setFeature"
    />
    <div v-else>
      <p class="p-mdSpace">{{ $t("generic.words.create_model_first") }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadMedia",
  inject: ["bus", "notificationService"],
  props: {
    existingMediaEndpoint: {
      type: String,
      default: "/api/generic/media/existing",
    },
    uploadMediaEndpoint: {
      type: String,
      default: "/api/generic/media/upload",
    },
    modelId: {
      type: String,
      required: true,
    },
    modelClass: {
      type: String,
      required: true,
    },
    messageAction: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      media: [],
    };
  },
  mounted() {
    if (this.modelId) {
      this.reloadMedia();
    }
  },
  methods: {
    appendToMedia(item) {
      this.media.push(item);
    },
    showConfirm({ id: mediaId, index }) {
      const _this = this;
      this.bus.$emit("openModal", {
        componentData: this.$t("generic.confirm.delete_warning"),
        callback: () => {
          _this.destroy({ id: mediaId, index });
        },
      });
    },
    destroy({ id: mediaId, index }) {
      const media = this.media[index];
      const endpoint =
        media && media.hasOwnProperty("delete_endpoint")
          ? media.delete_endpoint
          : "/api/generic/media/delete/" + mediaId;
      axios
        .delete(endpoint)
        .then(({ data }) => {
          // delete the row
          this.media.splice(index, 1);
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
    setFeature({ id: mediaId }) {
      const params = {};
      axios
        .patch(`/api/generic/media/${mediaId}/set-feature`, params)
        .then(({ data }) => {
          this.reloadMedia();
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
    reloadMedia() {
      const params = {
        modelClass: this.modelClass,
        modelId: this.modelId,
      };
      axios
        .get(this.existingMediaEndpoint, { params })
        .then(({ data }) => {
          this.media = [];
          if (data.length) {
            this.media = data;
            this.form.images = data[0].original_url;
          }
        })
        .catch(console.error);
    },
  },
};
</script>
