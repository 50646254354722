<template>
  <img
    v-if="!error"
    ref="img"
    v-bind="$attrs"
    :src="media[displayStyle + '_url']"
    :alt="media.name"
    @error="replaceByDefault"
  />
</template>

<script>
import MediaElement from "../../../mixins/MediaElement";
export default {
  // This component is copied from staircase
  mixins: [MediaElement],
};
</script>
