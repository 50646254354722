<template>
  <div
    ref="card"
    :id="`card-${appointment.id}`"
    class="card relative h-full rounded-b rounded-t border-b-2 border-t-2 max-xs:space-y-2xsSpace"
    :class="{
      '!border-blue-400 !bg-white !text-blue-600': forPreview,
      'border-x-2 border-blue-400 bg-white': appointment.id && appointment.is_online,
      'border-x-2 border-slate-400 bg-slate-100': appointment.id && !appointment.is_online,
      'border-tertiary-400 bg-tertiary-400 text-white': appointment.type === 'break',
      '!bg-amber-100': appointment.isDragOver,
      '!border !border-white !bg-green-100 text-black': appointment.type === 'note',
      '!mx-2xsSpace': isCompact,
      flex: isCompact && appointment.is_belonged_to_bathhouse,
      '!border-dotted !border-slate-400 !bg-gray-100': appointment.uncovered,
    }"
    :style="getStyle(appointment)"
  >
    <slot v-if="!appointment.uncovered"></slot>
    <div v-if="appointment.uncovered && appointment.type !== 'note'">
      <span v-if="appointment.uncovered" class="absolute left-0 top-0.5 px-2xsSpace text-xs text-slate-400">
        {{ appointment.value }}
      </span>
      <div
        v-if="isAdminOrManager"
        class="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 cursor-pointer flex-row rounded bg-tertiary-100 opacity-20 hover:bg-white hover:text-tertiary-800 hover:opacity-100"
      >
        <a class="rounded px-2xsSpace hover:bg-primary-200">
          <v-icon icon="PlusIcon" @click="$parent.addAppointment(appointment, this.item)"></v-icon>
        </a>
      </div>
    </div>

    <div
      v-if="appointment.type === 'note' || (appointment.type === 'appointment' && !appointment.uncovered)"
      class="relative flex w-full flex-row items-center justify-between p-xsSpace pt-1"
      :class="{
        '!pt-0': appointment.type === 'break' && currentZoom <= 0.4,
        '!p-0': isCompact && appointment.is_belonged_to_bathhouse,
      }"
    >
      <div
        class="flex w-full text-sm font-semibold"
        :class="{
          'items-center': appointment.type !== 'note',
          'text-blue-500': appointment.is_online && appointment.name === 'Available',
          '!text-xs': currentZoom <= 0.6,
          'overflow-hidden whitespace-nowrap': currentZoom === 0.4,
        }"
      >
        <a
          class="mr-2xsSpace flex-shrink-0 self-start rounded hover:bg-tertiary-50"
          :class="{ '!p-0': currentZoom === 0.4 }"
          v-if="appointment.is_booked || appointment.type === 'note'"
        >
          <v-icon
            icon="ArrowsPointingOutIcon"
            @mousedown="$emit('onMoveCard', appointment)"
            :classes="`rotate-45 cursor-move ${currentZoom <= 0.4 ? 'w-3.5 h-3.5' : 'w-4 h-4'}`"
          />
        </a>
        <open-side-panel-button
          :component-name="compactSidePanelComponent"
          :component-data="compactSidePanelComponent === 'BookingCreate' ? actionData : sidePanelData()"
          classes="cursor-pointer items-center hover:text-blue-200 flex justify-between w-full"
          v-if="compactSidePanelComponent"
        >
          <span>{{ appointment.name }}</span>
        </open-side-panel-button>
        <open-side-panel-button
          v-else-if="appointment.type === 'note'"
          component-name="BookingNoteDetail"
          :component-data="{ bookingNote: this.appointment, timeSlots: this.appointment.timeSlots }"
          classes="cursor-pointer items-center hover:text-secondary-300"
        >
          <div
            class="line-clamp-3 whitespace-normal"
            :class="{
              '!line-clamp-5': currentZoom <= 0.4 && appointment.duration > 60,
              '!line-clamp-2': currentZoom <= 0.8 && currentZoom > 0.4 && appointment.duration <= 60,
            }"
          >
            <p class="mt-0">{{ appointment.description || "Untitled" }}</p>
          </div>
        </open-side-panel-button>
        <span v-else>
          {{ appointment.name }}
        </span>
      </div>
      <template v-if="appointment.hasOwnProperty('labels') && appointment.labels && appointment.labels.length">
        <div
          class="flex"
          :class="{
            '!-mt-3xsSpace': currentZoom <= 0.6 && !appointment.is_booked && appointment.type !== 'break',
          }"
        >
          <v-badge
            v-for="label in appointment.labels"
            :type="label.type"
            :value="''"
            class="ml-2xsSpace !h-mdSpace !w-mdSpace !px-0 !py-0"
            :class="{ '!h-smSpace !w-smSpace': currentZoom <= 0.6 }"
          ></v-badge>
        </div>
      </template>
      <div class="relative flex flex-none justify-between gap-x-8 sm:w-auto" v-if="appointment.is_booked">
        <div class="flex cursor-pointer items-center gap-x-2xsSpace">
          <open-side-panel-button
            component-name="BookingDetail"
            :component-data="sidePanelData()"
            classes="flex space-x-1 justify-center items-center"
          >
            <v-tooltip v-if="appointment.is_addon_included" :offset="4" side="bottom">
              <div class="rounded text-xs font-semibold !text-black" :class="{ '!px-0 !py-0': currentZoom === 0.4 }">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="14px"
                  viewBox="0 -960 960 960"
                  width="14px"
                  fill="currentColor"
                >
                  <path
                    d="M280-600q-33 0-56.5-23.5T200-680q0-33 23.5-56.5T280-760q33 0 56.5 23.5T360-680q0 33-23.5 56.5T280-600ZM200-80q-17 0-28.5-11.5T160-120q-33 0-56.5-23.5T80-200v-240h120v-30q0-38 26-64t64-26q20 0 37 8t31 22l56 62q8 8 15.5 15t16.5 13h274v-326q0-14-10-24t-24-10q-6 0-11.5 2.5T664-790l-50 50q5 17 2 33.5T604-676L494-788q14-9 30-11.5t32 3.5l50-50q16-16 36.5-25t43.5-9q48 0 81 33t33 81v326h80v240q0 33-23.5 56.5T800-120q0 17-11.5 28.5T760-80H200Zm-40-120h640v-160H160v160Zm0 0h640-640Z"
                  />
                </svg>
              </div>
              <template #tooltip>
                {{ $t("booking.tooltips.has_bathhouse") }}
              </template>
            </v-tooltip>
            <a @click.stop.prevent="clickWaiver()" class="flex cursor-pointer text-xs font-semibold text-gray-400">
              <v-tooltip :offset="4" side="bottom">
                <div
                  class="flex h-5 w-5 flex-shrink-0 items-center justify-center overflow-hidden rounded-full border border-gray-500 text-[8px] text-gray-500"
                  :class="{
                    '!border-black !text-black': !appointment.is_waiver_expired,
                    '!h-3 !w-3': currentZoom === 0.4,
                  }"
                >
                  w
                </div>
                <template #tooltip>
                  {{ appointment.is_waiver_expired ? $t("waiver.tooltips.no_waiver") : $t("waiver.tooltips.complete") }}
                </template>
              </v-tooltip>
            </a>
            <template v-if="appointment.paid_amount === appointment.amount">
              <v-tooltip :offset="4" side="bottom" v-if="appointment.used_cc || appointment.used_terminal">
                <a class="rounded text-xs font-semibold !text-black hover:bg-tertiary-50">
                  <v-icon icon="CreditCardIcon" :classes="currentZoom === 0.4 ? '!h-4 !w-4' : 'h-5 w-5'" />
                </a>
                <template #tooltip>
                  {{
                    appointment.used_cc ? $t("booking.tooltips.paid_by_cc") : $t("booking.tooltips.paid_by_terminal")
                  }}
                </template>
              </v-tooltip>
              <v-tooltip :offset="4" side="bottom" v-if="appointment.used_cash">
                <a class="rounded text-xs font-semibold !text-black hover:bg-tertiary-50">
                  <v-icon icon="CurrencyDollarIcon" :classes="currentZoom === 0.4 ? '!h-4 !w-4' : 'h-5 w-5'" />
                </a>
                <template #tooltip>
                  {{ $t("booking.tooltips.paid_by_cash") }}
                </template>
              </v-tooltip>
              <v-tooltip :offset="4" side="bottom" v-if="appointment.used_gift_voucher">
                <a class="rounded text-xs font-semibold !text-black hover:bg-tertiary-50">
                  <v-icon icon="GiftIcon" :classes="currentZoom === 0.4 ? '!h-4 !w-4' : 'h-5 w-5'" />
                </a>
                <template #tooltip>
                  {{ $t("booking.tooltips.paid_by_voucher") }}
                </template>
              </v-tooltip>
            </template>
          </open-side-panel-button>
          <arrival-status-selector
            :appointment="appointment"
            :capacity-index="item.capacity_index"
            :zoom="currentZoom"
            :arrival-statuses="arrivalStatuses"
            :current-status="appointment.client_arrival_status"
          />
        </div>
      </div>
      <div
        class="flex items-center space-x-xsSpace"
        v-if="!appointment.is_booked && appointment.type !== 'break'"
        :class="{ 'right-xsSpace top-2xsSpace': currentZoom <= 0.6 }"
      >
        <div class="cursor-pointer" v-if="appointment.type === 'note' && !isCompact">
          <open-side-panel-button
            component-name="BookingNoteDetail"
            :component-data="{ bookingNote: this.appointment, timeSlots: this.appointment.timeSlots || [] }"
            classes="flex -space-x-0.5"
          >
            <a
              class="rounded px-1 py-1 text-xs font-semibold hover:bg-tertiary-50"
              :class="{ '!ml-1 !px-0 !py-0': currentZoom === 0.4 }"
            >
              <v-icon icon="PencilSquareIcon" :classes="currentZoom === 0.4 ? '!h-4 !w-4' : 'h-5 w-5'" />
            </a>
          </open-side-panel-button>
        </div>
        <v-toggle
          v-else-if="appointment.type !== 'note' && !appointment.is_belonged_to_bathhouse"
          :value="appointment.is_online"
          :title="appointment.is_online ? 'Online' : 'Offline'"
          :small="currentZoom === 0.4"
          @input="onChangeAppointmentStatus($event)"
        ></v-toggle>
        <span
          v-if="appointment.is_notified"
          class="block h-3 w-3 rounded-full bg-green-600"
          :title="$t('generic.buttons.notified_waitlist')"
        ></span>
        <v-actions :classes="'-left-0 top-[6px] w-[35px] text-xs'" @open="openCardMenu" @close="closeCardMenu">
          <template v-slot:dropdown>
            <ul class="divide-y font-medium">
              <li v-if="appointment.type !== 'note'">
                <open-side-panel-button
                  component-name="AppointmentDetail"
                  :component-data="{ appointment, currentUser }"
                  classes="flex w-full cursor-pointer items-center justify-center space-x-xsSpace p-2xsSpace hover:bg-neutral-100 hover:text-blue-600"
                >
                  <v-icon icon="ChatBubbleLeftIcon" classes="w-4 h-4"></v-icon>
                </open-side-panel-button>
              </li>
              <li v-if="appointment.type !== 'note'">
                <a
                  @click="copyLink()"
                  class="flex w-full cursor-pointer items-center justify-center space-x-xsSpace p-2xsSpace hover:bg-neutral-100 hover:text-blue-600"
                >
                  <v-icon icon="LinkIcon" classes="w-4 h-4"></v-icon>
                </a>
              </li>
              <li v-if="isAdminOrManager">
                <a
                  @click="deleteAppointment()"
                  class="flex w-full cursor-pointer items-center justify-center space-x-xsSpace p-2xsSpace hover:bg-neutral-100 hover:text-blue-600"
                >
                  <v-icon icon="TrashIcon" classes="w-4 h-4"></v-icon>
                </a>
              </li>
              <li v-if="isAdminOrManager && appointment.is_belonged_to_bathhouse">
                <v-toggle
                  class="flex justify-center p-xsSpace hover:bg-neutral-100"
                  :value="appointment.is_online"
                  :title="appointment.is_online ? '' : ''"
                  :small="currentZoom === 0.4"
                  @input="onChangeAppointmentStatus($event)"
                ></v-toggle>
              </li>
            </ul>
          </template>
        </v-actions>
      </div>
    </div>
    <template
      v-if="
        appointment.type === 'appointment' &&
        (!isCompact || (isCompact && !appointment.is_belonged_to_bathhouse)) &&
        !appointment.uncovered
      "
    >
      <div
        v-if="appointment.title"
        class="absolute top-8 z-10 flex flex-col px-xsSpace text-sm"
        :class="{
          '!text-2xs !leading-3': currentZoom === 0.6,
          '!top-5 !text-2xs !leading-3': currentZoom <= 0.4,
        }"
      >
        <open-side-panel-button
          component-name="BookingDetail"
          :component-data="sidePanelData()"
          classes="cursor-pointer items-center hover:text-secondary-200"
        >
          <div
            class="line-clamp-3"
            :class="{
              '!line-clamp-5': currentZoom <= 0.4 && appointment.duration > 60,
              '!line-clamp-2': currentZoom <= 0.8 && currentZoom > 0.4 && appointment.duration <= 60,
            }"
            @mouseover="showToolTip($event, appointment, 'appointment')"
            @mouseout="hideToolTip($event, appointment, 'appointment')"
          >
            <p>{{ appointment.title }}</p>
            <p class="mt-2xsSpace font-semibold">{{ appointment.start }} - {{ appointment.stop }}</p>
            <div
              :id="`appointment-${appointment.id}`"
              class="absolute -top-4 flex hidden w-[200px] -translate-x-1.5 -translate-y-full items-center justify-center rounded-md bg-gray-800 p-smSpace text-xs text-white opacity-100 transition-opacity"
            >
              <div>
                <p>{{ appointment.title }}</p>
                <p class="mt-2xsSpace font-semibold">{{ appointment.start }} - {{ appointment.stop }}</p>
              </div>
            </div>
          </div>
        </open-side-panel-button>
      </div>
      <div
        class="absolute bottom-0 w-full space-y-2xsSpace pb-xsSpace pl-xsSpace"
        :class="{ '!py-3xsSpace': currentZoom <= 0.6, '!-bottom-[2px]': currentZoom <= 0.4 }"
      >
        <div class="flex space-x-2xsSpace" v-if="!forPreview">
          <template v-if="!appointment.is_booked && appointment.is_online">
            <open-side-panel-button
              component-name="BookingCreate"
              :component-data="actionData"
              classes="cursor-pointer items-center"
            >
              <button type="button" class="btn-secondary btn-sm" :class="smallButton">
                {{ $t("generic.buttons.add") }}
              </button>
            </open-side-panel-button>
            <NotifyWaitlistButton
              :isNotified="appointment.is_notified"
              :action-data="actionData"
              :button-classes="smallButton"
            ></NotifyWaitlistButton>
          </template>
          <template v-else-if="currentZoom > 0.4">
            <open-side-panel-button
              component-name="BookingDetail"
              :component-data="sidePanelData('Reschedule')"
              classes="cursor-pointer items-center space-x-xsSpace"
            >
              <button type="button" class="btn-secondary btn-sm btn-transparent hover:cursor" :class="smallButton">
                {{ $t("generic.buttons.reschedule") }}
              </button>
            </open-side-panel-button>

            <open-side-panel-button
              component-name="BookingDetail"
              :component-data="sidePanelData('Cancel')"
              classes="cursor-pointer items-center space-x-xsSpace pl-2xsSpace"
            >
              <button type="button" class="btn-secondary btn-sm btn-transparent hover:cursor" :class="smallButton">
                {{ $t("generic.buttons.cancel") }}
              </button>
            </open-side-panel-button>
          </template>
        </div>
        <div
          v-if="appointment.turnaround_duration > 0"
          class="absolute left-0 top-0 w-full rounded !bg-tertiary-100 opacity-40"
          :class="{ 'top-[38px]': currentZoom > 0.6, '!top-[26px]': currentZoom === 0.6 }"
          :style="getStyle(appointment, 'turnaround_duration')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ArrivalStatusSelector from "./ArrivalStatusSelector.vue";
import NotifyWaitlistButton from "./NotifyWaitlistButton.vue";
import { mapState } from "vuex";
import axios from "axios";
import VToggle from "@/js/vue/components/common/ui/VToggle.vue";
import VTooltip from "@/js/vue/components/common/ui/VTooltip.vue";

export default {
  components: { VTooltip, VToggle, ArrivalStatusSelector, NotifyWaitlistButton },
  inject: ["bus", "config", "notificationService"],
  props: {
    item: {
      type: Object,
    },
    appointment: {
      type: Object,
    },
    bookingClass: {
      type: String,
      default: "",
    },
    currentUser: {
      type: Object,
    },
    currentZoom: {
      type: Number,
    },
    currentDate: {
      type: String,
      required: false,
    },
    arrivalStatuses: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      tooltipTimeout: null,
    };
  },
  computed: {
    ...mapState({
      isExpanded: (state) => state.booking.isExpanded,
      forPreview: (state) => state.booking.forPreview,
      forDefault: (state) => state.booking.forDefault,
    }),
    roles() {
      return this.currentUser?.roles.map((role) => role.name) || [];
    },
    isAdminOrManager() {
      return (
        this.roles.includes(this.config.roles.admin.toLowerCase()) ||
        this.roles.includes(this.config.roles.manager.toLowerCase())
      );
    },
    isCompact() {
      return this.forPreview || !this.isExpanded;
    },
    columnIndex() {
      return this.item.columnIndex;
    },
    room() {
      return {
        room_id: this.appointment.room_id,
        name: this.appointment.room_name,
        columnIndex: this.columnIndex,
      };
    },
    actionData() {
      return {
        room: this.room,
        appointment: this.appointment,
        currentDate: this.currentDate,
        currentUser: this.currentUser,
      };
    },
    compactSidePanelComponent() {
      if (!this.isCompact) {
        return null;
      }

      if (this.appointment.is_booked) {
        return "BookingDetail";
      }

      if (!["break", "note"].includes(this.appointment.type) && this.appointment.is_online) {
        return "BookingCreate";
      }

      return null;
    },
    smallButton() {
      return {
        "!text-2xs !py-xsSpace !pt-[8px]": this.currentZoom >= 0.8,
        "!text-2xs !py-xsSpace !pt-[9px]": this.currentZoom === 0.6,
        "!text-2xs !py-[6px] !pt-[7px]": this.currentZoom <= 0.4,
        "!py-[3.5px] !pt-[5px] !px-[4px]": !this.isExpanded && this.currentZoom <= 0.4,
      };
    },
  },
  methods: {
    sidePanelData(defaultTab = "Payment") {
      return {
        columnIndex: this.columnIndex,
        bookingId: this.appointment.booking_id,
        bookingClass: this.bookingClass,
        currentUser: this.currentUser,
        defaultTab: defaultTab,
        showAddTask: true,
        showNewScheduledBooking: true,
        forceCheckTerminalPayments: true,
      };
    },
    showToolTip($event, appointment, type = "waiver") {
      const el = document.getElementById(`${type}-${appointment.id}`);
      this.tooltipTimeout = setTimeout(() => {
        el.style = "display: inline;";

        setTimeout(() => {
          el.style = "display: hidden;";
          clearTimeout(this.tooltipTimeout);
        }, 2000);
      }, 500);
    },
    hideToolTip($event, appointment, type = "waiver") {
      const el = document.getElementById(`${type}-${appointment.id}`);
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }

      el.style = "display: hidden;";
    },
    onChangeAppointmentStatus(value) {
      if (this.appointment.is_shift_rescheduled) {
        return;
      }
      const params = {
        appointment_id: this.appointment.id,
        online: value,
      };
      axios.post(`/api/admin/bookings/rooms/toggle-status`, params).then(() => {
        this.bus.$emit("bookingsChange", {
          columnIndex: this.room.columnIndex,
          toggleStatus: params,
        });
      });
    },
    getStyle(item, field = "duration") {
      let unit = 0;
      let duration = item[field];
      switch (Number(this.currentZoom)) {
        case 0.8:
          unit = 32;
          break;
        case 0.6:
          unit = 24;
          break;
        case 0.4:
          unit = 16;
          break;
        default:
          unit = 40.125;
      }

      if (this.isCompact && field === "duration") {
        if (item.is_belonged_to_bathhouse) {
          duration = 15;
        }

        if (this.forPreview) {
          duration = 80;
        }
      }

      if (item.type === "note") {
        return {
          background: `repeating-linear-gradient(-55deg, #dcfce7 , #dcfce7 4px, white 4px, white 5px)`,
          height: unit * (duration / 15) + "px",
        };
      }

      if (!item.is_booked) {
        return { height: unit * (duration / 15) + "px" };
      }

      if (field !== "duration") {
        return {
          background: `repeating-linear-gradient(-55deg, ${item.colour}, ${item.colour} 5px, #333 5px, #333 8px)`,
          height: unit * (duration / 15) + "px",
        };
      }
      if (item.is_held) {
        const itemColor = this.hexToRgbA(item.colour, 0.3);
        return {
          background: `repeating-linear-gradient(-55deg, ${itemColor}, ${itemColor} 5px, #d6d6d6 5px, #d6d6d6 8px)`,
          height: unit * (duration / 15) + "px",
        };
      }

      let background = item.colour;
      if (item.is_addon && !item.keep_original_color) {
        background = "#c797f5";
      }

      return {
        height: unit * (duration / 15) + "px",
        background,
        color: this.setContrast(this.hexToRgb(background)),
      };
    },

    setContrast(rgb) {
      return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 120 ? "black" : "#f1f1f1";
    },

    hexToRgb(hex) {
      if (!hex) {
        return { r: 210, b: 100, g: 100 };
      }

      const h = "0123456789ABCDEF";
      const r = h.indexOf(hex[1]) * 16 + h.indexOf(hex[2]);
      const g = h.indexOf(hex[3]) * 16 + h.indexOf(hex[4]);
      const b = h.indexOf(hex[5]) * 16 + h.indexOf(hex[6]);
      return { r, g, b };
    },

    hexToRgbA(hex, opacity = 1) {
      const { r, g, b } = this.hexToRgb(hex);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },

    clickWaiver() {
      this.bus.$emit("openModal", {
        componentName: "SendWaiverConfirmationModal",
        callback: () => {
          this.sendWaiver();
        },
      });
    },
    sendWaiver() {
      if (this.appointment.is_waiver_expired && this.appointment.client_id) {
        axios.post(`/api/admin/customers/${this.appointment.client_id}/waivers/send`).then(({ data }) => {
          this.notificationService.notify(data.statusText);
        });
      }
    },
    copyLink() {
      navigator.clipboard.writeText(this.appointment.quick_book_url);
      this.notificationService.notify(
        this.$t("generic.responses.copy_success") +
          ` ${this.appointment.room_name} start at ${this.appointment.start} end at ${this.appointment.stop}`,
      );
    },
    deleteAppointment() {
      if (this.appointment.id) {
        const deleteUrl =
          this.appointment.type === "note"
            ? `/api/admin/rooms/${this.appointment.room_id}/booking-notes/${this.appointment.id}`
            : `/api/admin/appointments/${this.appointment.id}`;
        axios.delete(deleteUrl).then(({ data }) => {
          this.notificationService.notify(data.statusText);
          this.bus.$emit("bookingsChange", { columnIndex: this.room.columnIndex, delete: this.appointment });
        });
      }
    },
    openCardMenu() {
      this.$refs.card.closest("li").classList.add("!z-40");
    },
    closeCardMenu() {
      this.$refs.card.closest("li").classList.remove("!z-40");
    },
  },
};
</script>
