<template>
  <div class="isolate flex flex-auto flex-col" v-if="Object.keys(currentCustomer).length">
    <div v-if="bookings.length" class="flex flex-auto flex-col">
      <div class="relative my-6 flex items-center justify-between">
        <h1 class="text-xl font-semibold text-white2">{{ $t("booking.headings.all") }}</h1>

        <div class="flex justify-end pt-smSpace md:pt-0">
          <a
            :href="bookingUrl + `?customer=${currentCustomer.id}`"
            class="btn-emptyState font-semibold !text-white2 hover:!text-brand"
          >
            {{ $t("client.headings.book_new_appointment") }}
          </a>
        </div>
      </div>
      <div v-if="!loading">
        <div class="flex flex-col gap-6">
          <div
            v-for="bookings in groupedBookingsByOrderAndStatus"
            class="flex flex-col gap-6 rounded-xl border bg-transparent bg-white2 p-mdSpace text-sm text-brand"
          >
            <div class="flex flex-col-reverse items-start gap-4 md:flex-row">
              <div class="flex flex-1 flex-col gap-4">
                <div
                  v-for="booking in groupedBookingsByProduct(bookings)"
                  class="flex flex-col gap-2 md:flex-row md:gap-8"
                >
                  <div class="flex flex-col">
                    <div class="text-lg font-semibold">
                      {{ booking[0].date }}
                    </div>
                    <div class="text-sm">
                      {{ booking[0].booking_time }}
                    </div>
                  </div>

                  <div class="flex flex-1 flex-col">
                    <div class="flex justify-between text-lg font-semibold">
                      <h3>{{ booking[0].title }}</h3>

                      <span v-if="booking.length > 1">x{{ booking.length }}</span>
                    </div>

                    <div class="text-sm">{{ booking[0].variation_title }} (${{ booking[0].variation_price }})</div>
                  </div>
                </div>
              </div>

              <div class="flex justify-end md:min-w-[12rem]">
                <div>
                  <v-badge
                    class="py-1.5 !text-base font-medium"
                    :value="bookings[0].status.value"
                    :type="bookings[0].status.type"
                  />
                </div>
              </div>
            </div>

            <div class="flex flex-col justify-between gap-2 text-neutral-700 md:flex-row">
              <div>
                <div v-if="isPastBooking(bookings[0]) || bookings[0].is_cancelled" class="flex gap-2">
                  <span v-if="isPastBooking(bookings[0])">{{ $t("booking.headings.another_session") }}</span>
                  <span v-if="bookings[0].is_cancelled">{{ $t("booking.headings.try_again") }}</span>
                  <button @click="rebook(bookings[0].id)" class="underline">
                    {{ $t("client.headings.rebook") }}
                  </button>
                </div>

                <div v-else class="flex items-center gap-3">
                  <div v-if="!bookings[0].is_cancelled && !bookings[0].in_lockout" class="flex gap-2">
                    <span>{{ $t("booking.headings.cant_make_it") }}</span>

                    <div>
                      <button
                        @click="reschedule(bookings[0])"
                        class="relative w-fit bg-transparent text-right font-normal underline"
                        @mouseover="showToolTip($event, bookings[0])"
                        @mouseout="closeToolTip($event, bookings[0])"
                      >
                        {{ $t("client.headings.reschedule") }}
                      </button>
                      {{ $t("generic.words.or") }}
                      <button
                        @click="openConfirmCancellationModal(bookings[0])"
                        class="relative w-fit bg-transparent text-right font-normal underline"
                      >
                        {{ $t("generic.buttons.cancel") }}
                      </button>
                    </div>
                  </div>
                  <p v-if="bookings[0].in_lockout" :id="`booking-${bookings[0].id}`">
                    {{ $t("booking.headings.booking_in_lockout") }}
                  </p>
                </div>
              </div>

              <a
                v-if="!isPastBooking(bookings[0]) && !bookings[0].is_cancelled"
                href="https://sos-senseofself.com/cancelation-policy"
                target="_blank"
                class="text-neutral-400 underline"
              >
                {{ $t("generic.view_policy") }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        aria-label="Loading..."
        role="status"
        class="flex min-h-[500px] items-center justify-center space-x-2 text-white2"
      >
        <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
        <span class="text-lg font-medium">Loading . . .</span>
      </div>
    </div>
    <div v-else class="mx-auto flex max-w-[344px] flex-col items-center gap-6 md:mt-12 md:w-[600px] md:max-w-none">
      <h1 class="w-full text-xl font-semibold text-white2 md:hidden">{{ $t("booking.headings.all") }}</h1>

      <div class="flex w-full flex-col gap-6 rounded-xl bg-white2 p-6 md:flex-row md:gap-0">
        <img src="/img/empty_state.jpg" alt="Empty state" class="h-60 object-cover md:w-60" />
        <div class="flex flex-col items-center justify-center gap-6 md:px-6">
          <p class="text-center text-emptyState" v-html="$t('client.headings.empty_state')"></p>
          <a
            :href="bookingUrl + `?customer=${currentCustomer.id}`"
            class="btn-emptyState border-btn-emptyState text-hover:!text-emptyState !text-emptyState"
          >
            {{ $t("client.buttons.book_now") }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="h-[50vh]">
    <div class="pt-8 text-center text-white2">
      {{ $t("client.headings.null_customer") }}
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { groupBy, orderBy } from "lodash";

export default {
  inject: ["bus", "notificationService"],
  props: {
    bookingUrl: {
      type: String,
      required: true,
    },
    cancelBookingId: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data: function () {
    return {
      bookings: [],
      loading: false,
      tabs: [
        { id: "upcoming", label: this.$t("booking.labels.current_bookings"), active: true },
        { id: "past", label: this.$t("booking.labels.past_bookings"), active: false },
        { id: "cancelled", label: this.$t("customer.headings.cancelled"), active: false },
      ],
      haveConfirmedCancellation: false,
    };
  },
  computed: {
    ...mapState({
      currentCustomer: (state) => state.customer.currentCustomer,
    }),
    groupedBookingsByOrderAndStatus() {
      return orderBy(
        groupBy(this.bookings, (booking) => `${booking.order_id}-${booking.date}-${booking.status.type}`),
        ["0.start_at"],
        ["desc"],
      );
    },
  },
  watch: {
    currentCustomer: {
      handler(newValue, oldValue) {
        this.getCustomerBookings();
      },
      deep: true,
    },
  },
  methods: {
    showToolTip($event, booking) {
      if (!booking.in_lockout) {
        return;
      }

      const el = $event.target.querySelector(".tooltip");
      el.style.display = "inline";
    },
    closeToolTip($event, booking) {
      if (!booking.in_lockout) {
        return;
      }

      const el = $event.fromElement.querySelector(".tooltip");

      if (!el) {
        return;
      }

      el.style.display = "none";
    },
    getCustomerBookings() {
      if (!this.currentCustomer) return;

      this.loading = true;
      this.bookings = [];
      axios
        .get(`/api/client/${this.currentCustomer.id}/bookings`, {
          params: {
            sort: "-date",
          },
        })
        .then(({ data }) => {
          this.bookings = data.data;
          this.loading = false;
          setTimeout(() => {
            if (this.cancelBookingId && !this.haveConfirmedCancellation) {
              const cancelledBooking = this.bookings.find((b) => b.id == this.cancelBookingId);
              if (!cancelledBooking) {
                return;
              }

              this.haveConfirmedCancellation = true;
              this.openConfirmCancellationModal(cancelledBooking);
            }
          }, 1000);
        });
    },
    isPastBooking(booking) {
      return moment().isAfter(booking.start_at);
    },
    rebook(bookingId) {
      axios.post(`/api/client/bookings/${bookingId}/rebook`).then(() => {
        window.location.href = this.bookingUrl;
      });
    },
    reschedule(booking) {
      if (booking.in_lockout) {
        return;
      }

      window.location.href = booking.reschedule_url;
    },
    openConfirmCancellationModal(booking) {
      this.bus.$emit("openModal", {
        componentName: "ConfirmCancellationModal",
        componentData: {
          booking: booking,
        },
        callback: () => {
          this.cancel(booking);
        },
      });
    },
    cancel(booking) {
      if (booking.is_cancelled || booking.in_lockout || this.loading) {
        return;
      }

      this.loading = true;
      axios.post(`/api/client/bookings/${booking.id}/cancel`).then(({ data }) => {
        if (data.hasOwnProperty("statusText")) {
          this.notificationService.notify(data.statusText);
          return setTimeout(() => {
            window.location.href = "/client/bookings";
          }, 1500);
        }

        window.location.href = "/client/bookings";
        this.loading = false;
      });
    },
    toggle(item) {
      this.tabs.forEach((i) => {
        i.active = false;
      });
      item.active = !item.active;
      this.getCustomerBookings();
    },
    groupedBookingsByProduct(bookings) {
      return orderBy(groupBy(bookings, "variation_id"), ["0.start_at"], ["asc"]);
    },
  },
};
</script>
