<template>
  <div>
    <label class="block">
      <input
        class="block w-full rounded-md border border-tertiary-200 px-xsSpace py-2xsSpace !text-sm"
        type="text"
        :name="name"
        v-model="term"
        :placeholder="placeholder"
        v-on:keyup.enter="search"
        @input="$emit('input', term)"
      />
    </label>
    <button class="btn-secondary btn-sm" type="button" @click="search">Search</button>
  </div>
</template>
<script>
export default {
  name: "VSearch",
  inject: ["bus"],
  props: {
    name: {
      type: String,
      default: () => {
        return null;
      },
    },
    placeholder: {
      type: String,
      default: () => {
        return null;
      },
    },
    eventName: {
      type: String,
      default: () => {
        return "termChanged";
      },
    },
  },
  data() {
    return {
      term: null,
    };
  },
  created() {},
  methods: {
    search() {
      this.bus.$emit(this.eventName, { name: this.name, term: this.term });
    },
  },
};
</script>
