<template>
  <slot></slot>
</template>

<script>
import { computed } from "vue";

export default {
  name: "Collapsible",
  props: {
    context: {
      type: String,
      required: false,
      default: "default",
      description: "The context of the collapsible, the child components will use this to provide the collapsed state.",
    },
    open: {
      type: Boolean,
      required: false,
    },
    defaultOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      opened: this.open === undefined ? this.defaultOpen : this.open,
    };
  },
  watch: {
    open(value) {
      this.opened = value;
    },
  },
  provide() {
    return {
      collapsed: computed(() => this.opened),
      toggle: (context) => {
        if (this.context === context) {
          this.opened = !this.opened;
        }
      },
    };
  },
};
</script>
