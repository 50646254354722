<template>
  <div
    :class="{ '-open': isOpen, [componentName]: true }"
    class="side-panel flex"
    v-show="!disableListeners || (disableListeners && isOpen)"
  >
    <div class="h-full w-2/3" @click="close"></div>
    <aside :style="style">
      <div>
        <header v-if="title">
          <div class="flex h-2xlSpace w-full items-center justify-between border-b px-lgSpace">
            <h3 class="text-lg font-bold" v-html="title" />
            <a @click="close">
              <v-icon icon="XMarkIcon" classes="cursor-pointer rounded-lg hover:bg-tertiary-100 h-6 w-6" />
            </a>
          </div>
        </header>
        <v-notification class="pt-smSpace" emit-event-to="showNotificationPanel" />
        <component v-if="componentName" :is="componentName" v-bind="componentData" />
      </div>
    </aside>
    <div class="h-full w-full" @click="close"></div>
  </div>
</template>
<script>
export default {
  inject: ["bus"],
  props: {
    disableListeners: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentName: "",
      componentData: {},
      title: "",
      isOpen: false,
      closeCallback: null,
      style: null,
    };
  },
  created() {
    if (!this.disableListeners) {
      this.bus.$on("openSidePanel", ({ componentName, componentData, title, closeCallback }) => {
        this.open(componentName, componentData, title, closeCallback);
      });
      this.bus.$on("closePanel", (param) => {
        this.close();

        if (typeof param === "function") {
          param();
        }
      });
    }
  },
  methods: {
    open(componentName, componentData, title, closeCallback = () => {}) {
      this.componentName = componentName;
      this.componentData = componentData;
      this.title = title;
      this.closeCallback = closeCallback;

      if (this.disableListeners) {
        this.style = "position: absolute; left: 33.33%";
      }

      this.isOpen = true;
      this.$parent.$el.ownerDocument.documentElement.style.overflowY = "hidden";
    },
    close() {
      if (this.isOpen) {
        this.isOpen = false;
        this.componentName = "";
      }
      if (this.closeCallback) {
        this.closeCallback();
        this.closeCallback = null;
      }

      this.$parent.$el.ownerDocument.documentElement.style.overflowY = "";
    },
  },
};
</script>

<style scoped>
.side-panel {
  position: fixed;
  pointer-events: none;
  z-index: 50;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  transition: background-color 0.3s linear;

  aside {
    overflow: auto;
    bottom: 0;
    display: flex;
    padding: 0;
    position: fixed;
    pointer-events: all;
    transition: width 0.4s ease;
    top: 0;
    right: 0;
    width: 0;
    background-color: #ffffff;

    > div {
      width: 100%;
      flex: 0 0 auto;
      height: 100%;
      display: inline-flex;
      flex-direction: column;

      > header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  &.-open {
    pointer-events: all;
    background-color: rgba(50, 50, 50, 0.3);

    aside {
      width: 33.34%;
    }
  }
}
</style>
