<template>
  <form class="flex w-full flex-col justify-between" v-if="!loading">
    <div class="grow space-y-lgSpace p-lgSpace">
      <div class="space-y-lgSpace">
        <div class="grid grid-cols-1 gap-smSpace">
          <div class="text-lg font-bold">{{ title.date }} at {{ title.time }}</div>

          <div class="pb-lgSpace text-sm">{{ room.name }}</div>

          <div class="flex flex-row justify-between rounded bg-slate-100 p-mdSpace text-sm">
            <span>{{ cart.first_name + " " + cart.last_name }}</span>
            <a @click="close" v-text="$t('generic.buttons.edit')" class="cursor-pointer"></a>
          </div>

          <template v-for="formData in cart.forms" v-if="form.required_forms">
            <div
              class="flex flex-row justify-between rounded bg-slate-100 p-mdSpace text-sm"
              :class="{ '-mb-smSpace': form.required_forms.includes(formData.id) }"
            >
              <span>{{ formData.title }}</span>
              <a @click="editForm(formData.id)" v-text="$t('generic.buttons.edit')" class="cursor-pointer"></a>
            </div>
            <div v-if="form.required_forms.includes(formData.id)" class="bg-slate-50 p-mdSpace">
              <template v-for="field in formData.fields" :key="field.id">
                <input-wrapper
                  class="pt-mdSpace"
                  :field="fieldName(field, formData.id)"
                  :form="form"
                  :label-text="field.label"
                  :is-required="field.required"
                >
                  <v-field
                    class="v-field-custom-booking v-field-admin"
                    :class="{ '-checkbox': field.type === 'checkbox' }"
                    v-model="form[formName(formData.id)][fieldName(field)]"
                    :name="fieldName(field, formData.id)"
                    :type="field.type"
                    :options="field.options"
                    :placeholder="field.placeholder"
                    :field="field"
                    :editable="true"
                  ></v-field>
                </input-wrapper>
              </template>
              <a
                @click="editForm(formData.id, true)"
                class="btn-secondary btn-sm float-right"
                v-text="$t('generic.buttons.cancel')"
              ></a>
            </div>
          </template>

          <div
            class="rounded bg-slate-100 text-sm"
            v-if="cart.hasOwnProperty('payment') && cart.payment.type === this.config.PAYMENT_LATER"
          >
            <v-radio
              class="flex items-center space-x-xsSpace p-mdSpace"
              value="on_hold"
              name="booking_option"
              v-model="form.booking_option"
              @change="($event) => (form.booking_option = $event.target.value)"
              :label-text="$t('booking.headings.hold_customer_booking')"
            />
            <div v-if="form.booking_option === 'on_hold'" class="p-mdSpace pt-0">
              <input-wrapper field="expired_at" :form="form" :label-text="$t('booking.columns.expiry_date')">
                <date-picker
                  v-model:value="form.expired_at"
                  :placeholder="$t('booking.placeholders.select_date')"
                  value-type="format"
                  class="w-full"
                  format="DD-MM-YYYY"
                  type="date"
                  :disabled-date="disableDateNotAllowOnHoldBooking"
                ></date-picker>
              </input-wrapper>
              <v-checkbox
                class="flex select-none items-center space-x-xsSpace pt-mdSpace [&_span]:!text-xs"
                name="send_on_hold_information"
                v-model="form.send_on_hold_information"
                :label-text="$t('booking.headings.send_on_hold_information')"
              />
            </div>
          </div>

          <template v-if="cart.hasOwnProperty('payment') && cart.payment.type !== this.config.PAYMENT_LATER">
            <div class="rounded bg-slate-100 text-sm leading-10">
              <v-radio
                class="flex items-center space-x-xsSpace p-mdSpace"
                value="is_confirmed"
                name="booking_option"
                v-model="form.booking_option"
                @change="($event) => (form.booking_option = $event.target.value)"
                :label-text="$t('booking.headings.send_confirmation_email')"
              />
              <div
                v-if="form.booking_option === 'is_confirmed' && form.hasOwnProperty('message')"
                class="p-mdSpace pt-0"
              >
                <input-wrapper field="message" :form="form">
                  <ckeditor :editor="classicEditor" v-model="form.message" name="message"></ckeditor>
                </input-wrapper>
              </div>
            </div>

            <input-wrapper field="booking_option" :form="form">
              <div class="rounded bg-slate-100 text-sm leading-10">
                <v-radio
                  class="flex items-center space-x-xsSpace p-mdSpace"
                  value="no_action_required"
                  name="booking_option"
                  v-model="form.booking_option"
                  @change="($event) => (form.booking_option = $event.target.value)"
                  :label-text="$t('booking.headings.no_action_required')"
                />
              </div>
            </input-wrapper>

            <input-wrapper field="skip_reminder" :form="form">
              <div class="rounded bg-slate-100 leading-10 [&_span]:!text-xs">
                <v-checkbox
                  class="flex items-center space-x-xsSpace p-mdSpace"
                  name="skip_reminder"
                  v-model="form.skip_reminder"
                  :label-text="$t('booking.headings.skip_reminder')"
                />
              </div>
            </input-wrapper>
          </template>

          <v-notification
            v-if="notificationMessage"
            class="pt-lgSpace"
            :type="success ? 'success' : 'error'"
            :content="notificationMessage"
          >
          </v-notification>
        </div>
      </div>
    </div>
    <div
      class="flex items-end justify-end rounded p-lgSpace sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
    >
      <div class="flex space-x-xsSpace">
        <a @click="close" class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')"></a>
        <a @click.prevent="save" class="btn-primary btn-sm" v-text="$t('generic.buttons.continue')"></a>
      </div>
    </div>
  </form>
  <div v-else aria-label="Loading..." role="status" class="flex h-full items-center justify-center space-x-2">
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Form from "form-backend-validation";
import moment from "moment/moment";
import VField from "~vendor/form-builder/resources/js/components/VField.vue";
import axios from "axios";

export default {
  inject: ["bus", "notificationService", "config"],
  components: { VField },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      success: false,
      title: {
        date: null,
        time: null,
      },
      notificationMessage: null,
      form: new Form(),
      cart: {},
    };
  },
  computed: {
    ...mapState({
      appointment: (state) => state.bookingCreate.appointment,
      currentDate: (state) => state.bookingCreate.currentDate,
      currentUser: (state) => state.bookingCreate.currentUser,
      room: (state) => state.bookingCreate.room,
    }),
    isAdminOrManager() {
      const user = this.$store.state.user;
      return user && (user.role === this.config.roles.administrator || user.role === this.config.roles.manager);
    },
  },
  created() {
    this.title.date = moment(this.currentDate, this.config.DATETIME_FORMAT).format("dddd, MMMM Do YYYY");
    this.title.time = moment(this.appointment.start_at, "h:mm").format("LT");

    this.getUser();

    if (this.id) {
      axios.get("/api/admin/bookings/cart/" + this.id).then(({ data }) => {
        this.cart = data;

        axios
          .get("/api/admin/notifications/show", {
            params: {
              type: "booking_confirmation",
              product_id: this.cart.product_id,
              product_category_id: this.cart.product_category_id,
            },
          })
          .then(({ data }) => {
            let content = null;
            if (data.data.hasOwnProperty("content")) {
              content = data.data.content;
            }

            this.initiateForm(content);
          })
          .catch(() => {
            this.initiateForm();
          });
      });
    }
  },
  watch: {
    form: {
      handler(newValue, oldValue) {
        if (newValue.booking_option === "is_confirmed") {
          this.form.expired_at = null;
        }
      },
      deep: true,
    },
  },
  methods: {
    getUser() {
      this.$store.dispatch("user/fetchUser");
    },
    disableDateNotAllowOnHoldBooking(date) {
      const now = moment();
      const calenderDate = moment(date);
      let bookingDate = moment(this.currentDate, "DD-MM-YYYY");
      if (!this.isAdminOrManager) {
        bookingDate = bookingDate.subtract(1, "days");
      }

      return calenderDate.isBefore(now, "date") || calenderDate.isAfter(bookingDate, "date");
    },
    initiateForm(content = null) {
      const formDetail = {
        expired_at: null,
        message: content,
        booking_option: null,
        required_forms: [],
        skip_reminder: this.cart.product_variant?.price_per_unit === 0,
        send_on_hold_information: true,
      };
      if (this.cart.forms.length) {
        this.cart.forms.forEach((formData) => {
          const data = formData.fields;
          formDetail[`form${formData.id}`] = {};
          data.map((o) => {
            formDetail[`form${formData.id}`][`${o.name}`] = o.type === "check-group" ? [] : "";
          });
        });
      }

      if (this.cart.payment.type === this.config.PAYMENT_LATER) {
        formDetail.booking_option = "on_hold";
      } else if (this.cart.product_variant?.price_per_unit === 0) {
        formDetail.booking_option = "no_action_required";
      } else {
        formDetail.booking_option = "is_confirmed";
      }

      this.form = new Form(formDetail, { resetOnSuccess: false });
    },
    formName(formId) {
      return `form${formId}`;
    },
    fieldName(field, formId) {
      if (formId) {
        return `form${formId}.${field.name}`;
      }
      return `${field.name}`;
    },
    editForm(formId, isRemove = false) {
      if (isRemove) {
        const index = this.form.required_forms.indexOf(formId);
        if (index !== -1) {
          this.form.required_forms.splice(index, 1);
        }
        return;
      }
      if (!this.form.required_forms.includes(formId)) {
        this.form.required_forms.push(formId);
      }
    },
    close() {
      this.bus.$emit("openSidePanel", {
        componentName: "BookingCreate",
        title: "",
        componentData: {
          room: this.room,
          appointment: this.appointment,
          currentDate: this.currentDate,
          currentUser: this.currentUser,
          id: this.id,
        },
        closeCallback: "",
      });
    },
    save() {
      if (this.loading && this.notificationMessage) {
        return;
      }

      this.loading = true;
      this.form
        .post(`/api/admin/bookings/${this.id}/confirm`)
        .then((data) => {
          this.notificationMessage = data.statusText;
          this.success = true;

          setTimeout(() => {
            this.loading = false;
          }, 500);

          setTimeout(() => {
            this.bus.$emit("closePanel");
            if (data.appointment) {
              this.bus.$emit("bookingsChange", {
                column: {
                  room_id: data.appointment.room_id,
                  capacity_index: data.appointment.capacity_index,
                },
              });
              setTimeout(() => {
                this.bus.$emit("openSidePanel", {
                  componentName: "BookingDetail",
                  title: "",
                  componentData: {
                    bookingId: data.booking.id,
                    bookingClass: data.booking.class,
                    showCompletePayment: false,
                    columnIndex: this.room.columnIndex,
                    forceCheckTerminalPayments: true,
                    showNewScheduledBooking: true,
                    currentUser: this.currentUser,
                    showAddTask: true,
                    actionData: {
                      appointment: data.appointment,
                      currentDate: this.currentDate,
                      currentUser: this.currentUser,
                    },
                  },
                });
              }, 500);
            }
          }, 1000);
        })
        .catch(({ response }) => {
          this.notificationMessage = response.data.message;
          this.success = false;
          this.loading = false;
        });
    },
  },
};
</script>
