const state = () => ({
  productVariants: [],
});

const getters = {};

const actions = {
  setProductVariants({ commit }, productVariants) {
    commit("setProductVariants", productVariants);
  },
};

const mutations = {
  setProductVariants(state, productVariants) {
    state.productVariants = productVariants;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
