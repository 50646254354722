<template>
  <div class="space-y-6 pb-10">
    <div class="space-y-2">
      <label class="text-sm font-medium text-white2">
        {{ productTitle }}
      </label>
      <div class="grid grid-cols-2 gap-6">
        <button
          v-for="category in categories"
          type="button"
          class="btn-secondary !block items-center !bg-brand !py-4 font-sloganSerif !text-[26px] !font-bold !text-white2"
          :class="form.category_id && category.id === form.category_id ? 'opacity-100' : 'opacity-50'"
          @click="selectCategory(category)"
        >
          {{ category.name }}
        </button>
      </div>
      <p class="text-base text-white2">{{ categoryIntro }}</p>
    </div>
    <product-list
      :venue-id="cart.venue_id"
      :category-id="form.category_id"
      :is-massage="isBelongsMassageCategory"
      :quantity="form.quantity"
      v-model="form.product_id"
      :hide-others="true"
      :show-quantity-stepper="true"
      @update:products="products = $event"
      @update:quantity="(newQuantity) => form.populate({ quantity: newQuantity })"
    />
    <div v-if="form.errors.has('product_id')" class="flex w-full rounded-md bg-pink-100 px-xsSpace py-1">
      <v-icon icon="SolidExclamationCircleIcon" classes="w-5 h-5 text-red-400 mr-2xsSpace"></v-icon>
      <small class="block text-sm text-red-500"> {{ form.errors.first("product_id") }}</small>
    </div>
    <variant-list
      class="!mt-xsSpace"
      ref="variantList"
      v-if="selectedCategory && form.product_id && products.find((product) => product.id === form.product_id)"
      v-model="form.product_variant_id"
      :product-id="form.product_id"
    />
    <div class="!mt-4 flex justify-center">
      <button class="btn-secondary !block w-full items-center bg-white !text-brand" @click="continueBooking">
        {{ $t("booking.buttons.next_select_date_and_time") }}
      </button>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import { debounce } from "lodash";

export default {
  name: "BookingProduct",
  inject: ["bus", "notificationService"],
  props: {
    cart: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      form: new Form(
        {
          quantity: this.cart.quantity,
          category_id: this.cart.category_id,
          product_id: this.cart.product_id || null,
          product_variant_id: this.cart.product_variant_id || null,
          add_on_product_id: this.cart.add_on_product_id || null,
          add_on_product_variant_id: this.cart.add_on_product_id || null,
        },
        { resetOnSuccess: false },
      ),
      updateCartDebounce: debounce(this.updateCart, 500),
      isUpdating: false,
      products: [],
    };
  },

  computed: {
    productTitle() {
      return [`${this.selectedCategory.name} Appointments`].join("/");
    },
    selectedCategory() {
      return this.categories.find((category) => category.id === this.form.category_id);
    },
    isBelongsMassageCategory() {
      return this.selectedCategory.name.toLowerCase().includes("massage");
    },
    categoryIntro() {
      return this.isBelongsMassageCategory
        ? this.$t("booking.headings.massage_intro")
        : this.$t("booking.headings.bathhouse_intro");
    },
  },

  watch: {
    "form.product_id": {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.form.product_variant_id = null;
          this.form.add_on_product_id = null;
          this.form.add_on_product_variant_id = null;
          this.form.quantity = newValue ? 1 : 0;
          this.updateCartDebounce();
        }
      },
    },
    "form.product_variant_id": {
      handler() {
        this.updateCartDebounce();
      },
    },
    "form.quantity": {
      handler() {
        this.updateCartDebounce();
      },
    },
  },

  methods: {
    selectCategory(category) {
      this.form.category_id = category.id;
    },

    updateCart() {
      this.form.post("/api/booking/cart/product&skip_validation").then(() => {
        this.bus.$emit("reloadCart");
      });
    },

    continueBooking() {
      this.form
        .post("/api/booking/cart/product")
        .then(() => {
          window.location.href = "/booking/" + `appointment` + (this.customerId ? `customer=${this.customerId}` : "");
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
  },
};
</script>
