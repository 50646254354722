<template>
  <a v-if="isNotified" class="cursor-pointer items-center">
    <label
      class="btn-secondary btn-sm !cursor-default whitespace-nowrap !border-green-600 bg-green-600 !text-white"
      :class="buttonClasses"
    >
      {{ $t("generic.buttons.notified_waitlist") }}
    </label>
  </a>
  <open-side-panel-button
    v-else-if="isAvailable"
    component-name="NotifyWaitlist"
    :component-data="actionData"
    classes="cursor-pointer items-center"
  >
    <button type="button" class="btn-secondary btn-sm whitespace-nowrap" :class="buttonClasses">
      {{ $t("generic.buttons.notify_waitlist") }}
    </button>
  </open-side-panel-button>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  inject: ["bus", "notificationService"],
  props: {
    actionData: {
      type: Object,
    },
    buttonClasses: {
      type: [String, Object],
    },
    isNotified: {
      type: Boolean,
    },
  },
  computed: {
    isAvailable() {
      const date = moment(this.actionData.currentDate + " " + this.actionData.appointment.start_at, "DD-MM-yyyy H:i");

      return date.isAfter(moment.now());
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
