<template>
  <div
    class="flex min-h-[2rem] min-w-[2rem] items-center justify-center rounded-full bg-emptyState text-xs font-bold text-white"
  >
    {{ user.first_name.charAt(0).toUpperCase() + user.last_name.charAt(0).toUpperCase() }}
  </div>
</template>

<script>
export default {
  name: "VAvatar",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
