<template>
  <a class="flex w-full cursor-pointer items-center space-x-xsSpace p-xsSpace hover:bg-neutral-100" @click="edit">
    <span>{{ $t("generic.words.edit") }}</span>
  </a>
</template>

<script>
export default {
  name: "OpenVariantEditButton",
  inject: ["bus"],
  props: {
    rowData: {
      type: Object,
      required: true,
    },
  },

  methods: {
    edit() {
      this.bus.$emit("onEditVariant", this.rowData);
    },
  },
};
</script>
