<template>
  <div class="isolate flex flex-auto flex-col overflow-auto" v-if="Object.keys(currentCustomer).length">
    <div class="grid gap-4 pt-lgSpace lg:grid-cols-2">
      <template v-for="payment in payments">
        <div
          class="mt-smSpace flex cursor-pointer flex-col rounded-xl border bg-transparent p-mdSpace text-base font-semibold text-white2"
        >
          <p class="block">{{ $t("client.headings.payments.total") }}: {{ payment.total }}</p>
          <p class="block pt-xsSpace" v-if="payment.last_4">
            {{ $t("client.headings.payments.visa_ending_in") }} {{ payment.last_4 }}.
            {{ $t("client.headings.payments.exp") }} {{ payment.exp }}
          </p>
          <p class="block pt-xsSpace" v-if="payment.voucher">
            {{ $t("client.headings.payments.voucher_code") }} {{ payment.voucher }}.
          </p>
          <div class="grid grid-cols-1 gap-4 pt-xsSpace">
            <div class="text-right font-normal">{{ payment.date }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <div v-else class="h-[50vh]">
    <div class="pt-8 text-center text-white2">
      {{ $t("client.headings.null_customer") }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: function () {
    return {
      payments: [],
    };
  },
  computed: {
    ...mapState({
      currentCustomer: (state) => state.customer.currentCustomer,
    }),
  },
  watch: {
    currentCustomer: {
      handler(newValue, oldValue) {
        this.getPayments();
      },
      deep: true,
    },
  },
  methods: {
    getPayments() {
      axios.get(`/api/client/${this.currentCustomer.id}/payments`).then(({ data }) => {
        this.payments = data.data;
      });
    },
  },
};
</script>
