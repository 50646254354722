<template>
  <div
    v-if="loading"
    aria-label="Loading..."
    role="status"
    class="flex min-h-[500px] items-center justify-center space-x-2 text-white"
  >
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500 text-white">
      {{ $t("generic.words.processing") }}
    </span>
  </div>
  <div v-else class="flex flex-col space-y-lgSpace">
    <div
      class="flex flex-col space-y-mdSpace rounded-lg p-mdSpace"
      :class="{
        'bg-white': darkMode,
        'border border-white bg-brand': !darkMode,
      }"
    >
      <div class="flex flex-col space-y-smSpace" :class="{ 'text-brand': darkMode, 'text-white': !darkMode }">
        <span class="text-[19px] font-semibold">{{ $t("booking.headings.order_summary") }}</span>
        <div
          v-if="cart.hasOwnProperty('instances')"
          v-for="instance in cart.instances"
          class="flex flex-col space-y-smSpace rounded-lg bg-opacity-10 p-smSpace"
          :class="{ 'bg-white': !darkMode, 'bg-brand': darkMode }"
        >
          <p class="m-0 text-[19px] font-semibold">{{ voucher.title }}</p>
          <div>
            <p class="m-0 text-xs text-white/80">{{ $t("gift_voucher.fields.code") }}</p>
            <p class="mb-2 text-base">{{ instance.code }}</p>
            <p class="m-0 text-xs text-white/80">{{ $t("gift_voucher.fields.expiry") }}</p>
            <p class="mb-2 text-base">{{ instance.expiry }}</p>
          </div>
        </div>
        <div
          v-else
          class="flex flex-col rounded-lg bg-opacity-10 p-smSpace"
          :class="{ 'bg-white': !darkMode, 'bg-brand': darkMode }"
        >
          <p class="m-0 text-[19px] font-semibold">{{ voucher.title }}</p>
          <p v-if="cart.email" class="m-0 text-base">{{ cart.email }}</p>
        </div>
        <div
          class="flex flex-col space-y-smSpace divide-y text-sm"
          :class="{ 'text-slate-700': darkMode, 'text-white': !darkMode }"
        >
          <div class="flex flex-col space-y-xsSpace">
            <span class="text-lg font-bold">{{ $t("booking.columns.total_price") }}</span>
            <div class="flex justify-between">
              <span>{{ $t("booking.labels.unit_price") }}</span>
              <span>{{ toAUD(voucher?.value) }}</span>
            </div>
            <div class="flex justify-between">
              <span>{{ $t("booking.labels.quantity") }}</span>
              <span>{{ cart.quantity }}</span>
            </div>
            <div class="flex justify-between">
              <span>{{ $t("booking.labels.subtotal") }}</span>
              <span>{{ toAUD(cart.total) }}</span>
            </div>
          </div>

          <div class="flex flex-col">
            <div
              class="flex justify-between pt-smSpace font-semibold"
              :class="{ 'text-slate-700': darkMode, 'text-white': !darkMode }"
            >
              <span>{{ $t("booking.labels.total") }}</span>
              <span>{{ toAUD(cart.total) }}</span>
            </div>
            <div class="flex justify-end">
              <span class="text-xs" :class="{ 'text-white text-opacity-50': !darkMode, 'text-neutral-400': darkMode }">
                {{ $t("booking.labels.includes_gst") }} {{ toAUD(cart.total - cart.total / 1.1) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VoucherCartSummary",
  inject: ["notificationService", "config", "bus"],
  props: {
    initCart: {
      type: Object,
      required: false,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    loadOnStart: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      cart: this.initCart ? this.initCart : {},
      loading: this.loadOnStart,
    };
  },
  mounted() {
    this.bus.$on("reloadCart", () => {
      this.getCart();
    });
    if (this.loadOnStart) {
      this.getCart();
    }
  },
  computed: {
    voucher() {
      return this.cart.vouchers && this.cart.vouchers.length && this.cart.vouchers[0];
    },
    instance() {
      return this.cart.instances?.[0];
    },
  },
  methods: {
    async getCart() {
      const { data } = await axios.get("/api/voucher/cart");
      this.loading = false;
      this.cart = data;
    },
  },
};
</script>
