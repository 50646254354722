<template>
  <div class="max-w-xl space-y-lgSpace pt-xlSpace">
    <form class="grid grid-cols-1 gap-smSpace">
      <input-wrapper field="first_name" :form="form" :label-text="$t('user.columns.first_name')">
        <input type="text" v-model="form.first_name" :placeholder="$t('user.placeholders.first_name')" />
      </input-wrapper>
      <input-wrapper field="last_name" :form="form" :label-text="$t('user.columns.last_name')">
        <input type="text" v-model="form.last_name" :placeholder="$t('user.placeholders.last_name')" />
      </input-wrapper>
      <input-wrapper field="email" :form="form" :label-text="$t('user.columns.email')">
        <input type="email" v-model="form.email" :placeholder="$t('user.placeholders.email')" />
      </input-wrapper>
      <input-wrapper field="role_id" :form="form" :label-text="$t('user.columns.role')">
        <v-multi-select
          v-model="form.role_id"
          :init-options="roles"
          :placeholder="$t('user.columns.role')"
          track-by="value"
        />
      </input-wrapper>
      <input-wrapper field="password" :form="form" :label-text="$t('user.columns.password')">
        <input type="password" v-model="form.password" placeholder="••••••••" autocomplete="off" data-lpignore="true" />
      </input-wrapper>
      <input-wrapper field="password_confirmation" :form="form" :label-text="$t('user.columns.password_confirmation')">
        <input
          type="password"
          v-model="form.password_confirmation"
          placeholder="••••••••"
          autocomplete="off"
          data-lpignore="true"
        />
      </input-wrapper>

      <input-wrapper field="deputy_employee_id" :form="form" :label-text="$t('user.columns.deputy_employee_id')">
        <v-multi-select
          v-model="form.deputy_employee_id"
          :init-options="deputyEmployees"
          :placeholder="$t('user.columns.deputy_employee_id')"
          track-by="value"
        />
      </input-wrapper>

      <v-checkbox v-model="form.active" :label-text="$t('generic.words.active')"></v-checkbox>
    </form>
    <v-footer :list-url="listUrl" :save="save"></v-footer>
  </div>
</template>

<script>
import Form from "form-backend-validation";

export default {
  name: "UserEdit",
  inject: ["notificationService"],
  components: {},
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
    roles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    deputyEmployees: {
      type: Array,
      default: () => {
        return [];
      },
    },
    listUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      form: new Form(
        {
          id: this.getFieldValue("id"),
          first_name: this.getFieldValue("first_name"),
          last_name: this.getFieldValue("last_name"),
          email: this.getFieldValue("email"),
          active: this.getFieldValue("active", false),
          password: this.getFieldValue("password"),
          password_confirmation: this.getFieldValue("password"),
          role_id: this.getFieldValue("role_id"),
          // deputy_employee_id: this.getFieldValue("deputy_employee_id") || this.deputyEmployees[0].value,
          deputy_employee_id: this.getFieldValue("deputy_employee_id") || null,
        },
        { resetOnSuccess: false },
      ),
    };
  },

  mounted() {},

  methods: {
    getFieldValue(field, defaultValue = null) {
      if (this.user.hasOwnProperty(field)) {
        return this.user[field];
      }

      return defaultValue;
    },
    save() {
      if (this.user.hasOwnProperty("id")) {
        this.form
          .patch(`/api/admin/users/${this.user.id}`)
          .then(({}) => {
            window.location.reload();
          })
          .catch(({ response }) => {
            this.notificationService.notify(response.data.message, "error");
          });

        return;
      }

      this.form
        .post(`/api/admin/users`)
        .then(({ data }) => {
          window.location.reload();
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
  },
};
</script>
