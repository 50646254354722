<template>
  <label class="flex items-center space-x-xsSpace">
    <input
      :checked="isChecked"
      class="cursor-pointer rounded border border-tertiary-300"
      :class="{ '!cursor-not-allowed': disabled }"
      type="checkbox"
      v-model="input"
      @change="change"
      :disabled="disabled"
    />

    <span
      class="cursor-pointer text-sm text-tertiary-700"
      :class="{ '!text-white': darkTheme }"
      v-html="labelText"
    ></span>
  </label>
</template>

<script>
export default {
  name: "VCheckbox",
  props: {
    labelText: {
      type: String,
    },
    modelValue: {
      type: [Boolean, Number],
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: this.modelValue,
    };
  },
  computed: {
    isChecked() {
      return this.modelValue;
    },
    change($event) {
      $event.$emit("update:modelValue", this.input);
    },
  },
};
</script>
