<template>
  <div class="isolate flex flex-auto flex-col" v-if="Object.keys(currentCustomer).length">
    <ul role="list" class="divide-y divide-gray-100 text-white2">
      <li class="relative flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-xlSpace sm:flex-nowrap">
        <div class="w-1/3">{{ $t("client.headings.detail.name") }}</div>
        <div class="w-1/3">
          <div class="font-bold" v-if="!form.edit_customer">{{ currentCustomer.display_name }}</div>
          <template v-else>
            <div class="space-y-mdSpace">
              <input-wrapper field="first_name" :form="form" class="space-y-0 text-white2">
                <input
                  type="text"
                  class="bg-transparent"
                  v-model="form.first_name"
                  :placeholder="$t('customer.placeholders.first_name')"
                />
              </input-wrapper>
              <input-wrapper field="last_name" :form="form" class="space-y-0 text-white2">
                <input
                  type="text"
                  class="bg-transparent"
                  v-model="form.last_name"
                  :placeholder="$t('customer.placeholders.last_name')"
                />
              </input-wrapper>
            </div>
          </template>
        </div>
        <div class="absolute -top-xlSpace right-0 flex cursor-pointer gap-x-2.5 hover:text-orange-200">
          <v-icon icon="PencilSquareIcon" v-if="!form.edit_customer" @click="form.edit_customer = true"></v-icon>
        </div>
      </li>
      <li class="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-xlSpace sm:flex-nowrap">
        <div class="w-1/3">{{ $t("client.headings.detail.email") }}</div>
        <div class="w-1/3">
          <div class="font-bold" v-if="!form.edit_customer">{{ currentCustomer.email }}</div>
          <input-wrapper v-else field="email" :form="form" class="space-y-0 text-white2">
            <input
              type="text"
              class="bg-transparent"
              v-model="form.email"
              :placeholder="$t('customer.placeholders.email')"
            />
          </input-wrapper>
        </div>
      </li>
      <li class="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-xlSpace sm:flex-nowrap">
        <div class="w-1/3">{{ $t("client.headings.detail.phone") }}</div>
        <div class="w-1/3">
          <div class="font-bold" v-if="!form.edit_customer">{{ currentCustomer.phone }}</div>
          <input-wrapper v-else field="phone" :form="form" class="space-y-0 text-white2">
            <phone-number v-model="form.phone" :placeholder="$t('customer.placeholders.phone')" dark-mode />
          </input-wrapper>
        </div>
      </li>

      <li class="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-xlSpace sm:flex-nowrap">
        <div class="w-1/3">{{ $t("client.headings.detail.address") }}</div>
        <div class="w-1/3">
          <div class="font-bold" v-if="!form.edit_customer">{{ currentCustomer.full_address }}</div>
          <div class="grid" v-else>
            <input-wrapper field="full_address" :form="form" class="space-y-0 text-white2">
              <input type="text" disabled class="bg-transparent opacity-50" :value="fullAddress" />
            </input-wrapper>
            <div class="relative pt-smSpace">
              <label class="flex cursor-pointer items-center space-x-xsSpace">
                <input type="checkbox" v-model="isManualAddressInput" class="rounded border border-tertiary-300" />
                <span class="text-xs" v-text="$t('client.headings.detail.manual_input')"></span>
              </label>
            </div>
            <div class="relative pt-smSpace" v-if="isManualAddressInput">
              <input-wrapper
                field="address"
                class="text-white2"
                :form="form"
                :label-text="$t('customer.fields.address')"
              >
                <input
                  type="text"
                  class="bg-transparent"
                  v-model="form.address"
                  :placeholder="$t('customer.placeholders.address')"
                />
              </input-wrapper>
              <div class="flex flex-row space-x-3 pt-smSpace">
                <div class="basis-1/3">
                  <input-wrapper field="city" class="text-white2" :form="form" :label-text="$t('customer.fields.city')">
                    <input
                      type="text"
                      class="bg-transparent"
                      v-model="form.city"
                      :placeholder="$t('customer.placeholders.city')"
                    />
                  </input-wrapper>
                </div>
                <div class="basis-1/3">
                  <input-wrapper
                    field="state"
                    class="text-white2"
                    :form="form"
                    :label-text="$t('customer.fields.state')"
                  >
                    <input
                      type="text"
                      class="bg-transparent"
                      v-model="form.state"
                      :placeholder="$t('customer.placeholders.state')"
                    />
                  </input-wrapper>
                </div>
                <div class="basis-1/3">
                  <input-wrapper
                    field="postcode"
                    class="text-white2"
                    :form="form"
                    :label-text="$t('customer.fields.postcode')"
                  >
                    <input
                      type="text"
                      class="bg-transparent"
                      v-model="form.postcode"
                      :placeholder="$t('customer.placeholders.postcode')"
                    />
                  </input-wrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-xlSpace sm:flex-nowrap">
        <div class="w-1/3">{{ $t("client.headings.detail.payment_method") }}</div>
        <div class="w-1/3">
          <div class="grid">
            <div v-for="card in currentCustomer.cards" class="relative">
              <div class="bg-transparent !pt-smSpace font-bold">
                {{ $t("client.headings.detail.visa_card_ending_in") }} {{ card.last_4 }}
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-xlSpace sm:flex-nowrap">
        <div class="w-1/3">{{ $t("client.headings.detail.password") }}</div>
        <div class="w-1/3">
          <div class="flex -space-x-0.5" v-if="!form.edit_customer">
            <div class="font-bold">*********</div>
          </div>
          <input-wrapper v-else field="password" :form="form" class="space-y-0 text-white2">
            <input
              type="password"
              class="bg-transparent"
              v-model="form.password"
              placeholder="••••••••"
              autocomplete="off"
              data-lpignore="true"
            />
          </input-wrapper>
        </div>
      </li>
    </ul>

    <div
      class="rounded pb-smSpace sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
      v-if="form.edit_customer"
    >
      <div class="flex justify-center space-x-xsSpace lg:justify-end">
        <a
          @click.prevent="cancel"
          class="btn-secondary !bg-transparent !text-white2"
          v-text="$t('generic.buttons.cancel')"
        ></a>
        <a
          href="#"
          class="btn-secondary inline-block w-fit !bg-transparent font-semibold !text-white2"
          @click.prevent="save"
          v-text="$t('generic.buttons.save')"
        ></a>
      </div>
    </div>
  </div>
  <div v-else class="h-[50vh]">
    <div class="pt-8 text-center text-white2">
      {{ $t("client.headings.null_customer") }}
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import { mapState } from "vuex";
import { cloneDeep } from "lodash";

export default {
  data() {
    return {
      isManualAddressInput: false,
      loading: false,
      form: null,
    };
  },
  computed: {
    fullAddress() {
      if (this.isManualAddressInput) {
        let address = "";
        if (this.form.address) {
          address += this.form.address + ". ";
        }

        if (this.form.city) {
          address += this.form.city + ". ";
        }

        if (this.form.state) {
          address += this.form.state + ". ";
        }

        if (this.form.postcode) {
          address += this.form.postcode;
        }

        return address;
      }

      return this.currentCustomer.full_address;
    },
    ...mapState({
      currentCustomer: (state) => state.customer.currentCustomer,
    }),
  },
  watch: {
    currentCustomer: {
      handler(newValue, oldValue) {
        const formValue = cloneDeep(newValue);
        formValue.edit_customer = false;
        formValue.password = null;
        this.form = new Form(formValue, { resetOnSuccess: false });
      },
      deep: true,
    },
  },
  methods: {
    save() {
      this.form.full_address = this.fullAddress;
      this.form.patch(`/api/client/customers/${this.currentCustomer.id}`).then(({}) => {
        this.$store.dispatch("customer/fetchCustomer", this.currentCustomer.id);
        this.form.edit_customer = false;
      });
    },
    cancel() {
      this.isManualAddressInput = false;
      this.form.edit_customer = false;
    },
  },
};
</script>
