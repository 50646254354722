<template>
  <div class="max-w-xl space-y-lgSpace pt-xlSpace">
    <form class="grid grid-cols-1 gap-smSpace">
      <input-wrapper field="name" :form="form" :label-text="$t('product.fields.name')">
        <input type="text" v-model="form.name" :placeholder="$t('product.placeholders.name')" />
      </input-wrapper>
      <input-wrapper field="abstract" :form="form" :label-text="$t('product.fields.abstract')">
        <v-textarea :placeholder="$t('product.placeholders.abstract')" v-model="form.abstract"></v-textarea>
      </input-wrapper>
      <input-wrapper field="description" :form="form" :label-text="$t('product.fields.description')">
        <v-textarea :placeholder="$t('product.placeholders.description')" v-model="form.description"></v-textarea>
      </input-wrapper>
      <input-wrapper field="product_category_id" :form="form" :label-text="$t('product.fields.category')">
        <v-multi-select
          v-model="form.product_category_id"
          search-url="/api/generic/product-categories?filter[active]=1&filter[no_parent_category]=1"
          :placeholder="$t('product.placeholders.category')"
          track-by="id"
          label="name"
          search-key="filter[name]"
        />
      </input-wrapper>
      <input-wrapper field="selected_rooms" :form="form" :label-text="$t('product.fields.rooms')">
        <v-multi-select
          v-model="form.selected_rooms"
          search-url="/api/admin/rooms?filter[active]=1"
          :placeholder="$t('product.fields.rooms')"
          :is-multiple="true"
          track-by="id"
          label="name"
          search-key="filter[name]"
        />
      </input-wrapper>
      <input-wrapper field="selected_forms" :form="form" :label-text="$t('product.fields.form')">
        <v-multi-select
          v-model="form.selected_forms"
          search-url="/api/admin/waivers"
          :placeholder="$t('product.fields.form')"
          :is-multiple="true"
          track-by="id"
          label="title"
          search-key="filter[title]"
        />
      </input-wrapper>
      <input-wrapper field="duration" :form="form" :label-text="$t('product.fields.duration')">
        <hour-or-minute-input v-model="form.duration" duration-unit="minute"></hour-or-minute-input>
      </input-wrapper>
      <input-wrapper field="turnaround_duration" :form="form" :label-text="$t('product.fields.turnaround_duration')">
        <hour-or-minute-input v-model="form.turnaround_duration" duration-unit="minute"></hour-or-minute-input>
      </input-wrapper>
      <input-wrapper field="lockout_time" :form="form" :label-text="$t('product.fields.lockout_time')">
        <hour-or-minute-input v-model="form.lockout_hours" duration-unit="hour"></hour-or-minute-input>
      </input-wrapper>
      <input-wrapper field="colour" :form="form" :label-text="$t('product.fields.colour')">
        <color-picker v-model:pure-color="form.colour" shape="circle"></color-picker>
      </input-wrapper>

      <input-wrapper field="xero_code_id" :form="form" :label-text="$t('product.fields.xero_code_id')">
        <v-multi-select
          v-model="form.xero_code_id"
          search-url="/api/admin/xero/xero_codes"
          :placeholder="$t('product.fields.xero_code_id')"
          :is-multiple="false"
          track-by="id"
          label="select_option"
          search-key="filter[full_search]"
        />
      </input-wrapper>

      <v-checkbox v-model="form.bookable" :label-text="$t('product.fields.bookable')"></v-checkbox>
      <v-checkbox v-model="form.public" :label-text="$t('product.fields.public')"></v-checkbox>
      <v-checkbox v-model="form.active" :label-text="$t('product.fields.active')"></v-checkbox>
    </form>
    <v-footer :list-url="listUrl" :save="save"></v-footer>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import { ColorPicker } from "vue3-colorpicker";
import HourOrMinuteInput from "./HourOrMinuteInput.vue";

export default {
  name: "ProductEdit",
  components: { HourOrMinuteInput, ColorPicker },
  inject: ["bus", "notificationService"],
  props: {
    product: {
      type: Object,
      default: () => {
        return {};
      },
    },
    listUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      form: new Form(
        {
          id: this.getFieldValue("id"),
          xero_code_id: this.getFieldValue("xero_code_id"),
          name: this.getFieldValue("name"),
          abstract: this.getFieldValue("abstract"),
          description: this.getFieldValue("description"),
          colour: this.getFieldValue("colour") || "rgb(0,0,0)",
          selected_rooms: this.getFieldValue("room_ids", null, []),
          selected_forms: this.getFieldValue("form_ids") || [],
          bookable: Boolean(Number(this.getFieldValue("bookable"))),
          active: Boolean(Number(this.getFieldValue("active"))),
          public: Boolean(Number(this.getFieldValue("public"))),
          product_category_id: this.getFieldValue("product_category_id") || null,
          duration: this.getFieldValue("duration"),
          turnaround_duration: this.getFieldValue("turnaround_duration"),
          lockout_hours: this.getFieldValue("lockout_hours"),
        },
        { resetOnSuccess: false },
      ),
    };
  },

  methods: {
    getFieldValue(field, children = null, defaultValue = null) {
      if (children && this.product.hasOwnProperty(children) && this.product[children].hasOwnProperty(field)) {
        return this.product[children][field];
      }

      if (this.product.hasOwnProperty(field)) {
        return this.product[field];
      }

      return defaultValue;
    },
    save() {
      if (this.product.hasOwnProperty("id")) {
        this.form
          .patch(`/api/admin/products/${this.product.id}`)
          .then(({}) => {
            window.location = this.listUrl;
          })
          .catch(({ response }) => {
            this.notificationService.notify(response.data.message, "error");
          });

        return;
      }

      this.form
        .post(`/api/admin/products`)
        .then(({}) => {
          window.location = this.listUrl;
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
  },
};
</script>
