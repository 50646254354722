import moment from "moment";

const Formatter = {
  toAUD(value) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "AUD",
    });

    if (!value) {
      value = 0;
    }

    return formatter.format(value);
  },

  numberFormat(value) {
    const formatter = new Intl.NumberFormat("en-US");

    return formatter.format(value);
  },

  slug(name) {
    return name
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
  },

  word(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  },

  formatDate(date, withHour = false) {
    if (withHour) {
      return moment(date).format("DD/MM/YYYY H:m:s");
    }

    return moment(date).format("DD/MM/YYYY");
  },
};

export default Formatter;
