<template>
  <section class="relative min-w-[320px] p-xsSpace">
    <h2 v-html="header" class="text-md pb-smSpace font-semibold" />
    <p v-html="content" class="text-sm" />
    <footer v-if="showAction" class="flex justify-between pt-lgSpace">
      <button class="btn-secondary btn-sm" @click="$emit('closeModal')">{{ $t("generic.buttons.cancel") }}</button>
      <v-actions
        v-if="actionDropdown.length > 1"
        @close="isDropdownVisible = false"
        :show-action-icon="false"
        :classes="'w-[200px] text-xs mt-2xsSpace'"
      >
        <template v-slot:button>
          <button class="btn-primary btn-sm" type="button" @click="toggleDropdown" :disabled="isButtonDisabled">
            <span v-if="!isLoading">{{ $t("generic.buttons.save") }}</span>
            <span
              v-else
              class="inline-block h-4 w-4 animate-spin rounded-full border-2 border-t-2 border-gray-300 border-t-white"
            ></span>
          </button>
        </template>
        <template v-slot:dropdown>
          <ul v-if="isDropdownVisible" class="divide-y text-sm">
            <li v-for="action in actionDropdown" :key="action.event">
              <a
                @click="handleClickDropDown(action.event)"
                class="flex w-full cursor-pointer space-x-xsSpace p-xsSpace hover:bg-neutral-100 hover:text-blue-600"
              >
                <span v-html="action.label"></span>
              </a>
            </li>
          </ul>
        </template>
      </v-actions>
      <button v-else class="btn-primary btn-sm" type="button" @click="handleSave" :disabled="isButtonDisabled">
        <span v-if="!isLoading">{{ $t("generic.buttons.save") }}</span>
        <span
          v-else
          class="inline-block h-4 w-4 animate-spin rounded-full border-2 border-t-2 border-gray-300 border-t-white"
        ></span>
      </button>
    </footer>
  </section>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    actionDropdown: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showAction: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isButtonDisabled: false,
      isDropdownVisible: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    handleClickDropDown(event) {
      this.isDropdownVisible = false;
      this.isLoading = true;
      this.isButtonDisabled = true;
      this.$emit("confirm", event);
    },
    handleSave() {
      this.isLoading = true;
      this.isButtonDisabled = true;
      this.$emit("confirm");
    },
  },
};
</script>
