<template>
  <div class="date-nav flex h-full items-center">
    <div class="flex flex-col justify-end space-x-xsSpace p-mdSpace sm:flex-row sm:items-center max-sm:space-y-smSpace">
      <v-badge
        v-if="hasExpandButton"
        :value="isExpanded"
        class="cursor-pointer py-[7px] text-white hover:bg-primary-700"
        :class="{
          '!bg-primary-700': isExpanded,
          '!bg-primary-200': !isExpanded,
        }"
        @click="expand"
      >
        {{ $t("booking.headings.expand_bathhouse") }}
      </v-badge>
    </div>
    <div class="flex items-center gap-2 border-r pr-lgSpace" v-if="!disableZoom">
      <select
        @change="setZoom"
        :value="zoom"
        class="block w-full rounded-md border-gray-300 py-2xsSpace pr-xlSpace text-sm text-tertiary-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      >
        <option :value="1">1x</option>
        <option :value="0.8">0.8x</option>
        <option :value="0.6">0.6x</option>
        <option :value="0.4">0.4x</option>
      </select>
    </div>
    <div class="flex h-full gap-4 px-smSpace">
      <nav
        class="flex items-center justify-center space-x-2xsSpace text-xs font-medium leading-none text-tertiary-700 [&>a]:transition-all [&>a]:duration-100"
      >
        <button
          v-if="!isToday"
          class="rounded-full bg-primary-200 px-smSpace py-[7px] text-xs font-medium text-white hover:bg-primary-700"
          @click="backToToday"
        >
          {{ $t("generic.buttons.today") }}
        </button>
        <button
          @click="goToPreviousDay"
          class="inline-flex items-center rounded-md fill-current text-tertiary-700 hover:bg-tertiary-100"
        >
          <v-icon icon="ChevronLeftIcon" class="my-auto text-slate-400" />
        </button>
        <date-picker
          v-model:value="date"
          :placeholder="$t('wait_list.placeholders.date')"
          format="DD-MM-YYYY"
          value-type="format"
          type="date"
          :clearable="false"
          @change="changePicker(date)"
          popup-class="booking-filter"
          class="!w-[125px]"
        />
        <button
          @click="goToNextDay"
          class="inline-flex items-center rounded-md fill-current text-tertiary-700 hover:bg-tertiary-100"
        >
          <v-icon icon="ChevronRightIcon" class="my-auto text-slate-400" />
        </button>
      </nav>
      <div class="flex items-center gap-2">
        <v-badge
          v-for="category in categories"
          :value="category.id"
          class="cursor-pointer py-[7px] text-white hover:bg-primary-700"
          :class="{
            '!bg-primary-700': this.selectedCategory === category.id,
            '!bg-primary-200': this.selectedCategory !== category.id,
          }"
          @click="selectCategory(category)"
          >{{ category.name }}
        </v-badge>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "BookingFilters",
  inject: ["bus", "config"],
  props: {
    disableZoom: {
      required: false,
      default: false,
    },
    hasExpandButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultCategory: {
      type: Number,
      required: false,
    },
  },
  computed: {
    isToday() {
      return moment(this.date, this.config.DATETIME_FORMAT).isSame(moment(), "day");
    },
    ...mapState({
      isExpanded: (state) => state.booking.isExpanded,
      zoom: (state) => state.booking.zoom,
    }),
  },
  data() {
    return {
      date: moment().format(this.config.DATETIME_FORMAT),
      originDate: "",
      filters: {},
      isDateRange: false,
      categories: [],
      selectedCategory: this.defaultCategory,
    };
  },
  beforeUnmount() {
    this.bus.$off("tableFilterChange");
  },
  created() {
    this.bus.$on("tableFilterChange", ({ params }) => {
      if (Object.keys(params).length) {
        const tmpDate = (params["filter[date]"] || "").split(",");
        if (tmpDate.length > 1) {
          this.isDateRange = true;
        }
        this.originDate = tmpDate[0];
        this.date = moment(this.originDate, this.config.DATETIME_FORMAT).format(this.config.DATETIME_FORMAT);
        if (!this.defaultCategory) {
          this.selectedCategory = params["filter[categories]"] ? params["filter[categories]"] : null;
        }

        this.filters = params;
      }
    });
  },
  mounted() {
    axios.get("/api/generic/product-categories?filter[parent]=true").then(({ data }) => {
      this.categories = data.data.map((o) => {
        o.active = false;

        return o;
      });

      if (this.defaultCategory) {
        const category = this.categories.find((o) => o.id === this.defaultCategory);
        if (category) {
          if (!this.filters.hasOwnProperty("filter[date]")) {
            this.filters["filter[date]"] = this.getDateRange(this.date, this.config.DATETIME_FORMAT);
          }
          this.selectCategory(category);
        }
      }
    });
  },
  methods: {
    selectCategory(category) {
      if (!this.defaultCategory) {
        this.selectedCategory = this.selectedCategory === category.id ? null : category.id;
      } else {
        this.selectedCategory = category.id;
      }

      this.filters["filter[categories]"] = this.selectedCategory;
      this.bus.$emit("tableFilterChange", { params: this.filters });
    },
    setZoom($event) {
      this.$store.dispatch("booking/setZoom", Number($event.target.value));
    },
    expand() {
      this.$store.dispatch("booking/setExpanded", !this.isExpanded);
    },
    goToPreviousDay() {
      const previousDay = this.getNextDay(-1);
      this.changePicker(previousDay);
    },
    backToToday() {
      this.changePicker(moment().format(this.config.DATETIME_FORMAT));
    },
    goToNextDay() {
      const nextDay = this.getNextDay(1);
      this.changePicker(nextDay);
    },
    updateUrlWithDate(day) {
      const date = moment(day.split(",")[0], this.config.DATETIME_FORMAT);
      this.filters["filter[date]"] = day;
      this.bus.$emit("tableFilterChange", { params: this.filters, field: "date" });
      sessionStorage.setItem("filterDate", date.format(this.config.DATETIME_FORMAT));
      const fullPath = window.location.pathname + window.location.search + window.location.hash;
      const queryParams = new URLSearchParams(window.location.search);
      history.pushState(
        {},
        "",
        fullPath.replace(queryParams.get("filter[date]"), date.format(this.config.DATETIME_FORMAT)),
      );
    },
    getNextDay(value) {
      const newDate = moment(this.originDate, this.config.DATETIME_FORMAT);
      newDate.add(value, "d");
      const startDate = newDate.format(this.config.DATETIME_FORMAT);
      if (this.isDateRange) {
        return this.getDateRange(startDate);
      }
      return startDate;
    },
    changePicker(dateValue) {
      const date = moment(dateValue, this.config.DATETIME_FORMAT).format(this.config.DATETIME_FORMAT);
      if (this.isDateRange) {
        this.updateUrlWithDate(this.getDateRange(date));
      } else {
        this.updateUrlWithDate(date);
      }
    },
    getDateRange(startDate, format = this.config.DATETIME_FORMAT) {
      const endDate = moment(startDate, format);
      endDate.add(1, "M");
      return startDate + "," + endDate.format(format);
    },
  },
};
</script>
<style>
.date-nav .mx-datepicker {
  .mx-input-wrapper {
    .mx-input {
      @apply h-[31px];
    }
  }
}

.booking-filter .mx-calendar-header {
  .mx-btn-icon-double-left,
  .mx-btn-icon-double-right {
    @apply hidden;
  }
}
</style>
