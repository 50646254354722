<template>
  <form v-if="!success" class="flex w-full flex-col justify-between p-lgSpace">
    <side-panel ref="sidePanel" :disable-listeners="true"></side-panel>
    <h3 class="mb-mdSpace text-lg">{{ $t("gift_voucher.headings.purchase_summary") }}</h3>
    <div class="absolute right-4 top-6 space-x-xsSpace">
      <a @click="clickAddTask(data.vouchers[0])" class="btn-sm btn-secondary bg-white">
        <span>{{ $t("task.buttons.add") }}</span>
      </a>
    </div>
    <div class="space-y-xsSpace rounded p-smSpace text-sm ring-1">
      <div class="flex gap-smSpace">
        <p class="w-1/4">{{ $t("gift_voucher.fields.venue") }}</p>
        <strong class="w-3/4">{{ data.venue.name }}</strong>
      </div>
      <div class="flex gap-smSpace">
        <p class="w-1/4">{{ $t("gift_voucher.fields.title") }}</p>
        <strong class="w-3/4">{{ data.vouchers[0].title }}</strong>
      </div>
      <div class="flex gap-smSpace">
        <p class="w-1/4">{{ $t("gift_voucher.fields.value") }}</p>
        <strong class="w-3/4">{{ toAUD(data.vouchers[0].value) }}</strong>
      </div>
      <div class="flex gap-smSpace">
        <p class="w-1/4">{{ $t("gift_voucher.fields.description") }}</p>
        <strong class="w-3/4">{{ data.vouchers[0].description }}</strong>
      </div>
    </div>
    <h3 class="mb-smSpace mt-lgSpace text-lg">{{ $t("generic.words.customer") }}</h3>
    <div class="space-y-xsSpace">
      <customer-input
        :form="form"
        v-model:customer-id="form.customer_id"
        v-model:first-name="form.first_name"
        v-model:last-name="form.last_name"
        @selectClient="selectClient($event)"
      />
      <input-wrapper field="phone" :form="form" :label-text="$t('gift_voucher.fields.phone')">
        <phone-number v-model="form.phone" :placeholder="$t('gift_voucher.placeholders.phone')" />
      </input-wrapper>
      <input-wrapper field="email" :form="form" :label-text="$t('gift_voucher.placeholders.email')">
        <input type="text" v-model="form.email" :placeholder="$t('gift_voucher.placeholders.email')" />
      </input-wrapper>
      <div v-if="data.quantity === 1" class="space-y-xsSpace">
        <v-checkbox
          class="flex items-center space-x-xsSpace py-mdSpace"
          v-model="form.send_to_someone_else"
          :label-text="$t('gift_voucher.headings.send_to_someone_else')"
        />
        <div v-show="form.send_to_someone_else" class="relative flex w-full gap-2">
          <input-wrapper
            field="giftee_first_name"
            :form="form"
            :label-text="$t('gift_voucher.fields.giftee_name')"
            class="flex-1"
          >
            <input
              type="text"
              v-model="form.giftee_first_name"
              :placeholder="$t('gift_voucher.placeholders.customer_first_name')"
            />
          </input-wrapper>
          <input-wrapper field="giftee_last_name" :form="form" label-text="&nbsp;" class="flex-1">
            <input
              type="text"
              v-model="form.giftee_last_name"
              :placeholder="$t('gift_voucher.placeholders.customer_last_name')"
            />
          </input-wrapper>
        </div>
        <input-wrapper
          field="giftee_email"
          :form="form"
          :label-text="$t('gift_voucher.fields.giftee_email')"
          v-show="form.send_to_someone_else"
        >
          <input type="text" v-model="form.giftee_email" :placeholder="$t('gift_voucher.placeholders.giftee_email')" />
        </input-wrapper>
        <input-wrapper
          field="giftee_phone"
          :form="form"
          :label-text="$t('gift_voucher.fields.giftee_phone')"
          :dark-theme="true"
          v-show="form.send_to_someone_else"
        >
          <phone-number v-model="form.giftee_phone" :placeholder="$t('gift_voucher.placeholders.phone')" />
        </input-wrapper>
        <input-wrapper
          field="message"
          :form="form"
          :label-text="$t('gift_voucher.fields.message')"
          :dark-theme="true"
          v-show="form.send_to_someone_else"
        >
          <v-textarea v-model="form.message" :placeholder="$t('gift_voucher.placeholders.message')"></v-textarea>
        </input-wrapper>
        <input-wrapper
          field="sent_from"
          :form="form"
          :label-text="$t('gift_voucher.fields.sent_from')"
          :dark-theme="true"
          v-show="form.send_to_someone_else"
        >
          <input type="text" v-model="form.sent_from" :placeholder="$t('gift_voucher.placeholders.sent_from')" />
        </input-wrapper>
      </div>
    </div>
    <div>
      <div class="space-y-smSpace py-xsSpace" :class="{ 'py-mdSpace': form.payment.type !== config.PAYMENT_CARD }">
        <payment
          ref="payment"
          :exclude-methods="[config.PAYMENT_VOUCHER, config.PAYMENT_LATER]"
          :form="form"
          :amount="data.total"
          :customer-id="form.customer_id"
          :is-amount-changeable="false"
          endpoint="/api/admin/voucher/checkout"
        />
      </div>
    </div>
    <div
      class="mt-xlSpace flex flex-col justify-end rounded sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
    >
      <a @click.prevent="back" class="btn-secondary btn-sm" v-text="$t('generic.buttons.back')"></a>
      <a @click.prevent="complete" class="btn-primary btn-sm">
        <div
          v-if="loading"
          aria-label="Loading..."
          role="status"
          class="flex items-center justify-center space-x-2 text-white2"
        >
          <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
          <span class="font-medium text-tertiary-500 text-white2">{{ $t("generic.words.processing") }}</span>
        </div>
        <span v-else>{{ $t("generic.buttons.complete") }}</span>
      </a>
    </div>
  </form>
  <div v-else class="flex h-full items-center justify-center pt-lgSpace text-center text-lg">
    <p>{{ $t("gift_voucher_instance.response.success") }}</p>
  </div>
</template>
<script>
import Form from "form-backend-validation";
import Payment from "~vue/mixins/Payment";

export default {
  inject: ["config", "bus", "notificationService"],
  mixins: [Payment],
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      success: false,
      form: new Form(
        {
          customer_id: null,
          first_name: null,
          last_name: null,
          phone: null,
          email: null,
          send_to_someone_else: false,
          giftee_email: null,
          giftee_phone: null,
          giftee_first_name: null,
          giftee_last_name: null,
          message: "",
          sent_from: null,
          payment: {
            type: this.config.PAYMENT_CARD,
            amount: null,
          },
        },
        { resetOnSuccess: false },
      ),
    };
  },
  mounted() {
    this.bus.$on("closePanelInPanel", () => {
      this.$refs.sidePanel && this.$refs.sidePanel.close();
    });
  },
  beforeUnmount() {
    this.bus.$off("closePanelInPanel");
  },
  methods: {
    back() {
      this.bus.$emit("openSidePanel", {
        componentName: "GiftVoucherFindAvailabilities",
        componentData: {
          id: this.id,
        },
        closeCallback: () => {},
      });
    },
    async complete() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      try {
        const response = await this.$refs.payment.submit();
        if (this.isUseTerminal(this.form.payment.type, this.config)) {
          await this.checkPayments(response.order_id);
        }
        this.onSuccess(response);
      } catch (error) {
        const { response } = error;

        let err = "Invalid";
        if (error.hasOwnProperty("message")) {
          err = error.message;
        }
        if (response && response.hasOwnProperty("data") && response.data.hasOwnProperty("message")) {
          err = response.data.message;
        }
        this.notificationService.notifyPanel(err, "error");

        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    onSuccess({ vouchers }) {
      this.loading = false;
      this.success = true;
      this.bus.$emit("openSidePanel", {
        componentName: "VoucherInstanceDetails",
        componentData: {
          voucherCode: vouchers.map((voucher) => voucher.code).join(","),
          showAddTask: true,
          showActions: true,
        },
        closeCallback: () => {},
      });
    },
    selectClient(client) {
      if (client) {
        this.form.customer_id = client.id;
        this.form.first_name = client.first_name;
        this.form.last_name = client.last_name;
        this.form.phone = client.phone;
        this.form.email = client.email;
        return;
      }

      this.form.customer_id = null;
      this.form.first_name = null;
      this.form.last_name = null;
      this.form.phone = null;
      this.form.email = null;
    },
    clickAddTask(voucher) {
      const title = `Voucher Task: "${voucher.title}""`;
      const description = `Purchaser: ${(this.form.first_name + " " + this.form.last_name).trim() || ""}
Voucher Name: "${voucher.title}"
then let them type in anything else and save.`;
      this.$refs.sidePanel.open(
        "TaskEdit",
        {
          task: {
            title,
            description,
          },
          taskable: {
            class: this.config.taskTypes.giftVoucher,
            id: voucher.id,
          },
          inPanel: true,
        },
        this.$t("task.buttons.add"),
        () => {},
      );
    },
  },
};
</script>
