<template>
  <div
    v-if="!showNameInputMap[rowData.id]"
    class="cursor-pointer"
    @click.stop.prevent="toggleNameInput(rowData.id, value)"
  >
    <a v-if="action.link" :title="action.label" class="underline">
      <span class="text-sm text-gray-500">{{ value }}<slot></slot></span>
    </a>
    <span v-else class="text-sm text-gray-500">{{ value }}<slot></slot></span>
  </div>
  <div v-else class="flex space-x-xsSpace">
    <input
      type="text"
      @keyup.stop.prevent.enter="updateName(rowData.id, $refs[`nameInput` + rowData.id].value)"
      :ref="`nameInput` + rowData.id"
      :value="currentNameMap[rowData.id]"
    />
    <div class="flex space-x-2xsSpace">
      <button
        class="btn-primary btn-icon btn-sm"
        @click.stop.prevent="updateName(rowData.id, $refs[`nameInput` + rowData.id].value)"
      >
        <v-icon v-if="saving" icon="ArrowPathIcon" class="animate-spin" />
        <v-icon v-else icon="CheckIcon"></v-icon>
      </button>
      <button class="btn-secondary btn-icon btn-sm" @click.stop.prevent="toggleNameInput(rowData.id, value)">
        <v-icon icon="XMarkIcon"></v-icon>
      </button>
    </div>
  </div>
</template>

<script>
import EditableNameElement from "~vue/mixins/EditableNameElement";
import VTableTitle from "~vue/components/common/table/VTableTitle.vue";

export default {
  name: "VTableEditableTitle",
  mixins: [VTableTitle, EditableNameElement],
  props: {
    saveNameUrl: {
      type: String,
      default: "/api/admin/products/with-name",
    },
  },
  methods: {
    afterUpdateName(id, newName) {
      const field = typeof this.rowField === "string" ? this.rowField : this.rowField.field;
      this.rowData[field] = newName;
    },
  },
};
</script>
