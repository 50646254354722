<template>
  <transition type="transition">
    <div class="w-full" v-show="isCollapsed">
      <slot :collapsed="isCollapsed"></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CollapsibleContent",
  inject: {
    isCollapsed: { from: "collapsed" },
  },
  props: {
    context: {
      type: String,
      required: false,
      default: "default",
      description: "The context of the collapsible, the child components will use this to provide the collapsed state.",
    },
  },
};
</script>
