<template>
  <div
    v-if="loading"
    aria-label="Loading..."
    role="status"
    class="flex min-h-[500px] items-center justify-center space-x-2 text-white"
  >
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500 text-white">
      {{ $t("generic.words.processing") }}
    </span>
  </div>
  <div v-else class="flex flex-col space-y-lgSpace">
    <div class="relative flex flex-col space-y-mdSpace rounded-lg bg-white p-mdSpace">
      <div class="flex flex-col space-y-smSpace text-brand">
        <div class="flex items-center justify-between">
          <span class="text-[19px] font-semibold">{{ $t("booking.headings.order_summary") }}</span>
          <CountDownTimer v-if="cart.appointment?.held_until" :expiry-at="cart.appointment.held_until"></CountDownTimer>
        </div>
        <div class="flex flex-col space-y-smSpace rounded-lg bg-brand bg-opacity-10 p-smSpace">
          <template v-if="cart.product">
            <span class="text-[19px] font-semibold">{{ cart.product?.name }}</span>
            <div class="flex flex-col">
              <span class="text-xs font-normal text-brand/80">{{ $t("booking.labels.option") }}:</span>
              <span class="text-base">
                {{ cart.product_variant?.name }} ({{ toAUD(cart.product_variant?.price_per_unit) }})
              </span>
            </div>
            <div class="flex flex-col" v-if="cart.appointment">
              <span class="text-xs font-normal text-brand/80">{{ $t("booking.labels.schedule") }}:</span>
              <span class="text-base">{{ cart.appointment?.readable_period || "N/A" }}</span>
            </div>
          </template>
          <span v-else>
            {{ $t("checkout.labels.no_product_selected") }}
          </span>
        </div>
        <Transition>
          <div
            v-if="cart.add_on_product"
            class="flex flex-col space-y-smSpace rounded-lg bg-brand bg-opacity-10 p-smSpace"
          >
            <div class="flex flex-col">
              <span class="text-xs font-normal text-brand/80">{{ $t("booking.labels.add_on") }}:</span>
              <span class="text-[19px] font-semibold">{{ cart.add_on_product?.name }}</span>
            </div>
            <div class="flex flex-col">
              <span class="text-xs font-normal text-brand/80">{{ $t("booking.labels.option") }}:</span>
              <span class="text-base">
                {{ cart.add_on_product_variant?.name }} (${{ cart.add_on_product_variant?.price_per_unit }})
              </span>
            </div>
            <div class="flex flex-col">
              <span class="text-xs font-normal text-brand/80">{{ $t("booking.labels.schedule") }}:</span>
              <span class="text-base">
                {{ $t("booking.labels.after_massage") }}
              </span>
            </div>
          </div>
        </Transition>
      </div>
      <div class="flex flex-col space-y-smSpace divide-y text-sm text-slate-700">
        <div class="flex flex-col space-y-xsSpace">
          <span class="text-lg font-bold">{{ $t("booking.columns.total_price") }}</span>
          <div class="flex justify-between">
            <span>{{ $t("booking.labels.unit_price") }}</span>
            <span>{{ toAUD(cart.product_variant?.price_per_unit) }}</span>
          </div>
          <div v-if="cart.add_on_product" class="flex justify-between">
            <span>{{ $t("booking.labels.add_on") }}</span>
            <span>{{ toAUD(cart.add_on_product_variant?.price_per_unit) }}</span>
          </div>
          <div class="flex justify-between">
            <span>{{ $t("booking.labels.quantity") }}</span>
            <span>{{ cart.product ? cart.quantity : 0 }}</span>
          </div>
          <div class="flex justify-between">
            <span>{{ $t("booking.labels.subtotal") }}</span>
            <span>{{ toAUD(cart.sub_total) }}</span>
          </div>
        </div>
        <div v-if="cart.vouchers_info?.length" class="flex flex-col space-y-xsSpace pt-smSpace">
          <div :key="index" v-for="(voucher, index) in cart.vouchers_info" class="flex justify-between">
            <span>{{ $t("booking.labels.voucher") }}({{ voucher.code }})</span>
            <span v-if="!voucher.is_bath_pass">-{{ toAUD(voucher.discount) }}</span>
            <span v-else
              >{{ voucher.applicable_quantity }} {{ $t("generic.use") + (voucher.applicable_quantity > 1 ? "s" : "") }}
              {{ $t("gift_voucher.labels.redeemed") }}</span
            >
          </div>
        </div>
        <div class="flex flex-col pt-smSpace">
          <div class="flex justify-between font-semibold text-neutral-700">
            <span>{{ $t("booking.labels.total") }}</span>
            <span>{{ toAUD(remainingTotal) }}</span>
          </div>
          <div class="flex justify-end">
            <span class="text-xs text-neutral-400"
              >{{ $t("booking.labels.includes_gst") }} {{ toAUD(cart.sub_total - cart.sub_total / 1.1) }}</span
            >
          </div>
        </div>
      </div>
      <span class="text-sm text-neutral-500" v-html="reschedulePolicyLink"> </span>
      <a v-if="cart.product_variant?.name" class="absolute bottom-[16px] right-[16px]" href="/booking/product?refresh=1"
        ><v-icon icon="TrashIcon"></v-icon
      ></a>
    </div>
    <voucher-select v-model="form.vouchers" v-model:info="vouchersInfo" :cart-total="cart.sub_total"></voucher-select>
    <div class="flex flex-col rounded-lg bg-white bg-opacity-10 p-smSpace text-sm text-white2">
      <span v-html="stuckInductionLink"></span>
      <span v-html="troubleInductionLink"></span>
    </div>
    <div class="flex flex-col rounded-lg bg-white bg-opacity-10 p-smSpace text-sm text-white2">
      <p>{{ $t("checkout.headings.intro_1") }}</p>
      <p>{{ $t("checkout.headings.intro_2") }}</p>
      <p v-html="$t('checkout.headings.intro_3')"></p>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import CountDownTimer from "./CountDownTimer.vue";

export default {
  name: "CheckoutCartSummary",
  components: { CountDownTimer },
  inject: ["notificationService", "config", "bus"],
  props: {
    initCart: {
      type: Object,
      required: false,
    },
  },
  computed: {
    remainingTotal() {
      return Math.max(0, this.cart.sub_total - this.cart.discount);
    },
    reschedulePolicyLink() {
      return this.$t("booking.labels.cancellation_reschedule_policy").replace(
        ":link",
        "https://sos-senseofself.com/cancelation-policy",
      );
    },
    troubleInductionLink() {
      return this.$t("booking.labels.trouble_induction")
        .replace(":link", "mailto:bookings@sos-senseofself.com")
        .replace(":email", "bookings@sos-senseofself.com");
    },
    stuckInductionLink() {
      return this.$t("booking.labels.stuck_induction").replace(":link", "https://sos-senseofself.com/bookings");
    },
  },
  data() {
    return {
      cart: this.initCart ? this.initCart : {},
      loading: true,
      vouchersInfo: [],
      form: new Form(
        {
          vouchers: this.cart?.vouchers || null,
        },
        { resetOnSuccess: false },
      ),
    };
  },
  watch: {
    vouchersInfo: {
      handler(newValue, oldValue) {
        this.updateVouchers();
      },
      deep: true,
    },
  },
  mounted() {
    this.bus.$on("reloadCart", () => {
      this.getCart();
    });
    this.getCart();
  },
  methods: {
    async getCart() {
      const { data } = await axios.get("/api/booking/cart");
      this.loading = false;
      this.cart = data;
      this.form.vouchers = this.cart.vouchers;
    },
    async updateVouchers() {
      if (this.loading) {
        return;
      }

      // this.loading = true;
      this.form
        .post("/api/booking/cart/general")
        .then(() => {
          this.bus.$emit("vouchersUpdated");
        })
        .finally(() => {
          this.loading = false;
          this.getCart();
        });
    },
  },
};
</script>

<style scoped>
input::placeholder {
  @apply !text-white opacity-60;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}
</style>
