<template>
  <div class="max-w-xl space-y-lgSpace pt-xlSpace">
    <form class="grid grid-cols-1 gap-smSpace">
      <input-wrapper field="name" :form="form" :label-text="$t('venue.fields.name')">
        <input type="text" v-model="form.name" :placeholder="$t('venue.placeholders.name')" />
      </input-wrapper>
      <input-wrapper field="address.street_address" :form="form" :label-text="$t('venue.fields.street_address')">
        <input
          type="text"
          v-model="form.address.street_address"
          :placeholder="$t('venue.placeholders.street_address')"
        />
      </input-wrapper>
      <input-wrapper field="address.suburb" :form="form" :label-text="$t('venue.fields.suburb')">
        <input type="text" v-model="form.address.suburb" :placeholder="$t('venue.placeholders.suburb')" />
      </input-wrapper>
      <input-wrapper field="address.state" :form="form" :label-text="$t('venue.fields.state')">
        <input type="text" v-model="form.address.state" :placeholder="$t('venue.placeholders.state')" />
      </input-wrapper>
      <input-wrapper field="address.postcode" :form="form" :label-text="$t('venue.fields.postcode')">
        <input type="text" v-model="form.address.postcode" :placeholder="$t('venue.placeholders.postcode')" />
      </input-wrapper>
      <input-wrapper field="phone" :form="form" :label-text="$t('venue.fields.phone')">
        <phone-number v-model="form.phone" :placeholder="$t('venue.placeholders.phone')" />
      </input-wrapper>

      <input-wrapper field="timezone" :form="form" :label-text="$t('venue.fields.timezone')">
        <v-multi-select
          v-if="timezones.length"
          v-model="form.timezone"
          :init-options="timezones"
          :placeholder="$t('venue.placeholders.timezone')"
          track-by="id"
        />
      </input-wrapper>
      <input-wrapper field="currency" :form="form" :label-text="$t('venue.fields.currency')">
        <v-multi-select
          v-if="currencies.length"
          v-model="form.currency"
          :init-options="currencies"
          :placeholder="$t('venue.placeholders.currency')"
          track-by="id"
        />
      </input-wrapper>
      <input-wrapper field="deputy_location_id" :form="form" :label-text="$t('venue.fields.deputy_location_id')">
        <v-multi-select
          v-model="form.deputy_location_id"
          :init-options="deputyLocations"
          :placeholder="$t('venue.placeholders.deputy_location_id')"
          track-by="value"
        />
      </input-wrapper>
      <v-checkbox v-model="form.active" :label-text="$t('generic.words.active')"></v-checkbox>
    </form>
    <v-footer :list-url="listUrl" :save="save"></v-footer>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import axios from "axios";

export default {
  name: "VenueEdit",
  inject: ["notificationService"],
  components: {},
  props: {
    venue: {
      type: Object,
      default: () => {
        return {};
      },
    },
    listUrl: {
      type: String,
      required: true,
    },
    timezones: {
      type: Array,
      required: true,
    },
    deputyLocations: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      form: new Form(
        {
          id: this.getFieldValue("id"),
          name: this.getFieldValue("name"),
          timezone: this.getFieldValue("timezone"),
          currency: this.getFieldValue("currency"),
          phone: this.getFieldValue("phone"),
          deputy_location_id: this.getFieldValue("deputy_location_id"),
          address: {
            street_address: this.getFieldValue("street_address", "address"),
            suburb: this.getFieldValue("suburb", "address"),
            state: this.getFieldValue("state", "address"),
            postcode: this.getFieldValue("postcode", "address"),
          },
          active: Boolean(Number(this.getFieldValue("active"))),
        },
        { resetOnSuccess: false },
      ),
      currencies: [],
    };
  },
  mounted() {
    this.getListCurrency();
  },

  methods: {
    getFieldValue(field, children = null, defaultValue = null) {
      if (children && this.venue.hasOwnProperty(children) && this.venue[children].hasOwnProperty(field)) {
        return this.venue[children][field];
      }

      if (this.venue.hasOwnProperty(field)) {
        return this.venue[field];
      }

      return defaultValue;
    },
    save() {
      if (this.venue.hasOwnProperty("id")) {
        this.form
          .patch(`/api/admin/venues/${this.venue.id}`)
          .then(({}) => {
            window.location = this.listUrl;
          })
          .catch(({ response }) => {
            this.notificationService.notify(response.data.message, "error");
          });

        return;
      }

      this.form
        .post(`/api/admin/venues`)
        .then(({}) => {
          window.location = this.listUrl;
        })
        .catch(({ response }) => {
          this.notificationService.notify(response.data.message, "error");
        });
    },
    getListCurrency() {
      this.loading = true;
      axios.get(`/resources/json/currency.json`).then(({ data }) => {
        this.currencies = Object.keys(data).map((key) => {
          const label = key + " - " + data[key].name_plural + " - " + data[key].symbol;
          return { id: key, label: label };
        });
      });
    },
  },
};
</script>
