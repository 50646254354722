<template>
  <div v-click-outside="() => (this.active = false)" class="flex items-center">
    <div class="relative flex items-center">
      <div
        ref="button"
        :class="{ active }"
        class="relative flex cursor-pointer items-center justify-center rounded-lg hover:bg-neutral-100"
        @click.prevent="active = !active"
      >
        <v-icon v-if="showActionIcon" icon="EllipsisHorizontalIcon"></v-icon>
        <slot name="button" />
      </div>
      <transition name="fade">
        <div
          v-if="active"
          class="absolute right-0 top-full z-20 w-[150px] rounded bg-white shadow-xl ring-1 ring-neutral-100"
          :class="classes"
        >
          <slot name="dropdown" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "VActions",
  props: {
    classes: {
      type: String,
      default: "",
    },
    showActionIcon: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.$emit("open");
      } else {
        this.$emit("close");
      }
    },
  },
  data() {
    return {
      active: false,
    };
  },
};
</script>
