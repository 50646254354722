<template>
  <div class="flex gap-x-4 rounded bg-tertiary-100 p-2xsSpace text-sm" v-if="selectedItems.length">
    <i v-for="item in selectedItems">
      <strong>{{ item.name }}:</strong> {{ item.selected.map((o) => o.label).join("|") }}
    </i>
  </div>
</template>

<script>
export default {
  name: "VFilterInfo",
  inject: ["bus"],
  data() {
    return {
      selectedItems: [],
    };
  },
  created() {
    this.bus.$on("infoItems", (items) => {
      this.selectedItems = items ?? [];
    });
  },
  methods: {},
};
</script>
