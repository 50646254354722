<template>
  <div v-if="Object.keys(currentCustomer).length" class="isolate flex flex-auto flex-col overflow-auto">
    <div v-if="Object.values(waivers).length" class="flex flex-col gap-4 md:gap-6">
      <div class="mt-xlSpace flex justify-between">
        <h1 class="text-2mdSpace font-semibold text-white2">{{ $t("waiver.headings.all") }}</h1>
        <a
          :href="createUrl"
          class="btn-emptyState float-right inline-block w-fit !bg-transparent font-semibold !text-white2 hover:!bg-white2 hover:!text-brand"
        >
          {{ $t("waiver.buttons.sign_new_waiver") }}
        </a>
      </div>
      <div v-for="waiver in waivers" :key="waiver.id" class="space-y-6 rounded-xl bg-white2 p-mdSpace md:p-lgSpace">
        <div class="flex flex-col-reverse justify-between gap-mdSpace md:flex-row">
          <p class="text-lg font-semibold text-brand">{{ waiver.title }}</p>
          <v-badge class="!text-base font-semibold" :type="waiver.status.type" :value="waiver.status.value" />
        </div>

        <div v-if="waiver.date_sign" class="flex flex-row justify-between gap-6 md:flex-col">
          <div>
            <p class="text-xs text-neutral-400">{{ $t("waiver.fields.signed_date") }}</p>
            <p class="text-base text-neutral-700">{{ waiver.date_sign }}</p>
          </div>
          <div>
            <p class="text-xs text-neutral-400">{{ $t("waiver.fields.expired_date") }}</p>
            <p class="text-base text-neutral-700">{{ waiver.date_expired }}</p>
          </div>
        </div>

        <div class="flex flex-col justify-between gap-2 text-sm text-neutral-700 md:flex-row">
          <template v-if="waiver.date_sign">
            <p v-if="waiver.is_invalid">{{ $t("waiver.headings.invalid") }}</p>
            <p v-else-if="waiver.is_expired">{{ $t("waiver.headings.expired") }}</p>
            <p v-else>
              {{ $t("waiver.headings.conditions_changed") }}
              <a :href="createUrl" class="ml-1 text-neutral-700 underline">
                {{ $t("waiver.buttons.sign_new_waiver") }}
              </a>
            </p>
          </template>
          <p v-else>{{ $t("waiver.headings.havent_signed_yet") }}</p>

          <div class="ml-auto">
            <a :href="waiver.link" class="underline" :class="waiver.date_sign ? 'text-neutral-400' : 'text-brand'">
              {{ $t(waiver.date_sign ? "waiver.buttons.view_waiver" : "waiver.buttons.sign_this_waiver") }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mx-auto flex max-w-[344px] flex-col items-center gap-6 md:mt-12 md:w-[600px] md:max-w-none">
      <div class="flex w-full flex-col gap-6 rounded-xl bg-white2 p-6 md:flex-row md:gap-0">
        <div class="h-60 w-full flex-shrink-0 overflow-hidden md:w-60">
          <img src="/img/empty_state.jpg" alt="Empty state" class="object-cover" />
        </div>

        <div class="flex flex-col items-center justify-center gap-6 text-emptyState md:px-6">
          <p class="text-center" v-html="$t('waiver.headings.empty_state')"></p>
          <a :href="createUrl" class="btn-emptyState border-btn-emptyState !text-emptyState">
            {{ $t("waiver.buttons.sign_new_waiver") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: function () {
    return {
      waivers: [],
    };
  },
  computed: {
    ...mapState({
      currentCustomer: (state) => state.customer.currentCustomer,
    }),
    createUrl() {
      return `/customers/${this.currentCustomer.id}/waivers`;
    },
  },
  watch: {
    currentCustomer: {
      handler(newValue, oldValue) {
        this.getWaivers();
      },
      deep: true,
    },
  },
  methods: {
    getWaivers() {
      axios.get(`/api/client/${this.currentCustomer.id}/waivers`).then(({ data }) => {
        this.waivers = data.data;
      });
    },
  },
};
</script>
