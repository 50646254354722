<template>
  <div class="flex flex-col justify-end rounded sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace">
    <div class="flex space-x-xsSpace">
      <slot>
        <a @click.prevent="close()" class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')"></a>
      </slot>
      <a @click.prevent="save" class="btn-primary btn-sm">
        <v-icon v-if="loading" icon="ArrowPathIcon" class="h-smSpace w-smSpace animate-spin" />
        <span v-else>{{ $t("generic.buttons.save") }}</span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["bus"],
  props: {
    listUrl: String,
    save: Function,
    inPanel: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      if (this.inPanel) {
        this.bus.$emit("closePanelInPanel", true);
        return;
      }

      location.href = this.listUrl;
    },
  },
};
</script>
