<template>
  <div class="isolate flex flex-auto flex-col" v-if="Object.keys(currentCustomer).length">
    <div v-if="waitLists.length" class="flex flex-auto flex-col">
      <div class="relative my-6 flex items-center justify-between">
        <h1 class="text-xl font-semibold text-white2">{{ $t("wait_list.headings.all") }}</h1>

        <a
          :href="createUrl"
          class="btn-emptyState float-right inline-block w-fit !bg-transparent font-semibold !text-white2 hover:!bg-white2 hover:!text-brand"
        >
          {{ $t("wait_list.buttons.join") }}
        </a>
      </div>

      <div class="flex flex-col gap-6">
        <template v-for="waitlist in waitLists">
          <div class="flex flex-col gap-6 rounded-xl border bg-transparent bg-white2 p-mdSpace text-sm text-brand">
            <div class="flex flex-col">
              <p class="block font-semibold">{{ waitlist.title }}</p>

              <div v-if="waitlist.full_date">
                {{ $t("wait_list.labels.preferred_date") }} - {{ waitlist.full_date }}
              </div>
            </div>

            <div class="flex gap-2 text-neutral-700">
              <span>{{ $t("wait_list.headings.not_interested") }}</span>
              <delete-confirmation-button
                :content="$t('wait_list.message.confirm_delete')"
                :link="waitlist.actions.delete.link"
                classes="text-neutral-700 underline hover:text-neutral-900"
                :label="$t('generic.buttons.cancel')"
              ></delete-confirmation-button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="mx-auto flex max-w-[344px] flex-col items-center gap-6 md:mt-12 md:w-[600px] md:max-w-none">
      <h1 class="w-full text-xl font-semibold text-white2 md:hidden">{{ $t("wait_list.headings.all") }}</h1>

      <div class="flex w-full flex-col gap-6 rounded-xl bg-white2 p-6 md:flex-row md:gap-0">
        <img src="/img/empty_state.jpg" alt="Empty state" class="h-60 object-cover md:w-60" />
        <div class="flex flex-col items-center justify-center gap-6 md:px-6">
          <p class="text-center text-emptyState" v-html="$t('wait_list.headings.empty_state')"></p>

          <a :href="createUrl" class="btn-emptyState border-btn-emptyState !text-emptyState">
            {{ $t("wait_list.buttons.join") }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="h-[50vh]">
    <div class="pt-8 text-center text-white2">
      {{ $t("client.headings.null_customer") }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  inject: ["notificationService"],
  props: {
    createUrl: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      waitLists: [],
    };
  },
  computed: {
    ...mapState({
      currentCustomer: (state) => state.customer.currentCustomer,
    }),
  },
  watch: {
    currentCustomer: {
      handler(newValue, oldValue) {
        this.getCustomerWaitLists();
      },
      deep: true,
    },
  },
  methods: {
    getCustomerWaitLists() {
      axios.get(`/api/wait-lists?filter[customer_id]=${this.currentCustomer.id}`).then(({ data }) => {
        this.waitLists = data.data;
      });
    },
  },
};
</script>
