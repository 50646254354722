<template>
  <div>
    <input-wrapper field="product_id" :form="form" :label-text="$t('booking.columns.product')">
      <v-multi-select
        v-model="product"
        :search-url="endpoint"
        :placeholder="$t('booking.placeholders.product')"
        track-by="id"
        label="name"
        search-key="filter[name]"
        @selectOption="selectProduct"
        @optionLoaded="optionLoaded"
      />
    </input-wrapper>
    <div class="pb-smSpace" v-if="variants.length">
      <template v-for="variant in variants">
        <input-wrapper field="product_variant_id" :form="form">
          <label class="flex items-center space-x-xsSpace pt-xsSpace">
            <input
              :checked="variant.id === productVariantId"
              class="rounded border border-tertiary-300"
              type="checkbox"
              @change="selectProductVariant($event.target.checked, variant)"
            />
            <span
              class="cursor-pointer text-xs text-tertiary-700"
              :class="{ 'font-semibold': variant.id === productVariantId }"
              >{{ variant.name }} - ${{ toAUD(variant.price_per_unit) }}</span
            >
          </label>
        </input-wrapper>
      </template>
    </div>
    <p class="text-xs text-red-500" v-if="productId && !variants.length">
      {{ $t("product.headings.no_variant_found") }}
    </p>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    form: {
      type: Object,
    },
    productId: {
      type: Number,
    },
    productVariantId: {
      type: Number,
    },
    paymentValue: {
      type: Number,
    },
    endpoint: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      product: null,
    };
  },
  computed: {
    ...mapState({
      variants: (state) => state.product.productVariants,
    }),
  },
  beforeUnmount() {
    this.$store.dispatch("product/setProductVariants", []);
  },
  methods: {
    optionLoaded() {
      this.product = this.productId;
    },
    selectProduct(product) {
      if (!product || this.productId !== product.id) {
        this.$emit("update:productVariantId", null);
      }

      let variants = [];
      if (product) {
        variants = product.variants.items;
        this.$emit("update:productId", product.id);
        if (variants.length && !variants.find((o) => o.id === this.productVariantId)) {
          this.$emit("update:productVariantId", variants[0].id);
          this.$emit("update:paymentValue", variants[0].price_per_unit);
        }
      } else {
        variants = [];
        this.$emit("update:productId", null);
      }

      this.$store.dispatch("product/setProductVariants", variants);
    },

    selectProductVariant(value, item) {
      this.$emit("update:productVariantId", !value ? null : item.id);
      this.$emit("update:paymentValue", !value ? 0 : item.price_per_unit);
    },
  },
};
</script>
