<template>
  <td>
    <div v-if="editable" class="flex items-center gap-xsSpace">
      <editable
        :model-value="value"
        @update:model-value="$emit('update:model-value', $event)"
        @editing="$emit('editing', $event)"
      ></editable>
      <slot></slot>
    </div>
    <template v-else>
      <a v-if="action.link" :href="action.link" :title="action.label" class="underline">
        <span class="text-sm text-gray-500">{{ value }}<slot></slot></span>
      </a>
      <span v-else class="text-sm text-gray-500">{{ value }}<slot></slot></span>
    </template>
  </td>
</template>

<script>
export default {
  name: "VTableTitle",
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
    rowField: {
      type: [String, Object],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    value() {
      const field = typeof this.rowField === "string" ? this.rowField : this.rowField.field;

      return this.rowData[field];
    },
    action() {
      return (this.rowData.actions && (this.rowData.actions.view || this.rowData.actions.edit)) || null;
    },
  },
};
</script>
