<template>
  <div>
    <form id="payment-form">
      <div id="card-container"></div>
    </form>
    <div id="payment-status-container" class="text-white2"></div>
  </div>
</template>

<script>
export default {
  name: "SquarePayment",
  inject: ["config", "notificationService"],
  props: {},
  data() {
    return {
      payments: null,
      card: null,
    };
  },

  mounted() {
    this.loadSquare();
  },

  methods: {
    loadSquare() {
      if (!window.Square) {
        throw new Error("Square.js failed to load properly");
      }

      try {
        this.payments = window.Square.payments(this.config.SQUARE_APP_ID, this.config.SQUARE_LOCATION_ID);
      } catch (e) {
        const statusContainer = document.getElementById("payment-status-container");
        statusContainer.textContent = e.message;
        statusContainer.style.visibility = "visible";
        return;
      }

      try {
        this.card = this.initializeCard(this.payments);
      } catch (e) {
        this.notificationService.notify(e.message, "error");
      }
    },
    async submit() {
      return await this.tokenize();
    },
    async initializeCard(payments) {
      const card = await payments.card();
      await card.attach("#card-container");

      return card;
    },
    async tokenize() {
      const card = await this.card;
      const tokenResult = await card.tokenize();
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      }

      this.notificationService.notify(this.$t("payment.headings.invalid_card"), "error");
    },
  },
};
</script>
<style>
.sq-card-message-error,
.sq-card-message {
  @apply !text-white2;

  &::before {
    @apply !bg-white;
  }
}

.sq-card-wrapper .sq-card-message-error {
  @apply !mt-1 min-h-[28px] !rounded-[4px] !bg-[#FED9DB] !px-2 !py-1 !text-sm !font-normal !leading-normal !text-[#F23D3D];

  &::before {
    @apply !mr-1 !bg-[#F23D3D];
  }
}
</style>
