<template>
  <div class="space-y-lgSpace p-lgSpace">
    <div class="space-y-lgSpace">
      <div class="text-lg font-bold">{{ $t("gift_voucher_instance.headings.gift_voucher_instance") }}</div>
    </div>
    <v-notification v-if="error" type="error" :content="error" />
    <form class="grid gap-smSpace">
      <input-wrapper field="customer_id" :form="form" :label-text="$t('gift_voucher_instance.fields.customer')">
        <customer-input
          :form="form"
          v-model:customer-id="form.customer_id"
          v-model:first-name="customer_first_name"
          v-model:last-name="customer_last_name"
          @selectClient="selectClient($event)"
          hide-label
          class="[&_.v-field-label]:hidden"
        />
      </input-wrapper>
      <input-wrapper field="quantity" :form="form" :label-text="$t('gift_voucher.fields.quantity')">
        <input type="number" v-model="form.quantity" :placeholder="$t('gift_voucher.placeholders.quantity')" />
      </input-wrapper>
      <input-wrapper field="selected_venues" :form="form" :label-text="$t('gift_voucher.fields.venue')">
        <v-multi-select
          v-model="form.selected_venues"
          search-url="/api/admin/venues?filter[active]=1"
          :placeholder="$t('gift_voucher.fields.venue')"
          :is-multiple="true"
          track-by="id"
          label="name"
          search-key="filter[name]"
        />
      </input-wrapper>
      <div v-if="form.customer_id" class="rounded bg-slate-100 text-sm leading-10">
        <v-checkbox
          class="flex items-center space-x-xsSpace p-mdSpace"
          v-model="form.is_notification"
          :label-text="$t('booking.headings.send_confirmation_email')"
        />
      </div>
    </form>
  </div>
  <div
    class="flex w-full flex-col items-end justify-end rounded p-lgSpace sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
  >
    <div class="flex space-x-xsSpace">
      <a @click="close" class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')"></a>
      <a @click.prevent="save" class="btn-primary btn-sm" v-text="$t('generic.buttons.continue')"></a>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";

export default {
  name: "BathPassGiftVoucherInstanceCreate",
  inject: ["notificationService", "bus"],
  props: {
    saveUrl: {
      type: String,
      require: true,
    },
  },

  data() {
    return {
      loading: false,
      customer: "",
      customer_first_name: "",
      customer_last_name: "",
      error: "",
      form: new Form(
        {
          customer_id: null,
          quantity: 1,
          selected_venues: [],
          is_notification: true,
        },
        { resetOnSuccess: false },
      ),
    };
  },

  methods: {
    selectClient(client) {
      this.customer = client.display_name;
    },
    save() {
      this.error = null;
      this.loading = true;

      this.form
        .post(this.saveUrl)
        .then(() => {
          this.loading = false;
          this.bus.$emit("reloadTable");
          this.close();
          this.notificationService.notify(this.$t("generic.responses.create_success"));
        })
        .catch((e) => {
          this.error = e?.response?.data?.message;
          this.loading = false;
        });
    },
    close() {
      this.bus.$emit("closePanel");
    },
  },
};
</script>
