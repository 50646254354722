<template>
  <a class="btn-sm btn-secondary" @click.prevent.stop="exportData">
    <span v-if="!loading">{{ $t("generic.buttons.export") }}</span>
    <div v-else aria-label="Loading..." role="status" class="flex items-center justify-center space-x-2">
      <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
      <span class="text-sm font-medium text-tertiary-500">
        {{ $t("generic.words.exporting") }}
      </span>
    </div>
  </a>
</template>

<script>
export default {
  name: "ClientExportButton",
  props: {
    getUrl: {
      type: String,
      required: true,
    },
    header: {
      type: Object,
      required: true,
    },
    fileName: {
      type: String,
      required: false,
    },
    defaultParams: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async exportData() {
      if (this.loading) {
        return;
      }

      try {
        this.loading = true;
        const rows = await this.getData(this.getUrl, 1, [this.header]);
        this.loading = false;
        this.exportToCsv(Object.keys(this.header), rows, this.fileName || `${Date.now()}.csv`);
      } catch (e) {
        this.loading = false;
      }
    },
    async getData(url, page = 1, rows = []) {
      const urlObject = new URL(url);
      urlObject.searchParams.set("page", String(page));
      urlObject.searchParams.set("per_page", String(1000));
      const { data } = await axios.get(urlObject.toString(), { params: this.defaultParams });
      rows = rows.concat(data.data);
      if (!data.links.next) {
        return rows;
      }

      return this.getData(url, page + 1, rows);
    },
    exportToCsv(header = ["key"], rows = [{ key: "test" }], filename = "test.csv") {
      const csvString = rows
        .map((item) =>
          header.map((headerField) => {
            let val = item[headerField];
            if (typeof val === "string") {
              return `"${val.replace(/"/g, '\\"')}"`;
            }

            if (val === null || val === undefined) {
              val = "";
            }

            return String(val);
          }),
        )
        .map((e) => e.join(","))
        .join("\n");

      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  },
};
</script>
