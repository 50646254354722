<template>
  <div v-click-outside="onClickOutside" class="flex items-center">
    <div v-if="actions || connections" class="relative flex items-center">
      <button
        :class="{ active: active }"
        class="relative flex h-xlSpace w-xlSpace cursor-pointer items-center justify-center rounded-lg hover:bg-neutral-100"
        @click.prevent.stop="active = !active"
      >
        <v-icon icon="EllipsisHorizontalIcon"></v-icon>
      </button>
      <transition name="fade">
        <div
          v-if="active"
          class="absolute z-20"
          :class="{
            'right-10 top-auto': align === 'left',
            'right-0 top-10': align === 'bottom',
          }"
        >
          <div class="z-20 w-[180px] rounded-md bg-white shadow-xl ring-1 ring-neutral-100">
            <ul class="-my-smSpace p-1 text-xs font-medium">
              <template v-for="(action, id) in displayActions">
                <li v-if="id === 'delete'" :key="id">
                  <component
                    v-if="action.hasOwnProperty('component')"
                    :is="action.component"
                    :link="action.link"
                    :content="action.content"
                  >
                  </component>
                  <form v-else ref="form" :action="action.link" class="mb-0" method="POST">
                    <input :value="$root.csrf_token" name="_token" type="hidden" />
                    <input name="_method" type="hidden" value="DELETE" />
                    <button
                      class="flex w-full cursor-pointer items-center space-x-xsSpace rounded p-xsSpace hover:bg-neutral-100"
                      type="submit"
                    >
                      <template v-if="action.label">
                        <span>
                          {{ action.label }}
                        </span>
                      </template>
                      <template v-else>
                        <span>{{ $t("generic.buttons.delete") }}</span>
                      </template>
                    </button>
                  </form>
                </li>
                <li v-else :key="`action-${id}`" @click="onClickOutside">
                  <component
                    v-if="action.hasOwnProperty('component')"
                    :is="action.component"
                    v-bind="action.data"
                    :row-data="rowData"
                  />
                  <a
                    v-else
                    :href="action.link"
                    class="flex w-full cursor-pointer items-center space-x-xsSpace rounded p-xsSpace hover:bg-neutral-100"
                  >
                    <span>{{ action.label }}</span>
                  </a>
                </li>
              </template>
              <template v-if="connections">
                <hr v-if="displayActions" />
                <li v-for="connection in connections" :key="connection.label">
                  <a
                    :href="connection.link"
                    class="flex cursor-pointer items-center justify-between space-x-xsSpace rounded p-xsSpace hover:bg-neutral-100"
                  >
                    <span> {{ connection.label }} </span>
                    <v-icon :icon="connection.hasOwnProperty('icon') ? connection.icon : 'ArrowLongRightIcon'"></v-icon>
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: "VActions",
  props: {
    align: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      active: false,
      actions: this.$attrs.data.rowData.actions ?? [],
      connections: this.$attrs.data.rowData.links ?? null,
      index: this.$attrs.data.rowIndex ?? null,
      rowData: this.$attrs.data.rowData ?? null,
    };
  },
  computed: {
    displayActions() {
      const hasSiblingTitleComponent = Boolean(this.$attrs["has-sibling-title-component"]);
      const inputActions = Object.entries(this.$attrs.data.rowData.actions ?? []);
      let filtered = false;
      const actions = inputActions.filter(([key]) => {
        if (
          hasSiblingTitleComponent &&
          !filtered &&
          (key === "view" || (inputActions.every(([k]) => k !== "view") && key === "edit"))
        ) {
          filtered = true;
          return false;
        }

        return true;
      });

      return Object.fromEntries(actions);
    },
  },
  methods: {
    onClickOutside(event) {
      this.active = false;
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
