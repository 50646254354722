<template>
  <div v-if="!loading">
    <template v-for="voucher in vouchers">
      <div
        v-if="!(selectedVoucher && selectedVoucher !== voucher.id)"
        :class="selectedVoucher === voucher.id ? 'bg-blue-200' : 'bg-blue-50'"
        class="mt-xsSpace flex cursor-pointer items-center justify-between rounded p-2xsSpace text-sm"
      >
        <div class="flex w-full items-center justify-between" @click="selectVoucher(voucher.id)">
          <div class="flex w-full flex-col">
            <p class="block text-lg font-semibold">{{ voucher.title }}</p>
            <span class="text-md block pt-xsSpace">{{ voucher.description }}</span>
          </div>
          <a
            class="btn-secondary btn-sm border-2 !border-orange-900 bg-white !text-orange-900"
            :class="!selectedVoucher || selectedVoucher === voucher.id ? '' : '!bg-white opacity-50'"
          >
            {{ voucher.value }}
          </a>
        </div>
        <a
          @click="remove"
          v-if="selectedVoucher"
          class="btn-secondary btn-sm ml-xsSpace border-2 !border-orange-900 bg-white !text-orange-900"
          :class="!selectedVoucher || selectedVoucher === voucher.id ? '' : '!bg-white opacity-50'"
        >
          {{ $t("generic.buttons.remove") }}
        </a>
      </div>
    </template>
  </div>
  <div
    v-else
    aria-label="Loading..."
    role="status"
    class="my-auto flex h-[300px] items-center justify-center space-x-2"
  >
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>

<script>
import VoucherList from "~vue/components/gift-vouchers/checkout/VoucherList.vue";

export default {
  extends: VoucherList,
};
</script>
