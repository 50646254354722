<template>
  <draggable
    v-model="innerVouchers"
    group="voucher"
    item-key="id"
    handle=".dragging-cursor"
    class="mt-xsSpace table w-full"
    @change="handleChange"
  >
    <template #header>
      <tr key="header" class="table-row w-full bg-tertiary-100 font-medium">
        <div
          v-for="(field, index) in fields"
          class="table-cell"
          :class="index === 0 ? field.dataClass.replace('border-t', '') : field.dataClass"
          :key="index"
          :style="{ width: field?.width }"
        >
          <span class="flex">{{ field.title }}</span>
        </div>
      </tr>
    </template>
    <template #item="{ element }" :key="element.id">
      <tr :key="element.id" :id="`draggable-voucher-${element.id}`" class="draggable-item relative table-row divide-y">
        <td v-for="(field, index) in fields" :class="field.dataClass" :key="index">
          <template v-if="field.hasOwnProperty('name')">
            <v-table-actions
              v-if="field.name === 'actions'"
              :key="`${index} + ${Math.floor(Math.random() * 100)}`"
              :data="{ rowData: element, rowIndex: index }"
            />
            <div class="dragging-cursor" v-else-if="field.name === 'icon'">
              <v-icon icon="ArrowsUpDownIcon" class="cursor-move text-gray-400 hover:text-gray-800"></v-icon>
            </div>
            <v-table-title
              v-else-if="field.field === 'name'"
              :row-data="element"
              :row-field="field"
              :row-index="index"
            ></v-table-title>
            <component
              v-else-if="field.name.includes('__component:')"
              :is="field.name.replace('__component:', '')"
              :row-data="element"
              :row-field="field"
              :row-index="index"
            ></component>
            <div v-else>
              <span class="flex">{{ element[field.name] }}</span>
            </div>
          </template>
          <div v-else-if="field.hasOwnProperty('field')">
            <span class="flex">{{ element[field.field] }}</span>
          </div>
          <div v-else>
            <span class="flex"></span>
          </div>
        </td>
      </tr>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import VTableActions from "@/js/vue/components/common/table/VTableActions.vue";

export default {
  name: "VoucherList",
  inject: ["config"],
  components: { VTableActions, draggable },
  props: {
    categoryId: {
      type: [String, Number],
      required: true,
    },
    vouchers: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dragging: false,
      innerVouchers: this.vouchers,
    };
  },
  methods: {
    handleChange(changes) {
      if (changes.added) {
        this.$emit("onMove", this.categoryId, changes.added.element.id, changes.added.newIndex);
      }

      if (changes.removed) {
        this.$emit("onMove", this.categoryId, changes.removed.element.id);
      }

      if (changes.moved) {
        this.$emit(
          "onReorder",
          this.categoryId,
          this.innerVouchers.map((p) => p.id),
        );
      }
    },
  },
};
</script>
