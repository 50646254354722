<template>
  <div class="w-[300px] p-smSpace">
    <div v-html="$t('waiver.headings.send_waiver_to_client')" class="py-mdSpace"></div>
    <div class="flex justify-between space-x-xsSpace pt-xsSpace">
      <a @click="$emit('closeModal')" class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')"></a>
      <a @click.prevent="$emit('confirm')" class="btn-primary btn-sm" v-text="$t('generic.words.yes')"></a>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
