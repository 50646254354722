<template>
  <div class="space-y-4">
    <div class="space-y-xlSpace text-white2">
      <input-wrapper
        class="text-white2"
        field="email"
        :form="form"
        :label-text="$t('booking.labels.how_many_people_are_you_booking_for')"
        :dark-theme="true"
      >
        <select v-model="form.quantity" class="bg-brand">
          <option :key="option.id" v-for="option in quantityOptions" :value="option.id">{{ option.label }}</option>
        </select>
      </input-wrapper>

      <div class="block space-y-2xsSpace text-sm font-semibold leading-none">
        <span class="pb-xsSpace text-base">
          {{ $t("booking.labels.select_booking_date") }}
        </span>
        <div class="rounded-t-[4px] border border-white">
          <calendar
            :key="calendarRenderCount"
            v-if="Object.keys(cart).length"
            :venue-id="cart.venue_id"
            v-model:selectedDate="cart.date"
            v-model:appointmentIds="form.appointment_ids"
            :product-id="cart.product_id"
            :add-on-product-id="cart.add_on_product_id"
            :is-dark-theme="true"
            :horizon-mode="true"
            :booking-label="productInfo.label"
          />
        </div>
        <div class="flex items-center gap-2">
          <span class="inline-flex h-3 w-3 rounded-full bg-green-200"></span>{{ " " }}
          <span class="text-sm font-medium">{{ $t("booking.labels.availability") }}</span>
        </div>
        <div class="flex items-center gap-2">
          <span class="inline-flex h-3 w-3 rounded-full bg-red-200"></span>{{ " " }}
          <span class="text-sm font-medium">{{ $t("booking.labels.no_availability") }}</span>
        </div>
        <div
          v-if="form.errors.has('appointment_ids')"
          class="flex w-full rounded-md bg-pink-100 p-xsSpace py-1 font-normal"
        >
          <v-icon icon="SolidExclamationCircleIcon" classes="w-5 h-5 text-red-400 mr-2xsSpace"></v-icon>
          <small class="block text-sm text-red-500"> {{ form.errors.first("appointment_ids") }}</small>
        </div>
      </div>
      <div v-if="cart.add_on_product_id && cart.add_on_product_variant_id">
        <p class="text-base font-normal leading-normal">{{ $t("booking.headings.select_bathhouse_desc") }}</p>
      </div>
      <div class="flex justify-center">
        <button
          class="btn-secondary !block w-full items-center !bg-white !text-brand"
          :class="{ 'opacity-50': !form.appointment_ids }"
          @click="submit"
        >
          {{ isMassage && isAddonApplicable ? $t("booking.buttons.next_addon") : $t("booking.buttons.next_details") }}
        </button>
      </div>
    </div>
    <div v-if="false" class="block items-center rounded-md bg-white bg-opacity-10 px-4 py-2 text-base text-white2">
      {{ $t("booking.buttons.add_new_waitlist") }}
      <a class="cursor-pointer border-b" @click="addWaitList">{{ $t("booking.labels.waitlist") }} </a>.
    </div>
  </div>
</template>
<script>
import Form from "form-backend-validation";
import Calendar from "../../common/booking/Calendar.vue";
import axios from "axios";

export default {
  name: "BookingAvailability",
  inject: ["bus", "sessionStorageService", "notificationService"],
  components: { Calendar },
  props: {
    isBathing: {
      type: Boolean,
    },
    isAddonApplicable: {
      type: Boolean,
      default: false,
    },
    waitListUrl: {
      type: String,
      required: true,
    },
    nextUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cart: {},
      form: new Form(
        {
          quantity: 1,
          appointment_ids: null,
        },
        { resetOnSuccess: false },
      ),
      calendarRenderCount: 0,
    };
  },
  async mounted() {
    const { data } = await axios.get("/api/booking/cart");
    this.cart = data;
    this.populateForm();
  },
  computed: {
    quantityOptions() {
      const maxQuantity = parseInt(
        this.isMassage ? this.appConfigs.max_massage_slot : this.appConfigs.max_bathrooms_slot,
      );
      return new Array(maxQuantity || 1).fill(null).map((_, index) => ({
        id: index + 1,
        label: index + 1,
      }));
    },

    productInfo() {
      const isBathing = !!this.cart.product?.is_belonged_to_bathhouse;
      return {
        isBathing,
        label: this.$t(isBathing ? "booking.headings.select_bathing" : "booking.headings.select_massage"),
      };
    },
  },
  watch: {
    "form.appointment_ids": {
      handler(newValue, oldValue) {
        newValue = newValue || [];
        oldValue = oldValue || [];
        const merged = [...newValue, ...oldValue];
        const changes = newValue.length !== merged.length || newValue.some((id) => !oldValue.includes(id));
        if (changes) {
          this.updateCart();
        }
      },
      "cart.date": {
        handler(newValue, oldValue) {
          if (newValue !== oldValue) {
            this.form.appointment_ids = [];
            this.updateCart();
          }
        },
      },
      deep: true,
    },
    "form.quantity": {
      handler(newValue, oldValue) {
        this.form.appointment_ids = [];
        this.form.add_on_product_variant_id = null;
        this.cart.date = null;
        this.updateCart(() => {
          this.calendarRenderCount += 1;
        });
      },
    },
  },
  methods: {
    populateForm() {
      this.form.quantity = this.cart?.quantity ?? this.form.quantity;
      this.form.appointment_ids = this.cart?.appointment_ids ?? this.form.appointment_ids;
    },
    async updateCart(callable = () => {}) {
      await this.form.post("/api/booking/cart/availability");
      this.bus.$emit("reloadCart");
      callable();
    },
    async submit() {
      if (!this.form.appointment_ids || !this.form.appointment_ids.length) {
        this.notificationService.notify(this.$t("booking.validation.booking_appointment"), "error");
        return;
      }

      try {
        await this.updateCart();
        window.location.href = this.nextUrl;
      } catch (e) {
        this.notificationService.notify(e?.response?.data?.message, "error");
      }
    },
    addWaitList() {
      const { email, phone, name, venue_id, product_id, vouchers, date } = this.cart;
      this.sessionStorageService.setBookingInfoData({ email, phone, name, venue_id, product_id, vouchers, date });

      window.open(this.waitListUrl + "?booking", "_blank");
    },
  },
};
</script>
<style scoped>
input::placeholder {
  @apply !text-white2 opacity-60;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}

input[type="radio"],
input[type="radio"]:focus,
input[type="radio"]:hover {
  @apply h-4 w-4 cursor-pointer rounded-full border-2 border-white bg-transparent;
  @apply appearance-none shadow-none outline-0 ring-0 ring-transparent ring-offset-0;
}

input[type="radio"]:checked,
input[type="radio"]:checked:focus,
input[type="radio"]:checked:hover {
  @apply h-4 w-4 border-4 border-white bg-transparent bg-none shadow-none outline-0 ring-0 ring-transparent;
}
</style>
