<template>
  <div class="mb-lgSpace space-y-lgSpace rounded-md px-mdSpace pb-3xlSpace pt-lgSpace text-white2 shadow-sm">
    <h2 class="mb-smSpace text-center text-2xl">
      <span> {{ giftVoucherInstance.gift_voucher.title }} </span>
    </h2>
    <table class="w-full">
      <tbody>
        <tr v-if="giftVoucherInstance.venue">
          <td class="text-left">{{ $t("venue.headings.information") }} :</td>
          <td class="text-right">{{ giftVoucherInstance.venue }}</td>
        </tr>
        <tr>
          <td class="text-left">{{ $t("booking.labels.total") }} :</td>
          <td class="text-right">{{ toAUD(giftVoucherInstance.gift_voucher.value) }}</td>
        </tr>
        <tr>
          <td class="text-left">{{ $t("booking.labels.total_paid") }} :</td>
          <td class="text-right">{{ toAUD(giftVoucherInstance.total_paid) }}</td>
        </tr>
        <tr>
          <td class="text-left">{{ $t("gift_voucher_instance.labels.outstanding") }} :</td>
          <td class="text-right">{{ toAUD(giftVoucherInstance.remaining) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="flex flex-col space-y-lgSpace" v-if="giftVoucherInstance.remaining > 0">
      <input-wrapper
        class="text-white2"
        field="email"
        :form="form"
        :label-text="$t('booking.fields.email')"
        :dark-theme="true"
      >
        <input class="bg-brand" type="text" v-model="form.email" :placeholder="$t('booking.placeholders.email')" />
      </input-wrapper>
      <input-wrapper
        class="text-white2"
        field="phone"
        :form="form"
        :label-text="$t('booking.fields.phone')"
        :dark-theme="true"
      >
        <phone-number v-model="form.phone" :placeholder="$t('booking.placeholders.phone')" dark-mode />
      </input-wrapper>
      <div class="relative flex flex-1 gap-1">
        <input-wrapper
          class="flex-1 text-white2"
          field="first_name"
          :form="form"
          :label-text="$t('booking.fields.name')"
          :dark-theme="true"
        >
          <input
            class="bg-brand"
            type="text"
            v-model="form.first_name"
            :placeholder="$t('booking.placeholders.first_name')"
          />
        </input-wrapper>
        <input-wrapper class="flex-1 text-white2" field="last_name" :form="form" label-text="&nbsp;" :dark-theme="true">
          <input
            class="bg-brand"
            type="text"
            v-model="form.last_name"
            :placeholder="$t('booking.placeholders.last_name')"
          />
        </input-wrapper>
      </div>
    </div>
    <div v-if="message" class="p-xlSpace text-center text-xl text-white">
      <p>{{ message }}</p>
    </div>
    <div class="!mt-mdSpace space-y-mdSpace font-semibold text-white" v-else-if="giftVoucherInstance.remaining > 0">
      <div>
        <span class="text-sm font-semibold">{{ $t("generic.words.card") }}</span>
        <square-payment ref="square" />
      </div>
      <div class="mb-2xlSpace flex justify-center pt-mdSpace">
        <button
          class="btn-secondary !block items-center !text-white hover:!text-orange-900"
          @click="submit"
          :disabled="loading"
        >
          <div
            v-if="loading"
            aria-label="Loading..."
            role="status"
            class="flex items-center justify-center space-x-2 text-white"
          >
            <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
            <span class="text-lg font-medium text-tertiary-500 text-white">
              {{ $t("generic.words.processing") }}
            </span>
          </div>
          <span v-else>{{ $t("generic.buttons.confirm_payment") }}</span>
        </button>
      </div>
    </div>
    <div v-else class="p-xlSpace text-center text-xl text-white">
      <p>{{ $t("gift_voucher_instance.response.paid_complete") }}</p>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";
import { mapState } from "vuex";

export default {
  inject: ["notificationService", "config"],
  props: {
    giftVoucherInstance: {
      required: true,
    },
    endpoint: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      currentCustomer: (state) => state.customer.currentCustomer,
      customers: (state) => state.customer.items,
    }),
  },
  data() {
    return {
      loading: false,
      message: null,
      customerId: null,
      form: new Form(
        {
          email: "",
          phone: "",
          first_name: "",
          last_name: "",
          payment: {
            type: this.config.PAYMENT_CARD,
            amount: this.giftVoucherInstance.remaining,
            token: null,
          },
        },
        { resetOnSuccess: false },
      ),
    };
  },
  mounted() {
    const id = parseInt(localStorage.getItem("customerId"));
    if (id) {
      this.customerId = id;
      this.$store.dispatch("customer/fetchCustomers").then(() => {
        this.onSelectCustomer();
      });
    }
  },
  methods: {
    onSelectCustomer() {
      const customer = this.customers.find((o) => o.id === this.customerId);
      if (!customer) {
        return;
      }
      this.form.populate({
        first_name: customer.first_name,
        last_name: customer.last_name,
        email: customer.email,
        phone: customer.phone,
      });
    },
    async submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      try {
        this.form.payment.token = await this.$refs.square.submit();
        if (!this.form.payment.token) {
          this.loading = false;
          return;
        }

        const response = await this.form.post(this.endpoint);
        this.message = response.statusText;
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (e) {
        this.notificationService.notify(e?.response?.data?.message || e.message, "error");
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
input::placeholder {
  @apply !text-white opacity-60;
}

input,
select {
  @apply focus:border-white focus:ring focus:ring-white focus:ring-opacity-50;
}
</style>
