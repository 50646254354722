<template>
  <form class="group relative grid w-3/4 grid-cols-[200px_1fr_auto] gap-mdSpace" @submit.prevent="submit">
    <label class="flex min-h-[38px] items-center text-sm font-semibold text-tertiary-700">
      {{ $t(`config.labels.${configuration.key}`) }}
    </label>

    <div class="flex flex-col gap-smSpace">
      <input v-if="configuration.type === 'numeric'" v-model="form.value" type="number" placeholder="Enter number" />
      <v-multi-select
        v-else-if="configuration.type === 'select'"
        v-model="form.value"
        search-url="/api/admin/bathhouse-product-variants"
        :placeholder="$t('gift_voucher.placeholders.product_variant')"
        :is-multiple="true"
        track-by="id"
        label="full_name"
        search-key="filter[name]"
      />
      <input v-else v-model="form.value" type="text" placeholder="Enter value" />
      <template v-if="form.errors">
        <div v-if="form.errors.has('value')" class="v-field-error flex rounded-md bg-pink-100 px-xsSpace py-1">
          <v-icon icon="SolidExclamationCircleIcon" classes="w-5 h-5 text-red-400 mr-2xsSpace"></v-icon>
          <small class="block text-sm text-red-500">{{ form.errors.first("value") }}</small>
        </div>
      </template>
    </div>
    <button
      class="btn-primary btn-sm absolute -right-12 min-h-[38px]"
      :class="{ 'btn-disabled': loading, '!hidden group-hover:!block': !isDirty, '!block': isDirty }"
      :disabled="loading"
    >
      <v-icon v-if="loading" icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
      <span v-else>{{ $t("generic.buttons.save") }}</span>
    </button>
  </form>
</template>

<script>
import Form from "form-backend-validation";

export default {
  name: "ConfigurationForm",
  inject: ["bus", "notificationService"],
  props: {
    configuration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      form: new Form(
        {
          value: this.configuration.value || "",
        },
        { resetOnSuccess: false },
      ),
    };
  },
  computed: {
    isDirty() {
      if (this.configuration.type === "select") {
        let isDirty = false;
        this.form.value.forEach((v) => {
          if (!this.form.initial.value.includes(v)) {
            isDirty = true;
          }
        });

        return isDirty;
      }

      return this.form.value !== this.form.initial.value;
    },
  },
  methods: {
    async submit() {
      if (this.loading) return;

      this.loading = true;
      await this.form.post(`/api/admin/configuration/${this.configuration.id}`);
      this.form.setInitialValues(this.form.data());
      this.bus.$emit("reloadAppConfigs");
      this.loading = false;

      this.notificationService.notify(this.$t("generic.responses.update_success"));
    },
  },
};
</script>
