<template>
  <form class="flex w-full flex-col justify-between p-lgSpace">
    <h3 class="mb-mdSpace text-lg">{{ $t("appointment.headings.find_availability") }}</h3>
    <input-wrapper field="venue_id" :form="form" :label-text="$t('booking.fields.venue')">
      <v-multi-select
        v-model="form.venue_id"
        search-url="/api/admin/venues?filter[active]=1"
        :placeholder="$t('venue.placeholders.venue')"
        track-by="id"
        label="name"
        search-key="filter[name]"
        :allow-empty="false"
      ></v-multi-select>
    </input-wrapper>
    <v-tabs :tabs="tabs" v-if="tabs.length">
      <template v-slot:[tab.slug] v-for="tab in tabs">
        <h3 class="pt-mdSpace">{{ $t("generic.words.products") }}</h3>
        <product-select
          :venue-id="form.venue_id"
          :category-id="tab.id"
          v-model="form.product_id"
          :on-select-product="selectProduct"
        />
      </template>
    </v-tabs>
    <div class="space-y-smSpace pt-xsSpace" v-if="productVariants.length">
      <h3 class="-mt-smSpace pt-xsSpace">{{ $t("generic.words.product_variants") }}</h3>
      <div>
        <template v-for="variant in productVariants">
          <input-wrapper field="product_variant_id" :form="form">
            <label class="flex items-center space-x-xsSpace pt-xsSpace">
              <input
                :checked="variant.id === form.product_variant_id"
                class="rounded border border-tertiary-300"
                type="checkbox"
                @change="selectProductVariant($event.target.checked, variant)"
              />
              <span
                class="cursor-pointer text-xs text-tertiary-700"
                :class="{ 'font-semibold': variant.id === form.product_variant_id }"
                >{{ variant.name }}</span
              >
            </label>
          </input-wrapper>
        </template>
      </div>

      <input-wrapper
        field="quantity"
        :form="form"
        :label-text="$t('booking.columns.quantity')"
        class="w-1/2 pt-mdSpace"
      >
        <select v-model="form.quantity" @change="form.date = null">
          <option :key="option.id" v-for="option in quantityOptions" :value="option.id">{{ option.label }}</option>
        </select>
      </input-wrapper>

      <calendar
        :venue-id="form.venue_id"
        :product-id="form.product_id"
        v-model:appointmentIds="form.appointment_ids"
        :quantity="form.quantity"
        v-if="form.product_id && form.venue_id"
        v-model:selected-date="form.date"
      />

      <div class="flex items-center gap-2">
        <span class="inline-flex h-3 w-3 rounded-full bg-green-200"></span>{{ " " }}
        <span class="text-sm font-medium">{{ $t("booking.labels.availability") }}</span>
      </div>
      <div class="flex items-center gap-2">
        <span class="inline-flex h-3 w-3 rounded-full bg-red-200"></span>{{ " " }}
        <span class="text-sm font-medium">{{ $t("booking.labels.no_availability") }}</span>
      </div>
    </div>
    <div
      class="mt-2xlSpace flex flex-col justify-end rounded sm:flex-row sm:items-start sm:space-x-mdSpace max-sm:space-y-smSpace"
      v-if="form.appointment_ids.length"
    >
      <a @click.prevent="book" class="btn-primary btn-sm" v-text="$t('generic.buttons.continue_booking')"></a>
    </div>
  </form>
</template>
<script>
import Form from "form-backend-validation";
import Calendar from "../../common/booking/Calendar.vue";
import ProductSelect from "./ProductSelect.vue";

export default {
  inject: ["config", "bus"],
  components: { ProductSelect, Calendar },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      productVariants: [],
      form: new Form(
        {
          venue_id: 1,
          date: null,
          product_id: null,
          category_id: null,
          product_variant_id: null,
          quantity: 1,
          appointment_ids: [],
        },
        { resetOnSuccess: false },
      ),
      tabs: [],
    };
  },
  created() {
    this.bus.$on("tabChanged", (tab) => {
      this.form.product_id = null;
      this.form.product_variant_id = null;
      this.form.category_id = tab.id;
    });
  },
  mounted() {
    this.initiate();
  },
  computed: {
    maxQuantity() {
      let rootCategory = this.tabs.find((o) => o.id === this.form.category_id);
      if (!rootCategory) {
        rootCategory = this.tabs[0];
      }

      if (rootCategory.name === "Massage") {
        return parseInt(this.appConfigs.max_massage_slot);
      }

      return parseInt(this.appConfigs.max_bathrooms_slot);
    },
    quantityOptions() {
      return new Array(this.maxQuantity || 1).fill(null).map((_, index) => ({
        id: index + 1,
        label: index + 1,
      }));
    },
  },
  methods: {
    initiate() {
      axios.get("/api/generic/product-categories?filter[parent]=true").then(({ data }) => {
        this.tabs = data.data.map((o) => {
          o.active = false;

          return o;
        });

        if (!this.id && this.tabs.length) {
          this.tabs[0].active = true;
          this.form.category_id = this.tabs[0].id;
        }

        if (this.id) {
          axios.get(`/api/admin/bookings/quick/${this.id}`).then(({ data }) => {
            this.form.venue_id = data.venue_id;
            this.selectProduct(data.product);
            this.selectProductVariant(null, data.product_variant);
            this.form.quantity = data.quantity;
            this.form.date = data.date;
            this.form.appointment_ids = data.appointment_ids;
            this.form.category_id = data.category_id;
            if (this.tabs.length && this.form.category_id) {
              this.tabs.find((o) => o.id === this.form.category_id).active = true;
              this.tabs
                .filter((o) => o.id !== this.form.category_id)
                .forEach((o) => {
                  o.active = false;
                });
            }
          });
        }
      });
    },
    selectProductVariant(value, variant) {
      this.form.product_variant_id = variant.id;
    },
    selectProduct(product) {
      this.productVariants = product && product.hasOwnProperty("variants") ? product.variants.items : [];
      if (product && product.hasOwnProperty("product_variants")) {
        this.productVariants = product.product_variants;
      }

      if (product) {
        this.form.product_id = product.id;
      }

      if (this.productVariants.length) {
        this.form.product_variant_id = this.productVariants[0].id;
      }
    },
    book() {
      this.form.post("/api/admin/bookings/quick").then((res) => {
        this.bus.$emit("openSidePanel", {
          componentName: "QuickBookingDetails",
          componentData: res,
          closeCallback: () => {},
        });
      });
    },
  },
};
</script>
