<template>
  <div v-show="variants.length > 1" class="space-y-2 rounded-xl border border-white p-3">
    <p class="mb-mdSpace text-sm leading-normal text-white">
      <span v-if="variants.length">
        {{ $t("booking.headings.select_product_variants").replace(":variant", variants.length) }}
      </span>
      <span v-else>
        {{ $t("booking.headings.no_available_packages") }}
      </span>
    </p>
    <div class="space-y-smSpace">
      <div
        v-for="(variant, index) in variants"
        class="flex cursor-pointer flex-col rounded-xl p-mdSpace"
        :class="{
          'bg-white/10 text-white': !modelValue || modelValue !== variant.id,
          'bg-white': modelValue && modelValue === variant.id,
        }"
        @click="selectVariant(variant.id)"
      >
        <div :class="modelValue === variant.id ? 'text-brand' : 'text-white'">
          <div v-if="variant.name.split(':').length > 1">
            <p class="block text-lg leading-4">
              {{ variant.name.split(":")[0] }}
            </p>
            <p class="block text-lg font-semibold leading-6">
              {{ variant.name.split(":")[1] }}
              <span class="font-semibold">(${{ variant.price_per_unit }})</span>
            </p>
          </div>
          <p v-else class="block text-lg font-semibold leading-6">
            {{ variant.name }} <span class="font-semibold">(${{ variant.price_per_unit }})</span>
          </p>
        </div>
        <p
          class="mt-2xsSpace text-base"
          :class="modelValue === variant.id ? 'text-neutral-700' : 'text-white'"
          v-text="variant.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VariantList",
  inject: ["notificationService"],
  props: {
    productId: {
      required: true,
    },
    modelValue: {
      required: true,
    },
    forAddOn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      variants: [],
    };
  },
  watch: {
    productId: {
      handler(newValue, oldValue) {
        if (!newValue) {
          this.variants = [];
          this.$emit("update:modelValue", null);
        } else {
          this.getVariants();
        }
      },
    },
  },
  mounted() {
    this.getVariants();
  },

  methods: {
    selectVariant(id) {
      this.$emit("update:modelValue", id);
    },
    getVariants() {
      this.loading = true;
      axios.get(`/api/booking/products/${this.productId}/product-variants`).then(({ data }) => {
        this.variants = data.data;
        if (this.variants.length === 0) {
          this.notificationService.notify(this.$t("booking.responses.no_available_variants"), "error");
          return;
        }

        if (this.forAddOn) {
          this.variants = this.variants.filter((o) => !this.appConfigs.exclude_add_on_variants.includes(o.id));
        }

        if (!this.modelValue && this.variants.length) {
          this.selectVariant(this.variants[0].id);
        }

        this.loading = false;
      });
    },
  },
};
</script>
