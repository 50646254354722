<template>
  <div class="global-search relative w-96 rounded-md shadow-sm" v-click-outside="onClickOutside">
    <input type="text" v-model="term" :placeholder="$t('generic.words.input_search')" @input="onChangeInput" />
    <div
      v-if="display"
      class="absolute z-50 mt-0.5 flex h-[300px] max-h-[500px] w-full flex-col rounded border md:h-[70vh]"
    >
      <div v-for="(items, key) in results" :key="key" class="flex w-full flex-1 flex-col overflow-hidden bg-white">
        <div class="bg-gray-200 px-mdSpace py-1 text-sm font-bold">{{ $t("generic.words." + key) }}</div>
        <div
          v-if="(key === 'customers' && loadingCustomers) || (key === 'vouchers' && loadingVouchers)"
          aria-label="Loading..."
          role="status"
          class="flex flex-1 items-center justify-center space-x-2"
        >
          <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
          <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
        </div>
        <div v-else-if="items.length" class="flex-1 overflow-auto">
          <div
            v-for="item in items"
            class="flex w-full cursor-pointer items-center gap-smSpace border-b border-gray-200 bg-white px-mdSpace py-2xsSpace hover:bg-blue-200"
            @click="selectItem(item)"
          >
            <div
              v-if="item.logo"
              class="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-50"
            >
              <span class="font-medium text-gray-600 dark:text-gray-300">
                {{ item.heading.charAt(0).toUpperCase() + item.heading.charAt(1).toUpperCase() }}
              </span>
            </div>
            <div>
              <h3 class="text-base font-semibold">{{ item.heading }}</h3>
              <div class="flex flex-col gap-2xsSpace">
                <template v-for="line in item.lines">
                  <p v-if="line.value" class="flex items-center gap-xsSpace text-xs">
                    <v-icon v-if="line.icon" :icon="line.icon"></v-icon>
                    <span>{{ line.value }}</span>
                  </p>
                  <component v-else-if="line.component" :is="line.component" v-bind="line.data" />
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex flex-1 items-center justify-center">
          <p class="text-sm text-gray-500">{{ $t("generic.not_found") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import axios from "axios";

export default {
  name: "VGlobalSearch",
  inject: ["bus", "config"],
  components: {},
  props: {},
  computed: {},
  data() {
    return {
      term: "",
      results: {
        customers: [],
        vouchers: [],
      },
      display: false,
      loadingCustomers: false,
      loadingVouchers: false,
      onChangeInput: debounce(this.updateInput, 300),
    };
  },
  watch: {
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    updateInput(event) {
      this.search(event.target.value);
    },
    search(value) {
      const params = {
        [`filter[term]`]: value,
      };
      const handleResults = (key) => (response) => {
        this.results[key] = response.data;
        if (key === "customers") this.loadingCustomers = false;
        if (key === "vouchers") this.loadingVouchers = false;
      };

      this.loadingCustomers = true;
      this.loadingVouchers = true;
      this.display = true;

      Promise.all([
        axios.get(`/api/admin/quick-search/customers`, { params }).then(handleResults("customers")),
        axios.get(`/api/admin/quick-search/gift-voucher-instance`, { params }).then(handleResults("vouchers")),
      ])
        .catch(console.error)
        .finally(() => {
          this.loadingCustomers = false;
          this.loadingVouchers = false;
        });
    },
    onClickOutside() {
      this.display = false;
      this.results = {
        customers: [],
        vouchers: [],
      };
      this.term = "";
      this.loadingCustomers = false;
      this.loadingVouchers = false;
    },
    selectItem(item) {
      if (item.actions.hasOwnProperty("component")) {
        this.bus.$emit("openSidePanel", {
          componentName: item.actions.component,
          componentData: item.actions.componentData,
          closeCallback: this.callback,
        });
        return;
      }

      window.location.href = item.actions.link;
    },
  },
};
</script>
