<template>
  <div class="!mb-2xlSpace space-y-smSpace">
    <vuetable
      ref="vuetable"
      :api-url="getUrl"
      :append-params="filters"
      :fields="fields"
      :multi-sort="true"
      :show-sort-icons="true"
      :load-on-start="loadOnStart"
      :sort-params="getSortParam"
      :sort-order="defaultSortOrder"
      :is-draggable="isDraggable"
      :drag-api="dragApi"
      pagination-path="meta"
      :row-class="rowClass"
      @vuetable:loading="loading = true"
      @vuetable:loaded="loading = false"
      @vuetable:pagination-data="onPaginationData"
      @vuetable:row-clicked="onRowClicked"
    >
      <template v-for="(index, name) in $slots" #[name]="data">
        <slot :data="data" :name="name" />
      </template>
      <template #actions="props">
        <v-table-actions
          :key="`${props.rowIndex} + ${Math.floor(Math.random() * 100)}`"
          :data="{ ...props }"
          :has-sibling-title-component="fields.some((field) => field.name === '__component:v-table-title')"
        />
      </template>
    </vuetable>
    <vuetable-pagination-info
      v-show="!hidePagination"
      ref="paginationInfoEnd"
      :info-template="paginationInfoTemplate"
      class="text-sm"
    ></vuetable-pagination-info>
    <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage" />
  </div>
</template>

<script>
import VTableActions from "./VTableActions.vue";
import Vuetable from "@dcodegroup-au/vuetable-3/src/components/Vuetable.vue";
import VuetablePagination from "@dcodegroup-au/vuetable-3/src/components/VuetablePagination.vue";
import VuetablePaginationInfo from "@dcodegroup-au/vuetable-3/src/components/VuetablePaginationInfo.vue";

export default {
  name: "VTable",
  inject: ["bus"],
  components: {
    VTableActions,
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  props: {
    getUrl: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    loadOnStart: {
      type: Boolean,
      default: true,
    },
    defaultSortOrder: {
      type: Array,
      default() {
        return [];
      },
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    dragApi: {
      type: String,
      default: "",
    },
    rowClickable: {
      type: Boolean,
      default: false,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: this.loadOnStart,
      filters: {
        "filter[term]": null,
      },
      paginationInfoTemplate: `<p class="text-xs text-neutral-500">Showing {from} to {to} of  {total}</p>`,
      rowClass: this.rowClickable ? "cursor-pointer hover:bg-slate-50" : "",
    };
  },
  beforeUnmount() {
    this.bus.$off("tableFilterChange");
    this.bus.$off("reloadTable");
    this.bus.$off("termChanged");
  },
  created() {
    this.bus.$on("reloadTable", () => {
      this.$nextTick(() => this.getTableRef().reload());
    });

    this.bus.$on("tableFilterChange", ({ params }) => {
      this.filters = Object.assign({}, params, {
        "filter[term]": this.filters["filter[term]"],
      });
      this.$nextTick(() => {
        if (this.getTableRef()) {
          this.getTableRef().refresh();
        }
      });
    });

    this.bus.$on("termChanged", ({ term, name }) => {
      this.filters[`filter[${name}]`] = term;
      if (this.getTableRef()) {
        this.getTableRef().refresh();
      }
    });
  },
  mounted() {
    const thEls = Array.from(document.querySelectorAll(".vuetable thead tr th"));
    this.fields.forEach((field, index) => {
      thEls[index].style = `${field.style || ""}`;
    });
  },
  watch: {
    loading(newValue) {
      this.$emit("loading", newValue);
    },
  },
  methods: {
    onChangePage(page) {
      this.getTableRef().changePage(page);
    },
    onPaginationData(paginationData) {
      this.$refs.paginationInfoEnd.setPaginationData(paginationData);
      this.$refs.pagination.setPaginationData(paginationData);
    },
    getSortParam: function (sortOrder) {
      return sortOrder
        .map(function (sort) {
          return (sort.direction === "desc" ? "-" : "") + sort.sortField;
        })
        .join(",");
    },
    getTableRef() {
      return this.$refs.vuetable;
    },
    isCustomPanelColumn(data, event) {
      let field = event?.target.classList.length ? event?.target.classList[0] : null;
      if (field && data.hasOwnProperty(field)) {
        if (data[field].hasOwnProperty("panel")) {
          return true;
        }
      }

      if (event.target?.offsetParent) {
        field = event.target.offsetParent.classList.length ? event.target.offsetParent.classList[0] : null;
        if (field && data.hasOwnProperty(field) && data[field].hasOwnProperty("panel")) {
          return true;
        }
      }

      return false;
    },
    onRowClicked({ data, index, event }) {
      if (this.isCustomPanelColumn(data, event) || !this.rowClickable) {
        return;
      }

      if (
        !data.hasOwnProperty("actions") &&
        (!data.actions?.hasOwnProperty("view") || !data.actions?.hasOwnProperty("edit"))
      ) {
        return;
      }

      const action = data.actions.view || data.actions.edit;
      if (action.link) {
        window.location.href = action.link;
        return;
      }

      if (action.component && action.component === "OpenSidePanelButton") {
        this.bus.$emit("openSidePanel", {
          componentName: action.data.componentName,
          componentData: action.data.componentData,
        });
      }
    },
  },
};
</script>
