<template>
  <form
    class="flex w-full flex-col justify-between p-lgSpace"
    v-show="!loading"
    :class="{ '!pt-0': !task.hasOwnProperty('taskable') }"
  >
    <div class="grid gap-smSpace pb-2xlSpace">
      <div class="text-lg font-bold">
        <span v-if="task.related_resource">{{ $t("task.labels.task") }}: {{ task.related_resource }}</span>
      </div>
      <input-wrapper field="title" :form="form" :label-text="$t('task.labels.title')">
        <input type="text" v-model="form.title" :placeholder="$t('task.placeholders.title')" />
      </input-wrapper>
      <input-wrapper field="description" :form="form" :label-text="$t('task.labels.description')">
        <v-textarea :placeholder="$t('task.placeholders.description')" v-model="form.description"></v-textarea>
      </input-wrapper>
      <input-wrapper field="user_id" :form="form" :label-text="$t('task.labels.staff')">
        <v-multi-select
          v-model="form.user_id"
          :search-url="userFilterUrl"
          :placeholder="$t('task.placeholders.staff')"
          track-by="id"
          label="full_name"
          search-key="filter[full_name]"
        />
      </input-wrapper>
    </div>
    <v-footer :save="save">
      <a class="btn-secondary btn-sm" v-text="$t('generic.buttons.cancel')" @click.prevent="close"></a>
    </v-footer>
  </form>
  <div v-show="loading" aria-label="Loading..." role="status" class="flex h-full items-center justify-center space-x-2">
    <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
    <span class="text-lg font-medium text-tertiary-500">Loading . . .</span>
  </div>
</template>
<script>
import Form from "form-backend-validation";

export default {
  inject: ["bus", "config", "notificationService"],
  props: {
    task: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    taskable: {
      type: Object,
      required: false,
    },
    inPanel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    userFilterUrl() {
      const arrayRoleIds = [
        this.config.roleIdMapping.admin,
        this.config.roleIdMapping.manager,
        this.config.roleIdMapping.staff,
      ];
      return `/api/admin/users?filter[active]=1&filter[role_id]=${arrayRoleIds}`;
    },
  },
  data() {
    return {
      loading: false,
      form: new Form(
        {
          id: this.getFieldValue("id"),
          title: this.getFieldValue("title"),
          description: this.getFieldValue("description"),
          action: this.getFieldValue("action"),
          user_id: this.getFieldValue("user_id"),
          taskable_type: this.getFieldValue("taskable_type", this.taskable ? this.taskable.class : null),
          taskable_id: this.getFieldValue("taskable_id", this.taskable ? this.taskable.id : null),
        },
        { resetOnSuccess: false },
      ),
    };
  },
  methods: {
    getFieldValue(field, defaultValue = null) {
      if (this.task.hasOwnProperty(field)) {
        return this.task[field];
      }

      return defaultValue;
    },
    close() {
      if (this.inPanel) {
        this.bus.$emit("closePanelInPanel", true);
        return;
      }
      this.bus.$emit("closePanel");
    },
    async save() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      if (this.form.id) {
        try {
          await this.form.patch(`/api/admin/tasks/${this.form.id}`);
        } finally {
          this.loading = false;
        }
        this.notificationService.notify(this.$t("generic.responses.update_success"));
        if (!this.taskable && this.task.taskable_type !== this.config.taskTypes.custom) {
          this.bus.$emit("openSidePanel", {
            componentName: "Tasks",
            closeCallback: () => {},
          });
        } else {
          this.close();
        }

        return;
      }
      try {
        await this.form.post("/api/admin/tasks");
      } finally {
        this.loading = false;
      }
      this.notificationService.notify(this.$t("generic.responses.create_success"));
      this.close();
    },
  },
};
</script>
