<template>
  <div class="pt-mdSpace font-semibold text-white">
    <span class="text-lg" v-if="cart.total > 0">{{ $t("booking.headings.payment_method") }}</span>
    <div class="mt-smSpace">
      <square-payment ref="square" v-if="cart.total > 0" />
      <div class="space-y-4 rounded-xl bg-white/10 p-4 text-base font-normal leading-[21px] text-white2">
        <p>
          {{ $t("gift_voucher.headings.checkout_disclaimer") }}
        </p>
      </div>
      <div class="mb-2xlSpace flex justify-center pt-6">
        <button
          class="btn-secondary !block w-full items-center !bg-white !text-brand"
          @click="submit"
          :disabled="loading"
        >
          <div v-if="loading" aria-label="Loading..." role="status" class="flex items-center justify-center space-x-2">
            <v-icon icon="ArrowPathIcon" class="h-lgSpace w-lgSpace animate-spin" />
            <span>
              {{ $t("generic.words.processing") }}
            </span>
          </div>
          <span v-else>{{ $t("generic.buttons.order") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "form-backend-validation";

export default {
  name: "Payment",
  inject: ["config", "notificationService"],
  props: {
    initCart: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      form: new Form({
        payment: {
          type: this.config.PAYMENT_CARD,
          amount: null,
          token: null,
        },
      }),
      cart: this.initCart,
    };
  },
  methods: {
    async submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      try {
        if (this.cart.total > 0) {
          this.form.payment.amount = this.cart.total;
          this.form.payment.token = await this.$refs.square.submit();
        }

        await this.form.post("/api/voucher/checkout");
        window.location.href = `/voucher/confirm`;
      } catch (error) {
        const { response } = error;

        let err = "Invalid";
        if (error.hasOwnProperty("message")) {
          err = error.message;
        }
        if (response && response.hasOwnProperty("data") && response.data.hasOwnProperty("message")) {
          err = response.data.message;
        }
        this.notificationService.notify(err, "error");
      }

      this.loading = false;
    },
  },
};
</script>
