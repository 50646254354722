<template>
  <div class="relative">
    <v-badge v-for="tag in selectedTags" :type="tag.colour" class="mb-2xsSpace mr-2xsSpace">
      {{ tag.name }}
      <v-icon
        @click="onRemove(tag)"
        icon="XMarkIcon"
        classes="w-4 h-4 ml-2xsSpace -mr-2xsSpace font-bold hover:bg-tertiary-100 rounded-full hover:text-tertiary-900 cursor-pointer"
      />
    </v-badge>
    <Transition>
      <v-icon
        v-if="!active"
        @click="active = true"
        icon="PlusCircleIcon"
        classes="font-bold h-6 w-6 rounded-full text-tertiary-400 hover:text-tertiary-800 cursor-pointer inline"
      />
    </Transition>

    <Transition>
      <div
        v-if="active"
        class="absolute left-0 top-full z-50 mt-2xsSpace w-[250px] rounded bg-secondary-100 p-xsSpace shadow-lg"
        v-on:mouseleave="leave"
        v-on:mouseenter="enter"
      >
        <input
          type="text"
          v-model="text"
          :placeholder="$t('label.placeholders.search')"
          class="mb-2xsSpace"
          @input="debounceSearch"
        />
        <v-badge
          v-for="tag in localOptions"
          :type="tag.colour"
          class="mb-2xsSpace mr-2xsSpace inline-block"
          @click="onSelect(tag)"
        >
          {{ tag.name }}
          <v-icon
            icon="PlusCircleIcon"
            classes="w-4 h-4 ml-2xsSpace -mr-2xsSpace font-bold hover:bg-tertiary-100 rounded-full hover:text-tertiary-900 cursor-pointer"
          />
        </v-badge>
      </div>
    </Transition>
  </div>
</template>
<script>
import { debounce } from "lodash";

export default {
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filters: {
      type: Object,
      default: () => {
        return {
          "filter[active]": 1,
        };
      },
    },
    tags: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      active: false,
      text: null,
      debounceSearch: null,
      localOptions: this.options,
      selectedTags: this.tags,
      timeout: null,
    };
  },
  created() {
    this.debounceSearch = debounce(this.fetchTags, 200);
  },
  mounted() {
    if (!this.localOptions.length) {
      this.fetchTags();
    }
  },
  watch: {
    selectedTags: {
      handler(newValue, oldValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
  },
  methods: {
    fetchTags() {
      axios
        .get(`/api/admin/labels`, {
          params: {
            ...this.filters,
            ...(this.text ? { "filter[name]": this.text } : {}),
          },
        })
        .then(({ data }) => {
          this.localOptions = data.data.filter((o) => {
            return !this.selectedTags
              .map((o) => {
                return o.id;
              })
              .includes(o.id);
          });
        });
    },
    enter() {
      clearTimeout(this.timeout);
    },
    leave() {
      this.timeout = setTimeout(() => {
        this.active = false;
      }, 1000);
    },
    onSelect(tag) {
      this.selectedTags.push(tag);

      this.localOptions = Object.assign(
        [],
        this.localOptions.filter((o) => {
          return o.id !== tag.id;
        }),
      );
    },
    onRemove(tag) {
      this.selectedTags.splice(this.selectedTags.indexOf(tag), 1);
      this.localOptions.push(tag);
    },
  },
};
</script>
